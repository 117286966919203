import Modal from '@rd-web-markets/shared/dist/util/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card } from 'react-bootstrap';

import { Loader } from '@rd-web-markets/shared/dist/util/Loader';
import reportTemplateService from '@services/report_template.service';
import { getReportTemplateOptions } from '@rd-web-markets/shared/dist/util/tinyMceConfig';
import reportTemplateCategoryService from '@services/report_templates/report_template_category.service';
import { EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS, EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS } from '@rd-web-markets/market/dist/constants';
import ReviewableFieldEditorWithAutomaticUpdates from '@rd-web-markets/shared/dist/util/textEdit/ReviewableFieldEditorWithAutomaticUpdates';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { useSelector } from 'react-redux';
import OnlyCommentsEditor from '@rd-web-markets/shared/dist/util/textEdit/OnlyCommentsEditor';

export default function EditReportTemplateCategoryModal({
  reportTemplateId,
  reportTemplateCategoryId = null,
  onHide,
  onContentChanged,
  isVisible,
  title,
  isUnderReview=false,
  footnoteStartIndex = 0
}) {
  const [rndReportTemplate, setRndReportTemplate] = useState(null);
  const [reportTemplateCategory, setReportTemplateCategory] = useState(null);
  const [closeButtonLoading, setCloseButtonLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const showTrackChangesControls = user.account_type !== 'customer'

  const updateReportTemplateCagegory = useErrorHandling(useCallback(async updatedValue => {
    const updatedReportTemplateCategory = await reportTemplateCategoryService.update(
      reportTemplateId, {
        ...reportTemplateCategory,
        ...updatedValue
      }
    )

    setReportTemplateCategory({ ...updatedReportTemplateCategory })

    return updatedReportTemplateCategory
  }, [reportTemplateCategoryService, reportTemplateCategory, reportTemplateId]))

  const onStylesFormatterToggled = async should_apply_styles => {
    const updatedReportTemplateCategory = await updateReportTemplateCagegory({ should_apply_styles })
    return updatedReportTemplateCategory.should_apply_styles
  }

  const getCommonEditorModulesSettings = reportTemplateCategory => ({
    stylesFormatterToggleIniitialValue: reportTemplateCategory?.should_apply_styles,
    onStylesFormatterToggled,
    footnoteStartIndex
  })

  const fetchData = useErrorHandling(useCallback(async () => {
    const rndReportTemplate = await reportTemplateService.get(reportTemplateId)
    setRndReportTemplate(rndReportTemplate)

    const reportTemplateCategory = await reportTemplateCategoryService.get(reportTemplateId, reportTemplateCategoryId)
    setReportTemplateCategory(reportTemplateCategory)
  }, [reportTemplateCategoryId, reportTemplateId]))

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  const updateContentFn = useCallback(async (content) => {
    const updatedReportTemplateCategory = await reportTemplateCategoryService.update(
      reportTemplateId, {
        ...reportTemplateCategory,
        content
      }
    );

    return updatedReportTemplateCategory.content
  }, [reportTemplateCategory, reportTemplateId])

  const getContentFn = useCallback(async () => {
    const rndReportTemplate = await reportTemplateService.get(reportTemplateId)
    setRndReportTemplate(rndReportTemplate)

    const reportTemplateCategory = await reportTemplateCategoryService.get(reportTemplateId, reportTemplateCategoryId)
    setReportTemplateCategory(reportTemplateCategory)

    return reportTemplateCategory.content
  }, [reportTemplateCategoryId, reportTemplateId])

  const onUpdateContentFn = useCallback(() => {
    setCloseButtonLoading(false)
  }, [])

  const editorInitConfig = useMemo(() => {
    if (!rndReportTemplate) {
      return {}
    }

    return getReportTemplateOptions(
      rndReportTemplate,
      EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS,
      EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS,
      {
        ...getCommonEditorModulesSettings(reportTemplateCategory)
      }
    )
  }, [rndReportTemplate, reportTemplateCategory])

  const trackChangesEditorInitFn = useCallback((addCommentCallback) => {
    if (!rndReportTemplate) {
      return {}
    }

    return getReportTemplateOptions(
      rndReportTemplate,
      EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS,
      EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS,
      {
        isTrackingChanges: true,
        onAddCommentButtonClickCallback: addCommentCallback,
        showTrackChangesControls: showTrackChangesControls,
        ...getCommonEditorModulesSettings(reportTemplateCategory)
      }
    )
  }, [rndReportTemplate, showTrackChangesControls, reportTemplateCategory])

  const onTrackChangesCreateComment = useCallback(() => {
    setCloseButtonLoading(true)
  }, [])

  const onEditorChange = useCallback(() => {
    setCloseButtonLoading(true)
  }, [])

  const editReportTemplateCategoryModalBody = () => {
    if (!rndReportTemplate || !reportTemplateCategory) {
      return <Loader />
    }

    return (
      <Card>
        <Card.Body>
          { user.account_type === 'customer' && !rndReportTemplate?.claim_group.active_change_set?.finalized_at &&
            <OnlyCommentsEditor
              getContentFn={getContentFn}
              updateContentFn={updateContentFn}
              modelClass='ReportTemplateCategory'
              modelId={reportTemplateCategory.id}
              modelField={'content'}
            />
          }

          { user.account_type === 'customer' && !!rndReportTemplate?.claim_group.active_change_set?.finalized_at &&
            <div dangerouslySetInnerHTML={{ __html: reportTemplateCategory.content }}></div>
          }

          { user.account_type !== 'customer' &&
            <ReviewableFieldEditorWithAutomaticUpdates
              isUnderReview={isUnderReview}
              modelClass='ReportTemplateCategory'
              modelId={reportTemplateCategory.id}
              modelField={'content'}
              getContentFn={getContentFn}
              updateContentFn={updateContentFn}
              onUpdateContentFn={onUpdateContentFn}
              editorInitConfig={editorInitConfig}
              trackChangesOnCreateComment={onTrackChangesCreateComment}
              trackChangesEditoInitFn={trackChangesEditorInitFn}
              onEditorChange={onEditorChange}
              showTrackChangesControls={showTrackChangesControls}
            />
          }
        </Card.Body>
      </Card>
    );
  };

  return (
    <Modal
      size="xl"
      title={title || 'Edit text'}
      show={isVisible}
      renderBody={editReportTemplateCategoryModalBody}
      onHide={onContentChanged}
      closeButtonLoading={closeButtonLoading}
      disabled={closeButtonLoading}
      closeText='Save'
    />
  );
}
