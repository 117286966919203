import React, {
  useState
} from 'react';

import {
  Form,
  Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next'

import { DatePicker } from '@rd-web-markets/shared/dist/util/date';
import TextInputGroup from '@components/util/TextInputGroup';
import { FormattedNumberInput } from '@rd-web-markets/shared/dist/util';
import Modal from '@rd-web-markets/shared/dist/util/Modal';
import { CURRENCY_SYMBOL, LOCALE, RATES_SCHEME } from '@rd-web-markets/market/dist/constants';

export default function RateModal({
  title,
  editDate = null,
  editRate = null,
  isPercentage = true,
  editRateType = null,
  onHide,
  onSubmit,
  show
}) {
  const [startDate, setStartDate] = useState(editDate);
  const [rate, setRate] = useState(editRate || null);
  const [rateType, setRateType] = useState(editRateType || null);
  const { t } = useTranslation();

  const currencyFormatter = new Intl.NumberFormat(LOCALE, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const rateModalBody  = () => {
    return (
      <Form>
        <Form.Group>
          <Form.Row md={2}>
            <Form.Label column="md" md={3}>
            {t('start_date')}
            </Form.Label>
            <Col>
              <TextInputGroup placeholder={t('start_date')} prependIcon="calendar_month">
                <DatePicker
                  className="border-0"
                  selected={startDate}
                  onChange={date => { setStartDate(date) }}
                />
              </TextInputGroup>
            </Col>
          </Form.Row>
          <br />
          {RATES_SCHEME[title].map((rateFieled, index) => {
            return (
              <Form.Row key={index}>
                <Form.Label column md={3}>
                  {t(rateFieled)}
                </Form.Label>
                <Col>
                  <FormattedNumberInput
                    defaultValue={editRate && (isPercentage ? editRate[rateFieled] : currencyFormatter.format(editRate[rateFieled]))}
                    value={rate && rate[rateFieled]}
                    placeholder="Rate"
                    prependText={isPercentage ? '%' : CURRENCY_SYMBOL}
                    onChange={e => setRate({ ...rate, [rateFieled]: e.target.value})}
                  />
                </Col>
              </Form.Row>
            )
          })}
          {
            rateType &&
            <>
              <br />
              <Form.Row>
                <Form.Label column md={3}>
                  Type
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={rateType}
                    onChange={e => setRateType(e.target.value)}
                    size="md"
                  >
                    <option value="sme">SME</option>
                    <option value="rdec">RDEC</option>
                  </Form.Control>
                </Col>
              </Form.Row>
            </>
          }
        </Form.Group>
      </Form>
    );
  }

  return (
    <Modal
      size='sm'
      title={title}
      show={show}
      renderBody={ () => rateModalBody() }
      onHide={onHide}
      onSubmit={() => onSubmit(startDate, rate, rateType)}
    />
  );
}
