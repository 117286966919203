import React from 'react'
import { Toast as RBToast } from 'react-bootstrap'

export default function Toast ({ message, variant = 'success', show, onClose }) {
  const textColor = variant === 'light' ? 'secondary' : 'white';

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      right: 0,
      zIndex: 9999,
      width: '100%'
    }}>
      <RBToast
        className={`bg-${variant} text-${textColor}`}
        show={show}
        onClose={onClose}
        delay={2000}
        autohide
      >
        <RBToast.Body>{message}</RBToast.Body>
      </RBToast>
    </div>
  )
}
