import { Table } from '@rd-web-markets/shared/dist/util';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { makeKey } from '@rd-web-markets/shared/dist/hooks/useConvertForTranslationKey';

const dateToLocale = (date) => {
  return createTimezonedDate(new Date(date)).toCustomLocaleTimeString();
};

const sortByDate = (a, b) => {
  if (a.created_at < b.created_at) {
    return 1;
  }
  if (a.created_at > b.created_at) {
    return -1;
  }
  return 0;
};

const downloadLinkOrPendingStatus = (snapshot) => {
  if (snapshot.rnd_report.pdf_generation_status !== 'success') {
    return <div>Please wait while PDF is being generated</div>;
  } else {
    return (
      <small>
        <a
          href={`/api/report_templates/${snapshot.rnd_report.report_template_id}/rnd_reports/${snapshot.rnd_report.id}`}
          target='_blank'
          rel='noreferrer'
          download
        >
          <span className='fa fa-download'></span> Download
        </a>
      </small>
    );
  }
};

const status = (snapshotStatus, reviewType, t) => {
  const statusInfo = {
    consultant_review: {
      approved: { text: 'Accepted all outstanding changes and closed the review', icon: 'done' },
      rejected: { text: 'Rejected changes and closed review', icon: 'close' },
      finalized: { text: 'Completed Their Manager Review', icon: 'zoom_in' },
      started: { text: 'Started Manager QA Review', icon: 'engineering' },
    },
    client_review: {
      approved: { text: 'Accepted all outstanding changes and closed the review', icon: 'done' },
      rejected: { text: 'Rejected changes and closed review', icon: 'close' },
      finalized: { text: 'Client Review Completed by Manager/Consultant', icon: 'zoom_in' },
      started: { text: 'Started Client QA Review', icon: 'engineering' },
    },
  };
  return (
    <small>
      <span className='material-icons'>{statusInfo[reviewType][snapshotStatus].icon}</span> {t(makeKey(statusInfo[reviewType][snapshotStatus].text))}
    </small>
  );
};

const ObjectChangeSetsListWithSnapshots = ({ object, reviewType, resetCompanyAndClaimGroup }) => {
  const [intervalId, setIntervalId] = useState(null);
  const { t } = useTranslation();

  const everyReportIsSuccess = object.change_sets.every((c) =>
    c.report_snapshots.every((s) => s.rnd_report.pdf_generation_status === 'success')
  );

  useEffect(() => {
    if (everyReportIsSuccess) {
      resetCompanyAndClaimGroup();
      if (intervalId) {
        window.clearInterval(intervalId);
        setIntervalId(null);
      }
    } else {
      if (!intervalId) {
        const intervalId = window.setInterval(() => {
          resetCompanyAndClaimGroup();
        }, 2000);
        setIntervalId(intervalId);
      }
    }

    // clear any left interval on component dismount
    return () => window.clearInterval(intervalId);
  }, [everyReportIsSuccess, intervalId, resetCompanyAndClaimGroup]);

  const rows = object.change_sets
    .filter((changeSet) => changeSet.review_type === reviewType)
    .sort(sortByDate)
    .map((changeSet) => {
      return changeSet.report_snapshots.sort(sortByDate).map((snapshot) => {
        return {
          columns: [
            dateToLocale(snapshot.status_datetime),
            changeSet.author.name,
            status(snapshot.status, reviewType, t),
            downloadLinkOrPendingStatus(snapshot),
          ],
        };
      });
    })
    .flat();

  return (
    <div>
      <Row className='scrollable' style={{ height: '220px' }}>
        <Col md={12}>
          <Table className='table-scrollable-x' headers={[]} rows={rows} showControls={false} />
        </Col>
      </Row>
    </div>
  );
};

export default ObjectChangeSetsListWithSnapshots;
