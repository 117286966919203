import Modal from '@rd-web-markets/shared/dist/util/Modal';
import React, { useState } from 'react'
import EmailPreviewModalPreview from './EmailPreviewModalPreview';

export default function EmailPreviewModalSimple({ show, claimGroup, template, onHide }) {

  const modalBody = () => {
    return <EmailPreviewModalPreview claimGroup={claimGroup} template={template} />
  }
  return (
    <>
      <Modal
        size="lg"
        title={'Preview past sent email'}
        show={show}
        renderBody={modalBody}
        onHide={() => onHide()}
        closeText='Close'
      />
    </>
  );
}
