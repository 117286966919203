import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Loading, Modal } from '@rd-web-markets/shared/dist/util';
import { AddButton } from '@rd-web-markets/shared/dist/util/buttons';
import claimGroupQuestionnairesService from '@services/claim_groups/questionnaires.service';
import questionnairesService from '@services/questionnaires.service';
import userAccessProjectParticipationsService from '@rd-web-markets/shared/dist/services/user_access_project_participations.service';

const SetUpQuestionnaireModal = ({
  user,
  claimProjectReports,
  claimGroup,
  show,
  title,
  onHide,
  submitText
}) => {
  const { t } = useTranslation();

  const [questionnaire, setQuestionnaire] = useState({
    name: '',
    questionnaire_id: claimGroup.questionnaire_id || null,
    claim_project_report_ids: [],
    assignee_ids: []
  });

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [questionnaires] = questionnairesService.useQuestionnaires();
  const saveQuestionnaire = claimGroupQuestionnairesService.useSaveQuestionnaire(claimGroup.id, questionnaire);

  const questionnairesForSelect = questionnaires?.map(q => ({ value: q.id, label: q.name }));
  const reportsForSelect = claimProjectReports.map(report => ({ value: report.id, label: report.project_name }));
  const fetchedUsers = userAccessProjectParticipationsService.useParticipationsByReportIds(claimProjectReports.map(report => report.id));

  useEffect(() => {
    const users = fetchedUsers
      .filter((fetchedUser) => questionnaire.claim_project_report_ids.find((reportId) => reportId === fetchedUser.reportId))
      .map((fetchedUser) => JSON.stringify({ value: fetchedUser.value, label: fetchedUser.label }));
    if (users.length > 0) {
      setUsers([...new Set(users)].map(JSON.parse));
    } else {
      setUsers([{ value: null, label: t('first_select_a_project'), isDisabled: true }]);
    }
  }, [fetchedUsers, questionnaire.claim_project_report_ids, t]);

  const onSubmit = () => {
    saveQuestionnaire(questionnaire);
    onHide();
  }

  const setUpQuestionnaireModalBody = 
    <>
      <Form.Group as={Row}>
        <Form.Label column='md' md={3}>
          {t('name')}
        </Form.Label>
        <Col md={5}>
          <Form.Control
            onChange={(e) => setQuestionnaire({ ...questionnaire, name: e.target.value })}
            name='name'
            value={questionnaire.name}
            size='md'
            type='text'
            placeholder={t('please_add_a_name_for_this_questionnaire')}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column='md' md={3}>
          {t('template')}
        </Form.Label>
        <Col md={5}>
          <Form.Control
            as={Select}
            onChange={(e) => setQuestionnaire({ ...questionnaire, questionnaire_id: e.value })}
            name='template_id'
            value={questionnairesForSelect?.find(q => q.value === questionnaire.questionnaire_id)}
            options={questionnairesForSelect}
            size='md'
            className='p-0 border-0'
            styles={{
              control: (baseStyles) => ({ ...baseStyles, borderColor: '#80bbdc' })
            }}
          />
        </Col>
        <Col md={4}>
          <Row>
            <Form.Label column='md' md={2}>
              {t('or')}
            </Form.Label>
            <Form.Label column='md' md={8}>
              <Link to={`/${user.account_type}/questionnaires/create`}>
                {t('create_new')}
              </Link>
            </Form.Label>
          </Row>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column='md' md={3}>
          {t('projects')}
        </Form.Label>
        <Col md={9}>
          <Form.Control
            as={Select}
            onChange={(selectedReports) => setQuestionnaire({
              ...questionnaire,
              claim_project_report_ids: selectedReports.map(report => report.value)
            })}
            name='claim_project_report_ids'
            closeMenuOnSelect={false}
            isMulti
            options={reportsForSelect}
            size='md'
            className='p-0 border-0'
            styles={{
              control: (baseStyles) => ({...baseStyles, borderColor: '#80bbdc' })
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column='md' md={3}>
          {t('assign_contacts')}
        </Form.Label>
        <Col md={9}>
          <Form.Control
            as={Select}
            onChange={(selectedUsers) => {
              setSelectedUsers(selectedUsers);
              setQuestionnaire({
                ...questionnaire,
                assignee_ids: selectedUsers.map(user => user.value)
              });
            }}
            name='assignee_ids'
            closeMenuOnSelect={false}
            isMulti
            options={users}
            value={selectedUsers}
            size='md'
            className='p-0 border-0'
            styles={{
              control: (baseStyles) => ({...baseStyles, borderColor: '#80bbdc' })
            }}
          />
        </Col>
      </Form.Group>
    </>;
  
  const renderFooterLeftSide = useCallback(() => {
    return (
      <AddButton
        style={{marginRight: 'auto'}}
        text={t('add_engagement_team_contacts')}
        onClick={() => {
          setSelectedUsers(users.filter(u => !u.isDisabled));
          setQuestionnaire({
            ...questionnaire,
            assignee_ids: users.filter(u => !u.isDisabled).map(user => user.value)
          });
        }}
      />
    );
  }, [users]);

  if (!users) return <Loading />

  return (
    <Modal
      show={show}
      size='lg'
      title={title}
      onSubmit={onSubmit}
      onHide={onHide}
      renderBody={() => setUpQuestionnaireModalBody}
      submitText={submitText}
      renderFooterLeftSide={renderFooterLeftSide}
    />
  );
};

export default SetUpQuestionnaireModal;
