import React, { useEffect, useState } from 'react';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import TemplateForm from '@components/shared/TemplateForm';
import { useHistory } from 'react-router-dom';
import clientCostTemplateService from '@services/client_cost_template.service';
import MasterSidebar from '@components/util/MasterSidebar';

const ClientCostTemplatePage = ({ handleToaster, accountType }) => {
  const [templates, setTemplates] = useState([]);
  const [createdTemplate, setCreatedTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await clientCostTemplateService.all();
        setTemplates([{ id: 0, title: 'Create New' }, ...response]);
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [createdTemplate, dispatch]);

  const handleSubmit = async (template) => {
    setLoading(true);
    try {
      if(template.id === 0) {
        await clientCostTemplateService.create(template);
        window.location.reload();
      }
      else {
        await clientCostTemplateService.update(template.id, template);
        window.location.reload();
      }
      handleToaster('Template created');
    } catch(error) {
        dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleRemoveTemplate = async (template) => {
    setLoading(true);
    try {
      await clientCostTemplateService.delete(template.id);
      const templates = await clientCostTemplateService.all();
      setTemplates([{ id: 0, title: 'Create New' }, ...templates]);
    } catch (error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  }

  const handleBack = () => {
    history.replace('/admin');
  }

  if (!templates) return <Loading />;

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='client_cost_template' />

      <TemplateForm
        handleSubmit={handleSubmit}
        templates={templates}
        templateType="client_cost_template"
        loading={loading}
        createdTemplate={createdTemplate}
        handleBack={handleBack}
        handleRemoveTemplate={handleRemoveTemplate}
      />
    </>
  );
};

export default ClientCostTemplatePage;
