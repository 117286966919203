import React from 'react'
import Modal from '@rd-web-markets/shared/dist/util/Modal';
import FileUpload from '@rd-web-markets/shared/dist/util/FileUpload';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';



const NotesModal = ({setNewNote, newNote, setSelectedImage, createNote, setModalShow, modalShow, modalMode, selectedImage}) => {
  const { t } = useTranslation();
  const NOTE_TYPES = [t('feature_with_image'), t('feature_without_image'), t('bug')];

  const modalBody = () => {
    return (
      <Card className='mt-3'>
        <Card.Body>
          <p className='font-weight-bold'>{t('note_type')}</p>
          <Form.Control
            value={NOTE_TYPES[newNote.note_type]}
            name='new_note_type'
            size='md'
            type='text'
            disabled
          />
        </Card.Body>
        <Card.Body>
          <p className='font-weight-bold'>{t('enter_a_note_title')}</p>
          <Form.Control
            value={newNote.title}
            name='new_note_title'
            onChange={(e) => setNewNote({ ...newNote, title: e.target.value })}
            size='md'
            type='text'
            placeholder={t('add_a_note_title')}
          />
        </Card.Body>
        {newNote.note_type === 0 && (
          <Card.Body>
            <FileUpload
              title={t('add_image')}
              file={selectedImage}
              onSelectFile={setSelectedImage}
            />
          </Card.Body>
        )}
      </Card>
    );
  }

  return (
    <Modal
      size='lg'
      title={t(modalMode === 'create' ? 'add_a_note' : 'update_note')}
      show={modalShow}
      renderBody={() => modalBody()}
      onHide={() => setModalShow(false)}
      onSubmit={() => createNote(modalMode)}
      submitText={t(modalMode === 'create' ? 'add_a_note' : 'update_note')}
    />
  )
}

export default NotesModal