import React from 'react';
import background from './images/banner.jpg';

const BackgroundImage = () => {
  return (
    <style>
      {`
        body {
          background-image: url(${background});
          background-position: center;
          background-size: cover;
          height: 100%;
          background-repeat: no-repeat;
        }
      `}
      </style>
  )
}

export default BackgroundImage