import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import { useTranslation } from 'react-i18next';

const ClaimEditBreadcrumbs = ({claim}) => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  return (
    <BreadcrumbsPortal>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies`}}>Companies</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claim.company.id}/master`}}>{claim.company.name}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claim.company.id}/${claim.claim_group.id}/master`}}>{claim.claim_group.name}</Breadcrumb.Item>
        <Breadcrumb.Item active>Edit Claim</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>
        
  )
}

export default ClaimEditBreadcrumbs
