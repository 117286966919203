import React from 'react'
import ClaimGroupTechnicalNarrativeTemplateLink from './ClaimGroupTechnicalNarrativeTemplateLink';
import ClaimCostTemplateLinks from '@rd-web-markets/market/dist/email_template/email_preview/ClaimCostTemplateLinks';

export default function EmailPreviewModalPreview({ claimGroup, template }) {
  const claimGroupTechnicalNarrativeTemplateLink = <ClaimGroupTechnicalNarrativeTemplateLink claimGroup={claimGroup} />
  return (
    <>
      <h4 className="pb-3">Email: </h4>
        <h5 className='mb-3 mt-4'>{template.subject}</h5>
        <div className='d-flex justify-content-start mb-3'>
          {(template.attach_cost_template || template.include_cost_template) && <ClaimCostTemplateLinks claimGroup={claimGroup}/>}
          { template.include_technical_template && claimGroupTechnicalNarrativeTemplateLink }
          {template.attach_calendar_appointment && 
            <div title='The email includes the calendar schedule' className='d-flex align-items-center p-2' style={{border: '1px solid lightgrey'}}>
              <span className='mr-2'> Calendar Schedule</span>
              <span className="material-icons text-primary" style={{width: '2rem', height: '2rem' }}>event_available</span>
            </div>
          }
          { template.download_links?.map(link => {
            return (
              <div title='The email includes the calendar schedule' className='d-flex align-items-center p-2' style={{border: '1px solid lightgrey'}}>
                <span className='mr-2'>
                  <a 
                    href={link.url} target="_blank" rel="noreferrer"
                  >
                    {link.filename}
                  </a>
                </span>
                <span className="material-icons text-primary" style={{width: '2rem', height: '2rem' }}>description</span>
              </div>
            )
          })
            
          }
        </div>
        <iframe title="email preview" className='w-100' style={{ minHeight: '300px' }} srcDoc={template.parsed_body}/> 
    </>
  )
}
