import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const api = buildService('/faqs/{id}/faq_entries', {
  only: ['update']
});
const faqEntryService = {
  useSaveFaq: setLoading => {
    const saveFaqEntry = useErrorHandling(useCallback(async faqEntry => {
      setLoading && setLoading(true);
      const response = await api.update(faqEntry.faq_id, faqEntry.id, faqEntry);
      return response;
    }, [setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return [saveFaqEntry];
  }
};
faqEntryService.useCrud = setLoading => {
  const [saveFaqEntry] = faqEntryService.useSaveFaq(setLoading);
  return {
    saveFaqEntry
  };
};
export default faqEntryService;