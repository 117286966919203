import ReportTemplateForm from '@components/admin/reportTemplates/ReportTemplateForm'
import { Loading } from '@rd-web-markets/shared/dist/util';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import claimGroupReportTemplateService from '@rd-web-markets/shared/dist/services/claim_group_report_templates.service';
import reportTemplateService from '@services/report_template.service';
import React, { useState, useEffect } from 'react'
import { Breadcrumb, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import reportTemplateCategoryService from '@services/report_templates/report_template_category.service';
import { REPORT_SAVE_PARAM } from '@rd-web-markets/market/dist/constants';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';

const EditClaimGroupReportTemplatePage = ({handleToaster, accountType}) => {
  const { claim_group_id, id } = useParams();
  const [loading, setLoading] = useState(false);
  const [claimGroup, setClaimGroup] = claimGroupService.useGetClaimGroup(claim_group_id);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation() //Required for Italy
  const template_type = new URLSearchParams(search).get('template_type')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const template = await reportTemplateService.get(id);
        setSelectedTemplate(template);
      } catch(error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [dispatch, claim_group_id, id]);

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setSelectedTemplate({
      ...selectedTemplate,
      [event.target.name]: value,
    });
  };

  const handleCategoryChange = (event, index) => {
    const templateCategoriesCopy = selectedTemplate.report_template_categories;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    templateCategoriesCopy[index][event.target.name] = value;
    setSelectedTemplate({
      ...selectedTemplate,
      report_template_categories: templateCategoriesCopy
    });
  };

  const handleTinyMce = (content, index) => {
    const categoriesCopy = selectedTemplate.report_template_categories;
    categoriesCopy[index]['content'] = content;
    setSelectedTemplate({
      ...selectedTemplate,
      report_template_categories: categoriesCopy,
    });
  };

  const handleFinancialAnalysisMethodologyChange = (staffing_cost_methodology, consumables_methodology) => {
    setSelectedTemplate({
      ...selectedTemplate, 
      staffing_cost_methodology, 
      consumables_methodology 
    });
  };

  const handleBack = () => {
    history.replace(`/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}/master`);
  }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      selectedTemplate.report_template_categories_attributes = selectedTemplate.report_template_categories;
      selectedTemplate.claim_report_templates_attributes = selectedTemplate.claim_report_templates;
      await claimGroupReportTemplateService.update(claim_group_id, selectedTemplate, template_type);
      handleToaster('Template Updated');
      history.replace(`/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}/report_template${REPORT_SAVE_PARAM}`);
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChangeApplyAymingStyles = async (e, index) => {
    setLoading(true);
    try {
      const reportTemplateCategory = selectedTemplate.report_template_categories[index];
      await reportTemplateCategoryService.update(
        selectedTemplate.id,
        {...reportTemplateCategory, should_apply_styles: e.target.checked}
      );
      const updatedTemplate = await reportTemplateService.get(id);
      setSelectedTemplate(updatedTemplate);
    } catch(err) {
      dispatch(handleError(err))
    } finally {
      setLoading(false);
    }
  };


  if(!selectedTemplate || !claimGroup) return <Loading />;

  return (
    <>
      <SidebarPortal headerItem={{link: `companies/${claimGroup.company_id}/master`, text: claimGroup.company.name}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies/${claimGroup.company_id}/master`}}>{claimGroup.company.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      <Card>
        <Card.Header>Edit Company Report Template</Card.Header>
        <Card.Body>
          <ReportTemplateForm
            handleChangeApplyAymingStyles={handleChangeApplyAymingStyles}
            handleCategoryChange={handleCategoryChange}
            handleFinancialAnalysisMethodologyChange={handleFinancialAnalysisMethodologyChange}
            handleTinyMce={handleTinyMce}
            handleChange={handleChange}
            selectedTemplate={selectedTemplate}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            loading={loading} />
        </Card.Body>
      </Card>
    </>
  )
}

export default EditClaimGroupReportTemplatePage
