import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import QueryString from 'query-string';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { setIsUnderTechnicalReview } from '../store/features/reviewSlice';
import { useDispatch } from 'react-redux';
import { storeMultipleClaimProjectReports } from '../store/features/claimProjectReportsSlice';
import { buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
const api = buildService('/claim_groups', {
  only: ['all']
});
const claimGroupService = {
  // Refactoring to set the isUnderReview state on claim group fetch.
  // To replace get when the refactoring is done.
  useGetClaimGroup(claimGroupId) {
    let initialState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    const [claimGroup, setClaimGroup] = useState(initialState);
    const dispatch = useDispatch();
    const fetchClaimGroupAndUpdateTechnicalReviewState = useErrorHandling(useCallback(async claimGroupId => {
      if (claimGroupId) {
        const fetchedClaimGroup = await claimGroupService.get(claimGroupId);
        setClaimGroup(fetchedClaimGroup);
        dispatch(setIsUnderTechnicalReview(!!fetchedClaimGroup.active_change_set));
        dispatch(storeMultipleClaimProjectReports(!!fetchedClaimGroup.claim_project_reports));
      }
    }, [dispatch]));
    useEffect(() => {
      fetchClaimGroupAndUpdateTechnicalReviewState(claimGroupId);
    }, [fetchClaimGroupAndUpdateTechnicalReviewState, claimGroupId]);
    const resetClaimGroup = fetchClaimGroupAndUpdateTechnicalReviewState;
    return [claimGroup, setClaimGroup, resetClaimGroup];
  },
  useUpdateClaimGroup(claimGroupId, setClaimGroup) {
    return useErrorHandling(useCallback(async claimGroup => {
      const updatedClaimGroup = await claimGroupService.update(claimGroup);
      setClaimGroup(updatedClaimGroup);
    }, [setClaimGroup]));
  },
  useFetchAllClaimGroups(setLoading, initialQueryParams) {
    const [claimGroups, setClaimGroups, fetchClaimGroups] = serviceMethods.useFetch({
      callback: useCallback(async queryParams => {
        const response = await api.all({
          query: buildQueryString(queryParams)
        });
        return response.claim_groups;
      }, []),
      setLoading,
      initialQueryParams: initialQueryParams || ''
    });
    return [claimGroups, setClaimGroups, fetchClaimGroups];
  },
  get(claimGroupId) {
    return fetch("/api/claim_groups/".concat(claimGroupId), request.get);
  },
  update(claim_group) {
    return fetch("/api/claim_groups/".concat(claim_group.id), {
      ...request.put,
      body: JSON.stringify({
        claim_group
      })
    });
  },
  create(claim_group) {
    return fetch('/api/claim_groups/', {
      ...request.post,
      body: JSON.stringify({
        claim_group
      })
    });
  },
  all(params) {
    return fetch("/api/claim_groups?".concat(params), request.get);
  },
  delete(claimGroupId) {
    return fetch("/api/claim_groups/".concat(claimGroupId), {
      ...request.delete
    });
  }
};
export default claimGroupService;