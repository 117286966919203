import { useCallback } from 'react';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { buildService } from '../service';
const api = buildService('/companies/{id}/user_favourite_companies', {
  only: ['create', 'delete']
});
const useCreateFavouriteCompany = (companyId, setLoading, refreshCompanies) => {
  return useErrorHandling(useCallback(async () => {
    setLoading(true);
    await api.create(companyId, companyId);
    await refreshCompanies();
  }, [companyId, refreshCompanies, setLoading]), useCallback(() => {
    setLoading(false);
  }, [setLoading]));
};
const useDeleteFavouriteCompany = (companyId, setLoading, refreshCompanies) => {
  return useErrorHandling(useCallback(async () => {
    setLoading(true);
    await api.delete(companyId, companyId);
    await refreshCompanies();
  }, [companyId, refreshCompanies, setLoading]), useCallback(() => {
    setLoading(false);
  }, [setLoading]));
};
const userFavouriteCompaniesService = {
  useCrud: (companyId, setLoading, refreshCompanies) => {
    return {
      create: useCreateFavouriteCompany(companyId, setLoading, refreshCompanies),
      delete: useDeleteFavouriteCompany(companyId, setLoading, refreshCompanies)
    };
  }
};
export default userFavouriteCompaniesService;