import { useCallback } from 'react';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from './service';
const api = buildService('/business_units/{id}/user_access_business_unit_participations', {
  only: ['create', 'delete']
});
const userAccessBusinessUnitParticipationsService = {
  useDeleteUserAccessBusinessUnitParticipationFromClaimGroup(claimGroup, setClaimGroup, setLoading) {
    return useErrorHandling(useCallback(async (selectedUserAccess, participation, businessUnitId) => {
      setLoading(true);
      await api.delete(businessUnitId, participation.id);
      const userAccessInClaimGroup = claimGroup.user_accesses.find(userAccess => userAccess.id === selectedUserAccess.id);
      userAccessInClaimGroup.user_access_business_unit_participations = userAccessInClaimGroup.user_access_business_unit_participations.filter(existingParticipation => existingParticipation.id !== parseInt(participation.id));
      setClaimGroup({
        ...claimGroup
      });
    }, [claimGroup, setClaimGroup, setLoading]), useCallback(() => {
      setLoading(false);
    }, [setLoading]));
  },
  useCreateUserAccessBusinessUnitParticipationFromClaimGroup(claimGroup, setClaimGroup, setLoading) {
    return useErrorHandling(useCallback(async (selectedUserAccess, businessUnitId) => {
      setLoading(true);
      const newParticipation = await api.create(businessUnitId, {
        user_access_id: selectedUserAccess.id
      });
      const userAccessInClaimGroup = claimGroup.user_accesses.find(userAccess => userAccess.id === selectedUserAccess.id);
      userAccessInClaimGroup.user_access_business_unit_participations.push(newParticipation);
      setClaimGroup({
        ...claimGroup
      });
    }, [claimGroup, setClaimGroup, setLoading]), useCallback(() => {
      setLoading(false);
    }, [setLoading]));
  }
};
export default userAccessBusinessUnitParticipationsService;