import React from 'react'
import ClaimGroupListAdmins from './ClaimGroupListAdmins';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useSelector } from 'react-redux';
import ClaimGroupListClients from './ClaimGroupListClients';
import ClaimGroupListByCompanies from './ClaimGroupListByCompanies';

const ClaimGroupList = ({claimGroups, loading, deleteClaimGroup, groupedByCompanyClaimGroups}) => {
  const user = useSelector((state) => state.auth.user);

  if(!user) return <Loading />

  if(user.account_type === 'customer' || user.account_type === 'accountant'){
    return (
      // <ClaimGroupListClients groupedByCompanyClaimGroups={groupedByCompanyClaimGroups} claimGroups={claimGroups} loading={loading} accountType={user.account_type} />
      <ClaimGroupListByCompanies groupedByCompanyClaimGroups={groupedByCompanyClaimGroups} claimGroups={claimGroups} loading={loading} accountType={user.account_type} />
    )
  } else {
    return (
      <ClaimGroupListAdmins
        claimGroups={claimGroups}
        loading={loading}
        deleteClaimGroup={deleteClaimGroup}
      />
    )
  }
}

export default ClaimGroupList
