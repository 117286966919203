import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimTemplateCategoriesService = {
  update(claimGroupId, category, categoryIndex, taskIndex) {
    return fetch(`/api/claim_groups/${claimGroupId}/claim_template_categories/${categoryIndex}`, {
      ...request.put,
      body: JSON.stringify({ category: category, task_index: taskIndex }),
    });
  },
};

export default claimTemplateCategoriesService;
