import { useCallback } from 'react';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service'
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods'

const api = buildService('/my/tracking_tasks', { only: [ 'all']})

export const myTasksService = {
  useFetchAllTasks(setLoading, initialQueryParams) {
    const [tasks, setTasks, fetchTasks] = serviceMethods.useFetch({
      callback: useCallback(async (queryParams) => await api.all({ query: buildQueryString(queryParams) }), []),
      setLoading,
      initialQueryParams: initialQueryParams || ''
    })
  
    return [tasks, setTasks, fetchTasks]
  },
  all: (queryParams = null) => {
    return fetch(`/api/my/tracking_tasks?${queryParams}`, request.get);
  }
};
