import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const rndReportService = {
  create(report_template_id, report_version, template_type, claimId, show_comments = false) {
    const claimIdQuery = template_type === 'aif' && claimId ? `&claim_id=${claimId}` : ''; //UK Specific
    const claim_report_template = {
      report_type: report_version,
      template_type,
      claimIdQuery,
      show_comments
    }
    return fetch(
      `/api/report_templates/${report_template_id}/rnd_reports/`,
      {
        ...request.post,
        body: JSON.stringify(claim_report_template),
      }
    );
  },
  preview: (report_template_id, report_type, template_type, claimId, show_comments = false) => {
    const templateTypeQuery= template_type ? `&template_type=${template_type}` : ''; //Italy Specific
    const claimIdQuery = template_type === 'aif' && claimId ? `&claim_id=${claimId}` : ''; //UK Specific
    
    return fetch(
      `/api/report_templates/${report_template_id}/rnd_reports?report_type=${report_type}${templateTypeQuery}${claimIdQuery}&show_comments=${show_comments}`,
      request.get)
    ;
  },
  delete(report_template_id, report_id) {
    return fetch(`/api/report_templates/${report_template_id}/rnd_reports/${report_id}`, request.delete);
  },
  all(report_template_id) {
    return fetch(`/api/my/report_templates/${report_template_id}/rnd_reports/`, request.get);
  }
};

export default rndReportService;
