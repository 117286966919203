import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const companyGroupsCompaniesService = {
  delete(companyGroupId, companyId) {
    return fetch("/api/company_groups/".concat(companyGroupId, "/companies/").concat(companyId), request.delete);
  },
  create(companyGroupId, company) {
    return fetch("/api/company_groups/".concat(companyGroupId, "/companies"), {
      ...request.post,
      body: JSON.stringify({
        company
      })
    });
  }
};
export default companyGroupsCompaniesService;