import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import myClaimGroupsService from '@rd-web-markets/shared/dist/services/claim_groups/my_claim_groups.service';
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Breadcrumb, Pagination } from 'react-bootstrap'
import SearchBarDefault from '@components/util/SearchBarDefault';
import { useTranslation } from 'react-i18next';
import DashboardsTable from '@rd-web-markets/shared/dist/dashboards/dashboards/DashboardsTable';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import * as QueryString from 'query-string';

const initialParams = {
  page: 1,
  query: ''
}


export default function ClaimGroupDashboardListPage() {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const [claimGroups, setClaimGroups] = useState([])
  const [paginationItems, setPaginationItems] = useState([]);

  const [searchBarParams, setSearchBarParams] = useState(
    window.location.search ? QueryString.parse(window.location.search) : initialParams
  )

  const fetchAllClaimGroups = useErrorHandling(useCallback(async (queryString) => {
    return await myClaimGroupsService.all(queryString)
  }, []))


  const changeSearchBarParams = useCallback( (pageNumber) => {
    setSearchBarParams({ ...searchBarParams, page: pageNumber });
  }, [searchBarParams])

  const setClaimGroupsFromSearch = useCallback(searchResult => {
    if (searchResult) {

      const filteredClaimGroups = searchResult.claim_groups.filter(cg => cg.user_accesses.some(ua => ua.dashboard_access === true && ua.user_id === user.id));
      setClaimGroups(filteredClaimGroups)

      const pages = Array.from({length: searchResult.pages}, (_, i) => i + 1)
      const tempPaginationItems = []
      pages.forEach(pageNumber => {
        tempPaginationItems.push(
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === searchBarParams.page}
            onClick={() => changeSearchBarParams(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        );
      })

      setPaginationItems([...tempPaginationItems])
    }
  }, [])

  return (
    <>
      <SidebarPortal headerItem={{ link: '', text: 'all_claims' }} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${user.account_type}` }}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('dashboards')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card>
        <Card.Header>{t('dashboards')}</Card.Header>
        <Card.Body>
          <Row>
            <Col md={{ span: 4, offset: 8 }}>
              <SearchBarDefault 
                fetchDataCallback={fetchAllClaimGroups} 
                setDataResult={setClaimGroupsFromSearch} 
                params={searchBarParams}
                setParams={setSearchBarParams}
              />
            </Col>
          </Row>
          <Row>
            <DashboardsTable claimGroupsOrCompanies={claimGroups} />
          </Row>
          <Row>
            <Pagination className="justify-content-end">{paginationItems}</Pagination>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}
