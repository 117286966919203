import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimProjectReportSubRouteService from '@rd-web-markets/shared/dist/services/claim_groups/claim_project_report_sub_route.service';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import omit from 'lodash.omit';
import { useCallback, useEffect, useState } from 'react';
const researchersApi = buildService('/companies/{id}/researchers', {
  only: ['all', 'delete']
}, {
  only: ['postFormData', 'putFormData']
});
const projectLevelApi = buildService('/claim_project_reports/{id}/researchers', {
  only: ['all', 'delete']
}, {
  only: ['postFormData', 'putFormData']
});
const researcherProjectApi = buildService('/claim_project_reports/{id}/researcher_claim_project_reports', {
  only: ['update']
});
export function useProjectStaffService(companyId, rowData, setData, data, removeEmptyRow, emptyRowId) {
  let selectedProjectId = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : null;
  const [body, setBody] = useState({
    ...rowData
  });
  const [uploadAllowed, setUploadAllowed] = useState(!!rowData.id); // upload is allowd for existing rows, new rows should update on press of save button

  useEffect(() => {
    setBody({
      ...rowData
    });
  }, [rowData]);
  const addData = useCallback((response, data) => {
    setData([...data, response]);
    setBody({
      ...rowData
    });
  }, [rowData, setData]);
  const updateData = useCallback((response, documentOnly, data) => {
    const selectedDataIndex = data.findIndex(n => n.id === Number(body.id));
    if (documentOnly) {
      data[selectedDataIndex].document = {
        ...response.document
      };
    } else {
      data[selectedDataIndex] = {
        ...response
      };
    }
    setData([...data]);
  }, [body.id, setData]);
  const uploadFile = useErrorHandling(useCallback(async formData => {
    formData.append('researcher', JSON.stringify({
      id: rowData.id
    }));
    const response = await researchersApi.putFormData(companyId, body.claim_project_report_id, body.id, formData);
    updateData(response, true, data);
  }, [rowData.id, companyId, body.claim_project_report_id, body.id, updateData, data]));
  const saveData = useErrorHandling(useCallback(async body => {
    let fieldValues = omit(body, ['id', 'created_at', 'updated_at', 'claim_project_report_id']);
    const fd = new FormData();
    fd.append('researcher', JSON.stringify({
      ...fieldValues
    }));
    let response = null;
    if (selectedProjectId) {
      const researcherProjectIndex = body.researcher_claim_project_reports.findIndex(r => r.claim_project_report_id === Number(selectedProjectId));
      const researcherProjectBody = body.researcher_claim_project_reports[researcherProjectIndex];
      await researcherProjectApi.update(selectedProjectId, researcherProjectBody.id, researcherProjectBody);
      response = await projectLevelApi.putFormData(selectedProjectId, body.id, fd);
    } else {
      response = await researchersApi.putFormData(companyId, body.id, fd);
    }
    updateData(response, false, data);
  }, [selectedProjectId, updateData, data, companyId]));
  const createNewData = useErrorHandling(useCallback(async formData => {
    setUploadAllowed(false);
    let fieldValues = omit(body, ['id', 'created_at', 'updated_at', 'claim_project_report_id']);
    formData.append('researcher', JSON.stringify({
      ...fieldValues,
      company_id: companyId
    }));
    let response = null;
    if (body.claim_project_report_id) {
      response = await projectLevelApi.postFormData(body.claim_project_report_id, formData);
    } else {
      response = await researchersApi.postFormData(companyId, formData);
    }
    addData(response, data);
    removeEmptyRow(emptyRowId);
  }, [body, addData, data, removeEmptyRow, emptyRowId, companyId]));
  const deleteData = useErrorHandling(useCallback(async (body, data, selectedProjectId) => {
    if (selectedProjectId) {
      await projectLevelApi.delete(selectedProjectId, body.id);
    } else {
      await researchersApi.delete(companyId, body.id);
    }
    const deletedNameListIndex = data.findIndex(n => n.id === Number(body.id));
    data.splice(deletedNameListIndex, 1);
    setData([...data]);
  }, [companyId, setData]));
  return [saveData, createNewData, deleteData, uploadFile, body, setBody, uploadAllowed, setUploadAllowed];
}