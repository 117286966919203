import React from 'react';
import { Table } from '@rd-web-markets/shared/dist/util';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import SendInvitationEmail from './SendConfirmationInstructions';

const UserList = ({ users, usersType, handleDelete, onUsersChange, params, setParams, handleActivate }) => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const history = useHistory();
  const isConsultantOrAdmin = ['consultant', 'admin'].includes(loggedInUser.account_type);
  const { t } = useTranslation();
  if (!users) return <Table></Table>;

  const renderUserContacts = user =>
    <>
      <span><span className="material-icons">mail</span> <a href={'mailto:' + user.email}>{user.email}</a></span>
      <br />
      <span><span className="material-icons">call</span> {user.phone_number}</span>;
    </>;

  const headers = usersType === 'customer' ?
    [
      { text: t('name'), sortable: true, orderBy: 'name', ...( params.order_by?.split(' ')[0] === 'name' && {order: params.order_by.split(' ')[1] }) },
      { text: t('role'), sortable: true, orderBy: 'account_type', ...( params.order_by?.split(' ')[0] === 'account_type' && { order: params.order_by.split(' ')[1] }) },
      { text: t('contact'), },
      { text: t('active'), },
    ] :
    [
      { text: t('name'), sortable: true, orderBy: 'name', ...( params.order_by?.split(' ')[0] === 'name' && {order: params.order_by.split(' ')[1] }) },
      { text: t('contact'), },
      { text: t('active'), },
    ];

  if (isConsultantOrAdmin) {
    headers.push({ text: '' });
  }

  const userActiveText = user => {
    return user.is_active ? <p class="text-success">Active User</p> : <p class="text-danger">'Not an active user'</p>;
  }

  const rows = users.map(u => {
    const row = {
      key: u.id,
      columns: usersType === 'customer' ?
        [
          u.name,
          u.role,
          renderUserContacts(u),
          <Form.Check type='checkbox'>
            <Form.Check.Input
              type='checkbox'
              disabled={loggedInUser.account_type !== 'admin'}
              checked={u.is_active}
              value={u.is_active}
              name='is_active'
              onChange={(e) => handleActivate(e, u.id)}
            />
            <Form.Check.Label>{userActiveText(u)}</Form.Check.Label>
          </Form.Check>
        ] :
        [
          u.name,
          renderUserContacts(u),
          <Form.Check type='checkbox'>
            <Form.Check.Input
              type='checkbox'
              disabled={loggedInUser.account_type !== 'admin'}
              checked={u.is_active}
              value={u.is_active}
              name='is_active'
              onChange={(e) => handleActivate(e, u.id)}
            />
            <Form.Check.Label>{userActiveText(u)}</Form.Check.Label>
          </Form.Check>
        ]
    };

    if (isConsultantOrAdmin) {
      row.columns.push(<SendInvitationEmail user={u} onSend={onUsersChange} />);
    }

    return row;
  });

  const sortUsers = (eventKey, direction) => {
    setParams({...params, order_by: headers[eventKey].orderBy + ' ' + direction });
  };

  return (
    <>
      <Table
        headers={headers}
        rows={rows}
        onEdit={i => history.push(`/admin/users/${users[i].id}/edit`)}
        onSort={(i, d) => sortUsers(i, d)}
        onDelete={i => handleDelete(users[i].id)}
      />
    </>
  );
};

export default UserList
