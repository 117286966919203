import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import TechnicalProofMoreInfoModal from '@components/shared/technicalProof/TechnicalProofMoreInfoModal';
import ClaimStateAlerts from '@rd-web-markets/shared/dist/claim/ClaimStateAlerts';
import { CompanyInfoForm } from '@rd-web-markets/market/dist/technicalProof';
import { useSelector } from 'react-redux';
import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';

const modalText = () => {
  return (
    <>
      <p>
        Secure Corp Ltd is a London based company that builds advanced IT
        security solutions. It offers a number of unique connectivity solutions
        which help secure and interconnect private networks for governmental
        institutions, airports and large private companies. Secure Corp Ltd was
        awarded the title of the most innovative security company during the
        international security conference which took place in London in June
        2015. A dditionally the company holds more than 15 awards from a number
        of top security events in the last 10 years.
      </p>
      <p>
        The company was founded in 2005 by Joshua Rupert and Catherine Datshine.
        Joshua is a security expert and an active Internet Engineering Task
        Force (IETF) volunteer who contributed at the development of more than
        15 IETF documents/specifications related to the IT security industry.
        Catherine has vast experience in securing networks for large
        corporations. She worked previously as lead security architect for
        Microsoft and accumulated over 5 years of security research experience
        while at CISCO.
      </p>
      <p>
        This claim is structured around one main project spanning FY14 and FY15
        to develop the solution and to extend it to meet evolving customer
        demands overcoming the technological uncertainties encountered in doing
        so.
      </p>
    </>
  );
}

const CompanyInfoSection = ({claimGroup, setClaimGroup, updateClaimGroup, resetClaimGroup}) => {
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateClaimGroup();
  };

  const saveCompanyInfoField = (content, field) => {
    setClaimGroup({
      ...claimGroup,
      [field]: content,
    });
  };

  const handleContinue = async () => {
    await resetClaimGroup();
    history.push(`/${user.account_type}/claim_groups/${claimGroup.id}/technical_proof/project_reports`);
  }

  const setTotalProjectCount = (event) => {
    setClaimGroup({
      ...claimGroup,
      total_project_count: event.target.value
    })
  }

  if (!claimGroup) return <Loading />;

  return (
    <>
        <TechnicalProofMoreInfoModal
          title='Company Info Example'
          body={modalText()}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
        <Form onSubmit={handleSubmit} className="pt-4">
          <Card.Body>
            <Card>
              <Card.Body>
                <p className="lead">
                  Follow these steps to provide the information required to
                  produce the supporting written narrative which will tell HMRC
                  about your eligible R&D Projects. This information will be
                  passed across to one of our report writers to produce a
                  supporting report.
                </p>
              </Card.Body>
            </Card>

            <Form.Group as={Row} className="mt-5 mb-5 font-weight-bold">
              <Col md={3}>
                <Form.Label>Total Project Number</Form.Label>
              </Col>
              <Col md={1}>
                <ImmutableUnderReviewFormControl value={claimGroup.total_project_count} type="number" min="1" name="total_project_count" onChange={setTotalProjectCount} />
              </Col>              
            </Form.Group>

              <Form.Group as={Row}>
                <Col md={12}>
                  <h2>Company Overview & R&D Context</h2>
                </Col>
              </Form.Group>
              <CompanyInfoForm claimGroup={claimGroup} onExpandHelp={() => setModalShow(true)} saveCompanyInfoField={saveCompanyInfoField} />
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col md={6} className="text-left">
                <Button variant="light" as={Link} to={`/${user.account_type}/companies/${claimGroup.company_id}/${claimGroup.id}/master`}>
                  <span className="material-icons md-18">chevron_left</span> Back
                </Button>
              </Col>
              <Col md={6} className="text-right">
                <Button isImmutableUnderReview={true} type="submit" variant="light" className="mr-2">
                  <span className="material-icons md-18">save</span> Save
                </Button>
                <Button variant="primary" onClick={() => handleContinue()}>
                  Continue <span className="material-icons md-18">chevron_right</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Form>
    </>
  );
};

export default CompanyInfoSection;
