import React, { useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { Button } from '@rd-web-markets/shared/dist/util/buttons'
import SelectProjectsPopover from './SelectProjectsPopover';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';

export default function SelectProjectsPopup({ claimGroup, disabled, loading, accountType, ...props }) {
  const [showPopover, setShowPopover] = useState(process.env.PUBLIC_URL === '/it' ? true : false)
  const dispatch = useDispatch()

  const popover = <SelectProjectsPopover
    accountType={accountType}
    claimGroup={claimGroup} 
    onClose={() => setShowPopover(false)} 
  />

  const trigger = <OverlayTrigger show={showPopover} trigger="click" placement="bottom" overlay={popover}>
    <Button 
      {...props} 
      disabled={disabled} 
      loading={loading} 
      onClick={() => setShowPopover(!showPopover)}
    >
      Select Projects
    </Button>
  </OverlayTrigger>

  return trigger
}
