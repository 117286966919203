import React, { useState, useRef } from 'react'
import { Col, Row, Card, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Button } from '@rd-web-markets/shared/dist/util/buttons';

const TaskAttachmentsForm = ({handleSubmit, loading, isSignedOff}) => {
  const hiddenFileInput = useRef(null);
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachment, setAttachment] = useState({comments: ''});

  const handleChange = e => {
    setAttachment({
      ...attachment,
      comments: e.target.value
    })
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const addFile = event => {
    setSelectedFile(event.target.files[0]);
  }

  const submit = () => {
    handleSubmit(attachment, selectedFile);
    setSelectedFile(null);
    hiddenFileInput.current.value = '';
  }
  
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={2}>
            <Button variant="primary" onClick={handleClick} disabled={loading || isSignedOff}>
              {t('add_attachment')}
            </Button>
            <input
              style={{ display: 'none' }}
              type="file"
              data-testid="taskAttachment"
              ref={hiddenFileInput}
              onChange={(e) => addFile(e)}
            ></input>
            <p>{selectedFile && selectedFile.name}</p>
          </Col>
          <Form.Label column="md" md={2}>
            {t('please_add_additional_comments')}
          </Form.Label>
          <Col md={2}>
            <Form.Control
              onChange={handleChange}
              name="comments"
              value={attachment.comments || ''}
              size="md"
              type="text"
            ></Form.Control>
          </Col>
          <Col md={{span:1, offset: 1}}>
            <Button loading={loading} variant="primary" onClick={submit} disabled={isSignedOff}>
              {t('submit')}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>

  )
}

export default TaskAttachmentsForm
