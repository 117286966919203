export const getColumnWidths = _ref => {
  let {
    totalWidth,
    tableHeaders
  } = _ref;
  // headerIndex => headerWidth in pixels -- { 0: 100, 1: 200} etc.
  // empty object otherwise
  const columnFixedWidths = tableHeaders.map((header, index) => {
    if (header.width && typeof header.width === 'number') {
      return [index, header.width];
    } else if (header.width && typeof header.width === 'string') {
      // deprecated - because we cannot account for all possible strings - e.g. 1rem 1px 1vw 1% 1em etc.
      // assume string is pixels - e.g. '100px'
      return [index, parseInt(header.width)];
    }
    // some can be undefined
  }).reduce((accumulator, current) => {
    if (current) {
      accumulator[current[0]] = current[1];
    }
    return accumulator;
  }, {});

  // Calculate the total fixed width
  const totalFixedWidth = Object.values(columnFixedWidths).reduce((sum, width) => sum + width, 0);

  // Calculate width for dynamic columns
  const dynamicColumnsCount = tableHeaders.length - Object.keys(columnFixedWidths).length;
  const dynamicColumnWidth = (totalWidth - totalFixedWidth) / dynamicColumnsCount;
  const allColumnWidths = {
    ...columnFixedWidths,
    dynamicColumnWidth: dynamicColumnWidth
  };
  return allColumnWidths;
};