import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimProjectReportsClaimGroupService = {
  all(claim_group_id) {
    return fetch(`/api/de/claim_groups/${claim_group_id}/claim_project_reports`, request.get);
  }
}

export default claimProjectReportsClaimGroupService;
