

import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { ChangeHighlighter } from '@rd-web-markets/shared/dist/util';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';

const AttachmentItem = ({index, attachment, handleAttachmentChange, handleAttachmentSubmit, handleDelete, applicationSubmitted}) => {
  return (
    <>
      <tr key={attachment.id}>
        <td>{createTimezonedDate(new Date(attachment.created_at), true).toCustomLocaleDateString()}</td>
        <td>
          <a 
            href={attachment.download_link.url} target="_blank" rel="noreferrer"
          >
            {attachment.download_link.filename}
          </a>
        </td>
        <td>
          <ChangeHighlighter
            as={Form.Control}
            onChange={e => handleAttachmentChange(e, index)}
            onBlur={() => handleAttachmentSubmit(index)}
            object={attachment}
            name="notes"
            disabled={applicationSubmitted}
          />
        </td>
        <td>
          <Button
            variant="danger"
            disabled={applicationSubmitted}
            onClick={() => handleDelete(attachment.id)}
            className="mx-2">
            <span className="material-icons md-18">delete</span>
          </Button>
        </td>
      </tr>
    </>
  )
}

export default AttachmentItem
