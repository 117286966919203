const useConsultantRole = () => {
  const getConsultantRole = role => {
    switch (role) {
      case 'claim_group_access_lead_consultant':
        return 'Lead Consultant';
      case 'claim_group_access_suporting_consultant':
        return 'Supporting Consultant';
      default:
        return [''];
    }
  };
  return [getConsultantRole];
};
export default useConsultantRole;