import React from 'react'
import Dashboard from '@rd-web-markets/market/dist/dashboards/Dashboard';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { DASHBOARD_PAGE_SIDE_BAR_MENU_ITEMS } from '@rd-web-markets/market/dist/constants';

const DashboardPage = () => {

  return (
    <>
      <SidebarPortal headerItem={DASHBOARD_PAGE_SIDE_BAR_MENU_ITEMS} />
      <div className='mt-3'></div>
        <Dashboard />
      <div className='mb-5'></div>
    </>
  );
};
export default DashboardPage;
