import { api } from '@rd-web-markets/shared/dist/services/service';
const ProjectQuestionnaireTemplateService = {
  upload: body => api.postFormData("/project_questionnaire_documents", body),
  download: params => fetch("/api/project_questionnaire_documents?".concat(params)).then(response => {
    if (!response.ok) {
      throw new Error('Failed to fetch template document');
    }
    return response.blob();
  })
};
export default ProjectQuestionnaireTemplateService;