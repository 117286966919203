import React from 'react'
import Modal from '@rd-web-markets/shared/dist/util/Modal';
import { Card, Col, Row } from 'react-bootstrap';

export default function TechnicalProofMoreInfoModal({ title, body, onHide, show }) {
  const moreInfoModalBody = () => {
    return (
      <Row>
        <Col md={12}>
          {body}
        </Col>
      </Row>
    )
  };

  return (
    <Modal
      show={show}
      size="lg"
      title={title}
      renderBody={() => moreInfoModalBody()}
      onHide={onHide}
    />
  );
}
