import { api } from '@rd-web-markets/shared/dist/services/service';
const generalRouteSubRouteService = {
  get: function (id, route, subRoute) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.get("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId));
  },
  getAll: function (id, route, subRoute) {
    let queries = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.get("/".concat(route, "/").concat(id, "/").concat(subRoute).concat(queries));
  },
  update: function (id, route, subRoute) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    let body = arguments.length > 4 ? arguments[4] : undefined;
    return api.update("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  delete: function (id, route, subRoute) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.delete("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId));
  },
  create: function (id, route, subRoute) {
    let body = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    return api.create("/".concat(route, "/").concat(id, "/").concat(subRoute), body);
  },
  putFormData: function (id, route, subRoute) {
    let body = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    let subRouteId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    return api.putFormData("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  postFormData: (id, route, subRoute, body) => api.postFormData("/".concat(route, "/").concat(id, "/").concat(subRoute), body)
};
export default generalRouteSubRouteService;