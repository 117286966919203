import React, { useState, useEffect } from 'react'
import { Card, Tab, Tabs, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, CancelButton, AddButton } from '@rd-web-markets/shared/dist/util/buttons';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { Loading } from '@rd-web-markets/shared/dist/util';
import TimeTrackingTaskAttachmentsSection from './TimeTrackingTaskAttachmentsSection';

const TaskLists = ({ task, setTask, handleToaster }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [key, setKey] = useState('comments');
  const [users, setUsers] = useState();
  const user = useSelector((state) => state.auth.user);
  const [comment, setComment] = useState('');

  const dateTimeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };

  useEffect(() => {
    (async () => { // fetch users
      try {
        const response = await UserService.all('admin');
        setUsers(response.users);
      } catch(e) {
        dispatch(handleError(e))
      }
    })();
  }, [dispatch]);

  const addComment = () => {
    task.tracking_task_comments.push({ created_at: new Date(), user_id: user.id, body: comment });
    setTask({ ...task, tracking_task_comments: task.tracking_task_comments });
    setComment('');
  };

  if (!users) return <Loading/>;

  return (
    <Card.Body>
      <Tabs onSelect={(e) => setKey(e)} activeKey={key} defaultActiveKey={'comments'} transition={false} className='mb-3'>
        <Tab eventKey={'comments'} title={t('comments')}>
          {task.tracking_task_comments.map((comment, index) => {
            return (
              <div key={index}>
                <Form.Group as={Row} style={{marginBottom: 0}}>
                  <Form.Label column='md'>
                    <span className='material-icons'>account_circle</span>{users.find((user) => user.id === comment.user_id).name}
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column='md' md={1}>
                    {Intl.DateTimeFormat(window.AYMING_MARKET_LOCALE, dateTimeOptions).format(new Date(comment.created_at))}
                  </Form.Label>
                  <Form.Label column='md' md={10}>
                    {comment.body}
                  </Form.Label>
                </Form.Group>
              </div>
            );
          })}
          <Form.Group as={Row}>
            <Form.Label column='md'>
              <span className='material-icons'>account_circle</span>{user.name}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} className='col-md-8'>
            <Form.Control
              as='textarea'
              onChange={(event) => setComment(event.target.value)}
              size='md'
              type='text'
              value={comment}
              style={{ resize: 'none' }}
              rows='5'
            />
          </Form.Group>
          <Form.Group as={Row} className='col-md-8' style={{justifyContent: 'flex-end'}}>
            <AddButton
              disabled={comment.length === 0}
              className='mr-2'
              onClick={addComment}
              icon='add'
              text={t('add_comment')}
            />
            <CancelButton onClick={() => setComment('')}/>
          </Form.Group>
        </Tab>
        <Tab eventKey={'attachments'} title={t('attachments')}>
          <TimeTrackingTaskAttachmentsSection setTask={setTask} handleToaster={handleToaster} task={task} />
        </Tab>
        <Tab eventKey={'logs'} title={t('work_logs')}>
          {task.logs?.map((log) => {
            return (
              <div key={log.id}>
                <Form.Group as={Row} style={{marginBottom: 0}}>
                  <Form.Label column='md'>
                    <span className='material-icons'>account_circle</span>{log.user}
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column='md' md={2}>
                    Date: {Intl.DateTimeFormat(window.AYMING_MARKET_LOCALE, dateTimeOptions).format(new Date(log.date))}
                  </Form.Label>
                  <Form.Label column='md' md={2}>
                    {log.action}
                  </Form.Label>
                </Form.Group>
              </div>
            );
          })}
        </Tab>
      </Tabs>
    </Card.Body>
  )
}

export default TaskLists;
