import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimScheduleEmailTemplateService = {
  delete(claimScheduleId) { 
    return fetch(`/api/claim_schedules/${claimScheduleId}/email_template`, request.delete);
  },
  update(claimScheduleId, email_template) {
    return fetch(`/api/claim_schedules/${claimScheduleId}/email_template`, {
      ...request.put,
      body: JSON.stringify({ email_template }),
    });
  },
  create(claimId, email_template) { 
    return fetch(`/api/claim_schedules/${claimId}/email_template/`, {
      ...request.post,
      body: JSON.stringify({ email_template }),
    });
  }
}

export default claimScheduleEmailTemplateService;