import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const projectClaimTemplateCategoriesService = {
  update(projectId, category, categoryIndex, taskIndex) {
    return fetch("/api/claim_project_reports/".concat(projectId, "/claim_template_categories/").concat(categoryIndex), {
      ...request.put,
      body: JSON.stringify({
        category: category,
        task_index: taskIndex
      })
    });
  }
};
export default projectClaimTemplateCategoriesService;