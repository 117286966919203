import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { buildFormData } from '@rd-web-markets/shared/dist/services/service';

const importCostTemplateService = {
  create(claimId, formData, projectId) {
    return fetch(`/api/claims/${claimId}/claim_import_cost_template_infos?project_id=${projectId}`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData,
    });
  },
  /**
   * 
   * @param {*} formDataObject | can contain any key-value pair settings to pass to the backend for the import
   * e.g. in the UK this may be the with_projects setting to control whether the spreadsheet has data about
   * rnd apportionments
   * @param {*} claim 
   * @param {*} projectId 
   * @param {*} setLoading 
   * @param {*} setIsPending 
   * @param {*} onCreate 
   */
  async importCostTemplateFile(formDataObject = {}, claim, projectId, setLoading, setIsPending, onCreate) {
    let fd = buildFormData(formDataObject);
    setLoading(true);
    setIsPending(true);

    await this.create(claim.id, fd, projectId);

    if (onCreate) {
      await onCreate()
    }
    
    setLoading(false);
  }
}

export default importCostTemplateService;