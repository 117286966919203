import React, { useCallback, useState } from 'react'
import { Button } from 'react-bootstrap'
import ReportCompletionModal from '@components/shared/claim/complete/modals/ReportCompletionModal'
import { ReportCompletionService } from '@services/claim_groups/report_completion.service'
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils'
import { useTranslation } from 'react-i18next'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice'
import { useDispatch } from 'react-redux'
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling'

export default function CompleteAndSignOff({ claimGroup }) {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createReportCompletion = useErrorHandling(
    useCallback(async (date) => {
      await ReportCompletionService.create(claimGroup.id, date);
      window.location.reload();
    }, [])
  );

  return (
    <>
      <ReportCompletionModal
        show={modalVisible}
        onHide={ () => { setModalVisible(false); } }
        onSubmit={ date => createReportCompletion(date) }
      />

      <Button
        variant="info"
        className="mb-3 pb-3 pt-3 pl-3 pr-3"
        size="md"
        disabled={claimGroup.report_completion}
        onClick={() => setModalVisible(true)}
      >
        <span className="material-icons md-18">person_check</span> {t('report_completed_and_signed_off')}
      </Button>
      { claimGroup.report_completion &&
        <div className="app-fs-heading">Signed Off On: { createTimezonedDate(claimGroup.report_completion.completion_date).toCustomLocaleDateString() } by { claimGroup.report_completion.signed_off_by }</div>
      }
    </>
  )
}
