import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { DatePicker } from '@rd-web-markets/shared/dist/util/date'
import { FormattedNumberInput } from '@rd-web-markets/shared/dist/util/FormattedNumberInput';
import TextInputGroup from '@components/util/TextInputGroup';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { useTranslation } from 'react-i18next';

const KimbleInvoiceSubmissionForm = ({index, claim, loading, submitKimbleInvoice}) => {
  const { t } = useTranslation();
  const getInitialInvoiceDetailsFromClaim = claim => {
    return {
      invoice_date : claim.kimble_invoice?.invoice_date || new Date(),
      final_benefit_amount: claim.kimble_invoice ? claim.internal_invoice_detail?.final_benefit_amount : '',
      invoice_amount: claim.kimble_invoice ? claim.internal_invoice_detail?.invoice_amount : '',
    };
  };

  const [invoiceDetails, setInvoiceDetails] = useState(getInitialInvoiceDetailsFromClaim(claim));

  const updateInvoiceDetails = (detail, value) => {
    invoiceDetails[detail] = value
    setInvoiceDetails({ ...invoiceDetails })
  };

  const handleSubmit = () => {
    submitKimbleInvoice(index, claim, invoiceDetails);
  }
  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Label column='md' md={4}>
          {claim.kimble_invoice ? 'invoice_date' : t('update_invoice_date')}
        </Form.Label>
        <Col md={8}>
          <TextInputGroup
            disabled={!!claim.kimble_invoice}
            placeholder='Start Date'
            prependIcon='calendar_month'
          >
            <DatePicker
              disabled={!!claim.kimble_invoice}
              selected={invoiceDetails.invoice_date}
              className='border-0'
              onChange={date => updateInvoiceDetails('invoice_date', date)}
            />
          </TextInputGroup>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col md={6}>
          <Row>
            <Form.Label column='md' md={6}>
              {t('estimated_benefit_amount')}
            </Form.Label>
            <Col md={6}>
              <FormattedNumberInput
                customInput={Form.Control}
                value={claim.internal_invoice_detail?.estimated_benefit_amount}
                size='md'
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Form.Label column="md" sm={6}>
              {t('confirmed_benefit_amount')}
            </Form.Label>
            <Col sm={6}>
              <FormattedNumberInput
                customInput={Form.Control}
                value={invoiceDetails.final_benefit_amount}
                onChange={(e) => updateInvoiceDetails('final_benefit_amount', e.target.value)}
                size='md'
                disabled={!!claim.kimble_invoice}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col md={6}>
          <Row>
            <Form.Label column='md' sm={6}>
              {t('kimble_estimated_revenue')}
            </Form.Label>
            <Col sm={6}>
              <FormattedNumberInput
                customInput={Form.Control}
                value={claim.internal_invoice_detail?.kimble_forecast_revenue}
                size='md'
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Form.Label column="md" sm={6}>
              {t('actual_invoice_amount')}
            </Form.Label>
            <Col sm={6}>
              <FormattedNumberInput
                customInput={Form.Control}
                value={invoiceDetails.invoice_amount}
                onChange={(e) => updateInvoiceDetails('invoice_amount', e.target.value)}
                size='md'
                disabled={!!claim.kimble_invoice}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>
      
      <Row className='modal-footer'>
        <Col md={12}>
          <Button
            disabled={claim.kimble_invoice}
            loading={loading}
            onClick={handleSubmit}
            variant="light"
            className="float-right"
          >
            <span className="material-icons md-18">save</span> {t('save')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default KimbleInvoiceSubmissionForm
