import { createSlice } from '@reduxjs/toolkit';
export const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    companies: []
  },
  reducers: {
    storeCompanies(state, _ref) {
      let {
        payload: companies
      } = _ref;
      state.companies = companies;
    },
    storeCompany(state, _ref2) {
      let {
        payload: company
      } = _ref2;
      const companyIndex = state.companies.findIndex(storedCompany => storedCompany.id === company.id);
      if (companyIndex >= 0) {
        state.companies.splice(companyIndex, 1, company);
      } else {
        state.companies = [...state.claimProjectReports, company];
      }
    }
  }
});
export const {
  storeCompanies,
  storeCompany
} = companiesSlice.actions;
export default companiesSlice.reducer;