import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Row, Card, Breadcrumb } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import UserForm from '@components/users/UserForm';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { Loading } from '@rd-web-markets/shared/dist/util';

const EditUserPage = ({handleToaster}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.get(id);
      setUser(user);
    }
    getData();
  }, [id]);

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setUser({
      ...user,
      [event.target.name]: value,
    });
  };

  const handleCreateUser = async () => {
    setLoading(true);
    try {
      await UserService.update(user);
      history.push('/admin/users');
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  if(!user) return <Loading />
  return (
    <>
      <SidebarPortal headerItem={{link: 'companies', text: 'Companies'}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin/users'}}>Users</Breadcrumb.Item>
          <Breadcrumb.Item active>{ user.id ? 'Edit' : 'New User' }</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      <Card>
        <Card.Body>
          <UserForm
            //handleAddClaimToCompanyUser={handleAddClaimToCompanyUser}
            //handleCompanyUserUpdate={handleCompanyUserUpdate}
            //handleCompanyUserDelete={handleCompanyUserDelete}
            //handleDeleteClaimFromCompanyUser={handleDeleteClaimFromCompanyUser}
            //handleCompanyUserCreate={handleCompanyUserCreate}
            handleChange={handleChange}
            user={user}
            setUser={setUser}
          />
        </Card.Body>
        <Card.Footer>
          <Row className="modal-footer">
            <Button variant="light" as={Link} to={'/admin/users'}>
              <span className="material-icons md-18">chevron_left</span> Back
            </Button>
            <Button
              onClick={() => handleCreateUser()}
              icon='save'
              iconPosition={'left'}
              loading={loading}
              variant="primary">
              Save
            </Button>
          </Row>
          </Card.Footer>
      </Card>
    </>
  )
}

export default EditUserPage
