import { api } from '@rd-web-markets/shared/dist/services/service';

const releaseNotesService = {
  getAll: (queryParams = '') => api.get(`/releases?${queryParams}`),
  get: (releaseId) => api.get(`/releases/${releaseId}`),
  create: (data) => api.create('/releases', data),
  update: (releaseId, data) => api.update(`/releases/${releaseId}`, data),
  delete: (releaseId) => api.delete(`/releases/${releaseId}`),
  createNote: (releaseId, formData) => api.postFormData(`/releases/${releaseId}/notes`, formData),
  updateNote: (releaseId, noteId, formData) => api.putFormData(`/releases/${releaseId}/notes/${noteId}`, formData),
  deleteNote: (releaseId, noteId) => api.delete(`/releases/${releaseId}/notes/${noteId}`),
}

export default releaseNotesService;
