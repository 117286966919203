import { UserService } from '@rd-web-markets/shared/dist/services/user.service'
import { userListService } from '@services/user_list_service'

export async function fetchAndSetConsultants(setConsultants, dispatch, handleError) {
  try {
    const role = process.env.PUBLIC_URL === '/de' ? 'super_consultant' : 'consultant'
    const consultants = await userListService.all(role)
    setConsultants(consultants.users)

    return consultants
  } catch (error) {
    dispatch(handleError(error)) 
  }
}

export async function fetchAndSetAdmins(setAdmins, dispatch, handleError) {
  try {
    const admins = await userListService.all('admin')
    setAdmins(admins.users)

    return admins
  } catch (error) {
    dispatch(handleError(error)) 
  }
}