import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const api = buildService('/companies/{id}/researchers', {
  only: ['all', 'create', 'update']
});
const researchersService = {
  useFetchAllResearchers: (companyId, setLoading, shouldFetch) => {
    const [researchers, setResearchers] = useState([]);
    const [researchersTotalPages, setResearchersTotalPages] = useState(null);
    const {
      search
    } = useLocation();
    const pageNumber = new URLSearchParams(search).get('page');
    const fetchAllResearchers = useErrorHandling(useCallback(async () => {
      if (!pageNumber || !shouldFetch) return;
      setLoading && setLoading(true);
      const allCompanyResearchers = await api.all(companyId, {
        page: pageNumber
      });
      setResearchers([...allCompanyResearchers.researchers]);
      setResearchersTotalPages(Number(allCompanyResearchers.total_pages));
    }, [companyId, setLoading, setResearchers, pageNumber, shouldFetch]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      fetchAllResearchers();
    }, [fetchAllResearchers]);
    return [researchers, setResearchers, fetchAllResearchers, researchersTotalPages];
  },
  useUpdateResearcher: (companyId, researchers, setResearchers, setLoading) => {
    const updateResearcher = useErrorHandling(useCallback(async researcher => {
      setLoading && setLoading(true);
      await api.update(companyId, researcher.id, {
        researcher
      });
    }, [companyId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return updateResearcher;
  },
  useCreateResearcher: (companyId, researchers, setResearchers, setLoading) => {
    const updateResearcher = useErrorHandling(useCallback(async researcher => {
      setLoading && setLoading(true);
      await api.create(companyId, {
        researcher
      });
    }, [companyId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return updateResearcher;
  },
  delete(companyId, researcherId) {
    return fetch("/api/companies/".concat(companyId, "/researchers/").concat(researcherId), request.delete);
  }
};
researchersService.useCrud = function (companyId, setLoading) {
  let shouldFetch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const [researchers, setResearchers, fetchAllResearchers, researchersTotalPages] = researchersService.useFetchAllResearchers(companyId, setLoading, shouldFetch);
  const updateResearcher = researchersService.useUpdateResearcher(companyId, researchers, setResearchers, setLoading);
  const createResearcher = researchersService.useCreateResearcher(companyId, researchers, setResearchers, setLoading);
  return {
    researchers,
    setResearchers,
    fetchAllResearchers,
    updateResearcher,
    createResearcher,
    researchersTotalPages
  };
};
export default researchersService;