import { toCSS, toJSON } from "cssjson";

// when tinymce sets things like text align it only sets them on the data-mce-style attribute
// so if we set the style attribute's text-align to justify by default, we will be unable to override it in the editor.
export function copyDataMceStyleToStyle(element) {
  const dataMCEStyle = toJSON(element.getAttribute('data-mce-style')).attributes;
  if (dataMCEStyle) {
    for (const key in dataMCEStyle) {
      element.style[key] = dataMCEStyle[key];
    }
  }
}
export function copyStyleToDataMceStyle(element) {
  const dataMCEStyle = toJSON(element.getAttribute('data-mce-style'));
  const style = toJSON(element.getAttribute('style'));
  const mergedStyle = {
    attributes: {
      ...dataMCEStyle.attributes,
      ...style.attributes
    },
    children: {
      ...dataMCEStyle.children,
      ...style.children
    }
  };
  const stringStyle = toCSS(mergedStyle).replaceAll('\n', ' ').replaceAll('"', '');
  element.setAttribute("data-mce-style", stringStyle);
}