import ReportTemplateForm from '@components/admin/reportTemplates/ReportTemplateForm'
import { Loading } from '@rd-web-markets/shared/dist/util';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import claimGroupReportTemplateService from '@rd-web-markets/shared/dist/services/claim_group_report_templates.service';
import reportTemplateService from '@services/report_template.service';
import React, { useState, useEffect } from 'react'
import { Card, Form, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { REPORT_SAVE_PARAM } from '@rd-web-markets/market/dist/constants';
import useCustomTemplateTitle from '@rd-web-markets/shared/src/lib/hooks/useCustomTemplateTitle';
import { useTranslation } from 'react-i18next';
import { SHOULD_ADD_REPORT_TEMPLATE_SEARCH } from '@rd-web-markets/market/dist/constants';

const CreateClaimGroupReportTemplatePage = ({handleToaster, accountType}) => {
  const { claim_group_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [claimGroup, setClaimGroup] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation() //Required for Italy 
  const [getTemplateTitle] = useCustomTemplateTitle();
  const { t } = useTranslation();
  const italyTitle = t(getTemplateTitle(search));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const templates = await reportTemplateService.all(search);
        const claimGroup = await claimGroupService.get(claim_group_id)
        setClaimGroup(claimGroup);
        setTemplates(templates);
      } catch(error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [dispatch, claim_group_id, search])

  const handleSubmit = async () => {
    setLoading(true);
    try {
      selectedTemplate.report_template_categories_attributes = selectedTemplate.report_template_categories;
      selectedTemplate.claim_report_templates_attributes = selectedTemplate.claim_report_templates;
      await claimGroupReportTemplateService.create(claim_group_id, selectedTemplate);
      handleToaster('Template created');
      setLoading(false);
      history.replace(`/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}/report_template${REPORT_SAVE_PARAM}`);
    } catch (error) {
      dispatch(handleError(error));
    }
  };  

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setSelectedTemplate({
      ...selectedTemplate,
      [event.target.name]: value,
    });
  };

  const handleCategoryChange = (event, index) => {
    const templateCategoriesCopy = selectedTemplate.report_template_categories;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    templateCategoriesCopy[index][event.target.name] = value;
    setSelectedTemplate({
      ...selectedTemplate,
      report_template_categories: templateCategoriesCopy
    });
  };

  const updateReportTemplateCategoryContent = (content, index) => {
    const categoriesCopy = selectedTemplate.report_template_categories;
    categoriesCopy[index]['content'] = content;
    setSelectedTemplate({
      ...selectedTemplate,
      report_template_categories: categoriesCopy,
    });
  };

  const handleFinancialAnalysisMethodologyChange = async (staffing_cost_methodology, consumables_methodology) => {
    setSelectedTemplate({
      ...selectedTemplate, 
      staffing_cost_methodology, 
      consumables_methodology 
    });
  };


  const onSelectTemplate = event => {
    const templateId = event.target.value;
    const template = templates.find(template => template.id === +templateId);
    template.claim_report_templates = [];
    setSelectedTemplate(template);
  }

  const handleBack = () => {
    history.replace(`/${accountType}/companies/${claimGroup.company_id}/master`);
  };

  const deleteClaimReportTemplate = id => {
    setSelectedTemplate({
      ...selectedTemplate,
      claim_report_templates: [...selectedTemplate.claim_report_templates.filter(claimReportTemplate => claimReportTemplate.claim_id !== id)]
    })
  };

  const handleChangeApplyAymingStyles = (e, index) => {
    const report_template_categories = selectedTemplate.report_template_categories;
    report_template_categories[index]['should_apply_styles'] = e.target.checked;
    setSelectedTemplate({...selectedTemplate, report_template_categories: report_template_categories});
  }

  if(!templates || !claimGroup) return <Loading />;

  const selectOptions = templates.map((t) => (
    <option key={t.id} value={t.id}>
      {t.name}
    </option>
  ));

  return (
    <>
      <Card>
        <Card.Header>{SHOULD_ADD_REPORT_TEMPLATE_SEARCH ? `Create ${italyTitle} Template`:'Create Company Report Template'}</Card.Header>
        <Card.Body>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Form.Label className="col-form-label">
                {SHOULD_ADD_REPORT_TEMPLATE_SEARCH ? `Select ${italyTitle.toLowerCase()} template` : 'Select R&D Template'}
                
              </Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Control
                as="select"
                onChange={(e) => onSelectTemplate(e)}
              >
                <option value={null}>Select Template</option>
                {selectOptions}
              </Form.Control>
            </Col>
          </Form.Group>
          {selectedTemplate &&
            <>
              <ReportTemplateForm
                handleChangeApplyAymingStyles={handleChangeApplyAymingStyles}
                handleCategoryChange={handleCategoryChange}
                handleFinancialAnalysisMethodologyChange={handleFinancialAnalysisMethodologyChange}
                handleTinyMce={updateReportTemplateCategoryContent}
                handleChange={handleChange}
                selectedTemplate={selectedTemplate}
                handleBack={handleBack}
                handleSubmit={handleSubmit}
                loading={loading} />
            </>
          }
        </Card.Body>
      </Card>
    </>
  )
}

export default CreateClaimGroupReportTemplatePage
