import React, { useCallback, useEffect, useState } from 'react'
import * as QueryString from 'query-string';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import SearchBar from './SearchBar';



export default function SearchBarDefault({ fetchDataCallback, setDataResult, setTotalPages, params, setParams }) {

  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const fetchData = useErrorHandling(useCallback(async () => {
    setLoading(true)
    const result = await fetchDataCallback(QueryString.stringify(params))

    if (setTotalPages) {
      setTotalPages(result.total_pages)
    }

    setDataResult(result)
    history.push({
      path: `${window.location.pathname.split('/')[2]}/dashboards`,
      search: QueryString.stringify(params),
    })

    setLoading(false)
  }, [fetchDataCallback, history, params, setDataResult, setTotalPages]))

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <SearchBar onSubmit={handleSearch} loading={false} query={params.query} />
  )
}
