import { buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
const api = buildService('/companies/{id}/claim_project_reports', {
  only: ['all']
});
const useFetchCompanyProjects = _ref => {
  let {
    companyId,
    setLoading,
    initialQueryParams
  } = _ref;
  const [companyProjects, setCompanyProjects, fetchProjects] = serviceMethods.useFetch({
    callback: useCallback(async queryParams => await api.all(companyId, {
      query: buildQueryString(queryParams)
    }), [companyId]),
    setLoading,
    initialQueryParams: initialQueryParams || {}
  });
  return [companyProjects, setCompanyProjects, fetchProjects];
};
const companyProjectService = {
  useFetchCompanyProjects
};
export default companyProjectService;