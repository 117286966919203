import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import { Card, Row, Button } from 'react-bootstrap';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { Loading } from '@rd-web-markets/shared/dist/util';
import AffiliatedCompaniesForm from '@components/shared/claim_group/affiliated_companies/AffiliatedCompaniesForm';
import affiliatedCompaniesService from '@services/claim_groups/affiliated_companies.service';

const ClaimGroupEditAffiliatedCompaniesPage = ({accountType}) => {
  const { claimGroupId } = useParams();
  const [claimGroup, setClaimGroup] = useState(null);
  const [affiliatedCompany, setAffiliatedCompany] = useState(null);
  const [affiliatedCompanies, setAffiliatedCompanies] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const claimGroup = await claimGroupService.get(claimGroupId);
        const affiliatedCompanies = await affiliatedCompaniesService.all(claimGroupId);
        setAffiliatedCompanies(affiliatedCompanies);

        setClaimGroup(claimGroup);
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    getData();
  }, [claimGroupId, dispatch]);


  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setAffiliatedCompany({
      ...affiliatedCompany,
      [event.target.name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if(!affiliatedCompany.id) {
        const newAffiliatedCompany = await affiliatedCompaniesService.create(claimGroup.id, affiliatedCompany);
        setAffiliatedCompanies([...affiliatedCompanies, newAffiliatedCompany]);
        setAffiliatedCompany(null);
      } else {
        const updatedAffiliatedCompany = await affiliatedCompaniesService.update(claimGroup.id, affiliatedCompany);
        const newAffiliatedCompanies = affiliatedCompanies;
        const index = newAffiliatedCompanies.findIndex(company => {
          return company.id === affiliatedCompany.id;
        });
        newAffiliatedCompanies[index] = updatedAffiliatedCompany;
        setAffiliatedCompanies(newAffiliatedCompanies);
        setAffiliatedCompany(null);
      }
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  }

  const editAffiliatedCompany = affiliatedCompany => {
    setAffiliatedCompany(affiliatedCompany);
  };

  const deleteAffiliatedCompany = async (affiliatedCompany) => {
    setLoading(true);
    try {
      await affiliatedCompaniesService.delete(claimGroup.id, affiliatedCompany.id);
      const updatedAffiliatedCompanies = affiliatedCompanies.filter(company => company.id !== affiliatedCompany.id);
      setAffiliatedCompanies([...updatedAffiliatedCompanies]);
    } catch(error) {
      dispatch(handleError(error.description))
    } finally {
      setLoading(false);
    }
  }

  const closeForm = () => { 
    setAffiliatedCompany(null);
  }

  if(!claimGroup || !affiliatedCompanies) return <Loading />

  return (
    <>
      <Card.Body>
        <Row className="justify-content-end pb-3">
          <Button
            variant="primary"
            onClick={() => setAffiliatedCompany({})}
            className="mx-2">
            <span className="material-icons md-18">add</span> Add New Affiliated Company
          </Button>
        </Row>
        <Row>
        <table className="table borderless">
          <thead>
            <tr className='text-center'>
              <th>Company Name</th>
              <th>Street</th>
              <th>Postcode</th>
              <th>Location</th>
              <th>Tax Number</th>
              <th>A Note</th>
              <th></th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {affiliatedCompanies.map((affiliatedCompany) => {
              return (
                <tr key={affiliatedCompany.id}>
                  <td>{affiliatedCompany.name}</td>
                  <td>{affiliatedCompany.street}</td>
                  <td>{affiliatedCompany.postcode}</td>
                  <td>{affiliatedCompany.location}</td>
                  <td>{affiliatedCompany.tax_number}</td>
                  <td>{''}</td>
                  <td>
                  <Button
                    variant="primary"
                    onClick={() => editAffiliatedCompany(affiliatedCompany)}
                    className="mx-2">
                    <span className="material-icons md-18">edit</span>  Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => deleteAffiliatedCompany(affiliatedCompany)}
                    className="mx-2">
                    <span className="material-icons md-18">delete</span>
                  </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </Row>
        { affiliatedCompany &&
          <AffiliatedCompaniesForm closeForm={closeForm} affiliatedCompany={affiliatedCompany} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} />
        }
      </Card.Body>
    </>
  )
}

export default ClaimGroupEditAffiliatedCompaniesPage
