import { buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
const api = buildService('/claim_project_reports/{id}/project_questionnaires', {
  only: ['all', 'update']
});
const useFetchProjectQuestionnaires = _ref => {
  let {
    claimProjectReportId,
    setLoading
  } = _ref;
  const [questionnaires, setQuestionnaires, fetchQuestionnaires] = serviceMethods.useFetch({
    callback: useCallback(async () => await api.all(claimProjectReportId), [claimProjectReportId]),
    setLoading
  });
  return [questionnaires, setQuestionnaires, fetchQuestionnaires];
};
const useUpdateQuestionnaire = _ref2 => {
  let {
    claimProjectReportId
  } = _ref2;
  const updateQuestionnaire = serviceMethods.useUpdate({
    callback: useCallback(async project_questionnaire => {
      if (!project_questionnaire) {
        return null;
      }
      return await api.update(claimProjectReportId, project_questionnaire.id, {
        project_questionnaire
      });
    }, [claimProjectReportId])
  });
  return updateQuestionnaire;
};
const projectQuestionnaireService = {
  useFetchProjectQuestionnaires,
  useUpdateQuestionnaire
};
export default projectQuestionnaireService;