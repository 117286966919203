import React from 'react'

import {
  InputGroup,
  FormControl
} from 'react-bootstrap'

/**
 * Renders an input group with text input with icon or text on the left
 * Needs a font awesome icon class to show an icon.
 * The icon needs to be imported in the project, before it can be used here.
 * If no prependIcon is set, then prependText is used.
 *
 * @param Object children - jsx to render instead of the default form control
 * @param String prependIcon font awesome icon class - e.g. 'calendar', 'pen'
 * @param String prependText text
 * @param String placeholder Text input placeholder text
 * @param String value Text input default value
 * @param Function onChange to be called on input change
 * @returns
 */
export default function TextInputGroup({
  children = null,
  prependIcon = null,
  prependText = null,
  placeholder,
  value = null,
  disabled,
  onChange
}) {
  // when used as is from react-bootstrap, it actually doesn't look like it does there
  const prependStyle = {
    height: '100%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  };

  const prepend = prependIcon ? 
    <span className="material-icons">{prependIcon}</span> :
    prependText;

  const control = children ? 
    <div className={(disabled ? 'disabled' : '') + ' form-control'} >{children}</div> : 
    <FormControl placeholder={placeholder} defaultValue={value} onChange={e => onChange(e.target.value)}/>;

  return (
    <div>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text style={prependStyle}>
            {prepend}
          </InputGroup.Text>
        </InputGroup.Prepend>
        {control}
      </InputGroup>
    </div>
  );
}