import { Button } from '@rd-web-markets/shared/dist/util/buttons/Button';
import React from 'react';
import { Popover } from 'react-bootstrap';

function CreateClaimChangeSetPopover(props, ref) {
  return (
    <Popover {...props} ref={ref}>
      <Popover.Content>
        <p>Do you want to put all Claims within this Group in for Manager Cost Review?</p>
        <div style={{ width: 'fit-content', margin: '0 auto' }}>
          <Button
            className='mr-1'
            onClick={props.onAllSelected}
            iconPosition="left"
            variant="info"
            size="md"
          >
            Yes
          </Button>
          <Button
            onClick={props.onSingleSelected}
            iconPosition="left"
            variant="info"
            size="md"
          >
            No
          </Button>
        </div>
      </Popover.Content>
    </Popover>
  )
}

export default React.forwardRef(CreateClaimChangeSetPopover)