import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import * as QueryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const api = buildService('/lists/claim_groups/{id}/claim_project_reports', { only: [ 'all'] })

const claimProjectReportsListsService = {
  base: api,
  useFetchProjects: (claimGroupId) => {
    const getAll =  useErrorHandling(
      useCallback(async (params) => {
        const response = await api.all(claimGroupId, params)

        return response
      }, [claimGroupId])
    )
    
    return [getAll]
  },
  useFetchAll: ({ accountType, claimGroupId, initialQueryParams = '', setLoading, groupProjects = false }) => {
    const history = useHistory()
    const location = useLocation()
    const [projects, setProjects] = useState([])
    const [totalPages, setTotalPages] = useState(null)
    const [queryParams, setQueryParams] = useState({
      ...QueryString.parse(location.search || initialQueryParams),
      group_projects: groupProjects
    })

    const fetchAllProjetcs =  useErrorHandling(
      useCallback(async (queryParams) => {
        setLoading && setLoading(true)
        const projects = await api.all(claimGroupId, queryParams)
        setTotalPages(projects.pages)
        setProjects(projects.claim_project_reports)        

        history.push({
          path: `${accountType}/claims`,
          search: QueryString.stringify(queryParams),
        })
      }, [accountType, claimGroupId, history, setLoading]),
      useCallback(() => {
        setLoading && setLoading(false)
      }, [setLoading])
    )

    useEffect(() => {
      claimGroupId && fetchAllProjetcs(queryParams)
    }, [fetchAllProjetcs, queryParams, claimGroupId])

    return [projects, setProjects, totalPages, setTotalPages, queryParams, setQueryParams, fetchAllProjetcs]
  },
};

export default claimProjectReportsListsService;