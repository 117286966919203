import React from 'react'
import { Card, Table, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './personnelFramework.css'

const PersonnelFramework = ({personnelFrameworks, report, handlePersonnelFrameworkChange, saveChange}) => {
  const years = personnelFrameworks.map(a => a.year);
    const { t } = useTranslation();
  
  return (
 
    <Card>
      <Card.Header>
        {t('personnel_framework')}
      </Card.Header>
      <Card.Body>
        <Table  hover>
          <thead>
            <tr>
              <th style={{width: '41%'}}></th>
              {years.sort().map(year => {
                return <th className="text-center">{year}</th>
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{width: '41%'}}>{t('personnel_framework_of_the_project_total')}</td>
              {personnelFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                    <Form.Control
                      onChange={(e) => handlePersonnelFrameworkChange(e, index)}
                      onBlur={() => saveChange(index, 'personnel')}
                      name="total"
                      value={framework.total}
                      size="sm"
                      type="number"
                      min={0}
                      className="disable-spinners"
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={{width: '41%'}}>{t('personnel_framework_of_the_project_scientists_staf_with_technical_degree_in_manmonths')}</td>
              {personnelFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                    <Form.Control
                      onChange={(e) => handlePersonnelFrameworkChange(e, index)}
                      onBlur={() => saveChange(index, 'personnel')}
                      name="scientists_staff"
                      value={framework.scientists_staff}
                      size="sm"
                      type="number"
                      min={0}
                      className="disable-spinners"
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={{width: '41%'}}>{t('personnel_framework_of_the_project_technicians_in_manmonths')}</td>
              {personnelFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                    <Form.Control
                      onChange={(e) => handlePersonnelFrameworkChange(e, index)}
                      onBlur={() => saveChange(index, 'personnel')}
                      name="technicians"
                      value={framework.technicians}
                      size="sm"
                      type="number"
                      min={0}
                      className="disable-spinners"
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={{width: '41%'}}>{t('personnel_framework_of_the_project_others_in_manmonths')}</td>
              {personnelFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                    <Form.Control
                      onChange={(e) => handlePersonnelFrameworkChange(e, index)}
                      onBlur={() => saveChange(index, 'personnel')}
                      name="others"
                      value={framework.others}
                      size="sm"
                      type="number"
                      min={0}
                      className="disable-spinners"
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={{width: '41%'}}><p style={{inlineSize: '100%'}}>{t('wereare_the_personnel_expenses_andor_contract_costs_for_contracts_within_the_eueea_for_this_rd_project_funded_within_the_framework_of_other_funding_or_state_aid')}</p></td>
              {personnelFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                    <Form.Control
                      onChange={async (e) => {
                        await handlePersonnelFrameworkChange(e, index);
                        saveChange(index, 'personnel')
                      }}
                      name="funded_from_eu"
                      value={framework.funded_from_eu}
                      size="sm"
                      as="select"
                    >
                      <option value={true}>{t('yes')}</option>
                      <option value={false}>{t('no')}</option>
                    </Form.Control>
                  </td>
                )
              })}
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default PersonnelFramework
