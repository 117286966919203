/** 
 * Use this component if you do not rely on change_set.report_snapshots
 * At the moment CA / UK / US use change_set.report_snapshots so this component is
 * a bit redundant. But I do not want to remove it yet.
*/

import { Table } from '@rd-web-markets/shared/dist/util';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const dateToLocale = date => {
  return createTimezonedDate(new Date(date)).toCustomLocaleTimeString();
};

const sortByDate = (a, b) => {
  if (a.created_at < b.created_at) {
    return 1;
  }
  if (a.created_at > b.created_at) {
    return -1;
  }
  return 0;
};

const ObjectChangeSetsList = ({object, reviewType}) => {
  const status = changeSet => {
    if(changeSet.approved_at) {
      return (
        <small>
          <span className="material-icons">done</span> {' '}
          Approved {dateToLocale(changeSet.approved_at)}
        </small>
      )
    }
    else if(changeSet.rejected_at) {
      return (
        <small>
          <span className="material-icons">close</span> {' '}
          Rejected {dateToLocale(changeSet.rejected_at)}
        </small>
      )
    }
    else if (
      !changeSet.rejected_at &&
      !changeSet.approved_at &&
      !changeSet.finalized_at
    ) {
      return (
        <small>
          <span className="material-icons">engineering</span> {' '}
          Review in progress since  {dateToLocale(changeSet.created_at)}
        </small>
      )
    }
    else {
      return (
        <small>
          <span className="material-icons">zoom_in</span> {' '}
          Pending approve / reject since {dateToLocale(changeSet.finalized_at)}
        </small>
      );
    }
  }
  
  const rows = object.change_sets
    .filter(changeSet => changeSet.review_type === reviewType)
    .sort(sortByDate)
    .map((changeSet) => ({
    columns: [dateToLocale(changeSet.created_at), changeSet.author.name, status(changeSet)],
  }));
  
  return (
    <div>
      <Row className="scrollable" style={{ height: '220px' }}>
        <Col md={12}>
          <Table className="table-scrollable-x" headers={[]} rows={rows} showControls={false}/>
        </Col>
      </Row>
    </div>
  )
}

export default ObjectChangeSetsList
