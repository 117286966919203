import React from 'react'
import ClaimGroupItem from './ClaimGroupItem';
import { Table } from 'react-bootstrap';
import { SHOW_CLAIM_GROUP_TASKS } from '@rd-web-markets/market/dist/constants';
import { useTranslation } from 'react-i18next';

const ClaimGroupListAdmins = ({claimGroups, loading, deleteClaimGroup}) => {
  const { t } = useTranslation();

  const renderEmptyHeaders = () => {
    return (
      <tr className="text-primary app-fs-table-header">
        <th style={{ width: '100%' }}>{t('claim_group')}</th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
      </tr>
    )
  }

  const renderHeaders = () => {
    return (
      <tr className="text-primary app-fs-table-header">
        <th>{t('claim_group')}</th>
        <th className="text-center">{t('registration')}</th>
        <th className="text-center">{t('cost_collection')}</th>
        <th className="text-center">{t('cost_review')}</th>
        <th className="text-center">{t('technical_assessment')}</th>
        <th className="text-center">{t('review_submission')}</th>
        <th className="text-center">{t('claim_management')}</th>
        <th className="text-center"></th>
      </tr>
    )
  }

  return (
    <Table hover>
      <thead>
          { 
            SHOW_CLAIM_GROUP_TASKS ?
              renderHeaders()
              :
              renderEmptyHeaders()
          }
      </thead>
      <tbody>
        {claimGroups.map((claimGroup) => {
          return <ClaimGroupItem deleteClaimGroup={deleteClaimGroup} loading={loading} key={claimGroup.id} claimGroup={claimGroup}/>;
        })}
      </tbody>
    </Table>
  )
}

export default ClaimGroupListAdmins
