export const reportTemplateInitial = {
  id: 0,
  name: '',
  report_template_categories: [{
    title: 'Header',
    content: '',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: '242 - What scientifc or technological uncertainties did you attempt to overcome- uncertainties that could not be removed by using standard practice?',
    content: '',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: '244 - What work did you perform in the tax year to overcome the scientifc or technological uncertainties described in line 242?',
    content: '',
    exclude_from_report: false,
    should_apply_styles: true
  }, {
    title: '246 - What scientifc or technological advancements did you achieve as a result of the work described in line 244?',
    content: '',
    exclude_from_report: false,
    should_apply_styles: true
  }]
};