import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, SaveButton } from '@rd-web-markets/shared/dist/util/buttons';

const ClaimProjectReportFooter = ({ isImmutableUnderReview, handleDelete, accountType, claimGroup }) => {
  const { t } = useTranslation();

  return (
    <Card.Footer>
      <Row>
        <Col md={6}>
          <Button
            isImmutableUnderReview={isImmutableUnderReview}
            variant="danger"
            onClick={handleDelete}
            className="text-left"
          >
            <span className="material-icons md-18">delete</span> {t('delete')}
          </Button>
        </Col>
        <Col md={6} className="text-right">
          <Button
            as={Link}
            variant="light"
            to={`/${accountType}/claim_groups/${claimGroup.id}/project_overview`}
            className="mr-2"
          >
            {t('cancel')}
          </Button>
          <SaveButton isImmutableUnderReview={isImmutableUnderReview} />
        </Col>
      </Row>
    </Card.Footer>
  );
};

export default ClaimProjectReportFooter;
