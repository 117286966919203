import { removeAllNonContentElements } from "../../cleanup";
import { getContentStyle } from "../../trachChangesEditorStyles";
import { acceptAllChanges, rejectAllChanges } from "./acceptRejectChanges";
import { triggerCommentsReload } from "./triggerCommentsReload";
export function addEditorMenuButtons(editor, moduleInstance) {
  editor.ui.registry.addButton('track_changes_previous_change', {
    text: 'Previous Change',
    onAction: () => {
      triggerCommentsReload(editor, moduleInstance, 'previous');
    }
  });
  editor.ui.registry.addButton('track_changes_next_change', {
    text: 'Next Change',
    onAction: () => {
      triggerCommentsReload(editor, moduleInstance, 'next');
    }
  });
  editor.ui.registry.addButton('track_changes_accept_all', {
    text: 'Accept ALL Changes',
    onAction: function (_) {
      acceptAllChanges(editor);
      editor.fire('change', {
        content: editor.getContent()
      });
    }
  });
  editor.ui.registry.addButton('track_changes_reject_all', {
    text: 'Reject ALL Changes',
    onAction: function (_) {
      rejectAllChanges(editor);
      editor.fire('change', {
        content: editor.getContent()
      });
    }
  });
  editor.ui.registry.addButton('track_changes_toggle_changes', {
    text: 'Hide Track Changes',
    id: 'track_changes_toggle_changes',
    'aria-label': 'track_changes_toggle_changes',
    title: 'track_changes_toggle_changes',
    onAction: function (_) {
      const xpath = moduleInstance.toggleTrackChangesVisibility ? "//*[text()='Hide Track Changes']" : "//*[text()='Show Track Changes']";
      const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
      moduleInstance.toggleTrackChangesVisibility = !moduleInstance.toggleTrackChangesVisibility;
      editor.getDoc().head.querySelector('style').innerHTML = getContentStyle(moduleInstance.toggleTrackChangesVisibility);
      matchingElement.innerHTML = moduleInstance.toggleTrackChangesVisibility ? 'Hide Track Changes' : 'Show Track Changes';
      if (!moduleInstance.toggleTrackChangesVisibility) {
        removeAllNonContentElements(editor);
      }
    }
  });
}