import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import claimScheduleService from '@rd-web-markets/shared/dist/services/claim_schedule.service';
import Modal from '@rd-web-markets/shared/dist/util/Modal';
import { Loading } from '@rd-web-markets/shared/dist/util';
import EmailPreviewModalRecipients from '@components/shared/claim/emailPreview/EmailPreviewModalRecipients'
import EmailPreviewModalPreview from './EmailPreviewModalPreview';
import SelectConsultants from '@rd-web-markets/shared/dist/company/SelectConsultants';
import { AddButton, Button } from '@rd-web-markets/shared/dist/util/buttons';
import EmailSentStatus from '@components/shared/claim_group/EmailSentStatus';
import PastSentEmailList from './PastSentEmailList';

/**
 * @param {String|undefined} submitText is optional
 * @param {Function|undefined} onSubmit is optional
 * @returns 
 */
export default function EmailPreviewModal({ claimGroup, company, templateName, renderEditComponent, show, emailType, consultantsAndAdmins, onClaimGroupUpdate, onHide, submitText, onSubmit}) {
  const [claimSchedule, setClaimSchedule] = useState(null);
  const [template, setTemplate] = useState(null);
  const [showConsultantsSelect, setShowConsultantsSelect] = useState(false)
  const [pastTemplateToPreview, setPastTemplateToPReview] = useState(null)
  const dispatch = useDispatch()
  const selectEmailTemplate = useCallback((claimSchedule) => {
    if (!claimSchedule) {
      return
    }

    if (emailType === 'kickoff') {
      setTemplate(claimSchedule.active_email_template)
    } else if (emailType === 'ics') {
      setTemplate(claimSchedule.active_claim_schedule_ics_event_template)
    } else {
      setTemplate(claimSchedule.active_client_cost_template)
    }
  }, [emailType, claimGroup])

  const fetchClaimSchedule = useCallback(async () => {
    // allows us to dynamically render the updated template and contacts even if
    // the parent component does not know about the claim schedule
    try {
      const claimScheduleResult = await claimScheduleService.get(claimGroup.id, claimGroup.claim_schedule.id);
      setClaimSchedule({ ...claimScheduleResult })
      selectEmailTemplate(claimScheduleResult)
    } catch (error) {
      dispatch(handleError(error));
    }
  }, [claimGroup.claim_schedule.id, claimGroup.id, dispatch, selectEmailTemplate])

  const toggleShowConsultantSelect = useCallback(() => {
    setShowConsultantsSelect(!showConsultantsSelect)
  }, [showConsultantsSelect])

  useEffect(() => {
    fetchClaimSchedule()
  }, [fetchClaimSchedule])

  const renderFooterLeftSide = useCallback(() => {
      if (claimSchedule) {
        return <PastSentEmailList style={{ width: '100%' }} claimGroup={claimGroup} pastSentTemplatesList={claimGroup.claim_schedule.client_cost_templates} previewEmailInModal={false} />
        
      }
        
      return <span>Loading</span>
      
    },
    [claimGroup, claimSchedule]
  )

  const EmailPreviewModalBody = () => {
    if (!claimSchedule) {
      return <Loading />
    }

    if(!claimSchedule) {
      return <Card><Card.Header>Please Configure Claim Schedule</Card.Header></Card>;
    }

    return (
      <Tabs defaultActiveKey="preview" className="mb-3">
        <Tab eventKey="preview" title="Preview">
          {!template && <Card><Card.Header>Please configure {templateName} email template.</Card.Header></Card> }
          {!!template && 
            <Card>
              <Card.Header>
                <div style={{ textAlign: 'end' }}>
                  <AddButton
                    text="Add New Consultant"
                    onClick={toggleShowConsultantSelect}
                  />
                </div>
                {showConsultantsSelect &&
                  <div className="mb-5">
                    <SelectConsultants
                      size="lg"
                      claimGroup={claimGroup}
                      consultantsAndAdmins={consultantsAndAdmins[0]}
                      onClaimGroupConsultantRolesChange={onClaimGroupUpdate}
                    />
                  </div>
                  
                }
              </Card.Header>
              <Card.Body>
                <EmailPreviewModalPreview claimGroup={claimGroup} template={template} />
              </Card.Body>
            </Card>
          }
        </Tab>
        <Tab eventKey="configure" title={`Configure ${templateName}`}>
          {renderEditComponent(fetchClaimSchedule)}
        </Tab>
      </Tabs>
    );
  };

  return (
    <>
      <Modal
        size="lg"
        title={templateName}
        show={show}
        renderFooterLeftSide={renderFooterLeftSide}
        renderBody={() => EmailPreviewModalBody()}
        onHide={() => onHide()}
        onSubmit={onSubmit}
        submitText={submitText}
        closeText='Close'
      />
    </>
  );
}
