import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const icsEventEmailTemplateService = {
  all() {
    return fetch('/api/claim_schedule_ics_event_templates', request.get);
  },
  create(claim_schedule_ics_event_template) {
    return fetch('/api/claim_schedule_ics_event_templates/', {
      ...request.post,
      body: JSON.stringify({ claim_schedule_ics_event_template }),
    });
  },
  update(id, claim_schedule_ics_event_template) {
    return fetch(`/api/claim_schedule_ics_event_templates/${id}`, {
      ...request.put,
      body: JSON.stringify({ claim_schedule_ics_event_template }),
    });
  },
  delete(id) {
    return fetch(`/api/claim_schedule_ics_event_templates/${id}`, {
      ...request.delete
    });
  }
};

export default icsEventEmailTemplateService;