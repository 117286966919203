import React, { useState } from 'react';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CloudUploadButton } from '@rd-web-markets/shared/dist/util/buttons/CloudUploadButton';
import { useTranslation } from 'react-i18next';
import ProjectImportModal from './ProjectImportModal';

const ProjectImportButtonAndModal = ({ importProjects, importStatus }) => {
  const [showOverLay, setShowOverLay] = useState(false);
  const [showProjectImportModal, setShowProjectImportModal] = useState(false);
  const { t } = useTranslation();

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Content>
        <div style={{ fontSize: '16px' }}>
          Your import file needs to be an excel file with these fields in this order: Project Name, Start Date, End Date, Belspo
          Code (not mandatory) - Do you wish to continue with the import?
          <div>
            <Button
              variant='info'
              className='mr-1'
              size='lg'
              onClick={() => {
                setShowProjectImportModal(true);
                setShowOverLay(false);
              }}
            >
              {t('yes')}
            </Button>
            <Button variant='light' className='ml-1' size='lg' onClick={() => setShowOverLay(false)}>
              {t('no')}
            </Button>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
  
  return (
    <>
      <ProjectImportModal show={showProjectImportModal} onHide={() => setShowProjectImportModal(false)} importProjects={importProjects}/>

      <OverlayTrigger trigger='click' placement='bottom' overlay={popover} show={showOverLay}>
        <CloudUploadButton
          disabled={importStatus?.import_status === 'pending'}
          className='mr-2'
          onClick={() => setShowOverLay(!showOverLay)}
          text={t('import_projects')}
        />
      </OverlayTrigger>
    </>
  );
};

export default ProjectImportButtonAndModal;
