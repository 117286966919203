import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const clientCostTemplateService = {
  all() {
    return fetch('/api/client_cost_templates', request.get);
  },
  create(client_cost_template) {
    return fetch('/api/client_cost_templates/', {
      ...request.post,
      body: JSON.stringify({ client_cost_template }),
    });
  },
  update(id, client_cost_template) {
    return fetch(`/api/client_cost_templates/${id}`, {
      ...request.put,
      body: JSON.stringify({ client_cost_template }),
    });
  },
  delete(id) {
    return fetch(`/api/client_cost_templates/${id}`, {
      ...request.delete
    });
  }
};

export default clientCostTemplateService;