import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { Breadcrumb, Button, Card, Col, FormControl, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import ClaimGroupsListNavigation from '@rd-web-markets/market/dist/claim_group/list/ClaimGroupsListNavigation';
import MyClaimClaimGroupsNavigation from '@rd-web-markets/market/dist/my/MyClaimClaimGroupsNavigation';
import claimProjectReportService from '@services/claim_project_report.service';
import AllClaimProjectReportsList from '@components/shared/claim_project_report/all_projects/AllClaimProjectReportsList';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@components/util/MasterSidebar';
import { useTranslation } from 'react-i18next';
import myClaimGroupsService from '@rd-web-markets/shared/dist/services/claim_groups/my_claim_groups.service';
import Pagination from '@rd-web-markets/shared/dist/util/Pagination';
import claimProjectReportsOverviewService from '@services/overview/claim_project_reports_overview.service';

const initialParams = {
  page: 1,
  projectName: '',
  companyName: ''
}


const ProjectsPage = ({ accountType }) => {
  const user = useSelector((state) => state.auth.user);
  const [reports, setReports] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [params, setParams] = useState(
    window.location.search ? QueryString.parse(window.location.search) : initialParams
  );
  const { pathname } = useLocation();
  const isMyPage = /projects\/my/.test(pathname)
  const pageTitle = isMyPage ? 'my_projects' : 'all_projects'

  const fetchData = async () => {
    let response;
    setLoading(true);
    if(isMyPage){
      response = await myClaimGroupsService.all(QueryString.stringify(params));
      const allReports = []
      response.claim_groups.forEach( c => c.claim_project_reports.forEach(r => allReports.push({ ...r, claim_group: c})))
      setReports(allReports);
      setTotalPages(response.pages);
    }else{
      response = await claimProjectReportsOverviewService.all(QueryString.stringify(params));
      setReports(response.claim_project_reports);
      setTotalPages(response.pages);
    }

    history.push({
      path: 'admin/claim_project_reports',
      search: QueryString.stringify(params),
    });

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    setProjectName(params.projectName);
    setCompanyName(params.companyName);
  }, [history, params]);

  const handleSearch = () => {
    setParams({ ...params, projectName, companyName, page: 1 });
  };

  const handleChangePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const deleteClaimProjectReport = async (report) => {
    if(window.confirm('Are you sure you wish to delete this project ?')){
      setLoading(true);
      try {
        await claimProjectReportService.delete(report.claim_group.id, report.id);
        await fetchData();
      } catch(error){
        dispatch(handleError(error.description));
      };
      setLoading(false);
    }
  }

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handleChangePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  };


  return (
    <>
      <MasterSidebar accountType={accountType} currentPage={isMyPage ? 'my_claim_groups' : 'all_claim_groups'} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t(pageTitle)}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card>
        <Card.Header>
        { user.account_type === 'consultant' || isMyPage ?
          <MyClaimClaimGroupsNavigation accountType={user.account_type} page='projects'/>
          :
          <ClaimGroupsListNavigation page='projects' />
        }
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header>{t(pageTitle)}</Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={3}>
                    <FormControl
                        onChange={e => setCompanyName(e.target.value)}
                        value={companyName}
                        name='companyName'
                        type="text"
                        style={{width: '100%'}}
                      />
                    </Col>
                    <Col md={2}>
                      <FormControl
                        onChange={e => setProjectName(e.target.value)}
                        value={projectName}
                        name='projectName'
                        type="text"
                        style={{width: '100%'}}
                      />
                    </Col>
                    <Col md={{span: 1, offset: 6}}>
                      <Button loading={loading} icon='search' variant="primary" size="md" onClick={() => handleSearch()}>{t('search')}</Button>
                    </Col>
                  </Row>
                {reports && <AllClaimProjectReportsList deleteClaimProjectReport={deleteClaimProjectReport} reports={reports} loading={loading} />}
              </Card.Body>
              </Card>
            </Col>
          </Row>
          <Pagination currentPage={Number(params.page)} totalPages={totalPages} handlePageChange={handleChangePage} />   
        </Card.Body>
      </Card>
    </>
  )
}

export default ProjectsPage
