import { buildService } from '@rd-web-markets/shared/dist/services/service'
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods'
import { useCallback, useEffect } from 'react'
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const api = buildService('/claim_groups/{id}/project_template_imports', { only: [ 'all', 'base' ]}, { only: ['postFormData'] })

const useCreateProjectImport = ({ claimGroupId, setLastImportStatus }) => {
  //accepts an object with the formDataJson field and any other fields.
  const postFormData = serviceMethods.usePostFormData({
    callback: useCallback(
      async ({ formData, type }) => {
        setLastImportStatus({ import_status: 'pending' })
        return  await api.postFormData(claimGroupId, formData, { type })

    }, [claimGroupId, setLastImportStatus])
  })

  return postFormData
}

const useFetchLastImportStatusWithInterval = ({ claimGroupId, setLoading }) => {
  const {
    interval,
    setInterval,
    stateVar: lastImportStatus,
    setStateVar: setLastImportStatus
  } = serviceMethods.useFetchWithInterval({
    api,
    callback: useCallback(async () => await api.all(claimGroupId), [claimGroupId]),
    setLoading
  })

  return { lastImportStatus, setLastImportStatus, interval, setInterval }
}

const projectTemplateImportService = {
  useCreateProjectImport,
  useFetchLastImportStatusWithInterval,
  delete(claimGroupId, id) { 
    return fetch(`/api/claim_groups/${claimGroupId}/project_template_imports/${id}`, request.delete);
  }
}

export default projectTemplateImportService
