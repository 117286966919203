import { SHOW_FAQ_ENTRY_HEADER } from '@rd-web-markets/market/dist/constants';
import faqEntryService from '@rd-web-markets/shared/dist/services/faq/faq_entry.service';
import { SaveButton } from '@rd-web-markets/shared/dist/util/buttons';
import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';
import { apiKey, getDefaultConfig } from '@rd-web-markets/shared/dist/util/tinyMceConfig';
import { Editor } from '@tinymce/tinymce-react';
import React, { useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const languageKeyToValue = {
  en: 'english',
  fr: 'french',
  nl: 'dutch',
  de: 'german',
};

const editorConfig = {
  editMode: {
    stylesFormatterToggleIniitialValue: true,
    modules: { styles_formatter: true },
  },
  readOnly: {
    height: 300,
    width: 700,
    menubar: false,
    toolbar: false,
    plugins: [],
    branding: false,
    resize: false
  },
};

const FaqEntry = ({ data, editMode = true }) => {
  const [faqEntry, setFaqEntry] = useState(data);
  const [loading, setLoading] = useState(false);
  const { saveFaqEntry } = faqEntryService.useCrud(setLoading);
  const { t } = useTranslation();

  const handleTinyMce = (content) => {
    if (editMode) {
      setFaqEntry({
        ...faqEntry,
        content: content,
      });
    }
  };

  return (
    <>
      <Row className='justify-content-center' style={{ maxWidth: editMode ? '470px' : '', margin: '0px 10px' }}>
        <Card>
          {(editMode && SHOW_FAQ_ENTRY_HEADER) && <Card.Header className='bg-secondary'>{languageKeyToValue[data.language]}</Card.Header>}
          {editMode ? (
            <ImmutableUnderReviewFormControl
              as={Editor}
              apiKey={apiKey}
              onEditorChange={(content) => handleTinyMce(content)}
              value={faqEntry.content}
              name={'content'}
              init={getDefaultConfig(editorConfig.editMode)}
            />
          ) : (
            <Editor
              apiKey={apiKey}
              init={editorConfig.readOnly}
              initialValue={faqEntry.content}
              disabled={true}
            />
          )}
          {editMode && (
            <Row>
              <SaveButton
                onClick={() => saveFaqEntry(faqEntry)}
                style={{ margin: '10px 20px 10px auto' }}
                disabled={loading}
                text={loading ? 'saving' : 'save'}
              />
            </Row>
          )}
        </Card>
      </Row>
    </>
  );
};

export default FaqEntry;
