import { CLAIM_PROJECT_OTHER_FIELDS } from "@rd-web-markets/market/dist/claimProjectOtherFields";
import { modifyReportTemplateCategoriesForProject } from "@rd-web-markets/market/dist/constants";
const TemplateCateogiresForCreatingProject = chosenTemplate => {
  // add missing categories
  const missingFields = [];
  CLAIM_PROJECT_OTHER_FIELDS.forEach(additionalField => {
    const fieldIsAlreadyInTheTemplate = chosenTemplate.report_template_categories.some(category => category.title === additionalField.title);
    if (!fieldIsAlreadyInTheTemplate) {
      missingFields.push({
        ...additionalField
      });
    }
  });
  const fullReportTemplate = JSON.parse(JSON.stringify(chosenTemplate));
  fullReportTemplate.report_template_categories.splice(...modifyReportTemplateCategoriesForProject, ...missingFields);
  return fullReportTemplate;
};
export default TemplateCateogiresForCreatingProject;