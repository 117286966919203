import React from 'react'

import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary';

export default function UnexpectedSynchronousErrorBoundary({ children }) {
  const location = useLocation();

  const ErrorMessage = ({error, componentStack, resetErrorBoundary}) => {    
    return (
      /*
      * Shows an error message, when the page breaks from unexpected synchronous errors.
      * E.g. calling null.method().
      * Can be extended, but by default does not catch errors in events.
      */
      <div>
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <pre>{componentStack}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage} resetKeys={[location]}>
      {children}
    </ErrorBoundary>
  )
}
