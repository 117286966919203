import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { useCallback } from 'react';
const api = buildService('/claim_groups/{id}/claim_template_tasks', {
  only: ['update']
});
const useUpdateClaimGroupTask = _ref => {
  let {
    claimGroup,
    setClaimGroup,
    setLoading,
    handleToaster
  } = _ref;
  const updateClaimGroupTask = useErrorHandling(useCallback(async _ref2 => {
    let {
      taskIndex,
      categoryIndex,
      field,
      value
    } = _ref2;
    console.log('update called with', {
      ...{
        taskIndex,
        categoryIndex,
        field,
        value
      }
    });
    setLoading && setLoading(true);
    const updatedClaimGroup = await api.update(claimGroup.id, taskIndex, {
      category_index: categoryIndex,
      field,
      value
    });
    setClaimGroup({
      ...updatedClaimGroup
    });
    handleToaster && handleToaster('Task Updated!');
  }, [claimGroup, handleToaster, setClaimGroup, setLoading]), useCallback(() => {
    setLoading && setLoading(false);
  }, [setLoading]));
  return updateClaimGroupTask;
};
const claimTemplateTasksService = {
  useUpdateClaimGroupTask
};
export default claimTemplateTasksService;