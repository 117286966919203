import React, { useState, useEffect } from 'react'
import {  Card, Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CompanyService } from '@services/company.service';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import ClaimProjectReportForm from './ClaimProjectReportForm';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { companyListsService } from '@services/lists/company_list.service';

const CreateClaimProjectReportPage = ({handleToaster, accountType}) => {
  const [companies, setCompanies] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    async function getCompanies() {
      try {
        const response = await companyListsService.all();
        setCompanies(response.companies);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    getCompanies();
  }, [history]);

  return (
    <>
      <SidebarPortal headerItem={{link: 'list_of_companies', text: 'List of Companies'}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin/list_of_companies'}}>{t('list_of_companies')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('create_project')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      <Card>
        <Card.Header className="bg-gradient-primary text-white">{t('create_project')}</Card.Header>
        <Card.Body>
          <ClaimProjectReportForm setLoading={setLoading} accountType={accountType} companies={companies} loading={loading} Link={Link} handleToaster={handleToaster}/>
        </Card.Body>
      </Card>
    </>
  )
}

export default CreateClaimProjectReportPage