import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap'
import { DatePicker } from '@rd-web-markets/shared/dist/util/date'
import TextInputGroup from '@components/util/TextInputGroup'
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';

const ClaimSubmissionForm = ({loading, claim, submitClaim, index}) => {
  const [selectedDate, setSelectedDate] = useState(claim.claim_submission?.submission_date || new Date());

  const submitForm = () => {
    submitClaim(index, claim.id, selectedDate)
  }

  return (
    <Form>
      <Form.Group>
        <Form.Row md={2}>
          <Form.Label column="md" md={4}>
            Date submitted to HMRC for {claim.name}
          </Form.Label>
          <Col md={4}>
            <TextInputGroup placeholder="Start Date" prependIcon="calendar_month">
              <DatePicker
                className='border-0'
                selected={selectedDate}
                onChange={date => setSelectedDate(date)}
                disabled={!!claim.claim_submission?.submission_date}
              />
            </TextInputGroup>
          </Col>
          <Button loading={loading} variant="primary" disabled={claim.claim_submission?.submission_date} onClick={submitForm}>
          { claim.claim_submission?.submission_date
            ? `Submitted by ${claim.claim_submission?.submitted_by} on ${createTimezonedDate(claim.claim_submission.submission_date).toCustomLocaleDateString()}`
            : 'Submit'
          }
          </Button>
        </Form.Row>
      </Form.Group>
    </Form>
  )
}

export default ClaimSubmissionForm
