import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { createEndOfCurrentYear, createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import ClaimSelectionField from '@rd-web-markets/market/dist/company/ClaimSelectionField';

const ClaimsSelection = ({toggleInclude, claims, toggleForm, includeSingleClaim, loading, setFromDate, setClaims}) => {
  claims.forEach(claim => {
    if (!claim.to_date) {
      claim.to_date = createEndOfCurrentYear();
    }
  })

  const claimsSelected = () => {
    return claims.some(claim => claim.include === true);
  }

  const user = useSelector((state) => state.auth.user);

  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Header className="bg-gradient-primary text-white">
            New Company - Choose Claims
          </Card.Header>
          <Card.Body>
            <ClaimSelectionField 
            toggleInclude={toggleInclude} claims={claims} toggleForm={toggleForm} includeSingleClaim={includeSingleClaim} loading={loading} setFromDate={setFromDate} setClaims={setClaims}
            />
          </Card.Body>
          {!includeSingleClaim &&
          <Card.Footer>
            <Row>
              <Col md={12} className="text-left">
                <Button as={Link} variant="light" to={`/${user.account_type}/companies/`}>
                  <span className="material-icons md-18">chevron_left</span> Back
                </Button>
                <Button
                  variant="primary"
                  size="md"
                  className="float-right"
                  disabled={!claimsSelected()}
                  onClick={() => toggleForm()}
                >
                  Include & Continue <span className="material-icons md-18">chevron_right</span>
                </Button>
              </Col>
            </Row>
          </Card.Footer>
          }
        </Card>
      </Col>
    </Row>
  );
}

export default ClaimsSelection
