import ImmutableUnderReviewCompanyFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewCompanyFormControl';
import React, { useState } from 'react';
import { Button, Form, Col, Row, Badge, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import companyGroupsCompaniesService from '@rd-web-markets/shared/dist/services/company_groups/companies.service';
import { companyGroupService } from '@services/company_group.service';

const CompanyAddForm = ({companies, companyGroup, setCompanyGroup}) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { t } = useTranslation();

  const handleChange = e => {
    setSelectedCompany(+e.target.value);
  }

  const submit = async () => {
    await companyGroupsCompaniesService.create(companyGroup.id, selectedCompany)
    const response = await companyGroupService.get(companyGroup.id);
    setCompanyGroup(response);
    setSelectedCompany(null);
  }



  return (
    <Form.Group as={Row}>
      <Form.Label column md={4}>
        Select Company to Add to Group
      </Form.Label>
      <Col md={4}>
        <ImmutableUnderReviewCompanyFormControl
          as="select"
          size="md"
          name="companyId"
          onChange={e => handleChange(e)}
          type="text"
          placeholder="Small text"
          value={selectedCompany || ''}
        >
          <option value={''}></option>
          {companies
          .filter(company => companyGroup.companies.every(c => c.id !== company.id))
          .map((c) => {
            return (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            );
          })}
        </ImmutableUnderReviewCompanyFormControl>
      </Col>
      <Col md={2}>
        <Button
          disabled={!selectedCompany}
          variant="link"
          onClick={submit}>
          <span className="material-icons md-18">add</span> {t('add')}
        </Button>
      </Col>
    </Form.Group>
  )
}

export default CompanyAddForm

