import { useCallback, useEffect, useState } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
const api = buildService('/companies/{id}/company_contacts', {
  only: ['all', 'create', 'update', 'delete', 'base']
});

/**
 * Unhandled for use in Modals, because the Modal component handles errors itself, and shows
 * the error warnings in its own body.
 * Returns a method that creates a company contact for the selected company.
 * First it creates a user for the company contact.
 * Then it sends that user to the backend company contacts controller, which adds the relevant company role to that user.
 * @param {*} companyId 
 * @returns 
 */
const useUnhandledCreateCompanyContact = companyId => {
  return useCallback(async company_contact => {
    company_contact.account_type = 'customer';
    const createdUser = await UserService.create(company_contact);
    await api.create(companyId, {
      user_id: createdUser.id,
      contact_company_role: company_contact.contact_company_role
    });
    return {
      ...createdUser
    };
  }, [companyId]);
};

/**
 * Unhandled for use in Modals, because the Modal component handles errors itself, and shows
 * the error warnings in its own body.
 * Returns a method that updates a company contact.
 * @param {*} companyId 
 * @returns 
 */
const useUnhandledUpdateCompanyContact = companyId => {
  return useCallback(async company_contact => {
    const updatedUser = await UserService.update(company_contact);
    await api.update(companyId, company_contact.id, {
      contact_company_role: company_contact.contact_company_role
    });
    return updatedUser;
  }, [companyId]);
};
const useDeleteCompanyContact = (companyId, onFinally) => {
  return useErrorHandling(useCallback(async company_contact_id => {
    if (window.confirm('Are you sure you want to delete this company contact? This will NOT their associated express user account as well.')) {
      return await api.delete(companyId, company_contact_id);
    }
  }, []), useCallback(async () => {
    await onFinally();
  }, [onFinally]));
};

// const useUpdateCompanyContactClaimGroupRole = companyId => {
//   return useErrorHandling(useCallback(async (claim_group_id, company_contact) => {
//     const { contact_type } = company_contact

//     return await api.update(companyId, company_contact.id, { contact_type, claim_group_id })
//   }, [companyId]))
// }

const useFetchCompanyContacts = (companyId, setLoading) => {
  const [companyContacts, setCompanyContacts] = useState([]);
  const fetchContacts = useErrorHandling(useCallback(async () => {
    setLoading(true);
    const allContacts = await api.all(companyId);
    setCompanyContacts(allContacts);
  }, [companyId, setLoading]), useCallback(() => {
    setLoading(false);
  }, [setLoading]));
  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);
  return [companyContacts, setCompanyContacts, fetchContacts];
};
const useRemoveContactAccessToCompany = (user, setUser) => {
  return useErrorHandling(useCallback(async companyId => {
    await api.delete(companyId, user.id);
    user.user_accesses = user.user_accesses.filter(ua => !(ua.rollable_type === 'Company' && ua.rollable_id === companyId));
    setUser({
      ...user
    });
  }, [setUser, user]));
};
const companyContactsService = {
  base: api.base,
  useFetchCompanyContacts,
  useUnhandledCreateCompanyContact,
  useUnhandledUpdateCompanyContact,
  useDeleteCompanyContact,
  useRemoveContactAccessToCompany
};
export default companyContactsService;