import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ProjectItemName = ({report}) => {
  if(report.project_name.length > 40) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{report.project_name}</Tooltip>}
      >
        {({ ref, ...triggerHandler }) => (
            <span className="ms-1" ref={ref} {...triggerHandler}>{report.short_name}</span>
        )}
      </OverlayTrigger>
    );
  } else {
    return (
      <span>{report.project_name}</span>
    )
  }

}

export default ProjectItemName