import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { AppCache } from '../util/AppCache';
export const api = {
  get: endpoint => fetch("/api".concat(endpoint), request.get),
  delete: endpoint => fetch("/api".concat(endpoint), request.delete),
  update: (endpoint, data) => {
    return fetch("/api".concat(endpoint), {
      ...request.put,
      body: JSON.stringify(data)
    });
  },
  create: (endpoint, data) => {
    return fetch("/api".concat(endpoint), {
      ...request.post,
      body: JSON.stringify(data)
    });
  },
  postFormData: (endpoint, data) => {
    return fetch("/api".concat(endpoint), {
      ...request.postFormData,
      body: data
    });
  },
  putFormData: (endpoint, data) => {
    return fetch("/api".concat(endpoint), {
      ...request.putFormData,
      body: data
    });
  },
  createInterval: (callback, timeoutMilliseconds) => {
    const intervalObject = {
      started: false,
      // will contain the id of the started interval
      start: () => {
        const createdInterval = setInterval(() => {
          callback();
        }, [timeoutMilliseconds]);
        intervalObject.started = createdInterval;
      },
      clear: () => {
        clearInterval(intervalObject.started);
        intervalObject.started = false;
      }
    };
    return intervalObject;
  }
};
export function createCachedService(cacheName) {
  return {
    get: async endpoint => {
      const cache = AppCache.getCache(cacheName);
      if (cache[endpoint]) {
        return cache[endpoint];
      } else {
        const result = await fetch("/api".concat(endpoint), request.get);
        cache[endpoint] = result;
        return result;
      }
    },
    delete: api.delete,
    update: api.update,
    create: api.create
  };
}

/**
 * Creates a new FormData and adds each key value from the json object to it.
 * @param {*} jsonObject 
 * @returns 
 */
export function buildFormData(jsonObject) {
  const formData = new FormData();
  for (const key in jsonObject) {
    if (Object.hasOwnProperty.call(jsonObject, key) && ![null, undefined].includes(jsonObject[key])) {
      formData.append(key, jsonObject[key]);
    }
  }
  return formData;
}

/**
 * { key1: 'value1', key2: null, key3: '', key4: true, key5: 5, key6: false } would result into
 * key1=value1&key4=true&key5=5&key6=false
 * @param {*} jsonObject
 * @returns 
 */
export function buildQueryString(jsonObject) {
  const nonEmptyParamEntries = Array.from(Object.entries(jsonObject)).filter(_ref => {
    let [key, value] = _ref;
    return ![null, undefined, ''].includes(value);
  });
  const queryString = new URLSearchParams(Object.fromEntries(nonEmptyParamEntries)).toString();
  return queryString;
}

/**
 * Parses the location string or a provided query string to nested json object.
 * For nested objects. E.g. say the location string is order_by=report_order&page=1&query=project_name%3Db%26name%3Da%26project_category%3Drnd%26business_unit%3Db
 * This is a nested query case where the query param gets parsed to "project_name=b&name=a&project_category=rnd&business_unit=b"
 * In this case, because there is '=' in the value of 'query', then that value will be parsed again - to turn it into a json.
 * Ther first run of the function will result in
 * {
 *  "order_by": "report_order",
 *  "page": "1",
 *  "query": "project_name=b&name=a&project_category=rnd&business_unit=b"
 * }
 * 
 * Because of the recursion, the final result will be
 * {
 *      "order_by": "report_order",
 *      "page": "1",
 *      "query": {
 *          "project_name": "b",
 *          "name": "a",
 *          "project_category": "rnd",
 *          "business_unit": "b"
 *      }
 * }
 * 
 * @param {*} queryString 
 * @returns 
 */
export function parseQueryString() {
  let queryString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  const queryParams = new URLSearchParams(queryString || window.location.search);
  const jsonQueryParams = {};
  // entries are key value parirs - e.g. project_name='test' => ['project_name', 'test']
  for (const [key, value] of queryParams.entries()) {
    jsonQueryParams[key] = value;
  }
  for (const key in jsonQueryParams) {
    if (jsonQueryParams[key].includes('=')) {
      jsonQueryParams[key] = parseQueryString(jsonQueryParams[key]);
    }
  }
  return jsonQueryParams;
}

/**
 * 
 * @param {Array} routes   // an array of models - e.g. if it is a companies service then simply pass ['companies'].
 *                         // However if it is a nested service - e.g. companies/{id}/claim_groups - then pass ['companies', 'claim_groups'] instead.
 * @param {Object} methodSettings // An object specifying the methods of the service. Made to replicate the callbacks setup on the backend.
 *                         // { only: Array } - only these methods will be defined. E.g. { only: ['get', 'create' ] } will create a service with the get and create methods.
 *                         // { except: Array } - define all methods except the listed. E.g. { except: ['create'] }
 * @param {Object} formMethodSettings // Same as methodSettings except that almost all services do not need form methods so by default they are in the except array. E.g. { except: ['postFormData', 'putFormData'] }
 * @returns 
 */
export function buildService(modelsRoute, methodSettings) {
  var _methodSettings$only, _formMethodSettings$o;
  let formMethodSettings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    except: ['postFormData', 'putFormData']
  };
  const buildInstanceSpecificUrl = (method, idParams) => {
    const routeToUse = ['get', 'update', 'delete', 'putFormData'].includes(method) ? modelsRoute + '/{id}' : modelsRoute;
    let matchIndex = -1;
    const instanceSpecificUrl = routeToUse.replace(/{id}/g, match => {
      matchIndex += 1;
      const replacement = idParams[matchIndex] !== undefined ? idParams[matchIndex] : '';
      return replacement;
    });
    if (instanceSpecificUrl.includes('//')) {
      const urlWithError = instanceSpecificUrl.replace('//', '/{missing param}/');
      console.error('Invalid request endpoint format. ', urlWithError, idParams);
      throw new Error('Invalid request endpoint format.');
    }
    return [instanceSpecificUrl, matchIndex];
  };
  const serviceObject = {
    base: {
      ...api
    },
    get: function () {
      for (var _len = arguments.length, ids = new Array(_len), _key = 0; _key < _len; _key++) {
        ids[_key] = arguments[_key];
      }
      const [instanceSpecificUrl] = buildInstanceSpecificUrl('get', ids);
      return api.get("".concat(instanceSpecificUrl));
    },
    all: function () {
      for (var _len2 = arguments.length, params = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        params[_key2] = arguments[_key2];
      }
      const [instanceSpecificUrl, lastMatchIndex] = buildInstanceSpecificUrl('all', params);

      // queryParamsObject is optional
      const queryParamsObject = params[lastMatchIndex + 1];
      const queryString = queryParamsObject ? "?".concat(buildQueryString(queryParamsObject) || '') : '';
      return api.get("".concat(instanceSpecificUrl).concat(queryString));
    },
    update: function () {
      for (var _len3 = arguments.length, params = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        params[_key3] = arguments[_key3];
      }
      const [instanceSpecificUrl, lastMatchIndex] = buildInstanceSpecificUrl('update', params);
      // queryParamsObject is optional
      const [payload, queryParamsObject] = [params[lastMatchIndex + 1], params[lastMatchIndex + 2]];
      const queryString = queryParamsObject ? "?".concat(buildQueryString(queryParamsObject) || '') : '';
      return api.update("".concat(instanceSpecificUrl).concat(queryString), payload);
    },
    delete: function () {
      for (var _len4 = arguments.length, params = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        params[_key4] = arguments[_key4];
      }
      const [instanceSpecificUrl, lastMatchIndex] = buildInstanceSpecificUrl('delete', params);

      // queryParamsObject is optional
      const queryParamsObject = params[lastMatchIndex + 1];
      const queryString = queryParamsObject ? "?".concat(buildQueryString(queryParamsObject) || '') : '';
      return api.delete("".concat(instanceSpecificUrl).concat(queryString));
    },
    create: function () {
      for (var _len5 = arguments.length, params = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
        params[_key5] = arguments[_key5];
      }
      const [instanceSpecificUrl, lastMatchIndex] = buildInstanceSpecificUrl('create', params);

      // queryParamsObject is optional
      const [payload, queryParamsObject] = [params[lastMatchIndex + 1], params[lastMatchIndex + 2]];
      const queryString = queryParamsObject ? "?".concat(buildQueryString(queryParamsObject) || '') : '';
      return api.create("".concat(instanceSpecificUrl).concat(queryString), payload);
    },
    postFormData: function () {
      for (var _len6 = arguments.length, params = new Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
        params[_key6] = arguments[_key6];
      }
      const [instanceSpecificUrl, lastMatchIndex] = buildInstanceSpecificUrl('postFormData', params);

      // queryParamsObject is optional
      const [payload, queryParamsObject] = [params[lastMatchIndex + 1], params[lastMatchIndex + 2]];
      const queryString = queryParamsObject ? "?".concat(buildQueryString(queryParamsObject) || '') : '';
      return api.postFormData("".concat(instanceSpecificUrl).concat(queryString), payload);
    },
    putFormData: function () {
      for (var _len7 = arguments.length, params = new Array(_len7), _key7 = 0; _key7 < _len7; _key7++) {
        params[_key7] = arguments[_key7];
      }
      const [instanceSpecificUrl, lastMatchIndex] = buildInstanceSpecificUrl('putFormData', params);

      // queryParamsObject is optional
      const [payload, queryParamsObject] = [params[lastMatchIndex + 1], params[lastMatchIndex + 2]];
      const queryString = queryParamsObject ? "?".concat(buildQueryString(queryParamsObject) || '') : '';
      return api.putFormData("".concat(instanceSpecificUrl).concat(queryString), payload);
    }
  };
  if (methodSettings !== null && methodSettings !== void 0 && methodSettings.only && methodSettings !== null && methodSettings !== void 0 && methodSettings.except || formMethodSettings !== null && formMethodSettings !== void 0 && formMethodSettings.only && formMethodSettings !== null && formMethodSettings !== void 0 && formMethodSettings.except) {
    throw new Error('Cannot pass both only and except options. Pick only one.');
  }
  const except = [...((methodSettings === null || methodSettings === void 0 ? void 0 : methodSettings.except) || []), ...((formMethodSettings === null || formMethodSettings === void 0 ? void 0 : formMethodSettings.except) || [])];
  except.forEach(methodName => delete serviceObject[methodName]);
  if (methodSettings !== null && methodSettings !== void 0 && (_methodSettings$only = methodSettings.only) !== null && _methodSettings$only !== void 0 && _methodSettings$only.length) {
    Object.keys(serviceObject).forEach(methodName => {
      // delete all non required methods that are NOT form methods
      if (!methodSettings.only.includes(methodName) && !methodName.toLowerCase().includes('form')) {
        delete serviceObject[methodName];
      }
    });
  }
  if (formMethodSettings !== null && formMethodSettings !== void 0 && (_formMethodSettings$o = formMethodSettings.only) !== null && _formMethodSettings$o !== void 0 && _formMethodSettings$o.length) {
    Object.keys(serviceObject).forEach(methodName => {
      // delete all non required methods that ARE form methods
      if (!formMethodSettings.only.includes(methodName) && methodName.toLowerCase().includes('form')) {
        delete serviceObject[methodName];
      }
    });
  }
  return serviceObject;
}