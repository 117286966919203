import { api } from '@rd-web-markets/shared/dist/services/service';
const claimProjectReportSubRouteService = {
  getAll: (id, subRoute) => api.get("/claim_project_reports/".concat(id, "/").concat(subRoute)),
  delete: (id, subRoute, subRouteId) => api.delete("/claim_project_reports/".concat(id, "/").concat(subRoute, "/").concat(subRouteId)),
  update: function (id, subRoute, body) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.update("/claim_project_reports/".concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  create: (id, subRoute, body) => api.create("/claim_project_reports/".concat(id, "/").concat(subRoute), body),
  putFormData: function (id, subRoute, body) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.putFormData("/claim_project_reports/".concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  postFormData: function (id, subRoute, body) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.postFormData("/claim_project_reports/".concat(id, "/").concat(subRoute), body);
  }
};
export default claimProjectReportSubRouteService;