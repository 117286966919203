import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimProjectReportSubRouteService from '@rd-web-markets/shared/dist/services/claim_groups/claim_project_report_sub_route.service';
import generalRouteService from '@rd-web-markets/shared/dist/services/general_route.service';
import generalRouteSubRouteService from '@rd-web-markets/shared/dist/services/general_route_subroute.service';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import orderBy from 'lodash.orderby';
const staffService = {
  useImportStatus(companyId) {
    const [data, setData] = useState([]);
    const [importStatus, setImportStatus] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [intervalId, setIntervalId] = useState(null);
    const {
      report_id
    } = useParams();
    const getImportStatusAndResearcherData = useErrorHandling(useCallback(async () => {
      const response = await this.getImportStatus(companyId);
      if (response === null) {
        setImportStatus({
          import_status: 'never_imported'
        });
      } else {
        setImportStatus({
          ...response
        });
      }
      if ((response === null || response === void 0 ? void 0 : response.import_status) !== 'pending') {
        staffService.useGetStaffCost(companyId, setData, report_id);
      }
      return response;
    }, [companyId, report_id]));
    const importTemplate = useErrorHandling(useCallback(async body => {
      const response = await this.importResearcherTemplate(companyId, body, report_id);
      setImportStatus({
        ...response
      });
    }, [companyId, report_id]));
    useEffect(() => {
      if (importStatus.import_status === 'pending' && !intervalId) {
        const currentId = setInterval(() => {
          getImportStatusAndResearcherData();
        }, 4000);
        setIntervalId(currentId);
      } else if (importStatus.import_status !== 'pending' && !!intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
      return () => {
        clearInterval(intervalId);
      };
    }, [getImportStatusAndResearcherData, importStatus.import_status, intervalId, report_id]);
    useEffect(() => {
      getImportStatusAndResearcherData();
    }, [getImportStatusAndResearcherData]);
    return [data, setData, importStatus, importTemplate, report_id, searchQuery, setSearchQuery];
  },
  async useGetStaffCost(companyId, setData, selectedProjectId) {
    let searchQuery = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    setData([]);
    const response = await staffService.getResearchers(companyId, selectedProjectId, 'researchers', searchQuery);
    const responseOrderedById = orderBy(response, ['id'], ['asc']);
    setData([...responseOrderedById]);
  },
  useExportTemplate(selectedProjectId, companyId) {
    const companyRoute = "/api/companies/".concat(companyId, "/export_researcher_template");
    const projectRoute = "/api/claim_project_reports/".concat(selectedProjectId, "/export_researcher_template");
    const route = selectedProjectId ? projectRoute : companyRoute;
    window.open(route);
  },
  getResearchers(companyId) {
    let selectedProjectId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    let route = arguments.length > 2 ? arguments[2] : undefined;
    let searchQuery = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    const finalSearchQuery = "?search=".concat(searchQuery);
    if (selectedProjectId) {
      return generalRouteSubRouteService.getAll(selectedProjectId, 'claim_project_reports', 'researchers', finalSearchQuery);
    } else {
      const finalRoute = "".concat(route).concat(finalSearchQuery);
      return generalRouteService.getAll("/companies/".concat(companyId, "/").concat(finalRoute));
    }
  },
  getImportStatus(companyId) {
    return generalRouteService.getAll("companies/".concat(companyId, "/import_researcher_templates"));
  },
  importResearcherTemplate(companyId, body) {
    let claimProjectReportId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    return generalRouteService.postFormData("/companies/".concat(companyId, "/import_researcher_templates?project_id=") + claimProjectReportId, body);
  }
};
export default staffService;