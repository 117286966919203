import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { Breadcrumb, Button, Card, Col, Pagination, Row } from 'react-bootstrap';
import SearchBar from '@components/util/SearchBar';
import { useDispatch } from 'react-redux';
import { CompanyService } from '@services/company.service';
import { Loading } from '@rd-web-markets/shared/dist/util';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@components/util/MasterSidebar';
import { useTranslation } from 'react-i18next';
import CompanyList from '@rd-web-markets/shared/dist/company/CompanyList';
import { CompanyPageHeader, CompanyCardHeader, CompanyCardActionButtons } from '@rd-web-markets/market/dist/company';
import { SHOULD_SHOW_ADD_COMPANY_BUTTON } from '@rd-web-markets/market/dist/constants';
import { companyListsService } from '@services/lists/company_list.service';

const initialParams = 'page=1&query=';

const CompanyPage = ({accountType}) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [params, setParams] = useState(
    QueryString.parse(location.search || initialParams)
  );

  const queryString = QueryString.stringify(params)

  const [companies, totalPages, refreshCompanies] = companyListsService.useGetAll(params, setLoading)

  useEffect(() => {
    setLoading(true);
    history.push({
      path: `${accountType}/claims`,
      search: queryString,
    });
  }, [accountType, history, params, queryString]);


  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  if(!companies) return <Loading />;

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='companies' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('companies')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <CompanyPageHeader queryParams={params} setLoading={setLoading} loading={loading} page={'company_page'}/>
            </Card.Header>
            
            <Card.Body>
              { (accountType === 'admin' || accountType ==='consultant' || accountType ==='super_consultant') && 
              <Row>
                <Col md={4}>
                  { SHOULD_SHOW_ADD_COMPANY_BUTTON &&
                    <Button
                      variant="primary"
                      as={Link}
                      to={`/${accountType}/companies/new`}>
                      <span className="material-icons md-18">add</span> {t('add_company')}
                    </Button>
                  }
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                  <SearchBar onSubmit={handleSearch} loading={loading} query={params.query} />
                </Col>
              </Row> }
              {companies && (
                <CompanyList
                  models={companies}
                  accountType={accountType}
                  refreshCompanies={refreshCompanies}
                  CompanyCardHeader={CompanyCardHeader}
                  CompanyCardActionButtons={CompanyCardActionButtons}
                  setLoading={setLoading}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Pagination className="justify-content-end">{items}</Pagination>
    </>
  );
}

export default CompanyPage
