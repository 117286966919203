import { DDMMYYYStringToDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import { Breadcrumb } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';

export default function FinancialSectionBreadcrumb({ claim, page }) {
  const user = useSelector((state) => state.auth.user);

  return (
    <BreadcrumbsPortal>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>Home</Breadcrumb.Item>
        { user.account_type !== 'customer' &&
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies`}}>Companies</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claim.company.id}/master`}}>{claim.company.name}</Breadcrumb.Item>
          </>
        }
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{
            to: user.account_type !== 'customer'
              ? `/${user.account_type}/claim_groups/${claim.claim_group.id}/project_overview`
              : `/${user.account_type}/companies/${claim.company.id}/${claim.claim_group.id}`
          }}>
            {claim.claim_group.name}
          </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/claims/${claim.id}/financial_details/${page}`}}>{DDMMYYYStringToDate(claim.to_date).getFullYear()}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>
  );
}
