import { stopEvent } from "../../../domUtils";
import predictInputValue from "../../../predictInputValue";
import { shouldExitOnKeyDownTrackChangesFunctionWithoutStoppingEvent, shouldStopEventAndExitOnKeyDownTrackChangesFunction } from './tinyMceEditorUtils';
import * as DeleteAction from './editorActions/DeleteAction';
import * as InsertAction from './editorActions/InsertAction';
import * as PasteAction from './editorActions/PasteAction';
import * as CutAction from './editorActions/CutAction';
import * as AddNewLineAction from './editorActions/AddNewLineAction';
import DynamicField from "./models/DynamicField";
export function onSelectionChange(event, editor) {
  DynamicField.disableMenuButtonsIfTheSelectionFallsInsideADynamicField(editor);
}

/**
 * Cut but also highlight the selection with a deletion highliht.
 * Stop the event propagation so that the browser does not remove the cut content.
 * @param {*} event 
 * @param {*} activeEditorInstance 
 * @returns 
 */
export function onCut(event, activeEditorInstance) {
  CutAction.onCutEvent(event, activeEditorInstance);
}

/**
 * Either paste or add characters or delete.
 * Cut is handled inside onCut.
 * @param {*} event 
 * @param {*} editor 
 * @returns 
 */
export function onKeyDown(event, editor) {
  const cursorSelection = editor.selection.getSel();
  const contentNode = editor.selection.getNode();
  if (shouldExitOnKeyDownTrackChangesFunctionWithoutStoppingEvent(event, cursorSelection, contentNode)) {
    return;
  }
  if (shouldStopEventAndExitOnKeyDownTrackChangesFunction(event, cursorSelection, contentNode)) {
    stopEvent(event);
    return;
  }

  // Note that selection data is not passed, and this is a bit hacky.
  // We don't have a maxLength, so passing a big default value.
  // Ths just shows whether the pressed keys would result in a change of the value of the editor or not.
  const predictedInputValueOrNull = predictInputValue({
    ...event,
    // TODO: fix in new function
    target: {
      // TODO: fix in new function
      value: contentNode.textContent,
      selectionStart: cursorSelection.anchorOffset,
      // TODO: probably not necessary
      selectionEnd: cursorSelection.focusOffset,
      // TODO: probably not necessary
      maxLength: 9999999999 // TODO: fix in new function
    }
  });
  if (DeleteAction.isDeleting(event, editor, cursorSelection, contentNode)) {
    DeleteAction.doDelete(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  } else if (InsertAction.isInserting(event, editor, cursorSelection, contentNode, predictedInputValueOrNull)) {
    InsertAction.doInsert(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  } else if (PasteAction.isPasting(event)) {
    PasteAction.doPaste(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  } else if (CutAction.isCutting(event)) {
    CutAction.doCut(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  } else if (AddNewLineAction.isAddingNewLine(event)) {
    AddNewLineAction.doAddNewLine(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  }
}