

import React from 'react';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';

const TranslationPair = ({keyValue, deleteKeyValue, editKeyValue}) => {
  return (
    <>
      <tr key={keyValue.id}>
        <td>{keyValue.key}</td>
        <td>{keyValue.value}</td>
        <td>
          <Button
            variant="primary"
            onClick={e => editKeyValue(e, keyValue)}
            className="mx-2">
            <span className="material-icons text-white md-18">edit</span>
          </Button>
        </td>
        <td>
          <Button
            variant="danger"
            onClick={() => deleteKeyValue(keyValue)}
            className="mx-2">
            <span className="material-icons text-white md-18">delete</span>
          </Button>
        </td>
      </tr>
    </>
  )
}

export default TranslationPair