import { createSlice } from '@reduxjs/toolkit';
import { AppCache } from '../../util/AppCache';
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      AppCache.clear();
    },
    logout: state => {
      state.user = null;
      AppCache.clear();
    },
    getCurrentSession: (state, action) => {
      state.user = action.payload.user;
    }
  }
});
export const {
  login,
  logout,
  getCurrentSession
} = authSlice.actions;
export const selectUser = state => state.user;
export default authSlice.reducer;