import React, { useState, useCallback } from 'react'
import { Card} from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { Table } from '@rd-web-markets/shared/dist/util';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import TaskAttachmentsForm from './TaskAttachmentsForm';
import trackingTaskAttachmentService from '@services/task_management/tracking_tasks/tracking_task_attachments.service';

const TimeTrackingTaskAttachmentsSection = ({
  task,
  handleToaster,
  setTask,
}) => {
  const [uploads, setUploads] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchUploads = useErrorHandling(useCallback(async () => {
    const uploads = await trackingTaskAttachmentService.all(task.claim_project_report_id, task.id);
    setUploads(uploads);
    setTask({
      ...task,
      tracking_task_attachments: uploads
    })
  }, [task.id]));

  const handleSubmit = useErrorHandling(useCallback(async (document, file) => {
    if (!file) {
      handleToaster('Attach a file before submitting', 'warning');
    } else {
      setLoading(true)
      let fd = new FormData();
      fd.append('file', file);
      fd.append('tracking_task_attachment', JSON.stringify(document));
      await trackingTaskAttachmentService.create(task.claim_project_report_id, task.id, fd);

      await fetchUploads();
      setLoading(false);
    }
  }, [task.id]));

  const handleDelete = useErrorHandling(useCallback(async (uploadId) => {
    if (window.confirm('Are you sure ?')) {
      setLoading(true);
      await trackingTaskAttachmentService.delete(task.claim_project_report_id, task.id, uploadId);
      fetchUploads();
      setLoading(false);
    }
  }, [task.id]));


  const rows = upload => {
    return {
      key: upload.id,
      columns: [
        <a
          rel="noreferrer"
          href={upload.document.url}
          target="_blank"
        >
          <small>{upload.document.filename}</small>
        </a>,
        upload.comments,
        upload.uploaded_by,
        createTimezonedDate(new Date(upload.created_at)).toCustomLocaleDateString()
      ],
      onDelete: () => handleDelete(upload.id)
    }
  };

  const headers = [
    { text: t('document_name') },
    { text: t('document_comments') },
    { text: t('uploaded_by') },
    { text: t('date') },
    { text: '' }
  ];

  const tableRows = task.tracking_task_attachments ? task.tracking_task_attachments.map(rows) : [];

  return (
    <>
      <h1 className='text-primary mb-4'>{t('upload_attachment')}</h1>

      <Card>
        <Card.Body>
          <TaskAttachmentsForm handleSubmit={handleSubmit} loading={loading}/>
          <Table headers={headers} rows={tableRows} onDelete={(index, row) => handleDelete(row.key)}/>
        </Card.Body>
      </Card>
    </>
  );
};

export default TimeTrackingTaskAttachmentsSection;
