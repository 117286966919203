import TranslationsTabs from '@rd-web-markets/market/dist/translations/TranslationsTabs';
import React from 'react';
import { Breadcrumb, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@components/util/MasterSidebar';

const TranslationsPage = ({ accountType }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='translations' />

      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>Translations</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card>
        <Card.Body>
          <TranslationsTabs />
        </Card.Body>
      </Card>
    </>

  )
}

export default TranslationsPage