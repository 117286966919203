import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import * as QueryString from 'query-string';
import ClaimList from '@components/shared/claim/ClaimList';
import SearchBar from '@components/util/SearchBar';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';
import claimService from '@services/claim/claim.service';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@components/util/MasterSidebar';
import ClaimGroupsListNavigation from '@rd-web-markets/market/dist/claim_group/list/ClaimGroupsListNavigation';
import Pagination from '@rd-web-markets/shared/dist/util/Pagination';

const initialParams = {
  type: 'active',
  order_by: 'companies.name asc, to_date desc',
  page: 1,
  query: ''
}

const AllClaimsPage = ({ accountType }) => {
  const [claims, setClaims] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();
  const input = useRef(null);
  const [params, setParams] = useState(
    window.location.search ? QueryString.parse(window.location.search) : initialParams
  );

  useEffect(() => {
    setLoading(true);
    fetch(`/api/claims?${QueryString.stringify(params)}`, {
      method: 'GET',
    })
      .then((response) => {
        setClaims(response.claims);
        setTotalPages(response.pages);
        setLoading(false);
        history.push({
          path: 'admin/claims',
          search: QueryString.stringify(params),
        });
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [history, params]);

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  };

  const handleDeleteClaim = async id => {
    if(window.confirm('Are you sure you wish to delete this claim ?')){
      setLoading(true);
      try {
        await claimService.deleteClaim(id);
        setClaims(claims.filter(claim => claim.id !== id));
      } catch(error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  }

  return <>
    <MasterSidebar accountType={accountType} currentPage='all_claim_groups' />
    <BreadcrumbsPortal>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>All Claims</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>

    <Card>
      <Card.Header>
        <ClaimGroupsListNavigation page='claims' />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                <Row>
                  <Col md={1}>
                    All Claims
                  </Col>
                  <Col md={{ span: 4, offset: 7 }}>
                    <SearchBar onSubmit={handleSearch} loading={loading} query={params.query} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {claims && <ClaimList claims={claims} handleDeleteClaim={handleDeleteClaim} loading={loading} role={'admin'}/>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Pagination currentPage={Number(params.page)} totalPages={totalPages} handlePageChange={handlePage} />   
      </Card.Body>
    </Card>


  </>;
}

export default AllClaimsPage
