import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NoClaimsPage = ({company, accountType}) => {
  return (
    <Row className="pt-5 pb-5 text-center">
      <Col>
        <h3 className="text-center text-muted">No previous claim groups
          <br/>
          <Button as={Link} variant="primary" to={`/${accountType}/companies/add_claim_group/${company.id_in_provider_system}--${company.number}/companies/${company.id}`}>
            Import Claim Group
          </Button>
        </h3>
      </Col>
    </Row>
  )
}

export default NoClaimsPage
