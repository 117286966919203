import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { useSelector } from 'react-redux';

const ClaimItem = ({claim, loading, handleDeleteClaim, role}) => {
  const { template_categories_json, label, company } = claim;
  const user = useSelector((state) => state.auth.user);

  return (
    <tr>
      <td>
        { user.account_type === 'admin' || user.account_type === 'consultant' || user.account_type === 'super_consultant'
          ? <Link to={`/${user.account_type}/companies/${claim.company.id}/${claim.claim_group_id}/master`}>{claim.company.name} {label}</Link>
          : <span>{claim.company.name} {label}</span>
        }
      </td>
      <td>
        {role === 'admin' &&
          <Button variant='light' onClick={() => handleDeleteClaim(claim.id)} className="p-1 d-inline-flex rounded-circle" loading={loading}>
            <span className="material-icons md-18 text-warning rounded-circle mx-auto">delete</span>
          </Button>
        }
        {
          role === 'client' && claim.report_template?.id && (
            <Button
              variant="primary"
              as={Link}
              to={`/client/report_template/${claim.report_template.id}`}>
              View Report
            </Button>
          )
        }
      </td>
    </tr>
  );
}

export default ClaimItem
