import { Table } from '@rd-web-markets/shared/dist/util';

import { SubmitButton, AddButton, CancelButton, DeleteButton, EditButton } from '@rd-web-markets/shared/dist/util/buttons';
import { extractMonthYear } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import React, { useState } from 'react';
import { Form, Popover } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import claimProjectReportService from '@services/claim_project_report.service';
import { REPORT_TYPE_INCLUDE_SECTION_COLUMN_NAME, SHOULD_PROJECT_POPOVER_EXCLUDE_OTHER_PROJECTS } from '@rd-web-markets/market/dist/constants';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';

function SelectProjectsPopover({ claimGroup, width = '600px', onClose, accountType, ...props }, ref) {
  const { search } = useLocation(); //Required for Italy
  const reportType = new URLSearchParams(search).get('report_type') // returns 'inexpert_declaration_dossier' || 'technical' || 'expert_declaration_dossier'
  const italyReportType = {
    inexpert_declaration_dossier: 'own',
    expert_declaration_dossier: 'expert',
  };

  const storeClaimGroupProjects = useSelector((state) => state.claimProjectReports.claimProjectReports.filter((p) => p.claim_group_id === claimGroup.id));

  // get the projects from the store, in order to receive updates if the project names are editted from the preview
  const [projects, setProjects] = useState(
    storeClaimGroupProjects ? 
      window.structuredClone(
        storeClaimGroupProjects.filter((p) =>
          p.certification_method && reportType !== 'technical' ? p.certification_method === italyReportType[reportType] : {}
        )
      )
      :
      []
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const cancelButtonText = process.env.PUBLIC_URL === '/it' ? 'close': undefined;

  const [reportUpdated, setReportUpdated] = useState(false);

  const addProject = () => {
    const alreadyHasEmptyNewProject = projects.some((x) => x.new && !x.project_name);

    if (alreadyHasEmptyNewProject) {
      return;
    }

    setProjects([
      ...projects,
      {
        project_name: '',
        start_date: extractMonthYear(claimGroup.claims[0].from_date),
        end_date: extractMonthYear(claimGroup.claims[claimGroup.claims.length - 1].to_date),
        new: true,
      },
    ]);

    setReportUpdated(true)
  };

  const uncheckOtherReports = (marketSpecificReport) => {
    if (SHOULD_PROJECT_POPOVER_EXCLUDE_OTHER_PROJECTS && marketSpecificReport=== 'include_in_technical_report'){
      projects.forEach((project, i) =>  project.include_in_technical_report = false )
    }
  }

  const includeExcludeProject = async (event, index, marketSpecificReport) => {
    try {
      const project = projects[index];
      project[marketSpecificReport] = event.target.checked;
      const updatedProject = await claimProjectReportService.update(claimGroup.id, { ...project });
      uncheckOtherReports(marketSpecificReport);
      project[marketSpecificReport] = event.target.checked;
      projects[index] = updatedProject;
      setReportUpdated(true);
      setProjects([...projects]);
    } catch(error) {
      dispatch(handleError(error))
    }
  }


  const includeProject = (projectReport, index) => {
    let marketSpecificReport = REPORT_TYPE_INCLUDE_SECTION_COLUMN_NAME[reportType || 'technical'];
    return (
      <div style={{ textAlign: 'center' }}>
        <Form.Check
          type="checkbox"
          name="project_included"
          className="pr-5"
          checked={projectReport[marketSpecificReport]}
          value={projectReport[marketSpecificReport]}
          disabled={false}
          onChange={(event) => includeExcludeProject(event, index, marketSpecificReport)}
        />
      </div>
    );
  };

  const setProjectReportToEditted = (projectReport) => {
    projectReport.edit = true;
    setProjects([...projects]);
  };

  const projectName = (projectReport, index) => {
    if (projectReport.new || projectReport.edit) {
      return (
        <span className='d-flex'>
          <Form.Control
            value={projectReport.project_name}
            name='project_name'
            onChange={(event) => {
              projects[index].project_name = event.target.value;
              setProjects([...projects]);
              setReportUpdated(true)
            }}
          />
        </span>
      );
    }

    return (
      <span>
        <span className='mr-1'>{projectReport.project_name}</span>
        <EditButton title='Edit project name' onClick={() => setProjectReportToEditted(projectReport)} />
      </span>
    );
  };

  const rows = projects.sort((a, b) => a.project_name.localeCompare(b.project_name)).map((projectReport, index) => ({
    data: { project: projectReport },
    columns: [projectName(projectReport, index), includeProject(projectReport, index)],
    showDelete: true,
    showEdit: !projectReport.new,
  }));

  const deleteNewProject = async (index, row) => {
    const { project } = row.data;

    if (project.new) {
      const remainingProjetcs = [...projects];
      remainingProjetcs.splice(index, 1);
      setProjects(remainingProjetcs);
    } else {
      await claimProjectReportService.delete(project.claim_group_id, project.id);
      window.location.reload();
    }
  };

  const headers = [{ text: 'Project Name' }, { text: 'Include in report' }];

  const enableSubmit = () => {
    let marketSpecificReport = REPORT_TYPE_INCLUDE_SECTION_COLUMN_NAME[reportType];
    return projects.some(project => project[marketSpecificReport] === true)
  }

  const saveProjects = useErrorHandling(async () => {
    await claimGroupService.update({
      ...claimGroup,
      projects: projects,
      claim_project_reports_attributes: projects
    })
  })

  return (
    <div>
      <style>
        {`
          .popover-custom {
            max-width: ${width};
            width: ${width};
          }
        `}
      </style>
      <Popover bsPrefix='popover popover-custom' {...props} ref={ref}>
        <Popover.Content style={{ fontSize: '0.8rem' }}>
          <h5 className='text-center mt-3'>Select which projects to include in your report</h5>
          <hr />

          <Table
            style={{ width: '100%' }}
            opts={{ headersClass: 'text-primary text-start' }}
            headers={headers}
            rows={rows}
            showControls={true}
            onDelete={deleteNewProject}
            onEdit={(index, row) =>
              history.push(`/${accountType}/claim_groups/${claimGroup.id}/technical_proof/project_reports/${projects[index].id}`)
            }
          />
          {process.env.PUBLIC_URL !== '/it' &&
            <div className='mb-3'>
              <AddButton text='Add Project' onClick={addProject} />
            </div>
          }

          <div style={{ width: 'fit-content', margin: '0 auto' }}>
            <CancelButton className='mr-1' onClick={onClose} text={cancelButtonText}/>
            <SubmitButton
              text = { reportUpdated ? 'Save*' : 'Save'}
              onClick={async () => {
                if (reportUpdated) {
                  await saveProjects()
                  window.location.reload()
                } else {
                  onClose()
                }
              }}
            />
          </div>
        </Popover.Content>
      </Popover>
    </div>
  );
}

export default React.forwardRef(SelectProjectsPopover);
