import React,{ useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom'

import AlertList from '@rd-web-markets/shared/dist/util/AlertList';
import { clearAlerts, removeAlert } from '@rd-web-markets/shared/dist/store/features/alertSlice';

/**
 * Renders alerts from the redux state in a dom element with id= alertsContainerId
 */
export default function ErrorHandler({ children, alertsContainerId, alertClassName = '' }) {
  const location = useLocation();

  const [alertsDom, setAlertsDom] = useState(null);

  // each alert has an id and message string
  const { alerts } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAlerts());
  }, [dispatch, location]);

  useEffect(() => {
    const alertsContainer = document.getElementById(alertsContainerId);
    if (alertsContainer) {

      setAlertsDom(ReactDOM.createPortal(
        <AlertList alertClassName={alertClassName} alerts={alerts} onRemoveAlert={id => dispatch(removeAlert(id))} />,
        alertsContainer
      ));
    }

  }, [dispatch, alertsContainerId, alerts, alertClassName]);

  return (
    <>
      {!!alertsContainerId && 
        alertsDom
      }

      {!alertsContainerId && 
        <div
          style={{
            position: 'fixed',
            top: 15,
            right: '1vw',
            zIndex: 9999,
            width: 'max-content'
          }}
        >
          <AlertList alertClassName={alertClassName} alerts={alerts} onRemoveAlert={id => dispatch(removeAlert(id))} />
        </div>
      }

      {children}
    </>
  )
}
