import { Loading } from '@rd-web-markets/shared/dist/util';
import { Table } from '@rd-web-markets/shared/dist/util';
import { Card, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import reportTemplateService from '@services/report_template.service';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import rndReportService from '@services/report_templates/rnd_report.service';
import { Button, DeleteButton } from '@rd-web-markets/shared/dist/util/buttons';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CompanyReportPreview from '@components/shared/reportPreview/CompanyReportPreview';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';
import SelectProjectsPopup from '@components/shared/rnd_report/SelectProjectsPopup';
import EditDynamicFieldsModal from '@components/shared/rnd_report/EditDynamicFieldsModal';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { storeMultipleClaimProjectReports } from '@rd-web-markets/shared/dist/store/features/claimProjectReportsSlice';

const RndReportsPage = ({handleToaster, accountType}) => {
  const { report_template_id } = useParams();
  const dispatch = useDispatch();
  const [reportTemplate, setReportTemplate] = useState(null);
  const [aReportGenerationIsPending, setAReportGenerationIsPending] = useState(false);
  const [pollIntervalId, setPollIntervalId] = useState(null);
  const [showEditDynamicFieldsModal, setShowEditDynamicFieldsModal] = useState(false);
  const [isUnderReview, setIsUnderReview] = useState(false);
  const [showclaimIdSelect, setShowClaimIdSelect] = useState(false);
  const [claimId, setClaimId] = useState(null)
  const { search } = useLocation(); //Required for Italy and UK
  const reportType = new URLSearchParams(search).get('report_type');
  const [shouldShowComments, setShouldShowComments] = useState(true)
  
  const anyProjectInDossier = () => {    
    if (process.env.PUBLIC_URL !== '/it') return false;
    
    const italyReportType = {
      inexpert_declaration_dossier: 'own',
      expert_declaration_dossier: 'expert',
    };

    const projects = reportTemplate.claim_group.claim_project_reports.filter((p) => reportType !== 'technical' ? p.certification_method === italyReportType[reportType] && p['include_in_' + reportType + '_report']: {} )
    return projects?.length === 0
  }

  
  const isPdfGenerationPending = useCallback(report => 
    ['pending', 'not_started'].includes(report.pdf_generation_status),
    []
  )

  const fetchRnDReportTemplate = useErrorHandling(useCallback(async () => {
    const template = await reportTemplateService.get(report_template_id);
    setIsUnderReview(!!template.claim_group.active_change_set)
  
    const atLeastOneIsPending = template.non_rnd_reports_snapshots.some(
      report => isPdfGenerationPending(report)
    );

    dispatch(storeMultipleClaimProjectReports(template.claim_group.claim_project_reports))

    setAReportGenerationIsPending(atLeastOneIsPending);
    setReportTemplate(template);
  }, [isPdfGenerationPending, report_template_id]))

  useEffect(() => {
    if (!aReportGenerationIsPending) {
      fetchRnDReportTemplate();
      if (pollIntervalId) {
        // clear if any interval previously started
        window.clearInterval(pollIntervalId);
        setPollIntervalId(null);
        handleToaster('Report PDF generation finished.');
      }
    } else {
      if (!pollIntervalId) {
        const intervalId = window.setInterval(() => {
          fetchRnDReportTemplate();
        }, 2000);
  
        setPollIntervalId(intervalId);
      }
    }

    // clear any left interval on component dismount
    return () => window.clearInterval(pollIntervalId);
  }, [fetchRnDReportTemplate, pollIntervalId, aReportGenerationIsPending, handleToaster, report_template_id]);

  const deleteReport = useCallback(
    async (report_template_id, id) => {
      if (window.confirm('Are you sure you wish to delete this report?')) {
        try {
          await rndReportService.delete(report_template_id, id);
          await fetchRnDReportTemplate();
        } catch (error) {
          dispatch(handleError(error));
        }
      }
    },
    [dispatch, fetchRnDReportTemplate]
  );

  const generateReport = async (report_version) => {
    try {
      const showComments = report_version !== 'final' ? shouldShowComments : false
      await rndReportService.create(reportTemplate.id, report_version, reportType, claimId, showComments);
      const template = await reportTemplateService.get(report_template_id);
      setReportTemplate(template);
      setAReportGenerationIsPending(true);
    } catch(error) {
      dispatch(handleError(error));
    }
  }

  const changeClaimId = async (event) => {
    setClaimId(event.target.value)
  }

  if(!reportTemplate) return <Loading />

  const renderDownloadLink = report => {
    return (
      <small>
        <a 
          disabled={isPdfGenerationPending(report)}
          href={`/api/report_templates/${report_template_id}/rnd_reports/${report.id}`}
          target="_blank"
          rel="noreferrer"
          download
        >
          <span className="fa fa-download"></span> Download report
        </a>
      </small>
    );
  }

  const rows = reportTemplate.non_rnd_reports_snapshots
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map(r => ({
      key: r.id,
      columns: [
        createTimezonedDate(new Date(r.created_at)).toCustomLocaleTimeString(),
        r.author_name,
        r.pdf_generation_status,
        isPdfGenerationPending(r) ? <Loader /> : renderDownloadLink(r),
        <DeleteButton 
          disabled={isPdfGenerationPending(r)}
          onClick={isPdfGenerationPending(r) ? 
              () => {} :
              () => deleteReport(report_template_id, r.id)
          }
        />
      ]
    })
  );

  const reportsTableStyle = {
    maxHeight: '200px',
    overflowY: 'auto'
  };

  return (
    <>
      
      <SidebarPortal headerItem={{link: `companies/${reportTemplate.company.id}/${reportTemplate.claim_group.id}/report_template`, text: reportTemplate.company.name}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies/${reportTemplate.company.id}/master`}}>
            {reportTemplate.company.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} active>{reportTemplate.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      <Card className='shadow border-0'>
        <Card.Header className="bg-gradient-primary text-white">Reports</Card.Header>
        <Card.Body>
          <Row>
            <Col md={{span: 2}}>
              <Button 
                disabled={aReportGenerationIsPending || anyProjectInDossier()} 
                loading={aReportGenerationIsPending} 
                onClick={() => generateReport('final')}
                variant={isUnderReview ? 'dark' : 'primary'}
              >
                { isUnderReview ? 'PREVIEW PDF REPORT WITH ACCEPTED CHANGES' : 'GENERATE PDF REPORT' }
              </Button>
            </Col>
            <Col md={{span: 2, offset: 8}}>
              <Button
                style={{ width: '100%' }} 
                disabled={aReportGenerationIsPending}
                loading={aReportGenerationIsPending}
                onClick={() => setShowEditDynamicFieldsModal(true)}
              >
                Edit Dynamic Fields
              </Button>
            </Col>
          </Row>
          <Row className='mt-3'>

            { isUnderReview && 
              <Col md={{span: 3}}>
                <Button 
                  disabled={aReportGenerationIsPending || anyProjectInDossier()} 
                  loading={aReportGenerationIsPending} 
                  onClick={() => generateReport('current')}
                  variant={'dark'}
                >
                  PREVIEW PDF WITH HIGLIGHTS
                </Button>

            </Col>
            }
            <Col md={{span: 2, offset: isUnderReview ? 7 : 10 }}>
              <SelectProjectsPopup 
                style={{ width: '100%' }} 
                claimGroup={reportTemplate.claim_group} 
                disabled={aReportGenerationIsPending} 
                loading={aReportGenerationIsPending} 
                accountType={accountType} 
              />
            </Col>
          </Row>
          {reportType === 'aif' && <Row className='mt-3'>
            <Col md={{ span: 2, offset: 10 }}>
              <Button
                style={{ width: '100%' }}
                disabled={aReportGenerationIsPending}
                loading={aReportGenerationIsPending}
                onClick={() => setShowClaimIdSelect(true)}
              >
                Select Year
              </Button>

              {showclaimIdSelect && <Form.Control
                className='mt-1'
                type='text'
                as='select'
                name='claim_year'
                onChange={changeClaimId}
              >
                <option value={null}></option>
                {reportTemplate.claims.map((claim, i) => (
                  <option key={claim.id} value={claim.id}>{claim.name}</option>
                ))}
              </Form.Control>}
            </Col>
          </Row>}
          {isUnderReview &&
            <Row className='mt-3'>
              <Col md={6}>
                <Form.Check
                  checked={shouldShowComments}
                  label="Show comments in report?"
                  onChange={(e) => setShouldShowComments(e.target.checked)}
                />
              </Col>
            </Row>
          }
          <Row style={reportsTableStyle} className="pt-4">
            <Col md={12} className="text-center">
              <Table headers={[]} rows={rows} showControls={false} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row className="text-center mt-5">
        <Card className="border-0 ml-auto mr-auto">
          <Card.Header className="border-0 bg-gradient-secondary">
            <h4 className="text-center text-secondary">Preview</h4>
          </Card.Header>
          <Card.Body>
            <CompanyReportPreview
              reportTemplateId={report_template_id}
              claimId={claimId}
              claimGroup={reportTemplate.claim_group}
              shouldShowComments={isUnderReview && shouldShowComments}
            /> 
          </Card.Body>
        </Card>
      </Row>
      { showEditDynamicFieldsModal &&
        <EditDynamicFieldsModal
          accountType={accountType}
          claimGroup={reportTemplate.claim_group}
          show={showEditDynamicFieldsModal}
          onHide={() => setShowEditDynamicFieldsModal(false)}
        />
      }
    </>
  )
}

export default RndReportsPage
