import React from 'react';
import { Card, Tabs, Tab, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ClaimKickoffEmailTemplateConfigure from '../claim/templates/ClaimKickoffEmailTemplateConfigure';
import EmailPreviewModalRecipients from '@components/shared/claim/emailPreview/EmailPreviewModalRecipients';
import EmailPreviewModalPreview from '@components/shared/claim/emailPreview/EmailPreviewModalPreview';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import PastSentEmailList from '../claim/emailPreview/PastSentEmailList';

const IntroEmailPage = ({
  claimGroup,
  sendIntroductionMail,
  onClaimGroupUpdate,
  setClaimGroup,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-primary mb-4">{t('intro_email')}</h2>
      <Tabs defaultActiveKey="configure" className="mb-3">
        <Tab eventKey="configure" title={`${t('configure_email')}`}>
          <ClaimKickoffEmailTemplateConfigure claimGroup={claimGroup} setClaimGroup={setClaimGroup} />
        </Tab>
        <Tab eventKey="preview" title={`${t('preview')}`}>
          {!claimGroup.claim_schedule.active_email_template && <Card><Card.Header>{t('please_configure_email_template')}</Card.Header></Card>}
          {!!claimGroup.claim_schedule.active_email_template &&
            <Card>
              <Card.Header>
                <EmailPreviewModalRecipients
                  claimGroup={claimGroup}
                  emailType={'kickoff'}
                  claimSchedule={claimGroup.claim_schedule}
                  onClaimGroupRolesupdate={onClaimGroupUpdate}
                  setClaimGroup={setClaimGroup}
                />
              </Card.Header>
              <Card.Body>
                <EmailPreviewModalPreview claimGroup={claimGroup} template={claimGroup.claim_schedule.active_email_template} />
                <Row className='align-items-center'>
                  <Col md={9}>
                    <PastSentEmailList claimGroup={claimGroup} pastSentTemplatesList={claimGroup.claim_schedule.email_templates} />
                  </Col>
                  <Col md={3}>
                    <Button
                      loading={loading}
                      icon='mail'
                      iconPosition={'left'}
                      variant="success"
                      className="float-right"
                      onClick={() => sendIntroductionMail()}>
                      {t('send_introduction_mail')}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          }
        </Tab>
      </Tabs>
    </>
  )
};

export default IntroEmailPage;