import { hasClassOrParentWithClass, isEmptySelection, selectionContainsElementsWithClassname, userIsCuttingContent, userIsCuttingOrPasting, userIsPressingCtrlOrMetaOrShiftOnly } from "../../../domUtils";
import DeletionHighlight from "./models/DeletionHighlight";
import DynamicField from "./models/DynamicField";
import TrackChangesHighlight from "./models/TrackChangesHighlight";

/**
 * Edge cases in which we dont know what we want the editor to do.
 * So instead - just dont do anything.
 * @param {*} event 
 * @param {*} cursorSelection 
 * @param {*} contentNode 
 * @returns 
 */
export function shouldStopEventAndExitOnKeyDownTrackChangesFunction(event, cursorSelection, contentNode) {
  if (event.key === 'Enter' && DynamicField.isAddingContentInADynamicField(contentNode, cursorSelection)) {
    return true;
  } else if (event.key === 'Enter' && TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, DeletionHighlight.highlightType)) {
    return true;
  } else if (!['Delete', 'Backspace'].includes(event.key) && DynamicField.isAddingContentInADynamicField(contentNode, cursorSelection) && !hasClassOrParentWithClass(contentNode, 'TrackChanges-Highlight-Addition')) {
    return true;
  } else if (userIsCuttingOrPasting(event) && DynamicField.isEditingPartOfADynamicField(contentNode, cursorSelection)) {
    return true;
  } else if (!isEmptySelection(cursorSelection) && userIsCuttingContent(event) && TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, DeletionHighlight.highlightType)) {
    return true;
  } else if (event.key === 'Enter' && DynamicField.isEditingPartOfADynamicField(contentNode, cursorSelection)) {
    return true;
  } else if (event.key === 'Enter' && !isEmptySelection(cursorSelection) && TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, DeletionHighlight.highlightType)) {
    return true;
  } else if (event.key === 'Enter' && !isEmptySelection(cursorSelection) && selectionContainsElementsWithClassname(cursorSelection, 'TrackChanges-Highlight-Deletion')) {
    return true;
  } else if (!isEmptySelection(cursorSelection) && DynamicField.isEditingPartOfADynamicField(contentNode, cursorSelection)) {
    return true;
  } else if (isEmptySelection(cursorSelection) && ['Delete', 'Backspace'].includes(event.key) && selectionContainsElementsWithClassname(cursorSelection, 'TrackChanges-Highlight-Deletion')) {
    return true;
  }
  return false;
}
export function shouldExitOnKeyDownTrackChangesFunctionWithoutStoppingEvent(event, cursorSelection, contentNode) {
  if (userIsPressingCtrlOrMetaOrShiftOnly(event)) {
    return true;
  }

  // Handled inside onCut event
  if (userIsCuttingContent(event)) {
    return true;
  }

  // // is removing a line break before a text node
  if (['Backspace'].includes(event.key) && isEmptySelection(cursorSelection) && cursorSelection.anchorNode.parentElement.childNodes[0].isSameNode(cursorSelection.anchorNode) && cursorSelection.anchorOffset === 0) {
    return true;
  }

  // is deleting an empty addition line
  if (['Backspace'].includes(event.key) && isEmptySelection(cursorSelection) && cursorSelection.anchorNode.childNodes.length === 1 && cursorSelection.anchorNode.childNodes[0].tagName === 'BR') {
    return true;
  }
}
export function isEditorSelectionCollapsedOnAnImage(editor) {
  return editor.selection.isCollapsed() && editor.selection.getStart().tagName.toUpperCase() === 'IMG' && editor.selection.getStart().tagName.toUpperCase() === 'IMG';
}