function ctrlOrMetaDown(event) {
  return event.metaKey || event.ctrlKey;
}
export function userIsPastingContent(event) {
  return ctrlOrMetaDown(event) && event.key === 'v' || event.shiftKey && event.key === 'Insert';
}
export function userIsCuttingContent(event) {
  return ctrlOrMetaDown(event) && event.key === 'x';
}
export function userIsCuttingOrPasting(event) {
  return userIsCuttingContent(event) || userIsPastingContent(event);
}
export function userIsPressingCtrlOrMetaOrShiftOnly(event) {
  return ctrlOrMetaDown(event) && !['v', 'x'].includes(event.key) || !!event.shiftKey && !event.key;
}