import { useDispatch } from 'react-redux';
import { handleError } from '../store/features/alertSlice';
import { useCallback, useMemo } from 'react';
export function useErrorHandling(callback) {
  let onFinally = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let onError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  const dispatch = useDispatch();
  const onFinallyCallback = useMemo(() => onFinally || (() => {}), [onFinally]);
  const onErrorCallback = useMemo(() => onError || (() => {}), [onError]);
  const errorHandler = useCallback(async function () {
    try {
      return await callback(...arguments);
    } catch (error) {
      dispatch(handleError(error));
      onErrorCallback();
    } finally {
      onFinallyCallback();
    }
  }, [callback, dispatch, onErrorCallback, onFinallyCallback]);
  return errorHandler;
}