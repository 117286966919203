import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import releaseNotesService from '@services/release_notes.service';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { Table } from '@rd-web-markets/shared/dist/util';
import MasterSidebar from '@components/util/MasterSidebar';
import NotesModal from './NotesModal';

const IMAGE_PREVIEW_HEIGHT = 200;

export default function NotesPage({ accountType }) {
  const { release_id } = useParams();
  const [featuresWithImages, setFeaturesWithImages] = useState([]);
  const [featuresWithoutImages, setFeaturesWithoutImages] = useState([]);
  const [bugs, setBugs] = useState([]);
  const [modalMode, setModalMode] = useState('create');

  const refreshNotes = useErrorHandling(useCallback(async () => {
    const release = await releaseNotesService.get(release_id);
    setFeaturesWithImages(release.release_notes.filter(n => n.note_type === 'feature_with_image'));
    setFeaturesWithoutImages(release.release_notes.filter(n => n.note_type === 'feature_without_image'));
    setBugs(release.release_notes.filter(n => n.note_type === 'bug'));
  }, [release_id]));

  useEffect(() => {
    refreshNotes();
  }, [refreshNotes]);

  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [newNote, setNewNote] = useState({ id: null, note_type: 0, title: '' });
  const [selectedImage, setSelectedImage] = useState(null);

  
  const addNote = (note_type) => {
    setSelectedImage(null);
    setModalMode('create');
    setNewNote({ id: null, title: '', note_type });
    setModalShow(true);
  }
  const changeNote = async (note) => {
    setModalMode('update');
    setNewNote(note);
    setModalShow(true);
  }

  const createNote = useErrorHandling(useCallback(async (modalMode) => {
    if (newNote.note_type === 0 && !selectedImage) {
      throw new Error('Attach an image before submitting');
    } else {
      const fd = new FormData();
      fd.append('note', JSON.stringify(newNote));
      if (newNote.note_type === 0) {
        fd.append('image', selectedImage);
      }
      if (modalMode === 'create') {
        await releaseNotesService.createNote(release_id, fd);
      } else {
        await releaseNotesService.updateNote(release_id, newNote.id, fd);
      }
      await refreshNotes();
    }
  }, [newNote, refreshNotes, release_id, selectedImage]));

  const deleteNote = useErrorHandling(useCallback(async (note) => {
    await releaseNotesService.deleteNote(release_id, note.id);
    await refreshNotes();
  }, [refreshNotes, release_id]));

  const headersWithImages = [
    { text: t('add_feature_with_image') },
    { text: t('image') },
  ];
  const headersWithoutImages = [
    { text: t('add_feature_without_image') },
  ];
  const headersBugs = [
    { text: t('bugs') },
  ];

  const rowsWithImages = featuresWithImages.map((note) => {
    return {
      key: note.id,
      columns: [
        note.title,
        <img src={note.image?.url} height={IMAGE_PREVIEW_HEIGHT}/>,
      ]
    }
  });
  const rowsWithoutImages = featuresWithoutImages.map((note) => {
    return {
      key: note.id,
      columns: [
        note.title,
      ]
    }
  });
  const rowsBugs = bugs.map((note) => {
    return {
      key: note.id,
      columns: [
        note.title,
      ]
    }
  });

  const noteSection = (headers, rows, noteType, addButtonText) => {
    return (
      <>
        {accountType === 'admin' && <Button variant="text" onClick={() => addNote(noteType)}>
          <span className="material-icons md-18 blue">add</span>
          {t(addButtonText)}
        </Button>}
        <Table
          className='table table-hover'
          headers={headers}
          rows={rows}
          onEdit={accountType === 'admin' ? (_, note) => changeNote({ id: note.key, note_type: noteType, title: note.columns[0] }) : null}
          onDelete={accountType === 'admin' ? (_, note) => deleteNote({ id: note.key }) : null}
        />
      </>
    );
  }

  return <>
    <NotesModal
      setNewNote={setNewNote}
      newNote={newNote}
      setSelectedImage={setSelectedImage}
      createNote={createNote}
      setModalShow={setModalShow}
      modalShow={modalShow}
      modalMode={modalMode}
      selectedImage={selectedImage}
    />
    <MasterSidebar accountType={accountType} currentPage='' />
    <BreadcrumbsPortal>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/release_notes`}}>{t('releases')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/release_notes/${release_id}`}}>{t('release_notes')}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>
    {noteSection(headersWithImages, rowsWithImages, 0, 'add_feature_with_image')}
    {noteSection(headersWithoutImages, rowsWithoutImages, 1, 'add_feature_without_image')}
    {noteSection(headersBugs, rowsBugs, 2, 'add_bug')}
  </>;
};
