import React, { useEffect, useState } from 'react'
import { Card, Row, Col, ProgressBar, Button} from 'react-bootstrap'
import ImportCostTemplateSection from './ImportCostTemplateSection'
import ClientCostSection from './ClientCostSection';
import FinancialSectionLink from '@rd-web-markets/market/dist/claim/FinancialSectionLink';
import ClaimCostManagerQAReviews from '../managerReviews/ClaimCostManagerQAReviews';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'

const cardStyle = {
  height: '450px'
};

const progressBarStyle = {
  fontSize: '1.05rem', height: '24px'
};

const ClaimDashboard = ({
  accountType,
  selectedProject,
  claim,
  claimGroup,
  company,
  onClaimGroupUpdate,
  handleImportCostTemplate,
  loading,
  consultantsAndAdmins,
  sendClientCostEmail,
  createChangeSet,
  finalizeChangeSet,
  approveChangeSet,
  rejectChangeSet,
  createChangeSetsForAllClaimsInClaimGroup,
  handleToaster
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const generateCostTemplate = (from = null, to = null) => {
    if(from && to) {
      window.open(`/api/claims/${claim.id}/claim_export_cost_template?from=${from}&to=${to}`);
    }
    else {
      window.open(`/api/claims/${claim.id}/claim_export_cost_template`);
    }
  }

  return (
    <>
      { user.account_type !== 'customer' &&
        <Row className="justify-content-end">
          <Button as={Link} to={`/${accountType}/claims/${claim.id}/edit/general`}
            variant="primary"
            className="mx-2">
            <span className="material-icons md-18">edit</span> Edit Claim
          </Button>
        </Row>
      }
      <Row className="mt-5">
        <Col md={{ span: 10, offset: 1 }}>
          <Card className="border border-white" style={cardStyle}>
            <Card.Header className="bg-secondary text-center">
              <h4 className="text-center">{selectedProject?.project_name} - {t('r_d_costs')} {
                claim.is_locked || claim.financials_locked ? <span className="material-icons">lock</span> : ''
              }</h4>
            </Card.Header>
            <Card.Body>
              <FinancialSectionLink accountType={accountType} claim={claim} selectedProject={selectedProject}/>
              <ProgressBar
                className="mt-3" style={progressBarStyle}
                variant="success" now={claim.completed_percent_financial}/>
              <hr />
              <ImportCostTemplateSection
                claim={claim}
                claimGroup={claimGroup}
                user={user}
                templateInfo={claim.claim_import_cost_template_infos[claim.claim_import_cost_template_infos.length - 1]}
                loading={loading}
                handleToaster={handleToaster}
                selectedProject={selectedProject}
                generateCostTemplate={generateCostTemplate}
                handleImportCostTemplate={handleImportCostTemplate}
              />
              { user.account_type !== 'customer' &&
                <>
                  <hr />
                  <ClientCostSection
                    claimGroup={claimGroup}
                    company={company}
                    claim={claim}
                    consultantsAndAdmins={[consultantsAndAdmins]}
                    loading={loading}
                    onClaimGroupUpdate={onClaimGroupUpdate}
                    sendClientCostEmail={sendClientCostEmail}
                  />
                </>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      { user.account_type !== 'customer' &&
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <ClaimCostManagerQAReviews
              reviewedObject={''}
              reviewedObjectClass={''}
              loading={loading}
              claim={claim}
              claimGroup={claimGroup}
              company={company}
              sendClientCostEmail={sendClientCostEmail}
              handleImportCostTemplate={handleImportCostTemplate}
              createChangeSet={createChangeSet}
              finalizeChangeSet={finalizeChangeSet}
              approveChangeSet={approveChangeSet}
              rejectChangeSet={rejectChangeSet}
              createChangeSetsForAllClaimsInClaimGroup={createChangeSetsForAllClaimsInClaimGroup}
            />
          </Col>
        </Row>
      }
    </>
  );
}

export default ClaimDashboard
