import Modal from '@rd-web-markets/shared/dist/util/Modal';
import React, { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';

import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';
import reportTemplateService from '@services/report_template.service';
import debounce from 'lodash.debounce';
import { useMemo } from 'react';
import { EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS, EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS } from '@rd-web-markets/market/dist/constants';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ReviewableFieldEditor from '@rd-web-markets/shared/dist/util/textEdit/ReviewableFieldEditor';
import { getReportTemplateOptions } from '@rd-web-markets/shared/dist/util/tinyMceConfig';
import OnlyCommentsEditor from '@rd-web-markets/shared/dist/util/textEdit/OnlyCommentsEditor';

export default function EditClaimGroupFieldModal({
  claimGroupId,
  fieldName,
  reportTemplateId,
  onHide,
  onContentChanged,
  isVisible,
  title,
  isUnderReview=false,
  footnoteStartIndex=0
}) {
  const [rndReportTemplate, setRndReportTemplate] = useState(null);
  const [editText, setEditText] = useState({});
  const [apiUpdateResult, setApiUpdateResult] = useState({});
  const [highlightsText, setHighlightsText] = useState(null);
  const [textLoaded, setTextLoaded] = useState(false);
  const [closeButtonLoading, setCloseButtonLoading] = useState(false);
  const [reportTextWasChanged, setReportTextWasChanged] = useState(false);
  const [claimGroup, setClaimGroup] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user)
  const showTrackChangesControls = user.account_type !== 'customer'

  /**
   * 
   * @param {*} editText always provided
   * @param {*} highlightText always provided
   * @param {*} editableTitle only if editing a project report slice
   * @param {*} projectReportSlice 
   * @param {*} rndReportTemplate 
   */
  const setEditData = useCallback(
    (
      newEditText, newHighlightText, newReportTemplateCategory = null
    ) => {
      // setReportTemplateCategory({ ...newReportTemplateCategory })

      setEditText(newEditText)
      setHighlightsText(newHighlightText)
    }, 
    []
  )

  useEffect(() => {
    const setText = async () => {
      try {
        // only used for the editor settings
        const rndReportTemplate = await reportTemplateService.get(reportTemplateId)
        setRndReportTemplate(rndReportTemplate)

        const claimGroupResult = await claimGroupService.get(claimGroupId)
        setClaimGroup(claimGroupResult)

        const editText = {
          content: claimGroupResult[fieldName],
          timeOfUpdate: null
        }

        const highlightText = claimGroupResult[fieldName]

        setEditData(editText, highlightText, { })
        setTextLoaded(true);
      } catch (error) {
        dispatch(handleError(error))
      }
    };

    setText();
  }, [claimGroupId, dispatch, fieldName, reportTemplateId, setEditData]);

  const debouncedUpdateReportText = useMemo(
    () => {
      setReportTextWasChanged(true)

      const updateClaimGroupField = async (text, timeOfUpdate) => {
        const updatedClaimGroup = await claimGroupService.update({
            ...claimGroup,
            [fieldName]: text
        });


        setApiUpdateResult({
          editDataParams: [
            { content: updatedClaimGroup[fieldName] },
            updatedClaimGroup[fieldName],
            { ...updatedClaimGroup }
          ],
          timeOfUpdate
        })

        setClaimGroup(updatedClaimGroup)
      };

      return debounce(async (text, timeOfUpdate) => {
        try {
          await updateClaimGroupField(text, timeOfUpdate);
          setCloseButtonLoading(false);
        } catch (error) {
          console.log(error)
          dispatch(handleError(error))
        }
      }, 1000)
    }, 
    [claimGroup, fieldName, dispatch]
  )

  useEffect(() => {
    if (editText.timeOfUpdate) {
      if (editText.timeOfUpdate > apiUpdateResult.timeOfUpdate || !apiUpdateResult.timeOfUpdate) {
        debouncedUpdateReportText(editText.content, editText.timeOfUpdate)
      } else if (apiUpdateResult.timeOfUpdate && apiUpdateResult.timeOfUpdate >= editText.timeOfUpdate) {
        setEditData(...apiUpdateResult.editDataParams)
      }
    }
  }, [apiUpdateResult, debouncedUpdateReportText, editText, setEditData])

  const modalBody = () => {
    if (!textLoaded) {
      return <Loader />
    }

    return (
      <Card>
        <Card.Body>
          { user.account_type === 'customer' && !claimGroup.active_change_set.finalized_at &&
            <OnlyCommentsEditor
              getContentFn={() => editText.content}
              updateContentFn={content => {
                const timeOfUpdate = Date.now()
                setEditText({ content, timeOfUpdate })
                setCloseButtonLoading(true);
              }}
              modelClass='ClaimGroup'
              modelId={claimGroupId}
            />
          }

          { user.account_type === 'customer' && !!claimGroup.active_change_set.finalized_at &&
            <div dangerouslySetInnerHTML={{ __html: editText.content }}></div>
          }

          { user.account_type !== 'customer' &&
            <ReviewableFieldEditor
              isUnderReview={isUnderReview}
              editorContent={editText.content}
              modelClass='ClaimGroup'
              modelId={claimGroupId}
              modelField={fieldName}
              editorInitConfig={
                getReportTemplateOptions(rndReportTemplate, EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS, EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS, {
                  footnoteStartIndex
                })
              }
              trackChangesOnCreateComment={content => {
                const timeOfUpdate = Date.now()
                setEditText({ content, timeOfUpdate })
                setCloseButtonLoading(true);
              }}
              trackChangesEditoInitFn={
                addCommentCallback => getReportTemplateOptions(rndReportTemplate, EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS, EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS, {
                  isTrackingChanges: true,
                  onAddCommentButtonClickCallback: addCommentCallback,
                  showTrackChangesControls: showTrackChangesControls,
                  footnoteStartIndex
                })
              }
              onEditorChange={content => {
                const timeOfUpdate = Date.now()
                setEditText({ content, timeOfUpdate })
                setCloseButtonLoading(true);
              }}
              showTrackChangesControls={showTrackChangesControls}

            />
          }
        </Card.Body>
      </Card>
    );
  };

  return (
    <Modal
      size="xl"
      title={title || 'Edit text'}
      show={isVisible}
      renderBody={modalBody}
      onHide={onContentChanged}
      closeButtonLoading={closeButtonLoading}
      disabled={closeButtonLoading}
      closeText='Save'
    />
  );
}
