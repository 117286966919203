import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import { useTranslation } from 'react-i18next';

const AddTaskBreadcrumbs = ({claimGroup}) => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  return (
    <BreadcrumbsPortal>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies`}}>{t('companies')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claimGroup.company.id}/master`}}>{claimGroup.company.name}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claimGroup.company.id}/${claimGroup.id}/master`}}>{claimGroup.name}</Breadcrumb.Item>
        <Breadcrumb.Item active>Add Task</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>
  )
}

export default AddTaskBreadcrumbs;
