const en_translations = {
  project_objectives: 'Projects Objectives',
  company_references: 'Company References',
  intensive_interrogation: 'Intensive Interrogation',
  was_the_customer_questioned_intensively: 'Was the customer questioned intensively until a coherent, concrete description of the goal was possible?',
  summary: 'Summary',
  were_the_problem_motivation_and_the_desired_goal_summarized: 'Were the problem, motivation and the desired goal summarized during the exchange with the customer and was the summary confirmed by the customer?',
  discussion_with_colleagues: 'Discussion with Colleagues',
  was_the_description_discussed_intensively: 'Was the description "Objective of the project" discussed intensively with at least one colleague and "approved" together?',
  motivation: 'Motivation',
  do_i_have_captivating_introduction: 'Do I have a "captivating" introduction: i.e. is the motivation described in a striking and tangible way?',
  objectives_envisaged_results: 'Objectives & Envisaged results',
  are_the_project_goals_recorded: 'Are the project goals recorded as specifcally as possible? As far as possible',
  structure: 'Structure',
  can_i_already_recognize_structure_in_the_goal: 'Can I already recognize a structure/sequence in the goal that can be taken up in the other chapters?',
  security_queries: 'Security Queries',
  are_bad_words_on_target: 'Are bad words on target? (Must be rephrased, otherwise a green doesnt make sense)',
  have_terms_and_formulations_been_mentioned: 'Have terms and formulations been mentioned analogous to other chapters (IMPORTANT: common thread)?',
  have_abbreviations_been_introduced_or_are_technical_terms: 'Have abbreviations been introduced or are technical terms understandable?',
  work_schedule: 'Work Schedule',
  is_there_a_work_plan: 'Is there a work plan, If necessary, send sample work plan.',
  can_clear_rnd_activities: 'Can clear R&D activities for the project be derived from the work plan?',
  can_work_packages_be_divided_into_concept: 'Can work packages be divided into concept, development and test phases?',
  is_there_a_structured_description: 'Is there a structured description?',
  key_point_structure_in_phases: 'Key-point structure in phases/work packages Danger! Test phase always including feedback d. results in development',
  is_the_working_methodology_described: 'Is the working methodology described (sprints, milestones, parallel, iterative...)?',
  can_internal_and_external_activities_be_clearly_defined: 'Can internal and external activities be clearly defined/delineated?',
  have_certain_terms_and_formulations_from_the_goal_been_consistently_incorporated_into_the_work_phases: 'Have certain terms and formulations from the goal been consistently incorporated into the work packages/phases?',
  market_research: 'Market Research',
  have_project_delimitations_been_checked_and_compared: 'Have project delimitations been checked and compared with products/methods/services from competitors?',
  can_qualitative_and_quantitative_delimitation_features_be_listed: 'Can qualitative and quantitative delimitation features be listed?',
  has_the_state_of_research_or_development_in_the_industry_been_briefy_described: 'Has the state of research or development in the industry been briefly described?',
  have_all_new_scientifc_methods_approaches_been_mentioned: 'Have all new scientific/technical methods, approaches or approaches to be used been mentioned?',
  has_it_been_checked_whether_there_is_a_clear_demarcation: 'Has it been checked whether there is a clear demarcation from the market such as therefore there is no comparable product on the market or the company is not aware of any comparable products?',
  have_i_picked_up_the_structure_from_the_goal_here_as_well: 'Have I picked up the structure/sequence from the goal here as well?',
  does_the_customer_have_similar_products_or_is_he_already_advertising: 'Does the customer have similar products or is he already advertising the project on the website? Possibly demarcation necessary',
  routine: 'Routine',
  what_are_routine_activities_for_the_company: 'What are routine activities for the company?',
  how_do_the_new_rnd_activities_difer_from_routine_activities: 'How do the new R&D activities differ from routine activities?',
  what_was_new_to_achieve_the_goal: 'What was new (product/procedure/method/organization) to achieve the goal?',
  has_it_been_checked_whether_no_routine_occurs: 'Has it been checked whether, among other things, no routine occurs in the chapter?',
  are_there_any_bad_words_in_the_description: 'Are there any bad words in the description? e.g. further development, further develop, optimize',
  risks: 'Risks',
  which_technical_risks_have_arisen: 'Which technical risks/uncertainties/challenges have arisen/could arise?',
  has_it_been_carefully_checked_whether_it_is_not_just_hard_work: 'Has it been carefully checked whether it is not just "hard work"?',
  are_risks_quantifiable: 'Are risks quantifiable?',
  conficting_goals: 'Conflicting Goals & Termination Criteria',
  are_there_technical_conficts_of_interest: 'Are there technical conflicts of interest that lead to the project being canceled if they are not achieved?',
  are_the_technical_risks_of_interest_consistent: 'Are the technical risks/conflicts of interest consistent with the objectives and the descriptions of the work?',
  have_terms_and_formulations_been_mentioned_analogous_to_other_chapters: 'Have terms and formulations been mentioned analogous to other chapters (IMPORTANT: common thread)?',
  have_i_picked_up_the_structure_sequence_from_the_goal_here_as_well: 'Have I picked up the structure/sequence from the goal here as well?',
  has_the_common_consistency_from_the_descriptions_been_checked_with_at_least_one_colleague: 'Has the common consistency from the descriptions "Objective of the project", "Description of the work", "Technical risks been checked intensively with at least one colleague and approved together?',
  germany: 'Germany',
  afganistan: 'Afghanistan',
  albania: 'Albania',
  algeria: 'Algeria',
  andorra: 'Andorra',
  angolla: 'Angola',
  antigua: 'Antigua & Deps',
  argentina: 'Argentina',
  armenia: 'Armenia',
  australia: 'Australia',
  austria: 'Austria',
  azerbaijan: 'Azerbaijan',
  bahamas: 'Bahamas',
  bahrain: 'Bahrain',
  bangladesh: 'Bangladesh',
  barbados: 'Barbados',
  belarus: 'Belarus',
  belgium: 'Belgium',
  belize: 'Belize',
  benin: 'Benin',
  bhutan: 'Bhutan',
  bolivia: 'Bolivia',
  bosnia: 'Bosnia Herzegovina',
  botswana: 'Botswana',
  brazil: 'Brazil',
  brunei: 'Brunei',
  bulgaria: 'Bulgaria',
  burkina: 'Burkina',
  burundi: 'Burundi',
  cambodia: 'Cambodia',
  cameroon: 'Cameroon',
  canada: 'Canada',
  cape_verde: 'Cape Verde',
  central_african_rep:'Central African Rep',
  chad: 'Chad',
  chile: 'Chile',
  china: 'China',
  colombia: 'Colombia',
  comoros: 'Comoros',
  congo: 'Congo',
  congo_democratic: 'Congo {Democratic Rep}',
  cost_rica: 'Costa Rica',
  croatia: 'Croatia',
  cuba: 'Cuba',
  cyprus: 'Cyprus',
  czech_republic: 'Czech Republic',
  denmark: 'Denmark',
  djibouti: 'Djibouti',
  dominica: 'Dominica',
  dominican_republic: 'Dominican Republic',
  east_timor: 'East Timor',
  ecuador: 'Ecuador',
  egypt: 'Egypt',
  el_salvador: 'El Salvador',
  equatorial_guinea: 'Equatorial Guinea',
  eritrea: 'Eritrea',
  estonia: 'Estonia',
  ethiopia: 'Ethiopia',
  fiji: 'Fiji',
  finland: 'Finland',
  france: 'France',
  gabon: 'Gabon',
  gambia: 'Gambia',
  georgia: 'Georgia',
  ghana: 'Ghana',
  greece: 'Greece',
  grenada: 'Grenada',
  guatemala: 'Guatemala',
  guinea: 'Guinea',
  guinea_bissau: 'Guinea-Bissau',
  guyana: 'Guyana',
  haiti: 'Haiti',
  honduras: 'Honduras',
  hungary: 'Hungary',
  iceland: 'Iceland',
  india: 'India',
  indonesia: 'Indonesia',
  iran: 'Iran',
  iraq: 'Iraq',
  ireland: 'Ireland {Republic}',
  israel: 'Israel',
  italy: 'Italy',
  ivory_coast: 'Ivory Coast',
  jamaica: 'Jamaica',
  japan: 'Japan',
  jordan: 'Jordan',
  kazakhstan: 'Kazakhstan',
  kenya: 'Kenya',
  kiribiati: 'Kiribati',
  north_korea: 'Korea North',
  south_korea: 'Korea South',
  kosovo: 'Kosovo',
  kuwait: 'Kuwait',
  kyrgyzstan: 'Kyrgyzstan',
  laos: 'Laos',
  latvia: 'Latvia',
  lebanon: 'Lebanon',
  lesotho: 'Lesotho',
  liberia: 'Liberia',
  libya: 'Libya',
  liechenstein: 'Liechtenstein',
  lithuania: 'Lithuania',
  luxembourg: 'Luxembourg',
  macedonia: 'Macedonia',
  madagascar: 'Madagascar',
  malawi: 'Malawi',
  malaysia: 'Malaysia',
  maldives: 'Maldives',
  mali: 'Mali',
  malta: 'Malta',
  marshall_islands: 'Marshall Islands',
  mauritania: 'Mauritania',
  mauritius: 'Mauritius',
  mexico: 'Mexico',
  micronesia: 'Micronesia',
  moldova: 'Moldova',
  monaco: 'Monaco',
  mongolia: 'Mongolia',
  montenegro: 'Montenegro',
  morocco: 'Morocco',
  mozambique: 'Mozambique',
  myanmar: 'Myanmar, {Burma}',
  namibia: 'Namibia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlands: 'Netherlands',
  new_zealand: 'New Zealand',
  nicaragua: 'Nicaragua',
  niger: 'Niger',
  nigeria: 'Nigeria',
  norway: 'Norway',
  oman: 'Oman',
  pakistan: 'Pakistan',
  palau: 'Palau',
  panama: 'Panama',
  papua_new_guinea: 'Papua New Guinea',
  paraguay: 'Paraguay',
  peru: 'Peru',
  philippines: 'Philippines',
  poland: 'Poland',
  portugal: 'Portugal',
  qatar: 'Qatar',
  romania: 'Romania',
  russian_federation: 'Russian Federation',
  rwanda: 'Rwanda',
  st_kitts_nevis: 'St Kitts & Nevis',
  st_lucia: 'St Lucia',
  saint_vincent: 'Saint Vincent & the Grenadines',
  samoa: 'Samoa',
  san_marino: 'San Marino',
  sao_tome: 'Sao Tome & Principe',
  saudi_arabia: 'Saudi Arabia',
  senegal: 'Senegal',
  serbia: 'Serbia',
  seychelles: 'Seychelles',
  sierra_leone: 'Sierra Leone',
  singapore: 'Singapore',
  slovakia: 'Slovakia',
  slovenia: 'Slovenia',
  solomon_islands: 'Solomon Islands',
  somalia: 'Somalia',
  south_africa: 'South Africa',
  south_sudan: 'South Sudan',
  spain: 'Spain',
  sri_lanka: 'Sri Lanka',
  sudan: 'Sudan',
  suriname: 'Suriname',
  swaziland: 'Swaziland',
  sweden: 'Sweden',
  switzerland: 'Switzerland',
  syria: 'Syria',
  taiwan: 'Taiwan',
  tajikistan: 'Tajikistan',
  tanzania: 'Tanzania',
  thailand: 'Thailand',
  togo: 'Togo',
  thonga: 'Tonga',
  trinidad_and_tobago: 'Trinidad & Tobago',
  tunisia: 'Tunisia',
  turkey: 'Turkey',
  turkmenistan: 'Turkmenistan',
  tuvalu: 'Tuvalu',
  uganda: 'Uganda',
  ukraine: 'Ukraine',
  united_arab_emirates: 'United Arab Emirates',
  united_kingdom: 'United Kingdom',
  united_states: 'United States',
  uruguay: 'Uruguay',
  uzbekistan: 'Uzbekistan',
  vanuatu: 'Vanuatu',
  vatican_city: 'Vatican City',
  venezuela: 'Venezuela',
  vietnam: 'Vietnam',
  yemen: 'Yemen',
  zambia: 'Zambia',
  zimbabwe: 'Zimbabwe',
  baden_wuerttemberg: 'Baden-Wurttemberg',
  bayern: 'Bavaria',
  berlin: 'Berlin',
  brandenburg: 'Brandenburg',
  bremen: 'Bremen',
  hamuburg:'Hamburg',
  hesse:'Hesse',
  mecklenburg_vorpommern: 'Mecklenburg Western Pomerania',
  lower_saxony: 'Lower Saxony',
  north_rhine_westphalia: 'Northrhine-Westphalia',
  rheinland_pfalz: 'Rhineland Palatinate',
  saarland: 'Saarland', 
  saxony: 'Saxony', 
  saxony_anhalt: 'Saxony-Anhalt',
  schleswig_holstein: 'Schleswig Holstein',
  thuringia: 'Thuringia',
  
}

export default en_translations