import fetch from './Fetcher';
import { request } from './request';
export const kimbleCompanyService = {
  all: query => {
    return fetch("/api/kimble/companies?query=".concat(query), request.get);
  },
  get: id => {
    return fetch("/api/kimble/companies/".concat(id), request.get);
  },
  getCompanyDetails: company => {
    return fetch('/api/companies/get_company_details', {
      ...request.post,
      body: JSON.stringify({
        company
      })
    });
  },
  create: company => {
    return fetch('/api/companies/', {
      ...request.post,
      body: JSON.stringify({
        company
      })
    });
  }
};