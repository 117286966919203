import { createSlice } from '@reduxjs/toolkit';
export const reviewSlice = createSlice({
  name: 'review',
  initialState: {
    isUnderTechnicalReview: null,
    isAnyCompanyClaimGroupUnderTechnicalReview: null
  },
  reducers: {
    setIsUnderTechnicalReview: (state, action) => {
      state.isUnderTechnicalReview = action.payload;
    },
    setIsAnyCompanyClaimGroupUnderTechnicalReview: (state, action) => {
      state.isAnyCompanyClaimGroupUnderTechnicalReview = action.payload;
    }
  }
});
export const {
  setIsUnderTechnicalReview,
  setIsAnyCompanyClaimGroupUnderTechnicalReview
} = reviewSlice.actions;
export default reviewSlice.reducer;