import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { api as oldApi } from '../service';
import { storeClaimProjectReport } from '../../store/features/claimProjectReportsSlice';
import { useDispatch } from 'react-redux';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
const api = buildService('/claim_groups/{id}/claim_project_reports', {
  only: ['all', 'get']
});
const claimProjectReportService = {
  base: api,
  useFetchAll: (claimGroupId, setLoading) => {
    const [projects, setProjects] = useState([]);
    const fetchAndSetReports = useErrorHandling(useCallback(async () => {
      setLoading && setLoading(true);
      if (!claimGroupId) return;
      const reportsResponse = await api.all(claimGroupId);
      setProjects([...reportsResponse.claim_project_reports]);
    }, [claimGroupId, setLoading]), useCallback(() => {
      setLoading && setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      fetchAndSetReports();
    }, [fetchAndSetReports]);
    return [projects, setProjects, fetchAndSetReports];
  },
  useGet: (claimGroupId, reportId, setLoading) => {
    const [project, setProject] = useState(null);
    const fetchAndSetReport = useErrorHandling(useCallback(async () => {
      setLoading && setLoading(true);
      if (!claimGroupId || !reportId) return;
      const projectResponse = await api.get(claimGroupId, reportId);
      setProject({
        ...projectResponse
      });
    }, [claimGroupId, reportId, setLoading]), useCallback(() => {
      setLoading && setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      fetchAndSetReport();
    }, [fetchAndSetReport]);
    return [project, setProject, fetchAndSetReport];
  },
  useUpdateClaimProjectReportName: function (claimGroupId, claimProjectReport) {
    let shouldReturnHtml = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    const dispatch = useDispatch();
    return useErrorHandling(useCallback(async newName => {
      const updatedClaimProjectReport = await claimProjectReportService.update(claimGroupId, {
        ...claimProjectReport,
        project_name: newName
      });
      dispatch(storeClaimProjectReport(updatedClaimProjectReport));
      return shouldReturnHtml ? updatedClaimProjectReport.project_name_html : updatedClaimProjectReport.project_name;
    }, [claimGroupId, claimProjectReport, shouldReturnHtml, dispatch]));
  },
  useUpdateClaimProjectReport: (claimGroupId, claimProjectReport) => {
    return useErrorHandling(useCallback(async function () {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let onSuccess = arguments.length > 1 ? arguments[1] : undefined;
      const updatedClaimProjectReport = await claimProjectReportService.update(claimGroupId, {
        id: claimProjectReport.id,
        claim_project_report: {
          ...params
        }
      });
      onSuccess();
      return updatedClaimProjectReport;
    }, [claimGroupId, claimProjectReport]));
  },
  update: (claimGroupId, claim_project_report) => oldApi.update("/claim_groups/".concat(claimGroupId, "/claim_project_reports/").concat(claim_project_report.id), claim_project_report),
  delete: (claimGroupId, reportId) => oldApi.delete("/claim_groups/".concat(claimGroupId, "/claim_project_reports/").concat(reportId)),
  all: params => oldApi.get("/claim_project_reports?".concat(params)),
  get: (claimGroupId, reportId) => oldApi.get("/claim_groups/".concat(claimGroupId, "/claim_project_reports/").concat(reportId))
};
export default claimProjectReportService;