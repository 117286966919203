import { useCallback } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { useTranslation } from 'react-i18next';
const api = buildService('/companies/{id}/company_contracts/{id}/company_contract_attachments', {
  only: ['delete']
}, {
  only: ['postFormData']
});
const useUploadCompanyContractFile = (companyId, companyContractId, setCompanyContract) => {
  return useErrorHandling(useCallback(async formData => {
    const updatedCompanyContract = await api.postFormData(companyId, companyContractId, formData);
    setCompanyContract(updatedCompanyContract);
    return updatedCompanyContract;
  }, [companyContractId, companyId, setCompanyContract]));
};
const useDeleteCompanyContractFile = (companyId, companyContractId, setCompanyContract) => {
  const {
    t
  } = useTranslation();
  return useErrorHandling(useCallback(async attachment => {
    if (window.confirm(t('are_you_sure_you_want_to_delete_this_company_contract_file'))) {
      const updatedCompaynContract = await api.delete(companyId, companyContractId, attachment.id);
      setCompanyContract(updatedCompaynContract);
    }
  }, [companyContractId, companyId, setCompanyContract, t]));
};
const companyContractAttachmentsService = {
  useUploadCompanyContractFile,
  useDeleteCompanyContractFile
};
export default companyContractAttachmentsService;