import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimGroupTechnicalNarrativeService = {
  create(claimGroupId, formData) {
    return fetch(`/api/claim_groups/${claimGroupId}/technical_narrative_imports/`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData
    });
  },
}

export default claimGroupTechnicalNarrativeService;
