import TemplateForm from '@components/shared/TemplateForm';
import { Loading } from '@rd-web-markets/shared/dist/util';
import claimScheduleService from '@rd-web-markets/shared/dist/services/claim_schedule.service';
import claimScheduleIcsEventTemplateService from '@services/claim_schedule/claim_schedule_ics_event_template.service';
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import icsEventEmailTemplateService from '@services/ics_event_email_template.service';
import emailAttachmentsService from '@services/email_attachments.service';
import { MARKET_SPECIFIC_RD_OR_4 } from '@rd-web-markets/market/dist/constants';

const ClaimIcsEventTemplateConfigure = ({ claimGroup, setClaimGroup }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const initialSubject = `${claimGroup.company.abbreviated_name || claimGroup.company.name} - ${MARKET_SPECIFIC_RD_OR_4} - Template`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await icsEventEmailTemplateService.all()
        setTemplates(response);
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [claimGroup, dispatch]);

  const handleSubmit = async (template) => {
    setLoading(true);
    try {
      if (claimGroup.claim_schedule.active_claim_schedule_ics_event_template) {
        const updatedTemplate = await claimScheduleIcsEventTemplateService.update(claimGroup.claim_schedule.id, template);
        const updatedClaimSchedule = {...claimGroup.claim_schedule};
        updatedClaimSchedule.active_claim_schedule_ics_event_template = updatedTemplate;
        setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
      } else {
        const newTemplate = await claimScheduleIcsEventTemplateService.create(claimGroup.claim_schedule.id, template);
        const updatedClaimSchedule = {...claimGroup.claim_schedule};
        updatedClaimSchedule.active_claim_schedule_ics_event_template = newTemplate
        setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
      }
    } catch(error) { 
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleRemoveTemplate = async e => {
    try { 
      await claimScheduleIcsEventTemplateService.delete(claimGroup.claim_schedule.id);
      const templates = await fetch('/api/claim_schedule_ics_event_templates');
      setTemplates(templates);
      const updatedClaimSchedule = await claimScheduleService.get(claimGroup.id, claimGroup.claim_schedule.id)
      setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
    } catch(error) { 
      dispatch(handleError(error));
    }
  };

  const removeAttachment = async (id) => {
    setLoading(true);
    try {
      await emailAttachmentsService.delete('claim_schedule_ics_event_templates', claimGroup.claim_schedule.active_claim_schedule_ics_event_template.id, id);
      const updatedClaimSchedule = {...claimGroup.claim_schedule}
      updatedClaimSchedule.active_claim_schedule_ics_event_template.download_links = updatedClaimSchedule.active_claim_schedule_ics_event_template.download_links.filter(link => link.id !== id)
      setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
    } catch(error) {
      dispatch(handleError(error));
    } finally { 
      setLoading(false);
    }
  };

  const addAttachment = useCallback(async (formData) => {
      await emailAttachmentsService.create('claim_schedule_ics_event_templates', claimGroup.claim_schedule.active_claim_schedule_ics_event_template.id, formData);
      const updatedClaimSchedule = await claimScheduleService.get(claimGroup.id);
      setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
   
  }, [setClaimGroup, claimGroup.claim_schedule.active_claim_schedule_ics_event_template?.id, claimGroup.id])


  if (!templates) return <Loading />;

  return (
    <>
      <TemplateForm
        removeAttachment={removeAttachment}
        addAttachment={addAttachment}
        handleSubmit={handleSubmit}
        templates={templates}
        templateType='claim_schedule_ics_event_template'
        claimSchedule={claimGroup.claim_schedule}
        loading={loading}
        handleRemoveTemplate={handleRemoveTemplate}
        showInModal={true}
        initialSubject={initialSubject}
      />
    </>
  );
}

export default ClaimIcsEventTemplateConfigure
