import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function ButtonControls({
  company,
  claimGroup,
  reviewedObject,
  reviewedObjectClass,
  loading,
  createChangeSetForClient,
  createChangeSet,
  finalizeChangeSet,
  approveChangeSet,
  rejectChangeSet,
  buttonName,
  underManagerReviewButtonName = 'Under Manager Review',
  managerQAReviewButtonName = 'manager_review_completed',
  acceptChangesButtonText = 'accept_manager_review_changes',
  rejectChangesButtonText = 'reject_manager_review_changes',
  reviewType='consultant_review',
  setModalShow
}) {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const isAdmin = () => {
    return user.account_type === 'admin' || user.account_type === 'super_consultant';
  };

  const isClaimManager = () => {
    // the claim group may not be defined when this component is first loaded
    return user.id === claimGroup?.admin.id;
  };

  const isClaimConsultant = () => {
    if(reviewedObjectClass === 'ClaimGroup') {
      return user.roles?.find(role => role.resource_id === reviewedObject.id);
    } else {
      return user.roles?.find(role => role.resource_id === reviewedObject.claim_group_id);
    }
  };

  const renderButton = () => {
    if(reviewedObject.active_change_set && reviewedObject.active_change_set.review_type !== reviewType) {
      return (
        <div>
          Another review has already been started
        </div>
      )
    }
    const createChangeSetOrOpenModal = () => {
      if(reviewType === 'consultant_review'){
        createChangeSet(reviewedObject, reviewedObjectClass, reviewType)
      }else {
        setModalShow(true)
      }
    }
    if (!reviewedObject.active_change_set && (isClaimConsultant() || isAdmin())) {
      return (
        <Button
          onClick={() => createChangeSetOrOpenModal()}
          loading={loading}
          icon='zoom_in'
          disabled={reviewedObject.is_locked} // is_locked only exists for claims at the moment
          iconPosition="left"
          variant="info"
          size="md">
          {buttonName}
        </Button>
      )
    }
    else if (reviewedObject.active_change_set && !reviewedObject.active_change_set.finalized_at) {
      if(isAdmin() || isClaimManager()) {
        return (
          <Button
            onClick={() => finalizeChangeSet(reviewedObject)}
            loading={loading}
            icon='flag'
            disabled={reviewedObject.is_locked} // is_locked only exists for claims at the moment
            iconPosition="left"
            variant="danger"
            size="md">
            {t(managerQAReviewButtonName)}
          </Button>
        )
      } else {
        return (
          <Button
            icon='flag'
            disabled={true}
            iconPosition="left"
            variant="danger"
            size="md">
            {underManagerReviewButtonName}
          </Button>
        )
      }

    }
    else if ((isAdmin() || isClaimConsultant()) && reviewedObject.active_change_set && reviewedObject.active_change_set.finalized_at)  {
      return (
        <>
          <Button
            onClick={() => approveChangeSet(reviewedObject)}
            className="mx-2"
            loading={loading}
            icon='done'
            disabled={reviewedObject.is_locked} // is_locked only exists for claims at the moment
            iconPosition="left"
            variant="success"
            size="md"
          >
            {t(acceptChangesButtonText)}
            
          </Button>
          <Button
            onClick={() => rejectChangeSet(reviewedObject)}
            loading={loading}
            icon='close'
            disabled={reviewedObject.is_locked} // is_locked only exists for claims at the moment
            iconPosition="left"
            variant="danger"
            size="md"
          >
            {t(rejectChangesButtonText)}
          </Button>
        </>
      );
    }
  }

  return (
    <Row className='mt-5 mb-5'>
      <Col md={12} className="text-center">
        {renderButton()}
      </Col>
    </Row>
  )
}
