import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { buildService } from '@rd-web-markets/shared/dist/services/service';

const api = buildService('/api/lists/claim_groups/{id}/claim_project_reports', { only: [ 'all' ]})

const claimProjectReportsListsService = {
  all: (claimGroupId, queryParams) => {
    return fetch(`/api/lists/claim_groups/${claimGroupId}/claim_project_reports?${queryParams}`, request.get);
  },
};

export default claimProjectReportsListsService;