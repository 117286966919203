import React, { useState, useEffect, useCallback } from 'react'
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MARKET_SPECIFIC_RD_OR_4_MENU } from '@rd-web-markets/market/dist/constants';
import TechnicalUploadsForm from '@components/shared/technical_uploads/TechnicalUploadsForm';
import technicalUploadsService from '@services/claim/technical_uploads_service';
import { Loading, Table } from '@rd-web-markets/shared/dist/util';
import { useDispatch } from 'react-redux';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';

const ClientClaimGroupTechnicalSection = ({claimGroup, loading, handleToaster, setLoading}) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [uploads, setUploads] = useState(null);

  const fetchUploads = useErrorHandling(useCallback(async () => {
    const uploads = await technicalUploadsService.all(claimGroup.id);
    setUploads(uploads);
  }, [claimGroup]))

  useEffect(() => {
    fetchUploads();
  }, [fetchUploads]);

  const handleSubmit = async (technical_upload, file) => {
    if(!file) {
      handleToaster('Attach a file before submitting', 'warning');
    } else {
      setLoading(true);
      let fd = new FormData();
      fd.append('file', file);
      fd.append('technical_upload', JSON.stringify(technical_upload));
      try {
        await technicalUploadsService.create(claimGroup.id, fd);
        await fetchUploads();
      } catch(error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  };

  const handleDelete = async (uploadId) => {
    if(window.confirm('Are you sure ?')) {
      setLoading(true);
      try { 
        await technicalUploadsService.delete(claimGroup.id, uploadId);
        fetchUploads();
      } catch(error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  };

  if(!claimGroup || !uploads) return <Loading />;

  const rows = upload => {
    return {
      key: upload.id,
      columns: [
        <a
          rel="noreferrer"
          href={upload.upload_links.url}
          target="_blank"
        >
          <small>{upload.upload_links.filename}</small>
        </a>,
        upload.comments,
        upload.document_type === 'tax_computations' ? 'Tax Computations' : 'Others',
        upload.uploaded_by,
        createTimezonedDate(new Date(upload.created_at)).toCustomLocaleDateString()
      ],
      onDelete: () => handleDelete(upload.id),
      showDelete: user.account_type !== 'customer' || upload.uploaded_by_id === user.id
    }
  };

  const headers = [
    { text: t('document_name') },
    { text: t('document_comments') },
    { text: t('document_type') },
    { text: t('uploaded_by') },
    { text: t('date') },
    { text: '' }
  ];

  const tableRows = uploads.map(rows);

  return (
    <>
      <h2 className="text-primary mb-4">{MARKET_SPECIFIC_RD_OR_4_MENU}</h2>
      <Card>
        <Card.Body>
          <TechnicalUploadsForm handleSubmit={handleSubmit} loading={loading} isSignedOff={!!claimGroup.report_completion} />
          <Table headers={headers} rows={tableRows} onDelete={(index, row) => handleDelete(row.key)} disableDelete={!!claimGroup.report_completion} />
        </Card.Body>
      </Card>
    </>
  )
}

export default ClientClaimGroupTechnicalSection
