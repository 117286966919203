import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';

const ToReleaseNotesButton = () => {
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();

  const toReleaseNotes = () => {
    history.push(`/${user.account_type}/release_notes`);
  }

  return (
    <Button variant="text" onClick={toReleaseNotes}>
      <span className="material-icons md-18 blue">event_note</span>
    </Button>
  )
}

export default ToReleaseNotesButton