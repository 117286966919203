/**
 * This class accepts a string or an array, because sometimes
 * the backend returns a string error message, and sometimes it
 * returns an array of error messages - e.g. when model validation fails for
 * multiple reasons.
 */
export class ApiError extends Error {
  /**
   *
   * @param {Array | String} data - either a message to show to the user
   *    or an array of messages.
   */
  constructor (data) {
    super(data);

    if (Array.isArray(data)) {
      this.userFriendlyMessages = data;
    } else {
      this.userFriendlyMessages = [data];
    }
  }
}
