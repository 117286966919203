import React from 'react';
import Modal from '@rd-web-markets/shared/dist/util/Modal';
import { Card, Form } from 'react-bootstrap';
import { DatePicker } from '@rd-web-markets/shared/dist/util/date';
import { useTranslation } from 'react-i18next';

const ReleasesModal = ({setModalShow, modalShow, newRelease, setNewRelease, createRelease, modalMode}) => {
  const { t } = useTranslation();

  const modalBody = () => {
    return (
      <Card className='mt-3'>
        <Card.Body>
          <p className='font-weight-bold'>{t('release_number')}</p>
          <Form.Control
            value={newRelease.release_number}
            name='new_release_number'
            onChange={(e) => setNewRelease({ ...newRelease, release_number: e.target.value })}
            size='md'
            type='text'
            placeholder={t('add_a_release_number')}
          />
        </Card.Body>
        <Card.Body>
          <p className='font-weight-bold'>{t('choose_release_date')}</p>
          <DatePicker
            selected={newRelease.release_date}
            onChange={(date) => setNewRelease({ ...newRelease, release_date: date })}
            showTime={false}
          />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Modal
      size='lg'
      title={t(modalMode === 'create' ? 'add_a_release' : 'update_release')}
      show={modalShow}
      renderBody={() => modalBody()}
      onHide={() => setModalShow(false)}
      onSubmit={() => createRelease(modalMode)}
      submitText={t(modalMode === 'create' ? 'add_a_release' : 'update_release')}
    />
  )
}

export default ReleasesModal