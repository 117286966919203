import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { setIsUnderTechnicalReview } from '../store/features/reviewSlice';
let CompanyService = {};
CompanyService = {
  useRefresh(companyId, setCompanies, setLoading) {
    const refreshCompany = useErrorHandling(useCallback(async () => {
      setLoading(true);
      await this.refresh(companyId);
      const response = await this.all();
      setCompanies(response.companies);
    }, [companyId, setCompanies, setLoading]), () => setLoading(false));
    return refreshCompany;
  },
  useDelete(companyId, setCompanies, setLoading) {
    const deleteCompany = useErrorHandling(useCallback(async () => {
      if (window.confirm('Are you sure you wish to delete this company?')) {
        setLoading(true);
        await this.delete(companyId);
        const response = await this.all();
        setCompanies(response.companies);
      }
    }, [companyId, setCompanies, setLoading]), () => setLoading(false));
    return deleteCompany;
  },
  useUpdate(setCompany, setLoading) {
    let callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => {};
    const updateCompany = useErrorHandling(useCallback(async company => {
      setLoading && setLoading(true);
      const updatedCompany = await this.update(company);
      setCompany({
        ...updatedCompany
      });
      callback(updatedCompany);
    }, [setCompany, setLoading, callback]), useCallback(() => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return updateCompany;
  },
  useGetCompanyAndClaimGroup: function (companyId, claimGroupId) {
    let initialCompanyState = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let initialClaimGroupState = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    let setLoading = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : () => {};
    const [company, setCompany] = useState(initialCompanyState);
    const [claimGroup, setClaimGroup] = useState(initialClaimGroupState);
    const dispatch = useDispatch();
    const setClaimGroupFromCompany = useCallback(company => {
      const selectedClaimGroup = company.claim_groups.find(c => c.id === Number(claimGroupId)) || company.claim_groups[0];
      setClaimGroup(selectedClaimGroup);
      dispatch(setIsUnderTechnicalReview(!!selectedClaimGroup.active_change_set));
    }, [claimGroupId, dispatch, setClaimGroup]);
    const updateCompany = CompanyService.useUpdate(setCompany, setLoading, useCallback(updatedCompany => {
      setClaimGroupFromCompany(updatedCompany);
    }, [setClaimGroupFromCompany]));
    const fetchCompanyAndClaimGroup = useErrorHandling(useCallback(async () => {
      if (companyId && claimGroupId) {
        const fetchedCompany = await CompanyService.get(companyId);
        setClaimGroupFromCompany(fetchedCompany);
        setCompany(fetchedCompany);
      }
    }, [companyId, claimGroupId, setClaimGroupFromCompany]));
    useEffect(() => {
      fetchCompanyAndClaimGroup(companyId, claimGroupId);
    }, [fetchCompanyAndClaimGroup, companyId, claimGroupId, dispatch]);
    return [company, setCompany, claimGroup, setClaimGroup, fetchCompanyAndClaimGroup, updateCompany];
  },
  all: function () {
    let queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    return fetch("/api/companies?".concat(queryParams), request.get);
  },
  get: company_id => {
    return fetch("/api/companies/".concat(company_id), request.get);
  },
  update: company => {
    return fetch("/api/companies/".concat(company.id), {
      ...request.put,
      body: JSON.stringify({
        company
      })
    });
  },
  delete: company_id => {
    return fetch("/api/companies/".concat(company_id), request.delete);
  }
};
export { CompanyService };