import React from 'react'
import RegularRealtimeDashboard from '@rd-web-markets/market/dist/dashboards/RegularRealtimeDashboard';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';

const RegularRealtimeDashboardPage = () => {

  return (
    <>
      <SidebarPortal headerItem={{link: 'dashboards', text: 'Dashboards'}} />
      <div className='mt-3'></div>
        <RegularRealtimeDashboard />
      <div className='mb-5'></div>
    </>
  );
};
export default RegularRealtimeDashboardPage;
