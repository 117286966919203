import { setIsAnyCompanyClaimGroupUnderTechnicalReview } from '@rd-web-markets/shared/dist/store/features/reviewSlice'
import { CompanyService } from '@services/company.service'

export async function fetchAndSetCompany(id, setCompany, dispatch, handleError) {
  try {
    const company = await CompanyService.get(id) 

    setCompany(company)

    const anyClaimGroupIsUnderReview = company.claim_groups.some(cg => !!cg.active_change_set)
    dispatch(setIsAnyCompanyClaimGroupUnderTechnicalReview(anyClaimGroupIsUnderReview))

    return company
  } catch (error) {
    dispatch(handleError(error))
  }
}