import { api } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '../../hooks/useErrorHandling';
const projectStatusDashboarService = {
  get: (company_id, claim_group_id) => api.get("/dashboards/project_status_dashboards/".concat(company_id, "?claim_group_id=").concat(claim_group_id)),
  useFetchDashboardDataForClaimGroup: (companyId, claimGroupId) => {
    const [dashboardData, setDashboardData] = useState({});
    const fetchDashboardData = useErrorHandling(useCallback(async function () {
      const res = await projectStatusDashboarService.get(...arguments);
      setDashboardData(res);
    }, []));
    useEffect(async () => {
      fetchDashboardData(companyId, claimGroupId);
    }, [companyId, claimGroupId, fetchDashboardData]);
    return dashboardData;
  }
};
export default projectStatusDashboarService;