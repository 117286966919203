import React from 'react';
import EditClaimGroupFieldModal from './EditClaimGroupFieldModal';
import EditReportTemplateCategoryModal from './EditReportTemplateCategoryModal';
import EditProjectReportSliceModal from '@rd-web-markets/market/dist/reportPreview/EditProjectReportSliceModal';
import EditProjectReportNameModal from '@rd-web-markets/shared/dist/util/textEdit/modals/EditProjectReportNameModal';

export default function CompanyReportPreviewEditSectionModal({
  claimGroup,
  fieldName,
  reportTemplateId,
  reportTemplateCategoryId = null,
  onHide,
  onContentChanged,
  isVisible,
  title,
  projectId = null,
  sliceId = null,
  fieldId = null,
  claimGroupId = null,
  modalType = null,
  isUnderReview = false,
  ...props
}) {
  function renderModal () {
    switch (modalType) {
      case 'ProjectRemportNameModal':
        return <EditProjectReportNameModal
          reportTemplateId={reportTemplateId}
          claimGroupId={claimGroupId}
          projectReportId={projectId}
          onHide={onHide}
          onContentChanged={onContentChanged}
          isVisible={isVisible}
          title={title}
          isUnderReview={isUnderReview}
          {...props}
        />
      case 'ClaimGroupFieldModal':
        return <EditClaimGroupFieldModal
          claimGroupId={claimGroup.id}
          fieldName={fieldName}
          reportTemplateId={reportTemplateId}
          onHide={onHide}
          onContentChanged={onContentChanged}
          isVisible={isVisible}
          title={title}
          isUnderReview={isUnderReview}
          {...props}
        />
      case 'ReportTemplateCategoryModal':
        return <EditReportTemplateCategoryModal
          reportTemplateId={reportTemplateId}
          reportTemplateCategoryId={reportTemplateCategoryId}
          onHide={onHide}
          onContentChanged={onContentChanged}
          isVisible={isVisible}
          title={title}
          isUnderReview={isUnderReview}
          {...props}
        />
      default:
        return <EditProjectReportSliceModal
          reportTemplateId={reportTemplateId}
          onHide={onHide}
          onContentChanged={onContentChanged}
          isVisible={isVisible}
          title={title}
          projectId={projectId}
          sliceId={sliceId}
          fieldId={fieldId}
          claimGroupId={claimGroupId}
          modalType={modalType}
          isUnderReview={isUnderReview}
          {...props}
        />
    }
  }

  return renderModal()
}
