import { useCallback, useEffect, useState } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const api = buildService('/companies/{id}/company_contracts', {
  only: ['all', 'update']
});
const useFetchCompanyContacts = (companyId, setLoading) => {
  const [companyContacts, setCompanyContacts] = useState([]);
  const fetchContacts = useErrorHandling(useCallback(async () => {
    setLoading(true);
    const allContacts = await api.all(companyId);
    setCompanyContacts(allContacts);
  }, [companyId, setLoading]), useCallback(() => {
    setLoading(false);
  }, [setLoading]));
  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);
  return [companyContacts, setCompanyContacts, fetchContacts];
};
const useUpdateContract = _ref => {
  let {
    companyId,
    contract,
    contracts,
    setContracts,
    setLoading
  } = _ref;
  const updateContract = useErrorHandling(useCallback(async () => {
    setLoading(true);
    const updatedContract = await api.update(companyId, contract.id, contract);
    const indexInState = contracts.findIndex(c => c.id === updatedContract.id);
    contracts.splice(indexInState, 1, updatedContract);
    setContracts([...contracts]);
    setLoading(false);
  }, [companyId, contract, contracts, setContracts, setLoading]), useCallback(() => {
    setLoading(false);
  }, [setLoading]));
  return updateContract;
};
const companyContractsService = {
  useFetchCompanyContacts,
  useUpdateContract
};
export default companyContractsService;