import React, { useEffect, useState, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { Breadcrumb, Card} from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import TechnicalUploadsForm from '@components/shared/technical_uploads/TechnicalUploadsForm';
import technicalUploadsService from '@services/claim/technical_uploads_service';
import { SIDEBAR_TECHNICAL_SECTION_LINKS, MARKET_SPECIFIC_RD_OR_4_MENU } from '@rd-web-markets/market/dist/constants';
import { Table } from '@rd-web-markets/shared/dist/util';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import { makeKey } from '@rd-web-markets/shared/dist/hooks/useConvertForTranslationKey';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';

const TechnicalUploadsPage = ({handleToaster, accountType}) => {
  const { claimGroupId } = useParams();
  const [claimGroup, setClaimGroup] = useState(null);
  const [uploads, setUploads] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchUploads = useErrorHandling(useCallback(async () => {
    const uploads = await technicalUploadsService.all(claimGroupId);
    setUploads(uploads);
  }, [claimGroupId]))

  const fetchClaimGroupData = useErrorHandling(useCallback(async () => {
    const claimGroup = await claimGroupService.get(claimGroupId);
    setClaimGroup(claimGroup);
  }, [claimGroupId]))

  useEffect(() => {
    fetchClaimGroupData();
    fetchUploads();
  }, [fetchClaimGroupData, fetchUploads]);

  const handleSubmit = async (technical_upload, file) => {
    if(!file) {
      handleToaster('Attach a file before submitting', 'warning');
    } else {
      setLoading(true);
      let fd = new FormData();
      fd.append('file', file);
      fd.append('technical_upload', JSON.stringify(technical_upload));
      try {
        await technicalUploadsService.create(claimGroup.id, fd);
        await fetchUploads();
      } catch(error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  };

  const handleDelete = async (uploadId) => {
    if(window.confirm('Are you sure ?')) {
      setLoading(true);
      try { 
        await technicalUploadsService.delete(claimGroup.id, uploadId);
        fetchUploads();
      } catch(error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  };


  if(!claimGroup || !uploads) return <Loading />;


  const rows = upload => {
    return {
      key: upload.id,
      columns: [
        <a
          rel="noreferrer"
          href={upload.upload_links.url}
          target="_blank"
        >
          <small>{upload.upload_links.filename}</small>
        </a>,
        upload.comments,
        t(upload.document_type),
        upload.uploaded_by,
        createTimezonedDate(new Date(upload.created_at)).toCustomLocaleDateString()
      ],
      onDelete: () => handleDelete(upload.id)
    }
  };

  const headers = [
    { text: t('document_name') },
    { text: t('document_comments') },
    { text: t('document_type') },
    { text: t('uploaded_by') },
    { text: t('date') },
    { text: '' }
  ];

  const tableRows = uploads.map(rows);

  const getSubmenuItems = (menuItem) => {
    if(menuItem.link === 'project_reports') {
      return claimGroup.claim_project_reports.map((report) => {
        return {
          link: `claim_groups/${claimGroup.id}/technical_proof/project_reports/${report.id}`,
          text: report.project_name,
        }
      })
    }
      return menuItem.subMenu.map((item) => {
        return {
          link: `claim_groups/${claimGroup.id}/technical_proof/${item.link}`,
          text: item.text,
        }
      })
  }

  const getMenuItems = () => {
    return SIDEBAR_TECHNICAL_SECTION_LINKS.map((item) => {
      return {
        link: item.link === 'technical_uploads' ? `claim_groups/${claimGroup.id}/${item.link}` : `claim_groups/${claimGroup.id}/technical_proof/${item.link}`,
        text: item.text,
        subMenu: item.subMenu ? getSubmenuItems(item) : false,
        highlighted: item.link === 'technical_uploads',
      };
    })
  }

  return (
    <>
      <SidebarPortal headerItem={{ link: `claim_groups/${claimGroup.id}/project_overview`, text: claimGroup.company.name }} menuItems={getMenuItems()} />
      <BreadcrumbsPortal>
        <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}` }}>
            {t('home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/` }}>
            {t('companies')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}/master` }}>{claimGroup.company.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>{claimGroup.name.replace(claimGroup.company.name, '').trim()}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t(makeKey(MARKET_SPECIFIC_RD_OR_4_MENU))}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <h1 className='text-primary mb-4'>{t('upload_document')}</h1>

      <Card>
        <Card.Body>
          <TechnicalUploadsForm handleSubmit={handleSubmit} loading={loading}/>
          <Table headers={headers} rows={tableRows} onDelete={(index, row) => handleDelete(row.key)}/>
        </Card.Body>
      </Card>
    </>
  )
}

export default TechnicalUploadsPage
