/**
 * This is a hack to make the shared components market independent, even though they have date capabilities which depend on the market,
 * as such dependency would be bad for the unit tests and would add unnecessary coupling between the packages.
 * These 2 lines are added to the top of the file, so that we can make sure that nothing happens before we set the locale.
 */
import { LOCALE } from '@rd-web-markets/market/dist/constants'
window.AYMING_MARKET_LOCALE = LOCALE

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { ProSidebarProvider } from 'react-pro-sidebar';
import './style.scss';
import App from './App';
import store from '@rd-web-markets/shared/dist/store';
import './services/i18n';
import { AppCache } from '@rd-web-markets/shared/dist/util/AppCache';

// clear the app cache on page reload
AppCache.clear()

if (window.location.protocol !== 'https:' && !window.location.host.includes('localhost') && !window.location.host.includes('express')) {
  window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_RELEASE,
  ignoreErrors: ['ReferenceError']
});

ReactDOM.render(
  <Provider store={store}>
    <ProSidebarProvider>
      <div id='sidebar-container' />
      <App />
    </ProSidebarProvider>
  </Provider>,
  document.getElementById('root')
);
