import React from 'react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TechnicalProofNavigationLinks from '@rd-web-markets/market/dist/claim_group/technical_proof/TechnicalProofNavigationLinks'

const TechnicalProofNavigation = ({ page, claimGroup }) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <>
      <Nav variant="tabs">
        <TechnicalProofNavigationLinks user={user} page={page} claimGroup={claimGroup}/>
      </Nav>
    </>
  );
};

export default TechnicalProofNavigation;
