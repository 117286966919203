import React from 'react'
import { Card, FormControl, Table, Form } from 'react-bootstrap';
import { ChangeHighlighter, FormattedNumberInput } from '@rd-web-markets/shared/dist/util/';
import { useTranslation } from 'react-i18next';

const FinancialFramework = ({financialFrameworks, handleFinancialFrameworksChange, saveChange}) => {
  const years = financialFrameworks.map(a => a.year);
  const { t } = useTranslation();

  return (
 
    <Card>
      <Card.Header>
        {t('financial_framework')}
      </Card.Header>
      <Card.Body>
        <Table  hover>
          <thead>
            <tr>
              <th></th>
              {years.sort().map(year => {
                return <th className="text-center">{year}</th>
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('financial_framework_of_the_project_total')}</td>
              {financialFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                      <ChangeHighlighter
                        disabled
                        as={FormattedNumberInput}
                        thousandSeparator="."
                        decimalSeparator=","
                        onChange={(e) => handleFinancialFrameworksChange(e, index)}
                        onBlur={() => saveChange(index, 'financial')}
                        object={framework}
                        name="total"
                        type="text"
                        size="sm"
                        onlyPositiveNumbers={true}
                      />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>{t('rd_personnel_costs')}</td>
              {financialFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                    <ChangeHighlighter
                      disabled={!!framework.hours_per_month_staff_cost_per_hour_automatic}
                      as={FormattedNumberInput}
                      thousandSeparator="."
                      decimalSeparator=","
                      onChange={(e) => handleFinancialFrameworksChange(e, index)}
                      onBlur={() => saveChange(index, 'financial')}
                      object={framework}
                      name="rnd_personnel_costs"
                      type="text"
                      size="sm"
                      onlyPositiveNumbers={true}
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>{t('rd_material_costs_and_cost_of_investments_not_eligible_only_a_rough_estimate')}</td>
              {financialFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                          <ChangeHighlighter
                          as={FormattedNumberInput}
                          thousandSeparator="."
                          decimalSeparator=","
                          onChange={(e) => handleFinancialFrameworksChange(e, index)}
                          onBlur={() => saveChange(index, 'financial')}
                          object={framework}
                          name="material_and_investment_costs"
                          type="text"
                          size="sm"
                          onlyPositiveNumbers={true}
                        />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>{t('rd_contract_costs_within_eu_eea')}</td>
              {financialFrameworks.map((framework, index) => { 
                return (
                  <td key={index} className='text-center'>
                      <ChangeHighlighter
                      as={FormattedNumberInput}
                      thousandSeparator="."
                      decimalSeparator=","
                      object={framework}
                      name="contract_costs_in_eu"
                      type="text"
                      size="sm"
                      disabled={true}
                      onlyPositiveNumbers={true}
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>{t('rd_contract_costs_not_in_eueea')}</td>
              {financialFrameworks.map((framework, index) => { 
                return (
                  <td key={index} className='text-center'>
                    <ChangeHighlighter
                      as={FormattedNumberInput}
                      thousandSeparator="."
                      decimalSeparator=","
                      object={framework}
                      name="contract_costs_not_in_eu"
                      type="text"
                      size="sm"
                      disabled={true}
                      onlyPositiveNumbers={true}
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>{t('rd_other_costs')}</td>
              {financialFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                      <ChangeHighlighter
                      as={FormattedNumberInput}
                      thousandSeparator="."
                      decimalSeparator=","
                      onChange={(e) => handleFinancialFrameworksChange(e, index)}
                      onBlur={() => saveChange(index, 'financial')}
                      object={framework}
                      name="rnd_other_costs"
                      type="text"
                      size="sm"
                      onlyPositiveNumbers={true}
                    />
                  </td>
                )
              })}
            </tr>
            <hr />
            <tr>
              <td></td>
              {financialFrameworks.map((framework, index) => { 
                return (
                  <td key={index} className='text-center'>
                    Auto <br/>
                    <Form.Check
                      type="checkbox"
                      checked={framework.hours_per_month_staff_cost_per_hour_automatic}
                      value={framework.hours_per_month_staff_cost_per_hour_automatic}
                      name="hours_per_month_staff_cost_per_hour_automatic"
                      onChange={
                        async (e) =>  {
                          await handleFinancialFrameworksChange(e, index);
                          saveChange(index, 'financial')
                        }
                      }
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>{t('hours_per_month')}</td>
              {financialFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                    <ChangeHighlighter
                      disabled={!framework.hours_per_month_staff_cost_per_hour_automatic}
                      as={FormControl}
                      onChange={(e) => handleFinancialFrameworksChange(e, index)}
                      onBlur={() => saveChange(index, 'financial')}
                      object={framework}
                      name="hours_per_month"
                      type="text"
                      size="sm"
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>{t('staff_cost_per_hour')}</td>
              {financialFrameworks.map((framework, index) => { 
                return (
                  <td key={index}>
                    <ChangeHighlighter
                      disabled={!framework.hours_per_month_staff_cost_per_hour_automatic}
                      as={FormControl}
                      onChange={(e) => handleFinancialFrameworksChange(e, index)}
                      onBlur={() => saveChange(index, 'financial')}
                      object={framework}
                      name="staff_cost_per_hour"
                      type="text"
                      size="sm"
                    />
                  </td>
                )
              })}
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default FinancialFramework
