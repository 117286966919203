import React from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const ClaimGroupSelector = ({ accountType, loading, company, claimGroup, allClaimGroups }) => {
  const history = useHistory();

  const handleClaimGroupChange = async (e) => {
    history.push(`/${accountType}/companies/${company.id}/${e.target.value}/master`);
  }

  const getClaimGroupOptions = () => {
    const sortedClaimGroupYears = allClaimGroups.sort((a, b) => a.name.slice(-4) > b.name.slice(-4) ? -1 : 1)
    return sortedClaimGroupYears.map(claimGroup => {
      return <option value={claimGroup.id}>{claimGroup.name.replace(company.name, '').trim()}</option>
    })
  }

  if (!claimGroup) return null

  return (
    <div className='mx-2 my-3'>
      <Form.Control
        value={claimGroup.id}
        onChange={handleClaimGroupChange}
        name="current_claim_group"
        as="select"
        size="md"
        className='p-1 '
      >
        {!loading && getClaimGroupOptions()}
      </Form.Control>
    </div>
  )
}

export default ClaimGroupSelector;
