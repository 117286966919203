import { useCallback, useEffect, useState } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const api = buildService('/claim_groups/{id}/company_contacts_claim_group_roles', {
  only: ['update']
});
const useUpdateCompanyContactClaimGroupRole = claim_group_id => {
  return useErrorHandling(useCallback(async company_contact => {
    const {
      contact_mission_role
    } = company_contact;
    return await api.update(claim_group_id, company_contact.id, {
      contact_mission_role
    });
  }, [claim_group_id]));
};
const companyContactsClaimGroupRolesService = {
  useUpdateCompanyContactClaimGroupRole
};
export default companyContactsClaimGroupRolesService;