import { findAdjacentElementsWhere, isFirstNodeChildOfSecond, isTextNode, matchNodeOrAnyParentElement } from "../../../domUtils";
import AdditionHighlight from './models/AdditionHighlight';
import DeletionHighlight from './models/DeletionHighlight';
export function findLastInsertedHighlightElementInDocument(doc, originalAdditionElement) {
  const elementUniqueClassName = Array.from(originalAdditionElement.classList).find(c => c.includes('TrackChanges-el-uuid'));
  return Array.from(doc.querySelectorAll(".".concat(elementUniqueClassName))).pop();
}

/**
 * In case we have highlighted several characters as deleted and we click on the right of one of them and write a new character there.
 * Replaces the existing deletion-highlighted character with 2 spans - one deletion span with that character, followed by 1 addition span with the new character.
 * @param {*} insertedSpan 
 * @param {*} changeId 
 */
export function replaceDeletionHighlightWithNewAdditionHighlight(insertedSpan, changeId, editor) {
  const parent = insertedSpan.parentElement;
  const grandParent = parent.parentElement;
  const siblings = Array.from(parent.childNodes); //.map(child => child.cloneNode(true))

  let lastInsertedSibling = parent;
  // parent.replaceWith(siblings[siblings.length - 1])

  for (let index = siblings.length - 1; index >= 0; index--) {
    var _siblings$index$class;
    const deletionHighlight = DeletionHighlight.createHighlightedElement(siblings[index].textContent, changeId);
    const toInsert = (_siblings$index$class = siblings[index].classList) !== null && _siblings$index$class !== void 0 && _siblings$index$class.contains('TrackChanges-Highlight-Addition') ? siblings[index] : deletionHighlight;
    grandParent.insertBefore(toInsert, lastInsertedSibling);
    lastInsertedSibling = lastInsertedSibling.previousSibling;
  }
  parent.remove();
}
export function findAdjacentTrackChangesElementssOfTheSameType(contentNode, nodeTrackChangesTypeClass) {
  if (!nodeTrackChangesTypeClass) {
    return [];
  }
  const checkHighlightFn = nodeTrackChangesTypeClass.includes('Deletion') ? DeletionHighlight.isNodeAHighlight : AdditionHighlight.isNodeAHighlight;

  // In the case of footnotes - these can be textNodes:
  // e.g. If the footnote has this text: 'asdfg' and the user deletes 'sd' and 
  // then deletes the whole footnote from the marker, then we have <deletion>a<deletion>sd</deletion>fg</deletion>.
  // In this case the adjacent nodes of 'sd' will be text nodes whose parent is also a deletion.
  // In this case we need to return their parent element.
  const relatedNodesAndContentNode = findAdjacentElementsWhere(contentNode, adjacentNode => {
    return matchNodeOrAnyParentElement(adjacentNode, checkHighlightFn);
  }).map(
  // returns only track change elements, otherwise we will have random spans, divs, etc, as well
  node => {
    return matchNodeOrAnyParentElement(node, checkHighlightFn);
  });

  // if text node - get its parent track change element, otherwise return the node itself
  // Therefore it is possible that the array contains both a track change element and a child track change element.
  // This will work, but will show a wrong text in the modal that shows the text to be added / deleted.
  const adjacentElements = relatedNodesAndContentNode.map(node => isTextNode(node) ? matchNodeOrAnyParentElement(node, checkHighlightFn) : node);

  // For all elements that have parents in the array - filter them out.
  const filteredElements = adjacentElements.filter(element => {
    const hasParentInTheArray = adjacentElements.some(potentialParent => isFirstNodeChildOfSecond(element, potentialParent));
    return !hasParentInTheArray;
  });

  // In the case with the footnotes, its possible that multiple nodes have the same track change parent,
  // so here we would have duplicate elements.
  const uniqueAdjacentElements = [...new Set(filteredElements)];
  return uniqueAdjacentElements;
}