import { useCallback } from 'react';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service'
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods'

const api = buildService('/time_tracking/companies', { only: [ 'all']})

export const companiesService = {
  useFetchAllCompanies(setLoading, initialQueryParams) {
    const [taskCompanies, setTaskCompanies, fetchTaskCompanies] = serviceMethods.useFetch({
      callback: useCallback(async (queryParams) => await api.all({ query: buildQueryString(queryParams) }), []),
      setLoading,
      initialQueryParams: initialQueryParams || ''
    })
  
    return [taskCompanies, setTaskCompanies, fetchTaskCompanies]
  },
  all: (queryParams = null) => {
    return fetch(`/api/time_tracking/companies?${queryParams}`, request.get);
  }
};
