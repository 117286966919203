import React from 'react'
import ClaimGroupListClients from './ClaimGroupListClients'
import ClaimGroupListClientsByCompany from './ClaimGroupListClientsByCompany'

const ClaimGroupListByCompanies = ({claimGroups, loading, accountType, groupedByCompanyClaimGroups}) => {
  return (
    <>
      {Array.from(groupedByCompanyClaimGroups).map(([key, value]) => {
         return (
            <>
              <h2 className='claim-group-list-title mb-4'>{key}</h2>
              <ClaimGroupListClientsByCompany claimGroups={value} loading={loading} accountType={accountType}/>
            </>
        );
      })}
    </>
  )
}

export default ClaimGroupListByCompanies