import editorMenuUtils from "../editorMenuUtils";
import { fixElementStyles } from "./fixElementStyles";
import { fixSpecialElementStyles } from "./fixSpecialElementStyles";
const {
  loopChildNodes,
  stopEvent
} = require("../../../domUtils");

/**
 * @param {*} editorBody 
 */
function applyBodyStylesToAllDirectChildren(editor, editorBody) {
  Array.from(editorBody.children).forEach(child => {
    fixElementStyles(editor, child);
  });
}
function fixAllInlineStylesInEditorBody(editor) {
  const editorBody = editor.getBody();
  applyBodyStylesToAllDirectChildren(editor, editorBody);
  loopChildNodes(editorBody, node => {
    const stylesFixed = fixSpecialElementStyles(node);
    if (stylesFixed === false && !node.parentElement.isSameNode(editorBody)) {
      fixElementStyles(editor, node);
    }
  });
}
function addToggleStylesFormatterButton(editor, moduleInstance, onStylesFormatterToggled) {
  editorMenuUtils.addToggleButton({
    editor,
    moduleInstance,
    toggleStatePropertyName: 'stylesFormatterToggle',
    buttonName: 'styles_formatter_toggle',
    textIfToggled: 'Styles Formatter: ON',
    textIfNotToggled: 'Styles Formatter: OFF',
    onToggleCallback: stylesFormatterToggle => {
      onStylesFormatterToggled(stylesFormatterToggle);
      if (stylesFormatterToggle) {
        editor.fire('change', {
          content: editor.getContent()
        });
      }
    }
  });
}
const styles_formatter = {
  name: 'styles_formatter',
  stylesFormatterToggle: true,
  addIfApplicable(editor, _ref) {
    let {
      onStylesFormatterToggled,
      stylesFormatterToggleIniitialValue
    } = _ref;
    this.stylesFormatterToggle = stylesFormatterToggleIniitialValue;
    editor.on('change', e => {
      if (e.firedFromStyleFormatter || !this.stylesFormatterToggle) {
        return;
      }

      // stopping the event because initially it was called with the old editor content,
      // and if we allow it to proceed, the rest of the callbacks will act on the old content
      stopEvent(e);

      // fix all styles formatting
      fixAllInlineStylesInEditorBody(editor);

      // Re-fire the change event with the formatted content, but add a flag to prevent infinite loop.
      // Why are we not simply using mceCleanup? Because it puts the cursor location at the start of the content.
      // Alternatively we could use tinymce selection bookmarks together with mceCeanup, but the
      // bookmarks dont work.
      editor.fire('change', {
        content: editor.getContent(),
        firedFromStyleFormatter: true
      });
    });
    addToggleStylesFormatterButton(editor, this, onStylesFormatterToggled);
    return true;
  }
};
export default styles_formatter;