import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import ClaimsSelection from '@components/admin/companies/kimble/ClaimsSelection';
import { Breadcrumb, Card } from 'react-bootstrap';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { kimbleCompanyService } from '@rd-web-markets/shared/dist/services/kimble_company.service';

const AddClaimToClaimGroupPage = ({accountType}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id_in_provider_system, claimGroupId } = useParams();
  const [claimToSplit, setClaimToSplit] = useState(null);
  const [showClaimSplitModal, setShowClaimSplitModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [claims, setClaims] = useState(null);
  const [claimGroup, setClaimGroup] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const claims = await kimbleCompanyService.get(id_in_provider_system);
        const claimGroup = await claimGroupService.get(claimGroupId);

        setClaimGroup(claimGroup);
        setClaims(claims.map(claim => {
          return {...claim, include: claim.from_date ? true : false};
        }));
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [id_in_provider_system, dispatch]);

  const toggleInclude = async (eventOrClaim) => {
    claimGroup.claims.push({
      from_date: eventOrClaim.from_date,
      to_date: eventOrClaim.to_date,
      name: eventOrClaim.kimble_name,
      id_in_provider_system: eventOrClaim.id_in_provider_system
    });
    claimGroup.claims_attributes = claimGroup.claims;
    setLoading(true);
    try { 
      await claimGroupService.update(claimGroup);
      history.push(`/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}/master`);

    } catch(error) { 
      dispatch(handleError(error));
    } finally { 
      setLoading(false);
    }
  };

  const setFromDate = (date, claim) => {
    const claimIndex = claims.findIndex(c => c.kimble_name === claim.kimble_name);
    const newClaims = [...claims];
    newClaims[claimIndex] = {...claim, from_date: date};
    setClaims(newClaims);
  }

  if(!claims || !claimGroup) return <Loading />

  return (
    <>
      <SidebarPortal headerItem={{link: `companies/${claimGroup.company_id}/master`, text: claimGroup.company.name}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies`}}>Companies</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies/${claimGroup.company_id}/master`}}>{claimGroup.company.name}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}/master`}}>{claimGroup.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <ClaimsSelection
        toggleInclude={toggleInclude}
        claims={claims}
        setClaims={setClaims}
        includeSingleClaim={true}
        loading={loading}
        setFromDate={setFromDate}
      />
    </>
  )
}

export default AddClaimToClaimGroupPage
