import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import ClaimInternalInoivceDetailForm from '@components/shared/claim/EditForm/ClaimInternalInoivceDetailForm';
import ClaimStateAlerts from '@rd-web-markets/shared/dist/claim/ClaimStateAlerts';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import ClaimEditBreadcrumbs from '@components/shared/claim/EditForm/ClaimEditBreadcrumbs';
import ClaimEditNavigation from '@components/shared/claim/EditForm/ClaimEditNavigation';
import ClaimEditFooter from '@components/shared/claim/EditForm/ClaimEditFooter';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { FINANCIAL_SECTION_GENERAL_LINK, SIDEBAR_CLAIM_COSTS_LINKS, SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER } from '@rd-web-markets/market/dist/constants';
import claimService from '@rd-web-markets/shared/dist/services/claim/claim.service';

const ClaimEditInternalInvoiceDetailsPage = ({accountType}) => {
  const { claim_id } = useParams();
  const [claim, setClaim] = claimService.useGetClaim(claim_id)
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const links = user.account_type === 'customer' ? SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER : SIDEBAR_CLAIM_COSTS_LINKS;
  const history = useHistory();
  const dispatch = useDispatch();

  const submit = async e => {
    e.preventDefault();
    setLoading(true);
    claim.internal_invoice_detail_attributes = claim.internal_invoice_detail;
    try {
      const updatedClaim = await claimService.update(claim);
      history.replace(`/${accountType}/claims/${claim_id}/financial_details/${FINANCIAL_SECTION_GENERAL_LINK}`);
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleInternalInvoiceDetailChange = (event) => {
    const internalInvoiceDetailCopy = claim.internal_invoice_detail;
    const value = event.target.value;
    internalInvoiceDetailCopy[event.target.name] = value;
    setClaim({
      ...claim,
      internal_invoice_detail: internalInvoiceDetailCopy,
    });
  };

  if (!claim) return <Loading />;

  const menuItems = links.map((item) => {
    const linkArray = item.link.split('/');
    return {
      link: `claims/${claim.id}/${item.link}`,
      text: item.text,
      highlighted: linkArray[0] === 'edit',
    };
  });

  return (
    <>
      <SidebarPortal headerItem={{link: `companies/${claim.company.id}/master`, text: claim.claim_group.name}} menuItems={menuItems} />
      <ClaimEditBreadcrumbs claim={claim} />
      <Card>
        <Card.Header>
          <ClaimEditNavigation claim={claim} page={'internal_invoice_detail'}/>
        </Card.Header>
        <Card.Body>
          <ClaimStateAlerts claim={claim} />
          <Row>
            <Col md={12}>
              <ClaimInternalInoivceDetailForm
                claim={claim}
                handleInternalInvoiceDetailChange={
                  handleInternalInvoiceDetailChange
                }
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <ClaimEditFooter
            loading={loading}
            claim={claim}
            handleSubmit={submit}
            backUrl={`/${accountType}/claims/${claim.id}/edit/general`}
            continueUrl={`/${accountType}/claims/${claim.id}/edit/general`}
          />
        </Card.Footer>
      </Card>
    </>
  );
};

export default ClaimEditInternalInvoiceDetailsPage;
