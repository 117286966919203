import TemplateForm from '@components/shared/TemplateForm';
import { Loading } from '@rd-web-markets/shared/dist/util';
import claimScheduleService from '@rd-web-markets/shared/dist/services/claim_schedule.service';
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import claimScheduleEmailTemplateService from '@services/claim_schedule/claim_schedule_email_template.service';
import emailAttachmentsService from '@services/email_attachments.service';
import { MARKET_SPECIFIC_RD_OR_4 } from '@rd-web-markets/market/dist/constants';

const ClaimKickoffEmailTemplateConfigure = ({claimGroup, setClaimGroup}) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const initialSubject = `${claimGroup.company.abbreviated_name || claimGroup.company.name} - ${MARKET_SPECIFIC_RD_OR_4} Claim - Template`;


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/email_templates/');
        setTemplates(response);
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [claimGroup, dispatch]);

  const handleSubmit = async (template) => {
    setLoading(true);

    try {
      if (claimGroup.claim_schedule.active_email_template) {
        const updatedTemplate = await claimScheduleEmailTemplateService.update(claimGroup.claim_schedule.id, template);
        const updatedClaimSchedule = {...claimGroup.claim_schedule};
        updatedClaimSchedule.active_email_template = updatedTemplate;
        setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
      } else {
        const newTemplate = await claimScheduleEmailTemplateService.create(
          claimGroup.claim_schedule.id,
          template
        );
        const updatedClaimSchedule = {...claimGroup.claim_schedule}
        updatedClaimSchedule.email_template = newTemplate
        updatedClaimSchedule.active_email_template = newTemplate;
        setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
      }
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

    const removeAttachment = async (id) => {
    try {
      setLoading(true);
      await emailAttachmentsService.delete('email_templates', claimGroup.claim_schedule.active_email_template.id, id);
      const updatedClaimSchedule = {...claimGroup.claim_schedule}
      updatedClaimSchedule.active_email_template.download_links = updatedClaimSchedule.active_email_template.download_links.filter(link => link.id !== id)
      setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
    } catch(error) {
      dispatch(handleError(error));
    } finally { 
      setLoading(false);
    }
  };

  const addAttachment = useCallback(async (formData) => {
      setLoading(true);
      const claimScheduleUpdated = await claimScheduleService.get(claimGroup.id);
      await emailAttachmentsService.create('email_templates', claimScheduleUpdated.active_email_template.id, formData);
      
      const updatedClaimScheduled = await claimScheduleService.get(claimGroup.id);
      setClaimGroup({...claimGroup, claim_schedule: updatedClaimScheduled});
      setLoading(false);
  }, [setClaimGroup, claimGroup.claim_schedule.active_claim_schedule_ics_event_template?.id, claimGroup.id])


  const handleRemoveTemplate = async e => {
    try {
      await claimScheduleEmailTemplateService.delete(claimGroup.claim_schedule.id);
      const templates = await fetch('/api/email_templates');
      setTemplates(templates);
      const updatedClaimSchedule = await claimScheduleService.get(claimGroup.id, claimGroup.claim_schedule.id)
      setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
    } catch(error) {
      dispatch(handleError(error));
    }
  }

  if (!templates) return <Loading />;

  return (
    <TemplateForm
      removeAttachment={removeAttachment}
      addAttachment={addAttachment}
      handleSubmit={handleSubmit}
      setClaimGroup={setClaimGroup}
      templates={templates}
      loading={loading}
      templateType="email_template"
      claimSchedule={claimGroup.claim_schedule}
      handleRemoveTemplate={handleRemoveTemplate}
      showInModal={true}
      initialSubject={initialSubject}
    />
  );
};

export default ClaimKickoffEmailTemplateConfigure
