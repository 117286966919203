import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import EmailPreviewModal from '@components/shared/claim/emailPreview/EmailPreviewModal.jsx'
import ClaimClientCostTemplateConfigure from '@components/shared/claim/templates/ClaimClientCostTemplateConfigure';
import Toast from '@components/util/Toast';

const ClientCostSection = ({claimGroup, company, loading, claim, onClaimGroupUpdate, sendClientCostEmail, consultantsAndAdmins}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [showTemplateSavedToast, setShowTemplateSavedToast] = useState(false);
  const [showTemplateRemovedToast, setShowTemplateRemovedToast] = useState(false);

  return (
    <>
      <Toast show={showTemplateSavedToast} message={'Template saved!'} onClose={() => setShowTemplateSavedToast(false)} />
      <Toast show={showTemplateRemovedToast} message={'Template removed!'} onClose={() => setShowTemplateRemovedToast(false)} />
      {modalOpen && 
        <EmailPreviewModal
          show={modalOpen}
          claimGroup={claimGroup}
          consultantsAndAdmins={consultantsAndAdmins}
          onClaimGroupUpdate={onClaimGroupUpdate}
          claimId={claim.id}
          company={company}
          templateName='Client cost email'
          emailType='cost'
          renderEditComponent={onTemplateUpdate => 
            <ClaimClientCostTemplateConfigure
              claimId={claim.id}
              claimGroup={claimGroup}
              onTemplateSave={() => {
                onTemplateUpdate()
                setShowTemplateSavedToast(true)
              }}
              onTemplateAttachmentChange={() => {
                onClaimGroupUpdate();
              }}
              onTemplateRemove={() => {
                onTemplateUpdate()
                setShowTemplateRemovedToast(true)
              }}
            />
          }
          onSubmit={() => sendClientCostEmail(claim)}
          submitText="Send Email"
          onHide={() => setModalOpen(false)}
        />
      }
      <Row>
        <Col md={12} className="text-center">
          <Button
            variant="info"
            iconPosition="left"
            size="md"
            icon='mail'
            className="mb-4"
            loading={loading}
            onClick={() => setModalOpen(true)}
            >
              Preview / Send Client Cost Email
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ClientCostSection
