import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
const api = buildService('/cyclopp/companies', {
  only: ['get', 'all', 'base']
});
const fetchLastImportStatusAndUpdateLoadingState = async (setImportStatus, setLoading) => {
  setLoading && setLoading(true);
  const response = await api.all();
  if (response) {
    setImportStatus(response);
  }
  setLoading && setLoading(false);
};
const fetchLastSingleCompanyImportStatusAndUpdateLoadingState = async (companyId, setImportStatus, setLoading) => {
  setLoading && setLoading(true);
  const response = await api.get(companyId);
  if (response) {
    setImportStatus(response);
  }
  setLoading && setLoading(false);
};
const cycloppCompanyReloadService = {
  ...api,
  reloadAll: () => api.base.create('/cyclopp/companies/reload_all'),
  useReloadAll: setImportStatus => {
    return useErrorHandling(useCallback(async () => {
      const importStatus = await cycloppCompanyReloadService.reloadAll();
      setImportStatus(importStatus);
    }, [setImportStatus]));
  },
  reloadSingle: company_id => api.base.create("/cyclopp/companies/reload_single?company_id=".concat(company_id)),
  useReloadSingle: (companyId, setImportStatus) => {
    return useErrorHandling(useCallback(async () => {
      if (companyId) {
        const importStatus = await cycloppCompanyReloadService.reloadSingle(companyId);
        setImportStatus(importStatus);
      }
    }, [companyId, setImportStatus]));
  },
  useCreateFetchSingleCompanyImportStatusInterval: (companyId, setImportStatus, setLoading) => {
    const [interval, setInterval] = useState(null);
    const fetchLastImportStatus = useErrorHandling(useCallback(() => {
      fetchLastSingleCompanyImportStatusAndUpdateLoadingState(companyId, setImportStatus, setLoading);
    }, [companyId, setImportStatus, setLoading]));
    useEffect(() => {
      const newInterval = api.base.createInterval(() => {
        fetchLastImportStatus();
      }, 5000);
      setInterval(newInterval);
    }, [fetchLastImportStatus]);
    return [interval, setInterval];
  },
  // each 5 seconds send a request to get the last import status and then update the state accordingly
  useCreateFetchImportStatusInterval: setImportStatus => {
    const [interval, setInterval] = useState(null);
    const fetchLastImportStatus = useErrorHandling(useCallback(() => {
      fetchLastImportStatusAndUpdateLoadingState(setImportStatus);
    }, [setImportStatus]));
    useEffect(() => {
      const newInterval = api.base.createInterval(() => fetchLastImportStatus(), 5000);
      setInterval(newInterval);
    }, [fetchLastImportStatus]);
    return [interval, setInterval];
  },
  useGetLastImportStatus: setLoading => {
    const [importStatus, setImportStatus] = useState(null);
    const fetchLastImportStatus = useErrorHandling(useCallback(() => {
      fetchLastImportStatusAndUpdateLoadingState(setImportStatus, setLoading);
    }, [setLoading]));
    useEffect(() => {
      fetchLastImportStatus();
    }, [fetchLastImportStatus]);
    return [importStatus, setImportStatus];
  },
  useGetSingleCompanyLastImportStatus: (companyId, setLoading) => {
    const [importStatus, setImportStatus] = useState(null);
    const fetchLastImportStatus = useErrorHandling(useCallback(() => {
      fetchLastSingleCompanyImportStatusAndUpdateLoadingState(companyId, setImportStatus, setLoading);
    }, [companyId, setLoading]));
    useEffect(() => {
      if (companyId) {
        fetchLastImportStatus();
      }
    }, [companyId, fetchLastImportStatus]);
    return [importStatus, setImportStatus];
  }
};
export { cycloppCompanyReloadService };