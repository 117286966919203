import { isTextNode, matchNodeOrAnyParentElement } from "../../../domUtils";
import TrackChangesHighlight from "../track_changes/models/TrackChangesHighlight";
import { copyStyleToDataMceStyle } from "./tinyMCEFix";
function isHeaderElement(node) {
  return matchNodeOrAnyParentElement(node, nodeToCheck => {
    var _nodeToCheck$style;
    return (_nodeToCheck$style = nodeToCheck.style) === null || _nodeToCheck$style === void 0 || (_nodeToCheck$style = _nodeToCheck$style.fontFamily) === null || _nodeToCheck$style === void 0 ? void 0 : _nodeToCheck$style.toLowerCase().includes('georgia');
  });
}
function isTrackChangesElement(node) {
  return TrackChangesHighlight.isElementAnAdditionOrDeletionHighlight(node);
}
function fixHeaderElementStyles(node) {
  node.style.fontFamily = 'georgia generic';
  if (!node.style.textAlign) {
    node.style.textAlign = 'justify';
  }
  node.style.fontWeight = 'bold';
  node.style.fontSize = '28pt';
  node.style.color = '#666e7e';
  copyStyleToDataMceStyle(node);
}
function fixTrackChangesElementStyles(node) {
  // TODO in this ticket: https://www.pivotaltracker.com/story/show/185606206
}
const MATCHERS_FIXERS = [[isHeaderElement, fixHeaderElementStyles], [isTrackChangesElement, fixTrackChangesElementStyles]];
export const MATCHERS = MATCHERS_FIXERS.map(_ref => {
  let [matcher, _] = _ref;
  return matcher;
});
export function fixSpecialElementStyles(node) {
  if (isTextNode(node)) {
    return null;
  }
  let stylesFixed = false;
  MATCHERS_FIXERS.forEach(_ref2 => {
    let [matcher, fixer] = _ref2;
    if (matcher(node)) {
      fixer(node);
      stylesFixed = true;
    }
  });
  return stylesFixed;
}