import React from 'react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ClaimEditNavigation = ({page, claim}) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <>
      <Nav variant="tabs">
        <Nav.Item>
          <Nav.Link as={Link} active={page === 'general'}
          to={`/${user.account_type}/claims/${claim.id}/edit/general`}>
            General
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} active={page === 'internal_invoice_detail'}
          to={`/${user.account_type}/claims/${claim.id}/edit/internal_invoice_detail`}>
            Internal Invoice Detail
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  )
}

export default ClaimEditNavigation
