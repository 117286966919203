import SearchBar from '@components/util/SearchBar';
import React, { useState, useEffect } from 'react'
import { Button, Card, Row, Col, Pagination, Form, Breadcrumb } from 'react-bootstrap'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import * as QueryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { Table } from '@rd-web-markets/shared/dist/util';
import { companiesService } from '@services/task_management/companies.service';
import { claimGroupsService } from '@services/task_management/claimGroups.service';
import { useSelector } from 'react-redux';
import { CompanyService } from '@services/company.service';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import Filters from '@rd-web-markets/shared/dist/util/Filters';
import MasterSidebar from '@components/util/MasterSidebar';

const initialParams = 'page=1&query=';

const TimeTrackingClaimGroupsPage = ({accountType}) => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const { company_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [claimGroups, setClaimGroups] = useState(null);
  const [company, setCompany] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [params, setParams] = useState(
    QueryString.parse(location.search || initialParams)
  );
  const { t } = useTranslation();

  useEffect(() => {
    const fetchClaimGroups = async () => {
      const company = await CompanyService.get(company_id);
      setCompany(company);
      const claimGroups = await claimGroupsService.all(company_id, QueryString.stringify(params));
      setClaimGroups(claimGroups);
      history.push({
        search: QueryString.stringify(params),
      });
    };

    fetchClaimGroups();
  }, [history, params])

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const headers = [
    { text: t('name') },
    { text: t('number_of_projects')},
    { text: t('tasks')},
    { text: t('hours_spent')},
  ];


  if(!claimGroups) return <Loading />

  const claimGroupNamesList = claimGroups.map(claimGroup => claimGroup.name);

  const rows = claimGroups.map(claimGroup => {
    return {
      key: claimGroup.id,
      columns: [
        <Link to={`/${user.account_type}/time_tracking/claim_groups/${claimGroup.id}/claim_project_reports`}>{claimGroup.name}</Link>,
        claimGroup.number_of_projects,
        claimGroup.number_of_tasks,
        claimGroup.hours_spent
      ]
    }
  });

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='list_of_companies' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/`}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/list_of_companies`}}>Companies</Breadcrumb.Item>
          <Breadcrumb.Item active>{company.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal> 
      <Card>
        <Row>
          <Col md={12} className='text-center p-4'>
            <Button className='text-center' variant="light" size="md" as={Link} to={'/admin/time_tracking/create_project'}>
              <span className="material-icons md-18">add</span> Project
            </Button>
          </Col>
        </Row>
      </Card>

      <div className='text-right mb-2'>
        <Filters
          params={params}
          setParams={setParams}
          filters={[
            { name: 'claim_group', label: t('claim_group'), type: 'select', options: claimGroupNamesList }
          ]}
        />
      </div>

      <Card>
        <Table headers={headers}
               rows={rows}
        />
      </Card>

      <Pagination className="justify-content-end">{items}</Pagination>
    </>
  )
}

export default TimeTrackingClaimGroupsPage