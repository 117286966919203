import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_translations from './en_translations';
import de_translations from './de_translations';

let language = 'en';

const resources = {
  en: {
    translation: en_translations
  },
  de: {
    translation: de_translations
  },
  it: {
    translation: {}
  }
};
i18next
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: language,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;