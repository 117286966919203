import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button as SubmitButton } from '@rd-web-markets/shared/dist/util/buttons';

const ClaimEditFooter = ({loading, backUrl, continueUrl, handleSubmit}) => {
  return (
    <>
      <Col md={12}>
        <Button as={Link} className="float-left" variant="light" to={backUrl}>
          <span className="material-icons md-18">chevron_left</span> Back
        </Button>
        <Button as={Link} variant="primary" to={continueUrl} className="float-right">
          Continue <span className="material-icons md-18">chevron_right</span>
        </Button>
        <SubmitButton isImmutableUnderReview={true} loading={loading} onClick={handleSubmit} variant="light" className="mr-3 float-right">
          <span className="material-icons md-18">save</span> Save
        </SubmitButton>

      </Col>
    </>
  );
}

export default ClaimEditFooter
