import React from 'react';
import FaqEntry from './FaqEntry';
import { Row } from 'react-bootstrap';
import { DeleteButton } from '@rd-web-markets/shared/dist/util/buttons';

const Faq = ({ data, deleteFaq }) => {
  return (
    <div>
      <div className='d-flex justify-content-end mb-2'>
        <DeleteButton onClick={() => deleteFaq(data.id)} />
      </div>

      <Row style={{ marginBottom: '50px', borderBottom: '1px #2626 solid' }} className='justify-content-between'>
        {data.faq_entries.map((faqEntry) => (
          <FaqEntry data={faqEntry} />
        ))}
      </Row>
    </div>
  );
};

export default Faq;
