// import { isEmptySelection, stopEvent, surroundSelectionTextNodesWithDom } from "@rd-web-markets/shared/dist/util/domUtils"
import { isEmptySelection, isTextNode, stopEvent, surroundSelectionTextNodesWithDom } from "../../../../domUtils";
import DeletionHighlight from "../models/DeletionHighlight";
import { setCursorPosition, setCursorPositionToEndOfElement, setCursorPositionToStartOfElement } from "../cursorUtils";
export function isAddingNewLine(event) {
  return event.key === 'Enter';
}
export function doAddNewLine(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  if (!!(predictedInputValueOrNull !== null && predictedInputValueOrNull !== void 0 && predictedInputValueOrNull.full) && contentNode.textContent !== (predictedInputValueOrNull === null || predictedInputValueOrNull === void 0 ? void 0 : predictedInputValueOrNull.full)) {
    stopEvent(event);
  }
  const originalAnchorOffset = cursorSelection.anchorOffset;
  if (!isEmptySelection(cursorSelection)) {
    // cutting | pasting | event key enter
    if (contentNode.nodeName === 'IMG') {
      contentNode.classList.add(...DeletionHighlight.createDomElementHighlightClasses().split(' '));
      setCursorPositionToEndOfElement(editor, contentNode);
      return;
    }
    if (event.key === 'Enter') {
      const [leftSideTextNode, rightSideTextNode] = surroundSelectionTextNodesWithDom(editor.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion');

      // previous sibling may be null if we didnt surround the node with deletion highlight - in case it already had an addition highlight and we just deleted part of it
      // In case the left and right text nodes are the same text node under an addition highlight, then its possible that the selection was like the following (between the $):
      // <addition>t$es$t</addition>
      // In the above case we don't want to move the cursor to the start of the right side text node, becuase it would just move the cursor to the start of the text,
      // while in fact we want to add the new line at the middle of the text - like so t\nt.

      const leftSideTextNodeIsRightSideTextNode = leftSideTextNode.isSameNode(rightSideTextNode); // && isTextNode(leftSideTextNode)
      if (!leftSideTextNodeIsRightSideTextNode) {
        setCursorPositionToStartOfElement(editor, rightSideTextNode);
      } else {
        setCursorPosition(editor, cursorSelection.anchorNode, originalAnchorOffset);
      }
    }
  }
}