import { useCallback } from 'react';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimGroupService from './claim_group.service';
const userAccessService = {
  useCreateUserAccess(claimGroup, setLoading, setClaimGroup, selectedUserAccessOption) {
    return useErrorHandling(useCallback(async userAccess => {
      setLoading && setLoading(true);
      const newUserAccess = await userAccessService.create(claimGroup.id, userAccess);
      claimGroup.user_accesses.push(newUserAccess);
      setClaimGroup && setClaimGroup({
        ...claimGroup,
        user_accesses: [...claimGroup.user_accesses]
      });
      selectedUserAccessOption && selectedUserAccessOption({
        value: newUserAccess.id,
        label: newUserAccess.user.name
      });
    }, [claimGroup, selectedUserAccessOption, setClaimGroup, setLoading]), useCallback(() => {
      setLoading && setLoading(false);
    }, [setLoading]));
  },
  useUpdateUserAccess(claimGroup, setClaimGroup, setLoading) {
    return useErrorHandling(useCallback(async userAccess => {
      setLoading(true);
      await userAccessService.update(claimGroup.id, userAccess);
      const updatedClaimGroup = await claimGroupService.get(claimGroup.id);
      setClaimGroup(updatedClaimGroup);
    }, [claimGroup.id, setClaimGroup, setLoading]), useCallback(() => {
      setLoading(false);
    }, [setLoading]));
  },
  useDeleteUserAccess(claimGroup, setClaimGroup, setLoading) {
    return useErrorHandling(useCallback(async userAccessId => {
      setLoading(true);
      await userAccessService.delete(claimGroup.id, userAccessId);
      setClaimGroup({
        ...claimGroup,
        user_accesses: [...claimGroup.user_accesses.filter(ua => ua.id !== userAccessId)]
      });
    }, [claimGroup, setClaimGroup, setLoading]), useCallback(() => {
      setLoading(false);
    }, [setLoading]));
  },
  create(claimGroupId, user_access) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/user_accesses/"), {
      ...request.post,
      body: JSON.stringify({
        user_access
      })
    });
  },
  update(claimGroupId, user_access) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/user_accesses/").concat(user_access.id), {
      ...request.put,
      body: JSON.stringify({
        user_access
      })
    });
  },
  delete(claimGroupId, userAccessId) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/user_accesses/").concat(userAccessId), {
      ...request.delete
    });
  }
};
export default userAccessService;