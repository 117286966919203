import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from '@rd-web-markets/shared/dist/util/Modal';
import collaboratingCompaniesService from '@services/collaborating_companies.service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { useTranslation } from 'react-i18next';

export default function CollaboringCompanyModal({show, onHide, onSubmit, ...props}) {
  const [name, setName] = useState('');
  const { t } = useTranslation();


  const createCollaboratingCompany = useErrorHandling(
    useCallback(async () => {
      const collaboratingCompany = await collaboratingCompaniesService.create({name});
      onSubmit(collaboratingCompany);
      setName('')
    }, [name])
  );

  const rateModalBody  = () => {
    return (
      <Form>
        <Form.Group>
          <Form.Label className="col-form-label">
            {t('name')}
          </Form.Label>
          <Form.Control
            onChange={e => setName(e.target.value)}
            rows={6}
            value={name}
          />
        </Form.Group>
      </Form>
    );
  }

  return (
    <Modal
      size="sm"
      title="Add Company Collaborator"
      show={show}
      renderBody={ () => rateModalBody() }
      onHide={() => {
        onHide()
        setName('')
      }}
      onSubmit={() => createCollaboratingCompany(name)}
    />
  );
}
