import { ChangeHighlighter, FormattedNumberInput } from '@rd-web-markets/shared/dist/util';
import React from 'react';
const CostFormatter = _ref => {
  let {
    object,
    keyName,
    disabled
  } = _ref;
  return object[keyName];
};
export default CostFormatter;