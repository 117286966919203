import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

export const translationsService = {
  all: (params) => {
    return fetch(`/api/translations/?${params}`, request.get);
  },
  create(translation) { 
    return fetch('/api/translations/', {
      ...request.post,
      body: JSON.stringify({ translation }),
    });
  },
  update(translation) { 
    return fetch(`/api/translations/${translation.id}`, {
      ...request.put,
      body: JSON.stringify({ translation }),
    });
  },
  delete(translation_id) {
    return fetch(`/api/translations/${translation_id}`, {
      ...request.delete,
    });
  },
};
