import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLocalStorage from './useLocalStorage';
export default function useRecentVisits() {
  const user = useSelector(state => state.auth.user);
  const [recentClaimGroupIds, setRecentClaimGroupIds] = useLocalStorage('recentClaimGroupIds');
  const [recentCompanyIds, setRecentCompanyIds] = useLocalStorage('recentCompanyIds');
  const [recentAccountType, setRecentAccountType] = useLocalStorage('recentAccountType');
  useEffect(() => {
    if (user) {
      if (recentAccountType !== user.account_type) {
        setRecentClaimGroupIds([]);
        setRecentCompanyIds([]);
        setRecentAccountType(user.account_type);
      }
    }
  }, [user, recentAccountType, setRecentClaimGroupIds, setRecentCompanyIds, setRecentAccountType]);
  const addRecentClaimGroupId = id => {
    if (!id) return;
    if (recentClaimGroupIds) {
      const index = recentClaimGroupIds.indexOf(id);
      if (index !== -1) {
        const newRecentVisits = [id, ...recentClaimGroupIds.slice(0, index), ...recentClaimGroupIds.slice(index + 1)];
        setRecentClaimGroupIds(newRecentVisits);
        return;
      } else {
        const newRecentVisits = (recentClaimGroupIds === null || recentClaimGroupIds === void 0 ? void 0 : recentClaimGroupIds.length) >= 5 ? [id, ...recentClaimGroupIds.slice(0, 4)] : [id, ...recentClaimGroupIds];
        setRecentClaimGroupIds(newRecentVisits);
        return;
      }
    } else {
      setRecentClaimGroupIds([id]);
      return;
    }
  };
  const addRecentCompanyId = id => {
    if (!id) return;
    if (recentCompanyIds) {
      const index = recentCompanyIds.indexOf(id);
      if (index !== -1) {
        const newRecentVisits = [id, ...recentCompanyIds.slice(0, index), ...recentCompanyIds.slice(index + 1)];
        setRecentCompanyIds(newRecentVisits);
        return;
      } else {
        const newRecentVisits = (recentCompanyIds === null || recentCompanyIds === void 0 ? void 0 : recentCompanyIds.length) >= 5 ? [id, ...recentCompanyIds.slice(0, 4)] : [id, ...recentCompanyIds];
        setRecentCompanyIds(newRecentVisits);
        return;
      }
    } else {
      setRecentCompanyIds([id]);
      return;
    }
  };
  return {
    recentClaimGroupIds,
    recentCompanyIds,
    addRecentClaimGroupId,
    addRecentCompanyId
  };
}