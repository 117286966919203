import React, { useEffect, useState, useCallback } from 'react';
import { Carousel, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';

import { Row } from 'react-bootstrap'

import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';

import CompanyReportPreviewEditSectionModal from '@components/shared/reportPreview/modal/CompanyReportPreviewEditSectionModal';
import ReportDisplay from '@components/shared/reportPreview/ReportDisplay';
import rndReportService from '@services/report_templates/rnd_report.service';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import RndCommentsModal from '@rd-web-markets/shared/dist/util/textEdit/modules/comments/RndCommentsModal';
import PreviewEditReportTextButton from '@rd-web-markets/shared/dist/util/buttons/PreviewEditReportTextButton';

export default function CompanyReportPreview({ reportTemplateId, claimId, claimGroup, shouldShowComments }) {
  const [pageCount, setPageCount] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [reportHtml, setReportHtml] = useState(null);
  const [reportHtmlWithHighlights, setReportHtmlWithHighlights] = useState(null);
  const [displayedVersion, setDisplayedVersion] = useState('changes');
  const [editButtonsSettings, setEditButtonsSettings] = useState([]);
  const [editTextModalsSettings, setEditTextModalsSettings] = useState([]);
  const [visibleModalKey, setVisibleModalKey] = useState(undefined);
  const [activePageDomElement, setActivePageDomElement] = useState(null);
  const [commentSpanData, setCommentSpanData] = useState(null);
  const [showRndCommentsModal, setShowRndCommentsModal] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const { search } = useLocation(); //Required for Italy
  const report_type = new URLSearchParams(search).get('report_type');

  // The report html can be very large. Instead on depending on the report html changing,
  // the useEffect can depend on the timestamp.
  const [reportPreviewGenerateTimestamp, setReportPreviewGenerateTimestamp] = useState(null);

  const previewWidth = '793px'
  const previewHeight = '1130px'

  const dispatch = useDispatch();

  const resetPdfData = useCallback(() => {
    setReportHtml(null);
    setReportHtmlWithHighlights(null);
    setPageCount(null);
    setVisibleModalKey(undefined);
    setEditButtonsSettings([])
    setEditTextModalsSettings([])
  }, []);

  const previewClaimReportFinalVersion = useCallback(async () => {
    try {
      const htmlResult = await rndReportService.preview(reportTemplateId, 'final', report_type, claimId);
      setReportHtml(htmlResult.html);
    } catch (error) {
      console.error(error)
      dispatch(handleError(error));
    }
  }, [reportTemplateId, claimId, dispatch,]);

  const previewClaimReportHighlightsVersion = useCallback(async () => {
    try {
      const htmlResult = await rndReportService.preview(reportTemplateId, 'current', report_type, claimId, shouldShowComments);
      setReportHtmlWithHighlights(htmlResult.html);
      setReportPreviewGenerateTimestamp(Date.now())
    } catch (error) {
      console.error(error)
      dispatch(handleError(error));
    }
  }, [dispatch, reportTemplateId, claimId, shouldShowComments])

  // On report template change - preview the report with highlights
  useEffect(() => {
    resetPdfData();
    previewClaimReportHighlightsVersion()
  }, [dispatch, previewClaimReportHighlightsVersion, reportTemplateId, claimId, resetPdfData])

  // Once the preview with highlights is ready, preview the final version
  useEffect(() => {
    if (claimGroup.active_change_set) {
      previewClaimReportFinalVersion();
    }
  }, [reportTemplateId, dispatch, previewClaimReportFinalVersion, reportPreviewGenerateTimestamp, claimGroup.active_change_set]);

  const createEditSectionModalSettings = useCallback((hookClass, modalTitle, footnoteStartIndex) => {
    let modalSettings = {
      reportTemplateId,
      isVisible: false,
      key: `${hookClass}-modal`,
      title: modalTitle,
      footnoteStartIndex,
      onHide: () => setVisibleModalKey(null),
      onContentChanged: () => {
        resetPdfData()
        previewClaimReportHighlightsVersion()
      }
    }
    let claimGroupId, projectId, sliceId, fieldId, reportTemplateCategoryId, templateIdFromClass = reportTemplateId, modalType, fieldName

    if (hookClass.includes('js-hook-ClaimGroup')) {
      claimGroupId = hookClass.split('-ClaimGroup-')[1].split('-')[0]
      projectId = hookClass.split('-Project-')[1].split('-')[0]

      // may not be defined if the js hook is for the project report name
      sliceId = hookClass.split('-Slice-')[1]?.split('-')[0]
      modalType = sliceId ? 'ProjectRemportSliceModal' : 'ProjectRemportNameModal' // Default Project Report Slice Modal or project report name modal
    } else if (hookClass.includes('company-field')) {
      fieldName = hookClass.split('-field-')[1]
      modalType = 'ClaimGroupFieldModal'
    } else if (hookClass.includes('js-hook-Payment-Fields') || hookClass.includes('js-hook-ProjectReportFreeTextEdit')) { //Italy
      claimGroupId = hookClass.split('-ClaimGroup-')[1].split('-')[0]
      projectId = hookClass.split('-Project-')[1].split('-')[0]
      modalType = hookClass.includes('js-hook-Payment-Fields') ? 'paymentFields' : 'projectReportFreeTextEdit'; // Type of Project Report Slice Modal
    } else if (hookClass.includes('js-hook-Description-Fields')) { //Canada
      claimGroupId = hookClass.split('-ClaimGroup-')[1].split('-')[0]
      projectId = hookClass.split('-Project-')[1].split('-')[0]
      fieldId = hookClass.split('-Field-')[1].split('-')[0]
      modalType = 'descriptionFields'; // Type of Project Report Slice Modal
    } else {
      reportTemplateCategoryId = hookClass.split('-category-')[1]
      templateIdFromClass= hookClass.split('-category-')[0].split('js-hook-Report-Template-')[1]
      modalType = 'ReportTemplateCategoryModal'
    }

    /** Some of these settings will be undefined, depending on the modal type */
    modalSettings = {
      modalType,
      ...modalSettings,
      sliceId,
      claimGroup, // some models expect the claim group object
      claimGroupId, // some models expect the claim group id
      projectId,
      fieldId,
      fieldName,
      reportTemplateCategoryId,
      reportTemplateId: templateIdFromClass
    }

    return modalSettings;
  }, [reportTemplateId, resetPdfData, previewClaimReportHighlightsVersion, claimGroup]);

  const createButtonAndModalSettingsForPage = useCallback(activePageDomElement => {
    setActivePageDomElement(activePageDomElement)
    const hookPoints = Array.from(activePageDomElement.querySelectorAll('[class*="js-hook-"]'))
    if (!hookPoints) {
      return
    }

    const buttonsSettings = []
    const modalsSettings = []
    hookPoints.forEach(hookPoint => {
      const hookClass = Array.from(hookPoint.classList).find(c => c.startsWith('js-hook'))
      const { top, left } = hookPoint.getBoundingClientRect()

      const changeVsFinalVersionButtonsRowHeightPx = reportHtml ? 48 : 0
      const previewSectionDistanceToIframeTop = 77 + changeVsFinalVersionButtonsRowHeightPx
      const leftAlignPx = left - 90
      const companyFieldEditTextBtnLeftAlignPx = left - 140
      if (user.account_type === 'admin' || user.account_type === 'consultant' || claimGroup?.active_change_set?.review_type === 'client_review') {
        buttonsSettings.push({
          top: `${top + previewSectionDistanceToIframeTop + hookPoint.offsetHeight / 2}px`,
          left: hookClass.includes('-company-field-') ? `${companyFieldEditTextBtnLeftAlignPx}px` : `${leftAlignPx}px`,
          key: hookClass
        })
      }

      const modalTitleClass = Array.from(hookPoint.classList).find(c => c.startsWith('js-hook-title'))
      const modalTitle = modalTitleClass.split('js-hook-title-')[1].replaceAll('_', ' ')
      const footnoteStartIndex = parseInt(hookPoint.getAttribute('data-footnote-start-index') || 0)
      modalsSettings.push(createEditSectionModalSettings(hookClass, modalTitle, footnoteStartIndex))
    });

    if(claimGroup.active_change_set || (user && (user.account_type !== 'customer' || user.account_type !== 'accountant'))){
      setEditTextModalsSettings([...modalsSettings]);
      setEditButtonsSettings([...buttonsSettings]);
    }
  }, [claimGroup.active_change_set, createEditSectionModalSettings, reportHtml, user]);

  function previousPage() {
    const newPageNumber = pageNumber === 1 ? pageCount : pageNumber - 1;
    setPageNumber(newPageNumber);
  }

  function nextPage() {
    const newPageNumber = pageNumber === pageCount ? 1 : pageNumber + 1;
    setPageNumber(newPageNumber);
  }

  const onCarouselSelect = (_, e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    // dont do anything unless the iframes are done loading and we know the page count
    if (pageCount) {
      let link = e.target
      link = link.tagName === 'A' ? link : link.parentElement
      link = link.tagName === 'A' ? link : link.parentElement
      if(link.className.includes('carousel-control-prev')) {
        previousPage();
      } else if (link.className.includes('carousel-control-next')) {
        nextPage();
      }
    }
  };

  const createEditTextButton = settings => {
    const toggleHookHighlight = (shouldHaveHighlight) => {
      // hook text may flow to multiple pages in which case we have multiple hooks
      const hooks = Array.from(activePageDomElement.querySelectorAll(`.${settings.key}`))
      if (shouldHaveHighlight) {
        hooks.forEach(h => h.classList.add('preview-text--highlighted'))
      } else {
        hooks.forEach(h => h.classList.remove('preview-text--highlighted'))
      }
    }
    
    const button = <PreviewEditReportTextButton settings={settings} toggleHookHighlight={toggleHookHighlight} setVisibleModalKey={setVisibleModalKey} />

    return button;
  };

  const createEditTextButtons = editButtonsSettings => {
    const btnDimension = parseFloat(getComputedStyle(document.documentElement).fontSize) * 2 + 2 // + (10 /* offset from other buttons */)
    let lastSetting = null
    editButtonsSettings.forEach(setting => {
      if (lastSetting) {
        const lastSettingPos = { top: parseFloat(lastSetting.top), left: parseFloat(lastSetting.left) }
        const settingPos = { top: parseFloat(setting.top), left: parseFloat(setting.left) }

        const overlapVertically = ((lastSettingPos.top + btnDimension) >= settingPos.top) && (lastSettingPos.top <= (settingPos.top + btnDimension))
        const overlapHorizontally = ((lastSettingPos.left + btnDimension) >= settingPos.left) && (lastSettingPos.left <= (settingPos.left + btnDimension))
        const btnsOverlap = overlapVertically && overlapHorizontally

        if (btnsOverlap) {
          setting.top = `${lastSettingPos.top + btnDimension + 5}px`
        }
      }

      lastSetting = setting
    })

    return editButtonsSettings.map(createEditTextButton)
  }

  useEffect(() => {
    const getUpdatedModalSettings = editTextModalsSettings => {
      if (pageCount && visibleModalKey) {
        const modalSettings = editTextModalsSettings.find(s => s.key === visibleModalKey);
        modalSettings.isVisible = true;
      } else if (pageCount && visibleModalKey === null) {
        editTextModalsSettings.forEach(s => s.isVisible = false);
        if (activePageDomElement) {
          const hookPoints = Array.from(activePageDomElement.querySelectorAll('[class*="js-hook-"]'))
          hookPoints.forEach(h => h.classList.remove('preview-text--highlighted'))
        }
      }

      return [...editTextModalsSettings]
    }

    setEditTextModalsSettings(editTextModalsSettings => getUpdatedModalSettings(editTextModalsSettings));
  }, [activePageDomElement, pageCount, visibleModalKey])

  // functions passed to child components should be callbacks
  // as if there is a useEffect depending on an inline function it will be executed many times
  const onHtmlLoaded = useCallback(({ pageCount }) => {
    setPageCount(pageCount)
  }, [])

  const onPageChanged = useCallback((pageNumber, activePageDomElement) => {
    setPageNumber(pageNumber)
    createButtonAndModalSettingsForPage(activePageDomElement)
  }, [createButtonAndModalSettingsForPage])

  const onCommentSpanClicked = useCallback(data => {
    setCommentSpanData(data)
    setShowRndCommentsModal(true)
  }, [])

  return (
    <div className={'ClaimPreview'}>
      { reportHtmlWithHighlights && displayedVersion === 'changes' && user.account_type !== 'accountant' && createEditTextButtons(editButtonsSettings) }
      { reportHtmlWithHighlights && displayedVersion === 'changes' && editTextModalsSettings.map(
        settings => <CompanyReportPreviewEditSectionModal {...settings} isUnderReview={!!reportHtml} />
      )}

      { showRndCommentsModal &&
        <RndCommentsModal
          show={showRndCommentsModal}
          commentTextSelectionData={commentSpanData}
          onHide={() => {
            setShowRndCommentsModal(false)
            resetPdfData()
            previewClaimReportHighlightsVersion()
          }}
        />
      }

      {reportHtmlWithHighlights?
      <>
        { reportHtml &&
          <Row>
            <Col md={12}>
              <Row className="mb-3">
                <Col md={{span: 3, offset: 3  }}>
                  <Button
                      variant={displayedVersion === 'final' ? 'primary' : 'light'}
                      size="md"
                      onClick={() => setDisplayedVersion('final')}>
                        Show final
                    </Button>
                </Col>
                <Col md={{span: 3}}>
                  <Button
                    variant={displayedVersion === 'changes' ? 'primary' : 'light'}
                    size="md"
                    onClick={() => setDisplayedVersion('changes')}>
                      Show changes
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        <Row>
          <Col md={12}>
            <Carousel
              interval={null}
              activeIndex={0}
              onSelect={onCarouselSelect}
              nextIcon={<span className="material-icons text-primary" style={{ height: '2rem', width: '2rem' }}>chevron_right</span>}
              prevIcon={<span className="material-icons text-primary" style={{ height: '2rem', width: '2rem' }}>chevron_left</span>}
            >
              <Carousel.Item>
                <div style={{ height: previewHeight, width: previewWidth }}>
                  {reportHtml && 
                    <ReportDisplay
                      mode='report-final-version'
                      previewWidth={previewWidth}
                      previewHeight={previewHeight}
                      // zIndex here allows us to toggle between the html versions without the need of re-rendering on each toggle
                      style={{ position: 'absolute', top: 0, left: 0, zIndex: displayedVersion === 'final' ? 1 : 0 }}
                      reportHtml={reportHtml}
                      displayPageNumber={pageNumber}
                      ignoreMessages={true}
                      onHtmlLoaded={onHtmlLoaded}
                    />
                  }

                  <ReportDisplay
                    mode='report-changes'
                    allowComments={!!claimGroup.active_change_set}
                    previewWidth={previewWidth}
                    previewHeight={previewHeight}
                    // zIndex here allows us to toggle between the html versions without the need of re-rendering on each toggle
                    style={{ position: 'absolute', top: 0, left: 0, zIndex: displayedVersion === 'changes' ? 1 : 0 }}
                    reportHtml={reportHtmlWithHighlights} 
                    displayPageNumber={pageNumber} 
                    onHtmlLoaded={onHtmlLoaded}
                    onPageChanged={onPageChanged}
                    onPageLoaded={createButtonAndModalSettingsForPage}
                    onCommentSpanClicked={onCommentSpanClicked}
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </>
      :
        <Loader />
      }
    </div>
  );
}
