import { api } from '@rd-web-markets/shared/dist/services/service';
const generalRouteService = {
  get: (route, id) => api.get("/".concat(route, "/").concat(id)),
  getAll: function (route) {
    let queries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return api.get("/".concat(route).concat(queries));
  },
  update: (route, id, payload) => api.update("/".concat(route, "/").concat(id), payload),
  delete: (route, id) => api.delete("/".concat(route, "/").concat(id)),
  create: function (route) {
    let payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return api.create("/".concat(route), payload);
  },
  postFormData: (route, payload) => api.postFormData("/".concat(route), payload)
};
export default generalRouteService;