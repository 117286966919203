import React from 'react';

export const helpPages = [
  {
    header: 'Where can I find the numbers?',
    body: <><p>
      To fill in this section, you will need your CT600 form
      (which is your tax return) to hand. You may recognise the
      CT600 by its blue or green colour and a lot of white
      squares and rectangles with names like "Trading and
      professional profits".
    </p>
      <p>
        We recommend you add your consultant, bookkeeper or
        Financial Director as a collaborator from your dashboard
        so they can fill this section in for you with the right
        numbers.
      </p>
      <p>
        We've tried our best to guide you well on your
        self-service journey. Most common cases are covered here,
        but tax rules are complex and every company is unique, so
        your situation may require different input from the
        self-guided forms.
      </p></>
  },
  {
    header: 'What is my Normal Taxable Profit and where can I find it?',
    body: <>
      <p>
        For the purposes of R&D tax credits relief, your Normal
        Taxable Profit is the sum of your trading and non-trading
        profits (if any, that is).
      </p>
      <p>
        If you're looking at the 2008 version of the CT600, you
        can find your trading profits in box 3 and your
        non-trading profits in box 6 and boxes 8-15. If you're
        using the 2015 version of the CT600, you can find your
        trading profits in box 155 and non-trading profits in box
        170 and boxes 175-205.
      </p>
      <p>
        Sum up your trading and non-trading profits and you'll get
        your Normal Taxable Profit.
      </p>
    </>
  },
  {
    header: 'What is my Trading Loss and where can I find it?',
    body: <>
      If you're looking at the 2008 version of the CT600, you can
      find your Trading Loss in box 122. If you're using the 2015
      version of the CT600, your Trading Loss will be in box 780.
    </>
  },
  {
    header: 'What are my Utilised Current Year Losses and where are they?',
    body: <>
      Usually these are losses that you've used to set against
      your current year profits. You can find them in box 30 in
      the 2008 version of the CT600 and in box 275 of the 2015
      version of the CT600.
    </>
  },
  {
    header: 'What is my Loss Already Surrendered to Group and where is it?',
    body: <>
      If your company has a group structure, this is a loss that
      you have surrendered to one of the companies in the group.
      The precise amount can be found in the "Group and
      consortium relief" supplementary pages of your CT600. You
      may not need to fill these in so don't panic if your boxes
      are blank or you don't have the supplementary page!
    </>
  }
];