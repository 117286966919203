import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { translationsService } from '@services/translations.service';
import translationImportsService from '@services/translation_imports.service';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TranslationForm from './TranslationForm';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import TranslationPair from './TranslationPair';
import * as QueryString from 'query-string';
import { useHistory } from 'react-router-dom';
import SearchBar from '@components/util/SearchBar';
import { useTranslation } from 'react-i18next';
import Pagination from '@rd-web-markets/shared/dist/util/Pagination';

const initialParams = {
  page: 1,
  query: ''
}

const TranslationsSection = ({locale}) => {
  const [keyValues, setKeyValues] = useState(null);
  const [selectedKeyValue, setSelectedKeyValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [totalPages, setTotalPages] = useState(null);
  const [params, setParams] = useState(
    window.location.search ? QueryString.parse(window.location.search) : initialParams
  );
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await translationsService.all(QueryString.stringify({...params, locale: locale}));
      setKeyValues(response.translations);
      setTotalPages(response.pages);
      history.push({
        path: 'admin/translations',
        search: QueryString.stringify(params),
      });
    }

    fetchData();
  }, [history, params]);
  
  const deleteKeyValue = async (keyValue) => {
    if(window.confirm('Are you sure you want to remove this contractor')){
      await translationsService.delete(keyValue.id);
      setKeyValues([...keyValues.filter(c => c.id !== keyValue.id)]);
      setSelectedKeyValue(null);
    }
  };

  const createKeyValue = () => {
    setSelectedKeyValue({});
  };

  const closeForm = () => {
    setSelectedKeyValue(null);
  };

  const handleChange = e => {
    const value = e.target.value;

    setSelectedKeyValue({
      ...selectedKeyValue,
      [e.target.name]: value,
    });
  };

  const editKeyValue = (e, keyValue) => {
    e.preventDefault();
    setSelectedKeyValue(keyValue);
  };

  const handleSubmit = async () => {
    if(Object.keys(selectedKeyValue).length !== 0){
      selectedKeyValue.locale = locale;
      setLoading(true);
      try {
        if(!selectedKeyValue.id) {
          const newKeyValue = await translationsService.create(selectedKeyValue);
          setKeyValues([...keyValues, newKeyValue]);
          const json = {}
          json[newKeyValue.key] = newKeyValue.value
          i18n.addResourceBundle(locale, 'translation', json, true, true);
          i18n.changeLanguage(locale);
          setSelectedKeyValue(null);
        } else {
          const updatedKeyValue = await translationsService.update(selectedKeyValue);
          const newKeyValues = keyValues;
          const index = newKeyValues.findIndex(c => {
            return c.id === updatedKeyValue.id;
          });
          const json = {}
          json[updatedKeyValue.key] = updatedKeyValue.value
          i18n.addResourceBundle(locale, 'translation', json, true, true);
          i18n.changeLanguage(locale);
          newKeyValues[index] = updatedKeyValue;
          setKeyValues(newKeyValues);
          setSelectedKeyValue(null);
        }
      } catch(e) { 
        dispatch(handleError(e))
      } finally { 
        setLoading(false);
      }
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const importTranslations = async (event) => {
    let fd = new FormData();
    fd.append('file', event.target.files[0]);
    setLoading(true);
    try {
      await translationImportsService.create(fd);
      window.location.reload();
    } catch(error) {
      dispatch(handleError(error));
    } finally { 
      setLoading(false);
    }
  };

  const exportTranslations = () => {
    setLoading(true);
    window.open('/api/translation_exports');
    setLoading(false);
  };

  const handlePage = (pageNumber) => {
    setParams({ ...params, page: pageNumber });
  };

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  if(!keyValues) return <Loading />

  return (
    <>
        <Row className="justify-content-end pb-3">
        { !selectedKeyValue &&
          <Button
            onClick={() => createKeyValue({})}
            variant="primary"
            className="mx-2">
            <span className="material-icons md-18">add</span> Create Translation
          </Button>
        }
      </Row>
      <Row>
        <Col md={{ span: 4, offset: 8 }}>
          <SearchBar onSubmit={handleSearch} loading={loading} query={params.query} />
        </Col>
      </Row> 
      { selectedKeyValue &&
        <TranslationForm
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          closeForm={closeForm}
          selectedKeyValue={selectedKeyValue}
          loading={loading}
        />
      }
      {!selectedKeyValue &&
        <>
          <Row>
            <table className="table borderless" style={{tableLayout: 'fixed'}}>
              <thead>
                <tr className='text-center'>
                  <th width="45%">Key</th>
                  <th width="45%">Value</th>
                  <th width="5%">Edit</th>
                  <th width="5%">Delete</th>
                </tr>
              </thead>
              <tbody className='text-center'>
                {keyValues.map((keyValue, index) => {
                  return (
                    <TranslationPair
                      editKeyValue={editKeyValue}
                      deleteKeyValue={deleteKeyValue}
                      keyValue={keyValue}
                      index={index}
                    />
                  );
                })}
              </tbody>
            </table>
          </Row>
          <Row className="justify-content-end pb-3">
            <Button
              onClick={exportTranslations}
              loading={loading}
              variant="primary"
              className="mx-2"
            >Export Translations</Button>
            <Button
              onClick={handleClick}
              loading={loading}
              variant="primary"
              className="mx-2">
              <span className="material-icons md-18">add</span> Import Translations
            </Button>
            <input
              style={{ display: 'none' }}
              type="file"
              ref={hiddenFileInput}
              accept="application/xlsx, .xlsx" 
              onChange={(e) => importTranslations(e)}
            ></input>
          </Row>
          <Pagination currentPage={Number(params.page)} totalPages={totalPages} handlePageChange={handlePage} />       
        </>
      }
    </>
  )
}

export default TranslationsSection
 