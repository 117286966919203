import React from 'react'

import Modal from '@rd-web-markets/shared/dist/util/Modal'
import { Table } from '@rd-web-markets/shared/dist/util';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';

export default function ClaimActivitiesModal({ show, claimActivities, onHide }) {
  const headers = [ 
    { text: 'Activity' }, 
    { text: 'Date' }, 
    { text: 'User' } 
  ];

  const rows = claimActivities.map(x => {
    let activityType = x.activity_type.replace(/_/g, ' ');
    activityType = activityType[0].toUpperCase() + activityType.slice(1);

    return {
      key: x.id,
      columns: [activityType, createTimezonedDate(new Date(x.created_at)).toCustomLocaleTimeString(), x.user_name]
    }
  });

  return (
    <Modal
      size='xl'
      title='Claim Activities'
      show={show}
      renderBody={ () => <Table style={{ width: '100%' }} headers={headers} rows={rows} showControls={false} opts={{ bordered: true }} /> }
      onHide={onHide}
      onSubmit={null}
    />
  )
}
