import { findNextOrPreviousTextNode, hasClassOrParentWithClass, matchNodeOrAnyParentElement } from "@rd-web-markets/shared/dist/util/domUtils";
import AdditionHighlight from "./AdditionHighlight";
import DeletionHighlight from "./DeletionHighlight";

/**
 * Contains methods that call both AdditionHighlight methods and DeletionHighlight methods.
 * Aka its not specific to either one - aka checking if a dom element is either a deletion or an addition highlight.
 */
const TrackChangesHighlight = {
  /**
   * Returns the track changes type of the current node (or any of its parent nodes).
   * If no node in the chain is a track changes node, then it returns null. Otherwise returns the string of the class.
   * 
   * The contentNode can be the track chagnges node itself.
   * Otherwise if say the change is a nested html object, the content node may be e.g. the h1 in <{track_changes_element}> <h1></h1> </{track_changes_element}>
   * @param {*} contentNode 
   * @returns {String} 'TrackChanges-Highlight-Addition' | 'TrackChanges-Highlight-Deletion'
   */
  getNodeTrackChangesType(contentNode) {
    let nodeTrackChangesTypeClass = null;
    let currentNode = contentNode;
    while (currentNode) {
      if (currentNode.className.includes(AdditionHighlight.className)) {
        nodeTrackChangesTypeClass = AdditionHighlight.className;
        break;
      } else if (currentNode.className.includes(DeletionHighlight.className)) {
        nodeTrackChangesTypeClass = DeletionHighlight.className;
        break;
      } else {
        currentNode = currentNode.parentElement;
      }
    }
    return nodeTrackChangesTypeClass;
  },
  isElementAnAdditionOrDeletionHighlight(node) {
    return AdditionHighlight.isNodeAHighlight(node) || DeletionHighlight.isNodeAHighlight(node);
  },
  isNodeAOrInAHighlight(node, highlightType) {
    if (highlightType === AdditionHighlight.highlightType) {
      return matchNodeOrAnyParentElement(node, AdditionHighlight.isNodeAHighlight);
    } else {
      return matchNodeOrAnyParentElement(node, DeletionHighlight.isNodeAHighlight);
    }
  },
  /**
   * 
   * @param {*} node current DOM Node
   * @param {String} siblingDirection 'next' or 'previous'
   * @param {String} highlightType DeletionHighlight.highlightType | AdditionHighlight.highlightType
   */
  isSiblingAOrInAHighlight(node, siblingDirection, highlightType) {
    if (!node) {
      return false;
    }
    let sibling = findNextOrPreviousTextNode(node, siblingDirection);
    return TrackChangesHighlight.isNodeAOrInAHighlight(sibling, highlightType);
  },
  removeTrackChangesClasses(element) {
    const trackChangesClasses = Array.from(element.classList).filter(c => c.includes('TrackChanges'));
    element.classList.remove(...trackChangesClasses);
  },
  isAnySelectionNodeInTrackChangesHighlight(cursorSelection, highlightType) {
    const className = highlightType === AdditionHighlight.highlightType ? AdditionHighlight.className : DeletionHighlight.className;
    const anchorIsInHighlightedElement = hasClassOrParentWithClass(cursorSelection.anchorNode, className);
    const offsetIsInHighlightedElement = hasClassOrParentWithClass(cursorSelection.focusNode, className);
    return anchorIsInHighlightedElement || offsetIsInHighlightedElement;
  }
};
export default TrackChangesHighlight;