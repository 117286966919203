import React from 'react';
import { Card } from 'react-bootstrap';
import NavigationTabs from '@rd-web-markets/market/dist/claim/costSection/NavigationTabs';
import { useTranslation } from 'react-i18next';


const ClaimGroupClaimListShared = ({
  accountType,
  claimGroup,
  sendClientCostEmail,
  company,
  onClaimGroupUpdate,
  loading,
  currentClaimCostId,
  handleImportCostTemplate,
  createChangeSet,
  finalizeChangeSet,
  approveChangeSet,
  handleToaster,
  rejectChangeSet,
  createChangeSetsForAllClaimsInClaimGroup
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-primary mb-4">{t('claim_costs')}</h2>
      <Card>
        <Card.Body>
          <NavigationTabs
            handleToaster={handleToaster}
            accountType={accountType}
            sendClientCostEmail={sendClientCostEmail}
            claimGroup={claimGroup}
            company={company}
            currentClaimCostId={currentClaimCostId}
            onClaimGroupUpdate={onClaimGroupUpdate}
            loading={loading}
            handleImportCostTemplate={handleImportCostTemplate}
            createChangeSet={createChangeSet}
            finalizeChangeSet={finalizeChangeSet}
            approveChangeSet={approveChangeSet}
            rejectChangeSet={rejectChangeSet}
            createChangeSetsForAllClaimsInClaimGroup={createChangeSetsForAllClaimsInClaimGroup}
          />
        </Card.Body>
      </Card>
    </>
  )
}

export default ClaimGroupClaimListShared
