import React, { useState, useRef } from 'react'
import { Col, Row, Card, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';

const TechnicalUploadsForm = ({isImmutableUnderReview = false, handleSubmit, loading, isSignedOff, showDocumentType = true}) => {
  const hiddenFileInput = useRef(null);
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [technicalUpload, setTechnicalUpload] = useState({
    document_type: 'tax_computations'
  });

  const handleChange = e => {
    setTechnicalUpload({
      ...technicalUpload,
      comments: e.target.value
    })
  };

  const onDocumentTypeChange = e => {
    setTechnicalUpload({
      ...technicalUpload,
      document_type: e.target.value
    })
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const addFile = event => {
    setSelectedFile(event.target.files[0]);
  }

  const submit = () => {
    handleSubmit(technicalUpload, selectedFile);
    setSelectedFile(null);
    setTechnicalUpload({
      document_type: 'tax_computations'
    });
    hiddenFileInput.current.value = '';
  }
  
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={2}>
            <Button isImmutableUnderReview={isImmutableUnderReview} variant="primary" onClick={handleClick} disabled={loading || isSignedOff}>
              {t('add_document')}
            </Button>
            <input
              style={{ display: 'none' }}
              type="file"
              data-testid="technicalDocumentUpload"
              ref={hiddenFileInput}
              onChange={(e) => addFile(e)}
            ></input>
            <p>{selectedFile && selectedFile.name}</p>
          </Col>
          <Form.Label column="md" md={2}>
            {t('please_add_additional_comments')}
          </Form.Label>
          <Col md={2}>
            <ImmutableUnderReviewFormControl
              onChange={handleChange}
              name="comments"
              value={technicalUpload.comments || ''}
              size="md"
              type="text"
            ></ImmutableUnderReviewFormControl>
          </Col>
          {showDocumentType &&
            <>
              <Form.Label column="md" md={2}>
                {t('please_select_document_type')}
              </Form.Label>
              <Col md={2}>
                <ImmutableUnderReviewFormControl
                  as="select"
                  value={technicalUpload.document_type}
                  onChange={(e) => onDocumentTypeChange(e)}
                >
                  {
                    process.env.PUBLIC_URL !== '/us' && 
                    <option key={'tax_computations'} value={'tax_computations'}>
                      {t('tax_computations')}
                    </option>
                  }
                  <option key={'accounts'} value={'accounts'}>
                    {t('accounts')}
                  </option>
                  <option key={'other'} value={'other'}>
                    {t('other')}
                  </option>
                </ImmutableUnderReviewFormControl>
              </Col>
            </>
          }
          <Col md={{span:1, offset: 1}}>
            <Button isImmutableUnderReview={isImmutableUnderReview} loading={loading} variant="primary" onClick={submit} disabled={isSignedOff}>
              {t('submit')}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>

  )
}

export default TechnicalUploadsForm
