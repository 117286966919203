import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const api = buildService('/faqs', {
  only: ['all', 'create', 'delete']
});
const faqService = {
  useFetchAllFaqs: setLoading => {
    const [faqs, setFaqs] = useState([]);
    const fetchAllFaqs = useErrorHandling(useCallback(async () => {
      setLoading && setLoading(true);
      const response = await api.all();
      setFaqs([...response]);
    }, [setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      fetchAllFaqs();
    }, [fetchAllFaqs]);
    return [faqs, setFaqs];
  },
  useAddFaq: (setLoading, setFaqs, faqs) => {
    const addFaq = useErrorHandling(useCallback(async () => {
      setLoading && setLoading(true);
      const response = await api.create();
      setFaqs([...faqs, response]);
    }, [faqs, setFaqs, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return [addFaq];
  },
  useDeleteFaq: (setLoading, setFaqs, faqs) => {
    const deleteFaq = useErrorHandling(useCallback(async faqId => {
      setLoading && setLoading(true);
      await api.delete(faqId);
      const newFaqs = faqs.filter(faq => faq.id !== Number(faqId));
      setFaqs([...newFaqs]);
    }, [faqs, setFaqs, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return [deleteFaq];
  }
};
faqService.useCrud = setLoading => {
  const [faqs, setFaqs] = faqService.useFetchAllFaqs(setLoading);
  const [addFaq] = faqService.useAddFaq(setLoading, setFaqs, faqs);
  const [deleteFaq] = faqService.useDeleteFaq(setLoading, setFaqs, faqs);
  return {
    faqs,
    setFaqs,
    addFaq,
    deleteFaq
  };
};
export default faqService;