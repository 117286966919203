import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import React, { useState, useEffect } from 'react';
import { Breadcrumb, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Loading } from '@rd-web-markets/shared/dist/util';
import ClaimSubmissionForm from '@components/admin/claimGroup/ClaimSubmissionForm';
import { ClaimSubmissionService } from '@services/claim';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';

const ClaimGroupClaimSubmissionsPage = ({handleToaster, accountType}) => {
  const { claimGroupId } = useParams();
  const [claimGroup, setClaimGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();



  useEffect(() => {
    const fetchClaimGroup = async () => {
      const claimGroup = await claimGroupService.get(claimGroupId);
      setClaimGroup(claimGroup);
    }

    fetchClaimGroup();
  }, [claimGroupId]);

  const submitClaim = async (index, claimId, submission_date) => {
    setLoading(true);
    try {
      const claim_submission = await ClaimSubmissionService.create(claimId, { submission_date });
      const updated_claims = claimGroup.claims
      updated_claims[index].claim_submission = claim_submission;
      setClaimGroup({
        ...claimGroup,
        claims: updated_claims
      });
      handleToaster('Submission created');
    } catch(error) { 
      dispatch(handleError(error));
    }
    setLoading(false)
  }
  
  if(!claimGroup) return <Loading />;

  return (
    <>
      <SidebarPortal headerItem={{link: `companies/${claimGroup.company.id}/${claimGroup.id}/complete_claim`, text: claimGroup.company.name}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}` }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies` }}>
            Companies
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/${claimGroup.company.id}/master` }}>
            {claimGroup.company.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{claimGroup.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card>
        <Card.Header>
          Claim Submissions
        </Card.Header>
        <Card.Body>
          {claimGroup.claims.map((claim, index) => {
            return (
              <ClaimSubmissionForm loading={loading} claim={claim} key={claim.id} submitClaim={submitClaim} index={index} />
            );
          })}
        </Card.Body>
      </Card>
    </>
  )
}

export default ClaimGroupClaimSubmissionsPage
