import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import logo from '@assets/images/logo.png';
import BackgroundImage from '@assets/BackgroundImage';

export default function FailedLoginPage() {
  return (
      <Container>
        <BackgroundImage />
        <Row className="justify-content-center">
          <img src={logo} alt="Logo" className="m-5" />
        </Row>
        <Row className="justify-content-center">
          <Col xs={6}>
            <Card>
              <Card.Body>
                <h3 className="pb-3">Unfortunately, you are not allowed to access Advance at this time. </h3>
                <h5>If you believe this to be an error, please contact your Advance administrator in order to resolve this.</h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
  );
}
