import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import ClaimsSelection from '@components/admin/companies/kimble/ClaimsSelection';
import { Breadcrumb, Card, Row, Col, Button } from 'react-bootstrap';
import { claimTemplateService } from '@services/claim_template.service';
import questionnairesService from '@services/questionnaires.service';
import { CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS, SPRINT_CLAIM_NAME, CLAIM_GROUP_DEFAULT_PARAMS } from '@rd-web-markets/market/dist/constants';
import NewClaimListingModal from '@rd-web-markets/shared/dist/claim/NewClaimListingModal';
import { CardErrorMessage } from '@rd-web-markets/shared/dist/util';
import CompanyClaimGroupEditForm from '@rd-web-markets/shared/dist/company/CompanyClaimGroupEditForm';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { Button as SubmittButton } from '@rd-web-markets/shared/dist/util/buttons';
import { CompanyService } from '@services/company.service';
import { fetchAndSetAdmins, fetchAndSetConsultants } from 'src/effects/user.effects';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useTranslation } from 'react-i18next';
import { kimbleCompanyService } from '@rd-web-markets/shared/dist/services/kimble_company.service';


const AddClaimGroupToCompanyPage = ({handleToaster, accountType}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id_in_provider_system_and_companies_house_number, company_id } = useParams();
  const [claimToSplit, setClaimToSplit] = useState(null);
  const [showClaimSplitModal, setShowClaimSplitModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [claims, setClaims] = useState({});
  const [claimGroup, setClaimGroup] = useState(CLAIM_GROUP_DEFAULT_PARAMS);
  const [finalizeCompany, setFinalizeCompany] = useState(false);
  const [claimTemplates, setClaimTemplates] = useState([]);
  const [defaultTemplate, setDefaultTemplate] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [consultants, setConsultants] = useState(null);
  const [admins, setAdmins] = useState(null);
  const { t } = useTranslation();

  const [questionnaires] = questionnairesService.useQuestionnaires();

  CLAIM_GROUP_DEFAULT_PARAMS.company_id = company_id;
  
  const lead_consultant_exists = consultantsAttributes => {
    return consultantsAttributes.find(consultant => consultant.role === 'claim_group_access_lead_consultant')
  }

  useEffect(() => {
    fetchAndSetConsultants(setConsultants,dispatch, handleError);
    fetchAndSetAdmins(setAdmins, dispatch, handleError);
    const fetchData = async () => {
      try {
        const [kimbleId, companiesHouseNumber] = id_in_provider_system_and_companies_house_number.split('--');
        const claims = await kimbleCompanyService.get(kimbleId);
        const claimTemplates = await claimTemplateService.get();
        const tasks = [];
        const defaultTemplate = claimTemplates.find(template => template.name === SPRINT_CLAIM_NAME);
        const company = await CompanyService.get(company_id);

        if(defaultTemplate) {
          defaultTemplate.claim_template_categories.forEach(category => {
            category.claim_template_tasks.forEach(task => tasks.push({...task, category_title: category.title, included: true }));
          });
        }

        setCompany(company);
        setDefaultTemplate(defaultTemplate);
        setTasks(tasks);
        setClaimTemplates(claimTemplates);
        setClaims(claims.map(claim => {
          return {...claim, include: claim.from_date ? true : false};
        }));

      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [id_in_provider_system_and_companies_house_number, dispatch]);

  const toggleInclude = (eventOrClaim, index) => {
    if (!CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS) {
      claims[index]['include'] = eventOrClaim.target.checked;
      setClaims([...claims]);
    } else {
      // At the moment we select a single claim only for US, where we also have to split
      // multi-year claims from kimble into single-year claims.
      setClaimToSplit(eventOrClaim);
      setShowClaimSplitModal(true);
    }
  }

  const toggleForm = (newClaims = undefined) => {
    if(!finalizeCompany) {
      const companyClaims = (newClaims || claims).filter(claim => claim.include === true).map(claim => {
        
        return {...claim,
          from_date: claim.from_date,
          to_date: claim.to_date,
          label: new Date(claim.to_date).getFullYear(),
          name: claim.kimble_name,
        }
      })
      setClaims(companyClaims);
      setFinalizeCompany(true);
    } else {
      setFinalizeCompany(false);
    }
  }

  const handleSubmit = async () => {
    const claimGroupClaims = claims.filter(claim => claim.include === true).map(claim => {
      return {...claim,
        from_date: claim.from_date,
        to_date: claim.to_date,
        label: new Date(claim.to_date).getFullYear(),
        name: claim.kimble_name,
      }
    })
    claimGroup.claims_attributes = claimGroupClaims;
    claimGroup.tasksJSON = tasks.filter(task => task.included === true);

    setLoading(true);

    if (!claimGroup.consultants_attributes.find(cons => cons.role === 'claim_group_access_lead_consultant')) { 
      handleToaster('Please add lead consultant to claim group', 'warning');
      setLoading(false);
      return;
    }


    try {
      await claimGroupService.create(claimGroup);
      history.replace(`/${accountType}/companies/${company_id}/master`);
    } catch(error) {
      dispatch(handleError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = event => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setClaimGroup({
      ...claimGroup,
      [event.target.name]: value,
    });
  }

  const handleClaimTypeChange = e => { 
    const template = claimTemplates.find(template => template.id === +e)
    const newTasks = [];

    template.claim_template_categories.forEach(category => {
      category.claim_template_tasks.forEach(task => newTasks.push({...task, category_title: category.title, included: true }));
    });
    setTasks([...newTasks]);
  }

  const updateTasks = (tasks) => {
    setTasks(tasks);
  };

  const handleRadioButtonChange = event => {
    const value = event.target.value === 'true' ? true : false;
    setClaimGroup({
      ...claimGroup,
      [event.target.name]: value
    });
  };

  const handleConsultantToClaimGroupCreate = consultant => {
    const consultantsAttributes = claimGroup.consultants_attributes;
    if(!consultantsAttributes.find(cons => cons.id === consultant.id)){
      if(consultant.role === 'claim_group_access_lead_consultant' && lead_consultant_exists(consultantsAttributes)){
        handleToaster('Lead consultant already added', 'warning');
      } else {
        consultantsAttributes.push({
          id: consultant.id,
          email: consultant.email,
          name: consultant.name,
          role: consultant.role
        });
        setCompany({
          ...company,
          consultants_attributes: consultantsAttributes
        });
      }
    }
  }

  const removeConsultantFromClaim = consultant => {
    setClaimGroup(
      {
        ...claimGroup,
        consultants_attributes: claimGroup.consultants_attributes.filter(c => c.id.toString() !== consultant.id.toString())
      }
    )
  }

  const setFromDate = (date, claim) => {
    const claimIndex = claims.findIndex(c => c.kimble_name === claim.kimble_name);
    const newClaims = [...claims];
    newClaims[claimIndex] = {...claim, from_date: date};
    setClaims(newClaims);
  }

  const changeContactClaimGroup = contact => {
    const contactsAttributes = claimGroup.rnd_collaborate_contacts_attributes.filter(cont => cont.name !== contact.name);
    contactsAttributes.push({
      ...contact,
      claim_group_id: claimGroup.id,
    });
    setClaimGroup({
      ...claimGroup,
      rnd_collaborate_contacts_attributes: contactsAttributes,
    });
  }

  const removeContactAttribute = contactName => {
    const contactsAttributes = claimGroup.rnd_collaborate_contacts_attributes.filter(cont => cont.name !== contactName);
    setClaimGroup({
      ...claimGroup,
      rnd_collaborate_contacts_attributes: contactsAttributes,
    });
  }

  if(!claims || !tasks || !consultants || !admins || !questionnaires) return <Loading />;

  return (
    <>
      <SidebarPortal headerItem={{link: `companies/${company_id}/master`, text: company.name}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies`}}>Companies</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies/${company_id}/master`}}>{company.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      { claims && !claims.length && <CardErrorMessage errorMessage={'This company has no claims in Kimble.'} /> }

      { !!claimToSplit && showClaimSplitModal && claims?.length &&
        <NewClaimListingModal 
          singleOrMultiYearClaim={claimToSplit}
          show={true}
          onSubmit={(newClaims) => {
            setShowClaimSplitModal(false)
            newClaims.forEach(c => {
              c.include = true
              c.kimble_company_name = claimToSplit.kimble_company_name
              c.kimble_name = claimToSplit.kimble_name.replace((new Date(claimToSplit.to_date)).getFullYear(), c.to_date.getFullYear())
              c.name = claimToSplit.kimble_name.replace((new Date(claimToSplit.to_date)).getFullYear(), c.to_date.getFullYear())
              c.id_in_provider_system = claimToSplit.id_in_provider_system
              c.kimble_sic_code = claimToSplit.kimble_sic_code
              c.to_date = `${c.to_date.getFullYear()}-${c.to_date.getMonth() + 1}-${c.to_date.getDate()}`
              c.from_date = `${c.from_date.getFullYear()}-${c.from_date.getMonth() + 1}-${c.from_date.getDate()}`
            })

          toggleForm(newClaims)
        }}
        onHide={() => setShowClaimSplitModal(false)}
      />
    }
    { !finalizeCompany && claims?.length &&
      <ClaimsSelection
        toggleForm={toggleForm}
        toggleInclude={toggleInclude}
        claims={claims}
        setClaims={setClaims}
        setFromDate={setFromDate}
        includeSingleClaim={CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS}
      />
    }
    {
      finalizeCompany &&
      <Card>
        <Card.Header className="bg-gradient-primary text-white">New Company - Finalise</Card.Header>
        <Card.Body>
          <CompanyClaimGroupEditForm
            consultantsAndAdmins={[...consultants, ...admins]}
            admins={admins}
            claimGroupContacts={claimGroup.rnd_collaborate_contacts_attributes}
            handleClaimGroupChange={handleChange}
            handleClaimTypeChange={handleClaimTypeChange}
            updateTasks={updateTasks}
            claimTemplates={claimTemplates}
            defaultTemplate={defaultTemplate}
            handleRadioButtonChange={handleRadioButtonChange}
            claimGroup={claimGroup}
            changeContactClaimGroup={changeContactClaimGroup}
            tasks={tasks}
            removeConsultantFromClaim={removeConsultantFromClaim}
            onUpdateConsultants={handleConsultantToClaimGroupCreate}
            removeContactAttribute={removeContactAttribute}
            questionnaires={questionnaires}
          />
          <Row className="modal-footer">
            <Col md={12}>
              <Button as={Link} className="float-left" variant="light" to={`/${accountType}/companies/`}>
                <span className="material-icons md-18">chevron_left</span> {t('back')}
              </Button>
              <SubmittButton loading={loading} onClick={handleSubmit} variant="primary" className="mr-3 float-right">
                <span className="material-icons md-18">save</span> {t('save')}
              </SubmittButton>

              </Col>
            </Row>
          </Card.Body>
        </Card>
      }
    </>
  )
}

export default AddClaimGroupToCompanyPage
