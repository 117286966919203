import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

export const ReportCompletionService = {
  create (claim_group_id, completion_date) {
    return fetch(`/api/claim_groups/${claim_group_id}/report_completions`, {
      ...request.post,
      body: JSON.stringify({ report_completion: { completion_date } })
    });
  }
};