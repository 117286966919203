import React from 'react'
import { useTranslation } from 'react-i18next';

const ClaimTemplateImportStatus = ({ import_status, importMessage }) => {
  const { t } = useTranslation();

  if (import_status === 'pending') return <div>{t('upload_status_pending')}</div>;
  if (import_status === 'imported_successfully') return <div>{t('upload_status_imported_successfully')}</div>;
  if (import_status === 'imported_unsuccessfully') return <div>{t('upload_status_there_were_errors_importing_the_file') + ': ' + importMessage}</div>;
  if (import_status === 'never_imported') return <div>{t('upload_status_template_was_never_imported')}</div>;
};

export default ClaimTemplateImportStatus
