import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const clientCostTemplateService = {
  delete(claimScheduleId) { 
    return fetch(`/api/claim_schedules/${claimScheduleId}/client_cost_template`, request.delete);
  },
  update(claimScheduleId, client_cost_template) {
    return fetch(`/api/claim_schedules/${claimScheduleId}/client_cost_template`, {
      ...request.put,
      body: JSON.stringify({ client_cost_template })
    });
  },
  create(claimId, client_cost_template) { 
    return fetch(`/api/claim_schedules/${claimId}/client_cost_template/`, {
      ...request.post,
      body: JSON.stringify({ client_cost_template }),
    });
  }
}

export default clientCostTemplateService;