import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import TechnicalProofMoreInfoModal from '@components/shared/technicalProof/TechnicalProofMoreInfoModal';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimProjectReportDetails from '@rd-web-markets/market/dist/technicalProof/ClaimProjectReportDetails';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useTranslation } from 'react-i18next';
import { MARKET_SPECIFIC_RD_OR_4_MENU, SHOULD_AUTOSAVE } from '@rd-web-markets/market/dist/constants';
import { makeKey } from '@rd-web-markets/shared/dist/hooks/useConvertForTranslationKey';
import { CompanyService } from '@services/company.service';
import claimProjectReportsListsService from '@services/lists/claim_groups/claim_project_reports_lists.service';
import claimProjectReportService from '@rd-web-markets/shared/dist/services/project_report/claim_project_report.service';

const extractMonthYear = (dateString) => {
  if (dateString) {
    const month = dateString.getMonth() + 1;
    const year = dateString.getFullYear();
    return [month, year].join('/');
  }
};
const extractDayMonthYear = (dateString) => {
  if (dateString) {
    const day = dateString.getDay() + 1;
    const month = dateString.getMonth() + 1;
    const year = dateString.getFullYear();
    return [day, month, year].join('/');
  }
};

const ClaimProjectReportDetailPage = ({accountType, handleToaster}) => {
  const { claimGroupId, report_id } = useParams();
  const [report, setReport] = claimProjectReportService.useGet(claimGroupId, report_id)
  const [claimGroup, setClaimGroup, resetClaimGroup] = claimGroupService.useGetClaimGroup(claimGroupId);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [company, setCompany] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(true);
  const { t } = useTranslation();
  const [allReports, setAllReports] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      if (claimGroup) {
        const company = await CompanyService.get(claimGroup.company_id)
        setCompany(company);
      }
    }
    fetchData();

  }, [dispatch, claimGroup, claimGroupId]);

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    setReport({
      ...report,
      [event.target.name]: value,
    });
    setIsSaved(false);
  };

  const handleSliceChange = (event, index) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    const slices = report.project_report_slices;
    slices[index][event.target.name] = value;
    setReport({...report, project_report_slices: [...slices]})
    //handleSubmit();
  };
  const handleDelete = async e => {
    e.preventDefault();
    try { 
      if(window.confirm('Are you sure you want to delete this project and all of its files?')){
        await claimProjectReportService.delete(claimGroupId, report.id);
        history.replace(`/${accountType}/claim_groups/${claimGroupId}/project_overview`);
      }
    } catch(error) {
      dispatch(handleError(error))
    }
  }

  const handleTinyMce = (content, name, index) => {
    const slices = report.project_report_slices;
    index = slices.findIndex(slice => slice.title === name)
    slices[index]['body'] = content;
    setReport({...report, project_report_slices: [...slices]})
  };

  const toggleOngoing = event => {
    if (event.target.checked === true) {
      setReport({
        ...report,
        end_date: null
      });
    }
    else {
      if(process.env.PUBLIC_URL === '/it'){
        setReport({
          ...report,
          end_date: extractDayMonthYear(new Date())
        });
      }else{
        setReport({
          ...report,
          end_date: extractMonthYear(new Date())
        });
      }
    }
  }

  const updateReport = useCallback(async () => {
    report.project_report_slices_attributes = report.project_report_slices;
    try { 
      await claimProjectReportService.update(claimGroupId, report);
      setIsSaved(true)
    } catch(error) {
      dispatch(handleError(error))
    }
  }, [report, claimGroupId, dispatch]);

  const handleSubmit = useCallback(async (e) => {
    e?.preventDefault();
    await updateReport();
    resetClaimGroup(claimGroupId);
  }, [updateReport]);

  const handleCheckChange = async (e) => {
    try {
      const response = await claimProjectReportService.update(claimGroupId, {...report, [e.target.name]: e.target.checked});
      setReport({
        ...response
      });
      resetClaimGroup(claimGroupId);
    } catch (error) {
      dispatch(handleError(error))
    }
  }

  const handleDateChange = async (e) => {
    try {
      const updatedFields = { [e.target.name]: e.target.value };
      if (e.associatedTarget) {
        updatedFields[e.associatedTarget.name] = e.associatedTarget.value;
      }
      await claimProjectReportService.update(claimGroupId, {...report, ...updatedFields});
      setReport({
        ...report,
        ...updatedFields
      });
    } catch (error) {
      dispatch(handleError(error))
    }
  }

  useEffect(() => {
    if (claimGroup?.projects_count <= 20){
      const fetchReports = async () => {
        const response = await claimProjectReportsListsService.base.all(claimGroupId, {group_projects: false, order_by: 'report_order'})
        setAllReports([...response.claim_project_reports])
      }

      fetchReports()
    }
  }, [claimGroupId, report_id, claimGroup?.projects_count])
  
  
  useEffect(() => {
    if (SHOULD_AUTOSAVE && report) {
      updateReport();
    }
  }, [report, updateReport]);

  if(!report || !claimGroup || !company) return <Loading />

  const isOngoing = report.end_date === null ? true : false;

  const menuItems = allReports.map((report) => {
    return {
      link: `claim_groups/${claimGroup.id}/technical_proof/project_reports/${report.id}?key=project_report_tab`,
      text: report.project_name,
      highlighted: report.id === +report_id,
    };
  });

  return (
    <>
      <SidebarPortal headerItem={{link: `claim_groups/${claimGroupId}/project_overview`, text: t('project_overview')}} menuItems={menuItems} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies`}}>{t('companies')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies/${claimGroup.company_id}/master`}}>{claimGroup.company.name}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/claim_groups/${claimGroupId}/project_overview`}}>{claimGroup.name.replace(claimGroup.company.name, '').trim()}</Breadcrumb.Item>
          <Breadcrumb.Item active>{report.project_name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      <TechnicalProofMoreInfoModal
        show={modalShow}
        title={modalTitle}
        body={modalBody}
        onHide={() => {
          setModalShow(false);
        }}
      />
      {report && (
        <ClaimProjectReportDetails
          claimGroup={claimGroup}
          handleCheckChange={handleCheckChange}
          handleDateChange={handleDateChange}
          report={report}
          company={company}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          toggleOngoing={toggleOngoing}
          setReport={setReport}
          handleSliceChange={handleSliceChange}
          isOngoing={isOngoing}
          handleDelete={handleDelete}
          handleTinyMce={handleTinyMce}
          accountType={accountType}
          setClaimGroup={setClaimGroup}
          handleToaster={handleToaster}
          isSaved={isSaved}
        />
      )}
    </>
  );
}

export default ClaimProjectReportDetailPage
