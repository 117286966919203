import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import React, { useState, useEffect } from 'react';
import { Breadcrumb, Card, Tabs, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import KimbleInvoiceSubmissionForm from '@components/admin/claimGroup/KimbleInvoiceSubmissionForm';
import { KimbleInvoiceService } from '@services/claim';
import ClaimService from '@services/claim/claim.service';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';

const ClaimGroupKimbleInvoicesPage = ({handleToaster, accountType}) => {
  const { claimGroupId } = useParams();
  const [claimGroup, setClaimGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const claimGroup = await claimGroupService.get(claimGroupId);
      setClaimGroup(claimGroup);
    };

    fetchData();
  }, [claimGroupId]);

  const submitKimbleInvoice = async (index, claim, invoiceDetails) => {
    setLoading(true);
    try {
      await ClaimService.updateInvoiced(claim, invoiceDetails)
      const invoice = await KimbleInvoiceService.create(claim.id, invoiceDetails)
      const updatedClaims = claimGroup.claims;
      updatedClaims[index].kimble_invoice = invoice;
      setClaimGroup({
        ...claimGroup,
        claims: updatedClaims
      });
      handleToaster('Invoice updated');
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  if(!claimGroup) return <Loading />;

  return (
    <>
      <SidebarPortal headerItem={{link: `companies/${claimGroup.company.id}/${claimGroup.id}/complete_claim`, text: claimGroup.company.name}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}` }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies` }}>
            Companies
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/${claimGroup.company.id}/master` }}>
            {claimGroup.company.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{claimGroup.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card>
        <Card.Header>
          Kimble Invoices
        </Card.Header>
        <Card.Body>
          <Tabs
            defaultActiveKey={claimGroup.claims[0].id}
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            {claimGroup.claims
                        .filter(claim => claim.id_in_provider_system !== null)
                        .map((claim, index) => {
              return (
                <Tab eventKey={claim.id} title={claim.name}>
                  <KimbleInvoiceSubmissionForm claim={claim} loading={loading} index={index} submitKimbleInvoice={submitKimbleInvoice}/>
                </Tab>
              )
            })}
          </Tabs>
        </Card.Body>
      </Card>
    </>
  )
}

export default ClaimGroupKimbleInvoicesPage
