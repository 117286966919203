import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import FinancialFramework from '@components/shared/technicalProof/FinancialPersonnelFramework/FinancialFramework';
import PersonnelFramework from '@components/shared/technicalProof/FinancialPersonnelFramework/PersonnelFramework';
import FinancialAndPersonnelDocuments from '@components/shared/technicalProof/FinancialPersonnelFramework/FinancialAndPersonnelDocuments';
import claimProjectReportService from '@services/claim_project_report.service';
import financialFrameworksService from '@services/financial_frameworks.service';
import personnelFrameworksService from '@services/personnel_frameworks.service';
import financialAndPersonnelDocumentsService from '@services/financial_and_personnel_documents.service';

const ClaimProjectReportFinancialPersonnelFrameworksPage = ({report, setReport, handleToaster}) => {
  const [financialFrameworks, setFinancialFrameworks] = useState([]);
  const [personnelFrameworks, setPersonnelFrameworks] = useState([]);
  const [financialAndPersonnelDocuments, setFinancialAndPersonnelDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const financialFrameworks = await financialFrameworksService.all(report.id);
      const personnelFrameworks = await personnelFrameworksService.all(report.id);
      const financialAndPersonnelDocuments = await financialAndPersonnelDocumentsService.all(report.id)
      setFinancialAndPersonnelDocuments(financialAndPersonnelDocuments.sort((a,b) => a.id - b.id));
      setFinancialFrameworks(financialFrameworks);
      setPersonnelFrameworks(personnelFrameworks);
    }
    fetchData();

  }, [dispatch, report.id]);


  const handleChange = async (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    const updatedReport = await claimProjectReportService.update(report.claim_group_id, {...report, [event.target.name]: value });
    setReport(updatedReport);
    const financialFrameworks = await financialFrameworksService.all(report.id);
    setFinancialFrameworks(financialFrameworks);
  }

  const handlePersonnelFrameworkChange = (e, index) => {
    const val = e.target.value;

    if (val < 0) return;
    
    const personnelFrameworksCopy = [...personnelFrameworks]
    personnelFrameworksCopy[index][e.target.name] = val;
    setPersonnelFrameworks(personnelFrameworksCopy);
  }

  const handleFinancialFrameworksChange = (e, index) => {
    const val =
      e.target.type === 'checkbox'
      ? e.target.checked
      : e.target.value;
    const financialFrameworksCopy = [...financialFrameworks]
    financialFrameworksCopy[index][e.target.name] = val;
    setFinancialFrameworks(financialFrameworksCopy);
  }
  
  const saveChange = async (index, type) => {
    try {
      if(type === 'financial') {
        const upatedFinancialFramework = await financialFrameworksService.update(report.id, financialFrameworks[index]);
        const newFinancialFrameworks = [...financialFrameworks];
        newFinancialFrameworks[index] = upatedFinancialFramework
        setFinancialFrameworks(newFinancialFrameworks);
      } else if(type === 'personnel') {
        await personnelFrameworksService.update(report.id, personnelFrameworks[index]);
        const financialFrameworks = await financialFrameworksService.all(report.id);
        setFinancialFrameworks(financialFrameworks);
      }
    } catch(err) { 
      dispatch(handleError(err));
    }
  };

  const handleSubmit = async (attachment, file) => {
    if(!file) {
      window.alert('Please attach a file first');
    } else {
      setLoading(true);
      let fd = new FormData();
      fd.append('file', file);
      fd.append('financial_and_personnel_document', JSON.stringify(attachment));
      try {
        const att = await financialAndPersonnelDocumentsService.create(report.id, fd);
        setFinancialAndPersonnelDocuments([...financialAndPersonnelDocuments, att]);
      } catch(error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  };

  const handleAttachmentChange = (e, index) => {
    const value =
    e.target.type === 'checkbox'
      ? e.target.checked
      : e.target.value;
    const updatedAttachments = financialAndPersonnelDocuments;
    updatedAttachments[index]['notes'] = value
    setFinancialAndPersonnelDocuments([...updatedAttachments]);
  };

  const handleAttachmentSubmit = async (index) => {
    setLoading(true);
    try {
      const updatedAttachments = financialAndPersonnelDocuments;
      const updatedAttachment = await financialAndPersonnelDocumentsService.update(report.id, updatedAttachments[index]);
      updatedAttachments[index] = updatedAttachment;
      setFinancialAndPersonnelDocuments([...updatedAttachments]);
    } catch(error) {
      dispatch(handleError(error.description));
    } finally { 
      setLoading(false);
    }
  };

  const handleDelete = async (documentId) => {
    setLoading(true)
    try { 
      await financialAndPersonnelDocumentsService.delete(report.id, documentId);
      setFinancialAndPersonnelDocuments(financialAndPersonnelDocuments.filter(att => att.id !== documentId));
    } catch(err) {
      dispatch(handleError(err.description));
    } finally { 
      setLoading(false);
    }
  }

  if(!report || !financialFrameworks || !personnelFrameworks || !financialAndPersonnelDocuments) return <Loading />

  return (
    <>
      <Form>
          <FinancialFramework handleChange={handleChange} financialFrameworks={financialFrameworks} report={report} saveChange={saveChange} handleFinancialFrameworksChange={handleFinancialFrameworksChange}/>
          <PersonnelFramework personnelFrameworks={personnelFrameworks} report={report} saveChange={saveChange} handlePersonnelFrameworkChange={handlePersonnelFrameworkChange} />
          <FinancialAndPersonnelDocuments
            applicationSubmitted={report.application_submitted}
            handleSubmit={handleSubmit}
            loading={loading}
            handleAttachmentSubmit={handleAttachmentSubmit}
            handleAttachmentChange={handleAttachmentChange}
            financialAndPersonnelDocuments={financialAndPersonnelDocuments}
            handleDelete={handleDelete}
          />
      </Form>
    </>
  );
}

export default ClaimProjectReportFinancialPersonnelFrameworksPage
