import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Breadcrumb, Form, Row, Col } from 'react-bootstrap';
import { Loading } from '@rd-web-markets/shared/dist/util';
import claimGroupQuestionnairesService from '@services/claim_groups/questionnaires.service';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { SaveButton, CancelButton } from '@rd-web-markets/shared/dist/util/buttons';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import questionnairesService from '@services/claim_groups/questionnaires.service';

const ProjectReportEditQuestionnaire = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { claim_group_id, report_id, id } = useParams();
  const [claimGroup] = claimGroupService.useGetClaimGroup(claim_group_id);
  const [claimProjectReport, setClaimProjectReport] = useState(null);
  const [questions, setQuestions] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const [questionnaire] = claimGroupQuestionnairesService.useQuestionnaire(claim_group_id, id);
  const saveQuestionnaire = questionnairesService.useSaveQuestionnaire(
    claim_group_id,
    {
      ...questionnaire,
      claim_group_questionnaire_answers: questions?.map(q => ({ id: q.id, question_id: q.question_id, body: q.answer }))
    }
  );

  useEffect(() => {
    const claimProjectReport = questionnaire?.claim_project_reports.find(report => report.id === +report_id);
    setClaimProjectReport(claimProjectReport);
    let questions = [];
    if (questionnaire) {
      questions = questionnaire.questionnaire.questions.map(q => ({
        id: null,
        question_id: q.id,
        body: q.body,
        description: q.description,
        answer: ''
      }));
      const answers = questionnaire.questionnaire_answers;
      questions.forEach(q => {
        const answer = answers.find(a => a.question_id === q.question_id);
        if (answer) {
          q.id = answer.id;
          q.answer = answer.body;
        }
      });
    }
    setQuestions(questions);
  }, [report_id, questionnaire, setClaimProjectReport]);

  const setAnswer = (e, questionId) => {
    const question = questions.find(q => q.question_id === questionId);
    question.answer = e.target.value;
    setQuestions([...questions]);
  }

  const renderQuestion = (question) => {
    return (<div key={question.question_id} style={{borderBottom: '1px solid rgba(0, 0, 0, 0.125)'}}>
      <Form.Group as={Row}>
        <Form.Label column="md" md={12}>
          {question.body}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="md" md={12}>
          {question.description}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="md" md={2}>
          {t('answer')}
        </Form.Label>
        <Col md={10}>
          <Form.Control
            name="answer"
            value={question.answer}
            size="md"
            type="text"
            onChange={(e) => setAnswer(e, question.question_id)}
          />
        </Col>
      </Form.Group>
    </div>);
  }

  if (!claimGroup || !claimProjectReport) return <Loading />

  return (
    <>
      <SidebarPortal headerItem={{link: `claim_groups/${claim_group_id}/technical_proof/project_reports/${report_id}/questionnaires`, text: t('questionnaires')}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies`}}>{t('companies')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claimGroup.company_id}/master`}}>{claimGroup.company.name}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claimGroup.company_id}/${claim_group_id}/project_overview`}}>{claimGroup.name.replace(claimGroup.company.name, '').trim()}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/claim_groups/${claim_group_id}/technical_proof/project_reports/${report_id}`}}>{claimProjectReport.project_name}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/claim_groups/${claim_group_id}/technical_proof/project_reports/${report_id}/questionnaires`}}>{t('questionnaires')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{questionnaire.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card className="mt-3">
        <Card.Header>{`${questionnaire.name} — ${questionnaire.created_at.slice(0, 10)}`}</Card.Header>
        <Card.Body>
          {questions.map(renderQuestion)}
          <Form.Group as={Row} className='col-md-12 mt-3' style={{justifyContent: 'flex-end'}}>
            <SaveButton className='mr-2' onClick={saveQuestionnaire}/>
            <CancelButton onClick={history.goBack}/>
          </Form.Group>
        </Card.Body>
      </Card>
    </>
  );
}

export default ProjectReportEditQuestionnaire;
