import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { CompanyService } from '@services/company.service';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { useSelector, useDispatch } from 'react-redux';
import NoClaimsPage from '@components/shared/claim/NoClaimsPage';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import claimProjectReportsClaimGroupService from '@services/claim_project_reports_claim_group.service';
import { useTranslation } from 'react-i18next';
import { FINANCIAL_SECTION_GENERAL_LINK, SIDEBAR_CLAIM_OVERVIEW_LINKS_CUSTOMER } from '@rd-web-markets/market/dist/constants';
import ClientClaimGroupTechnicalSection from './ClientClaimGroupTechnicalSection';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';

const ClientClaimGroupOverviewPage = ({handleToaster, accountType}) => {
  const user = useSelector((state) => state.auth.user);
  const { company_id, claim_group_id } = useParams();
  const [company, setCompany] = useState(null);
  const [claimGroup, setClaimGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPending, setisPending] = useState(false);
  const [isTechnicalNarrativePending, setIsTechnicalNarrativePending] = useState(false);
  const [projects, setProjects] = useState(null);
  const isProjectsEnv = ['/de', '/it'].includes(process.env.PUBLIC_URL)
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const has_technical_access = () => {
    const roles = user.user_accesses.find(access => access.rollable_type === 'ClaimGroup' && access.rollable_id === claimGroup.id).roles;
    return roles.includes('technical_contact') || roles.includes('client_sponsor') || roles.includes('accountant');
  }

  const has_financial_access = () => {
    const roles = user.user_accesses.find(access => access.rollable_type === 'ClaimGroup' && access.rollable_id === claimGroup.id).roles;
    return roles.includes('financial_contact') || roles.includes('client_sponsor') || roles.includes('accountant');
  }

  const resetCompany = useErrorHandling(useCallback(async () => {
    const company = await CompanyService.get(company_id);
    setCompany(company);
    return company;
  }, [company_id]))

  const resetCompanyAndClaimGroup = useErrorHandling(useCallback(async () => {
    const company = await resetCompany();
    const claimGroup = company.claim_groups.find(c => c.id === Number(claim_group_id)) || company.claim_groups[0];
    setClaimGroup(claimGroup);
  }, [claim_group_id, resetCompany]))

  useEffect(() => {
    resetCompanyAndClaimGroup(company_id, claim_group_id);
  }, [company_id, claim_group_id, dispatch, resetCompanyAndClaimGroup]);

  const getProjects = useErrorHandling(useCallback(async () => {
    const projects = await claimProjectReportsClaimGroupService.all(claim_group_id)
    setProjects(projects);
  }, [claim_group_id]))

  useEffect(() => {
    if(isProjectsEnv) {
      getProjects();
    }
  }, [getProjects, isProjectsEnv]);

  useEffect(() => {
    if (isPending) {
      const intervalId = window.setInterval(() => {
        claimGroupService.get(claimGroup.id)
          .then(claimGroup => {
            setClaimGroup({ ...claimGroup });
            if(claimGroup.claims.every(function(claim) {
              return claim.claim_import_cost_template_infos[claim.claim_import_cost_template_infos.length - 1].import_status !== 'pending'
            })){
              setisPending(false);
              handleToaster('Import Completed!');
            }
          })
          .catch(err => {
            dispatch(handleError(err.description));
          });
      }, 3000);
      return () => window.clearInterval(intervalId);
    }
  }, [isPending, claimGroup, dispatch, handleToaster]);

  useEffect(() => {
    if (isTechnicalNarrativePending) {
      const intervalId = window.setInterval(() => {
        claimGroupService.get(claimGroup.id)
          .then(claimGroup => {
            setClaimGroup({ ...claimGroup });
            if(claimGroup?.technical_narrative_upload_statuses.slice(-1)[0].import_status !== 'pending') {
              setIsTechnicalNarrativePending(false);
              handleToaster('Import Completed!');
            }
          })
          .catch(err => {
            dispatch(handleError(err.description));
          });
      }, 5000);
      return () => window.clearInterval(intervalId);
    }
  }, [isTechnicalNarrativePending, claimGroup, dispatch, handleToaster]);

  const getMenuItems = () => {
    const menuItems = [];
    if (has_financial_access()) {
      const claimCostItem = SIDEBAR_CLAIM_OVERVIEW_LINKS_CUSTOMER.find(item => item.link === 'claim_cost')
      if(isProjectsEnv) {
        claimCostItem.subMenu = projects.map(project => {
          return { text: project.name, link: `claims/${claimGroup.claim[0].id}/projects/${project.id}/financial_details/${FINANCIAL_SECTION_GENERAL_LINK}` }
        })
        menuItems.push(claimCostItem);
      } else {
        claimCostItem.subMenu = claimGroup.claims.map(claim => {
          return { text: claim.name, link: `claims/${claim.id}/financial_details/${FINANCIAL_SECTION_GENERAL_LINK}` }
        })
        menuItems.push(claimCostItem);
      }
    } 
    if (has_technical_access()) {
      let technicalSectionItem = SIDEBAR_CLAIM_OVERVIEW_LINKS_CUSTOMER.find(item => item.link === 'technical_section')
      technicalSectionItem = {
        ...technicalSectionItem,
        link: `companies/${company_id}/${claim_group_id}`,
        highlighted: true,
      }
      menuItems.push(technicalSectionItem);
    }
    return menuItems
  }

  if(!company || !claimGroup || !user.user_accesses) return <Loading />;

  if(company && !claimGroup) return <NoClaimsPage company_id={company.id} />;

  return (
    <>
      <SidebarPortal
        headerItem={{ link: 'companies', text: 'all_claims' }}
        menuItems={getMenuItems()}
      />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${user.account_type}` }}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('claim_costs')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      {
        has_technical_access() && <ClientClaimGroupTechnicalSection claimGroup={claimGroup} loading={loading} setLoading={setLoading} />
      }
    </>
  )
}

export default ClientClaimGroupOverviewPage
