import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

export const KimbleInvoiceService = {
  create (claim_id, kimble_invoice) {
    return fetch(`/api/claims/${claim_id}/kimble_invoices`, {
      ...request.post,
      body: JSON.stringify({ kimble_invoice })
    });
  }
};