import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import debounce from 'lodash.debounce';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
const api = buildService('/claim_project_reports/{id}/project_elements', {
  only: ['all', 'update']
});
export function useProjectElementServiceHandler() {
  const [projectElements, setProjectElements] = useState([]);
  const [loadingProjectElements, setLoadingProjectElements] = useState(false);
  const {
    report_id
  } = useParams();
  const getProjectElements = useErrorHandling(useCallback(async () => {
    setLoadingProjectElements(true);
    const response = await api.all(report_id);
    setProjectElements([...response]);
    setLoadingProjectElements(false);
  }, [report_id]));
  const updateElement = useMemo(() => {
    return debounce(async (content, element, setSavingStatus, changesSavedMessage) => {
      await api.update(report_id, element.id, {
        body: content
      });
      setSavingStatus(changesSavedMessage);
    }, 1500);
  }, [report_id]);
  useEffect(() => {
    getProjectElements();
  }, [getProjectElements]);
  return [projectElements, setProjectElements, updateElement, loadingProjectElements];
}