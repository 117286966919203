import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import generalRouteSubRouteService from '@rd-web-markets/shared/dist/services/general_route_subroute.service';
import { FileUploadModalBody, Modal } from '@rd-web-markets/shared/dist/util';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ProjectImportModal = ({ show, onHide, importProjects }) => {
  const { t } = useTranslation();
  const [uploadAllowed, setUploadAllowed] = useState(false);
  const [fileNotSelected, setFileNotSelected] = useState(false);
  
  useEffect(() => {
    if (!show) {
      setUploadAllowed(false);
    }
  }, [show]);

  const uploadFile = useErrorHandling(
    useCallback(
      async (formData) => {
        const response = await importProjects(
          formData
        );

        // setCompany({...response})
      },
      [importProjects]
    )
  );

  const renderBody = (
    <FileUploadModalBody
      uploadAllowed={uploadAllowed}
      upload={uploadFile}
      setFileNotSelected={setFileNotSelected}
    />
  );

  return (
    <Modal
      size='lg'
      title={t('import_projects')}
      show={show}
      renderBody={() => renderBody}
      onHide={onHide}
      onSubmit={() => setUploadAllowed(true)}
      submitText={t('upload')}
      disabled={fileNotSelected}
    />
  );
};

export default ProjectImportModal;
