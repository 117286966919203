import EmailSentStatus from '@components/shared/claim_group/EmailSentStatus'
import React, { useState } from 'react'
import EmailPreviewModalPreview from './EmailPreviewModalPreview'
import EmailPreviewModalSimple from './EmailPreviewModalSimple'

export default function PastSentEmailList({ claimGroup, pastSentTemplatesList, previewEmailInModal = true, ...props }) {
  const [pastTemplateToPreview, setPastTemplateToPReview] = useState(null)
  if(!pastSentTemplatesList) return <></>;

  return (
    <div {...props}>
      {
        pastSentTemplatesList.filter(t => t.sent_at)
        .sort((t1, t2) => t2.sent_at.localeCompare(t1.sent_at))
        .map(template => 
          <div className="mb-1 cursor-pointer" onClick={() => setPastTemplateToPReview(template)}><EmailSentStatus emailTemplate={template} /></div>
        )
      }
      { pastTemplateToPreview && previewEmailInModal &&
        <EmailPreviewModalSimple show={!!pastTemplateToPreview} claimGroup={claimGroup} template={pastTemplateToPreview} onHide={() => setPastTemplateToPReview(null)} />
      }

      { pastTemplateToPreview && !previewEmailInModal &&
        <div>
            <hr />
            <h4>Past sent email preview</h4>
            <EmailPreviewModalPreview claimGroup={claimGroup} template={pastTemplateToPreview} />
        </div> 
      }
    </div>
  )
}
