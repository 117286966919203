import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import ClaimTasksForm from './ClaimTasksForm';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import claimGroupTasksService from '@rd-web-markets/shared/dist/services/claim_group_tasks.service';
import ClaimGroupBreadcrumbLinks from '@components/shared/claim_group/edit/ClaimGroupBreadcrumbLinks';
import ClaimGroupEditNavigation from '@components/shared/claim_group/edit/ClaimGroupEditNavigation';
import ClaimGroupEditFooter from '@components/shared/claim_group/edit/ClaimGroupEditFooter';
import ClaimGroupSidebar from '../ClaimGroupSidebar';

const ClaimGroupTasksPage = ({ handleToaster, accountType }) => {
  const { claimGroupId } = useParams();
  const [claimGroup] = claimGroupService.useGetClaimGroup(claimGroupId);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);

  const refreshTasks = useErrorHandling(useCallback(async () => {
    const tasks = await claimGroupTasksService.all(claimGroupId);
    setTasks(tasks);
  }, [claimGroupId]));

  useEffect(() => {
    refreshTasks();
  }, [refreshTasks]);

  const removeTask = useErrorHandling(useCallback(async (taskId) => {
    await claimGroupTasksService.delete(claimGroupId, taskId);
    await refreshTasks();
  }, [refreshTasks, claimGroupId]));

  if(!claimGroup) return <Loading/>;

  return (
    <>
      <ClaimGroupSidebar
        claimGroup={claimGroup}
        company={claimGroup.company}
        claims={claimGroup.claims}
        highlightedMenuItem='edit/general'
        accountType={accountType}
      />
      <ClaimGroupBreadcrumbLinks claimGroup={claimGroup}/>
      <Card>
        <Card.Header>
          <ClaimGroupEditNavigation accountType={accountType} page={'claim_tasks'} claimGroup={claimGroup}/>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={12}>
              <ClaimTasksForm
                tasks={tasks}
                removeTask={removeTask}
                handleToaster={handleToaster}
              />
            </Col>
          </Row>
          <Row className="modal-footer">
            <ClaimGroupEditFooter
              loading={loading}
              handleSubmit={() => {}}
              backUrl={`/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}/master`}
            />
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default ClaimGroupTasksPage;
