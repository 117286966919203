function createPopoverHeader(doc, popover, headerTitle) {
  const closeButton = doc.createElement("button");
  closeButton.innerHTML = "X";
  closeButton.className = "btn__close";
  closeButton.onclick = function () {
    popover.parentNode.removeChild(popover);
  };
  const headerText = doc.createElement('span');
  headerText.innerHTML = headerTitle;
  const header = doc.createElement('div');
  header.className = 'popover-header';
  header.appendChild(headerText);
  header.appendChild(closeButton);
  popover.appendChild(header);
}

/**
 * 
 * @param {*} doc The document in which we want to show a popover - e.g. main document vs an iframe's document
 * @param {*} anchorElement The element over which we want the popover
 * @param {*} headerTitle The title of the popover
 * @param {*} createFooterFn Function to dynamically create the popover footer - e.g. buttons
 * @returns 
 */
export function showPopover(doc, anchorElement, headerTitle, createBodyFn, createFooterFn) {
  let options = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
  if (!(anchorElement.nodeType === Node.ELEMENT_NODE)) {
    console.warn('[EditorUtils - popover]: Attempted to create a popover on an element from a different window.');
    return;
  }
  const popover = doc.createElement("div");
  // without this line we seem to be able to edit the popover itself
  popover.setAttribute('contenteditable', false);

  // In case a click event from anywhere in the popover bubbles up to here
  // then stop it. It should not go further up, and it should not get cought by the editor.
  popover.addEventListener('click', e => {
    e.preventDefault();
    e.stopPropagation();
  });
  popover.className = "Aym-TextEditor-popover Editor-non-content ".concat(options.className || '');
  createPopoverHeader(document, popover, headerTitle);
  createBodyFn(popover);
  createFooterFn(popover);
  doc.body.appendChild(popover);
  let dynamicPopoverTopPosition = anchorElement.offsetTop - popover.offsetHeight - 5;

  // if the popover is outside of the screen, just display it below the content.
  if (dynamicPopoverTopPosition < 0) {
    dynamicPopoverTopPosition = anchorElement.offsetTop + anchorElement.offsetHeight + 5;
  }
  let dynamicPopoverLeftPosition = anchorElement.offsetLeft;
  if (dynamicPopoverLeftPosition + popover.offsetWidth > doc.body.offsetWidth) {
    dynamicPopoverLeftPosition = doc.body.offsetWidth - popover.offsetWidth;
  }
  popover.style.top = "".concat(dynamicPopoverTopPosition, "px");
  popover.style.left = "".concat(dynamicPopoverLeftPosition, "px");
}