import { v4 as uuidv4 } from 'uuid';
import { findAnyNodeOrElementSibling, findFirstNonEmptyTextNode, findLDeepestRightmostChildTextNodeElement, findLastNonEmptyTextNode, getTextNodeByCharIndex, isEmptySelection, isTextNode, stopEvent, surroundSelectionTextNodesWithDom, surroundTextNodeContent, userIsCuttingOrPasting } from "@rd-web-markets/shared/dist/util/domUtils";
import { moveCursorLeftByOneCharacter, moveCursorRightByOneCharacter, setCursorPositionToEndOfElement, setCursorPositionToStartOfElement } from "../cursorUtils";
import selectionUtils from "../selectionUtils";
import TrackChangesHighlight from "../models/TrackChangesHighlight";
import { isEditorSelectionCollapsedOnAnImage } from "../tinyMceEditorUtils";
import DeletionHighlight from "../models/DeletionHighlight";
import DynamicField from "../models/DynamicField";

// Depending on whether we delete slowly or quickly and this seems to only depend on the speed
// sometimes the textContent iis '' as the editor seems to produce an empty text node for whatever reason.
// Thuse we need to change the cursorSelection to either the left or the right node
function getCursorSelectionForCharacterDeletionWhenAnchorTextContentIsEmpty(deleteKey, cursorSelection) {
  let selection = cursorSelection;
  if (deleteKey === 'Backspace') {
    var _cursorSelection$anch, _cursorSelection$anch2;
    const targetNode = isTextNode((_cursorSelection$anch = cursorSelection.anchorNode) === null || _cursorSelection$anch === void 0 ? void 0 : _cursorSelection$anch.previousSibling) ? (_cursorSelection$anch2 = cursorSelection.anchorNode) === null || _cursorSelection$anch2 === void 0 ? void 0 : _cursorSelection$anch2.previousSibling : findLastNonEmptyTextNode(cursorSelection.anchorNode.previousElementSibling);
    selection = {
      anchorNode: targetNode,
      anchorOffset: targetNode.textContent.length - 1,
      focusNode: targetNode,
      focusOffset: targetNode.textContent.length - 1
    };
  } else if (deleteKey === 'Delete') {
    var _cursorSelection$anch3, _cursorSelection$anch4;
    const targetNode = isTextNode((_cursorSelection$anch3 = cursorSelection.anchorNode) === null || _cursorSelection$anch3 === void 0 ? void 0 : _cursorSelection$anch3.nextSibling) ? (_cursorSelection$anch4 = cursorSelection.anchorNode) === null || _cursorSelection$anch4 === void 0 ? void 0 : _cursorSelection$anch4.nextSibling : findFirstNonEmptyTextNode(cursorSelection.anchorNode.nextElementSibling);
    selection = {
      anchorNode: targetNode,
      anchorOffset: 0,
      focusNode: targetNode,
      focusOffset: 0
    };
  }
  return selection;
}
function onDeleteCharacter(editor, tinyContentNode, cursorSelection, charMarkedForDeletionIndex, deleteKey) {
  if (cursorSelection.focusNode.nodeName === 'DIV') {
    const childNode = cursorSelection.focusNode.childNodes[cursorSelection.focusOffset - 1];
    if ((childNode === null || childNode === void 0 ? void 0 : childNode.nodeName) === 'IMG') {
      childNode.classList.add(...DeletionHighlight.createDomElementHighlightClasses().split(' '));
      const prevNode = findAnyNodeOrElementSibling(childNode, 'previous');
      const nextNode = findAnyNodeOrElementSibling(childNode, 'next');
      return [prevNode, nextNode];
    }
  }
  let selection = cursorSelection;
  if (cursorSelection.anchorNode.textContent === '') {
    selection = getCursorSelectionForCharacterDeletionWhenAnchorTextContentIsEmpty(deleteKey, cursorSelection);
  }
  const dynamicFieldBeingEditted = DynamicField.isEditingPartOfADynamicField(selection.anchorNode, selection);

  /**
   * If pressing DELETE / BACKSPACE on a dynamic field - highlight the whole dynamic field for deletion
   */
  if (dynamicFieldBeingEditted) {
    return DeletionHighlight.surroundDynamicField(editor, dynamicFieldBeingEditted);
  }

  // assume this is the Backspace button
  if (charMarkedForDeletionIndex < 0) {
    return [null, null];
  }
  let targetNode = isTextNode(selection.anchorNode) ? selection.anchorNode : getTextNodeByCharIndex(selection.anchorNode, charMarkedForDeletionIndex);
  if (charMarkedForDeletionIndex >= targetNode.textContent.length && deleteKey === 'Delete') {
    const nextSibling = targetNode.nextSibling || targetNode.parentElement.nextSibling;
    if (!nextSibling) {
      return [null, null];
    }
    targetNode = isTextNode(nextSibling) ? nextSibling : getTextNodeByCharIndex(nextSibling, charMarkedForDeletionIndex);
    charMarkedForDeletionIndex = 0;
  }
  const [beforeTextNode, afterTextNode] = surroundTextNodeContent(editor.getDoc(), targetNode, DeletionHighlight.createHighlightedElement, charMarkedForDeletionIndex, charMarkedForDeletionIndex + 1);
  return [beforeTextNode, afterTextNode];
}
function highlightDeletionChangesWhenSelectionisNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  // addition | deletion
  if (!userIsCuttingOrPasting(event)) {
    if (!!(predictedInputValueOrNull !== null && predictedInputValueOrNull !== void 0 && predictedInputValueOrNull.full) && contentNode.textContent !== (predictedInputValueOrNull === null || predictedInputValueOrNull === void 0 ? void 0 : predictedInputValueOrNull.full)) {
      stopEvent(event);
    }
  }

  // addition | deletion | cutting | pasting
  if (contentNode.nodeName === 'IMG') {
    contentNode.classList.add(...DeletionHighlight.createDomElementHighlightClasses().split(' '));
    return;
  }
  if (!!(predictedInputValueOrNull !== null && predictedInputValueOrNull !== void 0 && predictedInputValueOrNull.full) && contentNode.textContent !== (predictedInputValueOrNull === null || predictedInputValueOrNull === void 0 ? void 0 : predictedInputValueOrNull.full) || userIsPastingContent(event) || event.key === 'Enter') {
    const [leftSideTextNode, rightSideTextNode] = surroundSelectionTextNodesWithDom(editor.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion');
    if (event.key === 'Backspace') {
      setCursorPositionToEndOfElement(editor, leftSideTextNode);
    } else if (event.key === 'Delete') {
      // previous sibling may be null if we didnt surround the node with deletion highlight - in case it already had an addition highlight and we just deleted part of it
      setCursorPositionToStartOfElement(editor, rightSideTextNode);
    }
  }
}

// we are deleting a single character - nothing would happen if we press CTRL-[C|X] with empty selection
function highlightDeletionChangesWhenSelectionisEmpty(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  if (event.key === 'Backspace') {
    const {
      cursorSelectionCopy,
      targetNode
    } = selectionUtils.createSelectionObjectForDeletion(cursorSelection, true);
    const [beforeTextNode, _] = onDeleteCharacter(editor, targetNode, cursorSelectionCopy, cursorSelectionCopy.anchorOffset - 1, event.key);
    if (beforeTextNode === null) {
      return;
    }
    setCursorPositionToEndOfElement(editor, beforeTextNode);
  } else if (event.key === 'Delete') {
    const {
      cursorSelectionCopy,
      targetNode
    } = selectionUtils.createSelectionObjectForDeletion(cursorSelection, false);
    const [_, afterTextNode] = onDeleteCharacter(editor, targetNode, cursorSelectionCopy, cursorSelectionCopy.anchorOffset - 1, event.key);
    setCursorPositionToStartOfElement(editor, afterTextNode);
  }
  stopEvent(event);
}

// Only called if ['Delete', 'Backspace'].includes(event.key)
//
export function doDelete(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  // if we are trying to delete a deletion highlight, just move the cursor and return
  // from the event without doing anything else
  if (selectionUtils.isUserDeletingADeletionHighlightWithEmptySelection(cursorSelection, event.key)) {
    const moduleInstance = editor.AYMING_MODULES.find(m => m.name === 'track_changes');
    const isShowingTrackChanges = moduleInstance.toggleTrackChangesVisibility;
    if (event.key === 'Backspace') {
      if (isShowingTrackChanges) {
        moveCursorLeftByOneCharacter(editor, cursorSelection);
      } else {
        let untilOutsideDeletionSelection = {
          anchorNode: cursorSelection.anchorNode,
          anchorOffset: cursorSelection.anchorOffset,
          focusNode: cursorSelection.focusNode,
          focusOffset: cursorSelection.focusOffset
        };
        while (selectionUtils.isUserDeletingADeletionHighlightWithEmptySelection(untilOutsideDeletionSelection, event.key)) {
          const {
            targetNode,
            targetOffset
          } = moveCursorLeftByOneCharacter(editor, untilOutsideDeletionSelection);
          untilOutsideDeletionSelection = {
            anchorNode: targetNode,
            focusNode: targetNode,
            anchorOffset: targetOffset,
            focusOffset: targetOffset
          };
        }
      }
    } else if (event.key === 'Delete') {
      if (isShowingTrackChanges) {
        moveCursorRightByOneCharacter(editor, cursorSelection);
      } else {
        let untilOutsideDeletionSelection = {
          anchorNode: cursorSelection.anchorNode,
          anchorOffset: cursorSelection.anchorOffset,
          focusNode: cursorSelection.focusNode,
          focusOffset: cursorSelection.focusOffset
        };
        while (selectionUtils.isUserDeletingADeletionHighlightWithEmptySelection(untilOutsideDeletionSelection, event.key)) {
          const {
            targetNode,
            targetOffset
          } = moveCursorRightByOneCharacter(editor, untilOutsideDeletionSelection);
          untilOutsideDeletionSelection = {
            anchorNode: targetNode,
            focusNode: targetNode,
            anchorOffset: targetOffset,
            focusOffset: targetOffset
          };
        }
      }
    }

    // prevent tinymce from handling the keypress and deleting
    stopEvent(event);
    return;
  }
  if (selectionUtils.isUserDeletingAnAdditionHighlightWithEmptySelection(cursorSelection, event.key)) {
    if (event.key === 'Backspace' && !TrackChangesHighlight.isNodeAOrInAHighlight(cursorSelection.anchorNode, 'addition')) {
      var _previousSibling$chil;
      let previousSibling = findAnyNodeOrElementSibling(cursorSelection.anchorNode, 'previous');
      // if it is an element with children - e..g <div><span></span><span></span>text_node</div> - we need its last child element,
      // as technicallly it is the first sibling on the left of node
      if ((_previousSibling$chil = previousSibling.children) !== null && _previousSibling$chil !== void 0 && _previousSibling$chil.length) {
        previousSibling = findLDeepestRightmostChildTextNodeElement(previousSibling);
      }
      setCursorPositionToEndOfElement(editor, previousSibling);
    }
    return;
  }
  if (isEditorSelectionCollapsedOnAnImage(editor)) {
    editor.selection.getStart().className += " ".concat(DeletionHighlight.createDomElementHighlightClasses());
    stopEvent(event);
    return;
  }
  if (isEmptySelection(cursorSelection)) {
    highlightDeletionChangesWhenSelectionisEmpty(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
  } else {
    highlightDeletionChangesWhenSelectionisNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
  }
}
export function isDeleting(event) {
  return ['Delete', 'Backspace'].includes(event.key);
}