import Modal from '@rd-web-markets/shared/dist/util/Modal';
import React from 'react'

const CostTemplateErrorsModal = ({title, body, onHide, show }) => {

  const importErrorsModalBody = () => {
    return (
      <>
        {body}
      </>
    )
  }
  return (
    <Modal
      show={show}
      size="lg"
      title={title}
      renderBody={() => importErrorsModalBody()}
      onHide={onHide}
    />
  );
}

export default CostTemplateErrorsModal
