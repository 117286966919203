import ErrorHandler from '@components/util/ErrorHandler';
import React, { useState, useEffect, useRef } from 'react'
import { Container, Card, Row, Col, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import logo from '@assets/images/logo.png';
import { Loading } from '@rd-web-markets/shared/dist/util';
import technicalUploadLinkService from '@services/technical_upload_link.service';
import TechnicalUploadsForm from '@components/shared/technical_uploads/TechnicalUploadsForm';


const TechnicalUploadLinkPage = () => {
  const { technical_upload_id, link_id } = useParams();
  const [oneTimeLink, setOneTimeLink] = useState(null);
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const oneTimeLink = await technicalUploadLinkService.get(technical_upload_id, link_id);
        setOneTimeLink(oneTimeLink);
      } catch(error) { 
        dispatch(handleError(error))
      }
    };
    getData();
  }, [link_id, dispatch]);

  const handleSubmit = async (technicalUpload, selectedFile) => {
    if(!selectedFile) {
      window.alert('Attach a file before submitting');
    } else {
      setLoading(true);
      technicalUpload.document_type = 'tax_computation';
      let fd = new FormData();
      fd.append('file', selectedFile);
      fd.append('technical_upload', JSON.stringify(technicalUpload));
      try {
        const response = await technicalUploadLinkService.update(technical_upload_id, oneTimeLink.url, fd);
        setOneTimeLink({...response});
      } catch(error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  };

  const renderStatus = () => {
    if(oneTimeLink.used_at){
      return (
        <div className="text-center">
          Document has been successfully imported
        </div>
      )
    } else {
      return (
        <TechnicalUploadsForm loading={loading} handleSubmit={handleSubmit}/>
      )
    }
  }


  if (!oneTimeLink) return <Loading />;

  return (
    <>
      <ErrorHandler alertsContainerId='alerts-container' alertClassName='w-100 mw-100 mb-3'>
        <Container>
          <Row className="justify-content-center">
            <img src={logo} alt="Logo" className="m-5" />
          </Row>
          <Row className="justify-content-center">
            <Col xs={10}>
              <Card>
                <Card.Header>
                  <span>
                    { oneTimeLink.used_at ? 'You have uploaded your technical document for' : 'Upload Technical Document for' }
                    {oneTimeLink.one_time_uploadable.claim.name}
                  </span>
                </Card.Header>
                <Card.Body>
                  {renderStatus()}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </ErrorHandler>
    </>
  )
}

export default TechnicalUploadLinkPage
