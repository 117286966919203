import React, { useEffect, useState } from 'react'
import TemplateForm from '@components/shared/TemplateForm';
import { Loading } from '@rd-web-markets/shared/dist/util';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useHistory } from 'react-router-dom';
import icsEventEmailTemplateService from '@services/ics_event_email_template.service';
import { useTranslation } from 'react-i18next';
import MasterSidebar from '@components/util/MasterSidebar';

const ClaimScheduleIcsEventTemplate = ({ handleToaster, accountType }) => {
  const [templates, setTemplates] = useState([]);
  const [createdTemplate, setCreatedTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const initialTemplate = {id: 0, title: t('create_new')}

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/claim_schedule_ics_event_templates');
        setTemplates([initialTemplate, ...response]);
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [createdTemplate, dispatch]);

  const handleSubmit = async (template) => {
    setLoading(true);

    if (template.id === 0) {
      try {
        await icsEventEmailTemplateService.create(template);
        handleToaster(t('template_created'));
        window.location.reload();
      } catch (error) {
        dispatch(handleError(error));
      }
    }
    else {
      try {
        await icsEventEmailTemplateService.update(template.id, template);
        handleToaster(t('template_updated'));
        window.location.reload();
      } catch (error) {
        dispatch(handleError(error));
      }
    }
    setLoading(false);
  };

  const handleBack = () => {
    history.push('/admin/')
  }

  const handleRemoveTemplate = async (template) => {
    setLoading(true);
    try {
      await icsEventEmailTemplateService.delete(template.id);
      const templates = await icsEventEmailTemplateService.all();
      setTemplates([initialTemplate, ...templates]);
    } catch (error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  }


  if (!templates) return <Loading />;

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='claim_schedule_ics_event_template' />

      <TemplateForm
        handleSubmit={handleSubmit}
        templates={templates}
        templateType='claim_schedule_ics_event_template'
        loading={loading}
        createdTemplate={createdTemplate}
        handleBack={handleBack}
        handleRemoveTemplate={handleRemoveTemplate}
      />
    </>
  );
}

export default ClaimScheduleIcsEventTemplate
