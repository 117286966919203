import React from 'react';
import { useParams } from 'react-router-dom';
import { urlToSubpage } from '@rd-web-markets/market/dist/allBase';
import { Loading } from '@rd-web-markets/shared/dist/util';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';

export default function AllSumPage({accountType}) {
  const { page, claim_group_id, type } = useParams();
  const [claimGroup, setClaimGroup] = claimGroupService.useGetClaimGroup(claim_group_id, null)

  if (!claimGroup) return <Loading />;

  let subpage = urlToSubpage(accountType, page, type, claimGroup)
  return ( 
    <>
      {subpage}
    </>
  )
};
