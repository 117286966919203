import Modal from '@rd-web-markets/shared/dist/util/Modal';
import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';
import React from 'react'
import { useState } from 'react';
import { Card, Form, Row, Col} from 'react-bootstrap';

const CustomiseStaffingCostMethodologyModal = ({onHide, show, handleFinancialAnalysisMethodologyChange, template}) => {
  const [staffingCostMethodology, setStaffingCostMethodology] = useState(template.staffing_cost_methodology);
  const [consumablesMethodology, setConsumablesMethodology] = useState(template.consumables_methodology);

  const body = () => {
    return (
      <>
        <Card>
          <Card.Body>
            <Form.Group as={Row}>
              <Form.Label column md={4}>
                Staffing Cost Table Aggregation
              </Form.Label>
              <Col md={8}>
                <ImmutableUnderReviewFormControl
                  as="select"
                  name="methodology_id"
                  value={staffingCostMethodology}
                  onChange={(e) => setStaffingCostMethodology(e.target.value)}
                  size="md"
                  type="text"
                >
                  <option value='employee'>Employee</option>
                  <option value='function'>Function</option>
                </ImmutableUnderReviewFormControl>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column md={4}>
                Consumables Table Aggregation
              </Form.Label>
              <Col md={8}>
                <ImmutableUnderReviewFormControl
                  as="select"
                  name="methodology_id"
                  value={consumablesMethodology}
                  onChange={(e) => setConsumablesMethodology(e.target.value)}
                  size="md"
                  type="text"
                >
                  <option value='item_type'>Item Type (i.e. Consumables, Utilities)</option>
                  <option value='description'>Actual Description</option>
                </ImmutableUnderReviewFormControl>
              </Col>
            </Form.Group>
          </Card.Body>
        </Card>
      </>
    );
  }
  return (
    <Modal
      show={show}
      size="lg"
      title="Customise Staffing Cost Methodology"
      renderBody={() => body()}
      onHide={onHide}
      onSubmit={() => handleFinancialAnalysisMethodologyChange(staffingCostMethodology, consumablesMethodology)}
    />
  );
}

export default CustomiseStaffingCostMethodologyModal;
