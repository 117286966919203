import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { DateTimePicker } from '@rd-web-markets/shared/dist/util/date';
import { DatePicker } from '@rd-web-markets/shared/dist/util/date';

const ClaimScheduleSetTimes = ({sendIntroductionMail, claimSchedule, handleClaimSchedule, handleClaimScheduleSave, loading, handleProccessDate}) => {

  return (
    <Form.Group>
      <Row>
        <Col md={5}>
          <strong>Date*</strong>
        </Col>
        <Col md={7}>
        <DatePicker
          filterDate={date => date.getDay() === 1}
          selected={claimSchedule.process_start_date}
          onChange={(date) => handleProccessDate({target: {name: 'process_start_date', value: date}})}
        />
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={5}>Briefing Call</Col>
        <Col md={7}>
        <DateTimePicker
          selected={claimSchedule.briefing_call}
          onChange={(date) => handleClaimSchedule({target: {name: 'briefing_call', value: date}})}
        />
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={5}>Financial Data in Completed and Provided to Ayming</Col>
        <Col md={7}>
        <DateTimePicker
          selected={claimSchedule.financial_data_completion}
          onChange={(date) => handleClaimSchedule({target: {name: 'financial_data_completion', value: date}})}
        />
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={5}>Technical Project Discussions</Col>
        <Col md={7}>
        <DateTimePicker
          selected={claimSchedule.technical_project_discussions}
          onChange={(date) => handleClaimSchedule({target: {name: 'technical_project_discussions', value: date}})}
        />
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={5}>
          Draft Report is Completed and Provided to Client for Review
        </Col>
        <Col md={7}>
        <DateTimePicker
          selected={claimSchedule.draft_report_completion}
          onChange={(date) => handleClaimSchedule({target: {name: 'draft_report_completion', value: date}})}
        />
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={5}>Final Review and Written Sign-Off from Client</Col>
        <Col md={7}>
        <DateTimePicker
          selected={claimSchedule.final_review}
          onChange={(date) => handleClaimSchedule({target: {name: 'final_review', value: date}})}
        />
        </Col>
      </Row>

    </Form.Group>
  );
}

export default ClaimScheduleSetTimes
