import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import ObjectChangeSetsList from './ObjectChangeSetsList';
import ButtonControls from './ButtonControls';
import { useTranslation } from 'react-i18next';
import ClientReviewMailerModal from './ClientReviewMailerModal';
import ObjectChangeSetsListWithSnapshots from './ObjectChangeSetsListWithSnapshots';

const ClaimGroupClientReview = ({
  setClaimGroup,
  claimGroup,
  loading,
  company,
  createChangeSetForClient,
  approveChangeSet,
  rejectChangeSet,
  finalizeChangeSet,
  resetCompanyAndClaimGroup
}) => {
  const reviewType = 'client_review';
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);


  return (
    <>
      <h2 className='text-primary mb-4'>{t('client_review')}</h2>
      <Card className='shadow border-0'>
        <Card.Body>
          {claimGroup && (
            <>
              <ClientReviewMailerModal
                setClaimGroup={setClaimGroup}
                claimGroup={claimGroup}
                show={modalShow}
                setModalShow={setModalShow}
                loading={loading}
                createChangeSetForClient={createChangeSetForClient}
                reviewType={reviewType}
                reviewedObjectClass={'ClaimGroup'}
                reviewedObject={claimGroup}
              />

              <ButtonControls
                reviewedObject={claimGroup}
                reviewedObjectClass={'ClaimGroup'}
                loading={loading}
                company={company}
                finalizeChangeSet={finalizeChangeSet}
                approveChangeSet={approveChangeSet}
                rejectChangeSet={rejectChangeSet}
                buttonName={t('click_to_start_client_review')}
                managerQAReviewButtonName={t('under_client_review')}
                acceptChangesButtonText={t('accept_client_review_changes')}
                rejectChangesButtonText={t('reject_client_review_changes')}
                reviewType={reviewType}
                underManagerReviewButtonName={t('under_client_review')}
                setModalShow={setModalShow}
              />

              <ObjectChangeSetsListWithSnapshots object={claimGroup} reviewType={'client_review'} resetCompanyAndClaimGroup={resetCompanyAndClaimGroup}/>

            </>
          )}

          {/* If by any chance */}
          {!claimGroup && <span>Please create a Claim Group</span>}
        </Card.Body>
      </Card>
    </>
  );
};

export default ClaimGroupClientReview;
