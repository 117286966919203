import { useCallback, useEffect, useState } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const api = buildService('/claims/{id}/proratas');
const useFetchProratas = (claimId, setLoading) => {
  const [proratas, setProratas] = useState([]);
  const fetchProratas = useErrorHandling(useCallback(async () => {
    setLoading(true);
    const allProratas = await api.all(claimId);
    setProratas(allProratas);
  }, [claimId, setLoading]), useCallback(() => {
    setLoading(false);
  }, [setLoading]));
  useEffect(() => {
    fetchProratas();
  }, [fetchProratas]);
  return [proratas, setProratas, fetchProratas];
};
const useUpdateProrata = _ref => {
  let {
    claimId,
    proratas,
    setProratas,
    setLoading
  } = _ref;
  const updateProrata = useErrorHandling(useCallback(async prorata => {
    setLoading(true);
    const updatedProrata = await api.update(claimId, prorata.id, prorata);
    const indexInState = proratas.findIndex(c => c.id === updatedProrata.id);
    proratas.splice(indexInState, 1, updatedProrata);
    setProratas([...proratas]);
    setLoading(false);
  }, [claimId, proratas, setProratas, setLoading]), useCallback(() => {
    setLoading(false);
  }, [setLoading]));
  return updateProrata;
};
const useCrud = _ref2 => {
  let {
    claimId,
    setLoading
  } = _ref2;
  const [proratas, setProratas, fetchAllProratas] = useFetchProratas(claimId, setLoading);
  const updateProrata = useUpdateProrata({
    claimId,
    proratas,
    setProratas,
    setLoading
  });
  return {
    fetchAllProratas,
    updateProrata,
    proratas,
    setProratas
  };
};
const proratasService = {
  useCrud
};
export default proratasService;