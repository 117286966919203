import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import importCostTemplateService from '@services/claim/import_cost_template.service';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import ImportCostTemplateSection from '@components/shared/claim/ImportCostTemplateSection'
import { buildQueryString } from '@rd-web-markets/shared/dist/services/service';
import './progressStyle.css'
import { useTranslation } from 'react-i18next';
import { UPLOAD_COST_TEMPLATE_PAGE_TITLE } from '@rd-web-markets/market/dist/constants';

const progressBarStyle = {
  fontSize: '1.05rem', height: '24px'
};

const UploadCostTemplatePage = ({ 
  claim,
  selectedProject,
  refreshClaim,
  handleToaster
}) => {
  const user = useSelector((state) => state.auth.user);
  const [currentClaim, setCurrentClaim] = useState(claim);
  const [claimGroup, setClaimGroup] = useState(claim.claim_group);
  const [loading, setLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const generateCostTemplate = useCallback((from = null, to = null) => {
    const queryString = buildQueryString({ from, to })

    window.open(`/api/claims/${currentClaim.id}/claim_export_cost_template?${queryString}`)
  }, [currentClaim])

  const handleImportCostTemplate = useErrorHandling(async (formDataObject, claim, projectId) => {
    if(claim.active_change_set || claimGroup.active_change_set) {
      handleToaster('Cost Template cannot be loaded during Manager/Client Technical Review', 'warning')
    } else {
      await importCostTemplateService.importCostTemplateFile(
        formDataObject,
        claim,
        projectId,
        setLoading,
        setIsPending,
        async () => {
          const response = await claimGroupService.get(claimGroup.id);
          setClaimGroup({ ...response });
          const newClaim = response.claims.find(c => c.id === currentClaim.id);
          setCurrentClaim({ ...newClaim });
      })
    }
  })

  useEffect(() => {
    if (isPending) {
      const intervalId = window.setInterval(() => {
        claimGroupService.get(claimGroup.id)
          .then(claimGroup => {
            setClaimGroup({ ...claimGroup });
            const newClaim = claimGroup.claims.find(c => c.id === currentClaim.id);
            setCurrentClaim({ ...newClaim });
            if(claimGroup.claims.every(function(claim) {
              return claim.claim_import_cost_template_infos[claim.claim_import_cost_template_infos.length - 1].import_status !== 'pending'
            })){
              setIsPending(false);
              handleToaster('Import Completed!');
              refreshClaim()
            }
          })
          .catch(err => {
            dispatch(handleError(err.description));
          });
      }, 3000);
      return () => window.clearInterval(intervalId);
    }
  }, [isPending, currentClaim, claimGroup, dispatch, handleToaster, refreshClaim]);

  const progressBarVariant  = (
    currentClaim.claim_import_cost_template_infos[currentClaim.claim_import_cost_template_infos.length - 1]?.import_status == 'imported_successfully'?
      'success':
      'never-imported'
  )
  return (
    <div className="d-flex flex-column">
      <h1 className="text-primary mb-5">
        {t(UPLOAD_COST_TEMPLATE_PAGE_TITLE)}
      </h1>

      <div>
        <ProgressBar
          style={progressBarStyle}
          variant={progressBarVariant} now={currentClaim.completed_percent_financial}
        />
      </div>
      <hr />
      <ImportCostTemplateSection
        claim={currentClaim}
        claimGroup={claimGroup}
        user={user}
        templateInfo={currentClaim.claim_import_cost_template_infos[currentClaim.claim_import_cost_template_infos.length - 1]}
        loading={loading}
        handleToaster={handleToaster}
        selectedProject={selectedProject}
        generateCostTemplate={generateCostTemplate}
        handleImportCostTemplate={handleImportCostTemplate}
      />
    </div>
  )
}

export default UploadCostTemplatePage;
