import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button as SubmitButton } from '@rd-web-markets/shared/dist/util/buttons';
import { useTranslation } from 'react-i18next';

const ClaimGroupEditFooter = ({loading, backUrl, handleSubmit, isUnderReview}) => {
  const { t } = useTranslation();

  return (
    <Row className='modal-footer'>
      <SubmitButton isImmutableUnderReview={true} disabled={isUnderReview} loading={loading} onClick={handleSubmit} variant="primary">
        <span className="material-icons md-18">save</span> {t('save')}
      </SubmitButton>
    </Row>
  )
}

export default ClaimGroupEditFooter
