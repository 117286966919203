import { useCallback, useEffect, useState } from 'react';
import { buildFormData, buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const useFetch = _ref => {
  let {
    callback,
    setLoading,
    initialQueryParams,
    initialStateValue
  } = _ref;
  // items can also be a single item - depending on the endpoint we hit
  const [items, setItems] = useState(initialStateValue || []);
  const fetchItems = useErrorHandling(useCallback(async queryParams => {
    setLoading && setLoading(true);
    const allItems = await callback(queryParams);
    setItems(allItems);

    // returns the promise with data just in case we need it somewhere
    return allItems;
  }, [callback, setLoading]), useCallback(() => {
    setLoading && setLoading(false);
  }, [setLoading]));
  useEffect(() => {
    fetchItems(initialQueryParams || {});
  }, [fetchItems, initialQueryParams]);
  return [items, setItems, fetchItems];
};
const useFetchWithInterval = _ref2 => {
  let {
    api,
    callback,
    setLoading,
    initialQueryParams,
    initialStateValue,
    intervalTimeMs
  } = _ref2;
  const [interval, setInterval] = useState(null);
  const [stateVar, setStateVar, fetchMethod] = useFetch({
    callback,
    setLoading,
    initialQueryParams,
    initialStateValue
  });

  // On the first render of the component where this method is used, we create the inverval.
  // At this point the interval is not started and no request is made. We need to call
  // interval.start() - to start it and interval.clear() - to clear it.
  useEffect(() => {
    const newInterval = api.base.createInterval(() => fetchMethod(), intervalTimeMs || 5000);
    setInterval(newInterval);
  }, [api.base, fetchMethod, intervalTimeMs]);
  return {
    stateVar,
    setStateVar,
    fetchMethod,
    interval,
    setInterval
  };
};
const useCreate = _ref3 => {
  let {
    createCallback,
    setLoading,
    onError,
    onFinally
  } = _ref3;
  const createModel = useErrorHandling(useCallback(async () => {
    setLoading && setLoading(true);
    const result = await createCallback();
    setLoading && setLoading(false);
    return result;
  }, [setLoading, createCallback]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return createModel;
};

/**
 * The resulting method accepts an object with the formDataJson field and any other fields.
 * It turns the formDataJson into a real form data - formData.
 * 
 * { field1: 1, value1, ..., formDataJson } => callback({ field1: value 1, ..., formData })
 * 
 * @param {*} param0 
 * @returns 
 */
const usePostFormData = _ref4 => {
  let {
    callback,
    setLoading,
    onError,
    onFinally
  } = _ref4;
  const postFormData = useErrorHandling(useCallback(async paramsObject => {
    setLoading && setLoading(true);
    const {
      formDataJson
    } = paramsObject;
    const formData = buildFormData(formDataJson);
    const result = await callback({
      ...paramsObject,
      formData
    });
    setLoading && setLoading(false);
    return result;
  }, [setLoading, callback]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return postFormData;
};
const useUpdate = _ref5 => {
  let {
    callback,
    setLoading,
    onError,
    onFinally
  } = _ref5;
  const updateModel = useErrorHandling(useCallback(async paramsObject => {
    setLoading && setLoading(true);
    const result = await callback(paramsObject);
    setLoading && setLoading(false);
    return result;
  }, [setLoading, callback]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return updateModel;
};
const useDelete = _ref6 => {
  let {
    deleteCallback,
    setLoading,
    onError,
    onFinally
  } = _ref6;
  const deleteModel = useErrorHandling(useCallback(async () => {
    setLoading && setLoading(true);
    const result = await deleteCallback();
    setLoading && setLoading(false);
    return result;
  }, [setLoading, deleteCallback]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return deleteModel;
};
const serviceMethods = {
  useFetch,
  useFetchWithInterval,
  useUpdate,
  useDelete,
  useCreate,
  usePostFormData
};
export default serviceMethods;