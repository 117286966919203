import { useCallback, useState, useEffect } from 'react';
import { api } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';

const trackingTaskFilesService = {
  useFetchFiles: (claimProjectReportId, trackingTaskId) => {
    const [files, setFiles] = useState([]);

    const fetchFiles = useErrorHandling(useCallback(async () => {
      if (trackingTaskId) {
        const response = await trackingTaskFilesService.all(claimProjectReportId, trackingTaskId);
        setFiles(response.files);
      }
    }, [claimProjectReportId, trackingTaskId]));

    useEffect(() => {
      fetchFiles();
    }, [fetchFiles]);

    return [files, setFiles];
  },
  useUploadFile: (claimProjectReportId, trackingTaskId, file, cb) => {
    return useErrorHandling(useCallback(async (formData) => {
      formData.append('tracking_task_file', JSON.stringify(file));
      if (file.link) {
        formData.delete('file');
      }
  
      const response = await trackingTaskFilesService.create(claimProjectReportId, trackingTaskId, formData);
  
      cb && cb(response);
    }, [claimProjectReportId, trackingTaskId, file, cb]));
  },
  useDeleteFile: (claimProjectReportId, trackingTaskId, file, cb) => {
    return useErrorHandling(useCallback(async () => {
      if (file.id) {
        await trackingTaskFilesService.delete(claimProjectReportId, trackingTaskId, file.id);
      }
  
      cb && cb();
    }, [claimProjectReportId, trackingTaskId, file, cb]));
  },
  all: (claimProjectReportId, trackingTaskId) => api.get(`/time_tracking/claim_project_reports/${claimProjectReportId}/tracking_tasks/${trackingTaskId}/tracking_task_files/`),
  create: (claimProjectReportId, trackingTaskId,  formData) => api.postFormData(`/time_tracking/claim_project_reports/${claimProjectReportId}/tracking_tasks/${trackingTaskId}/tracking_task_files/`, formData),
  delete: (claimProjectReportId, trackingTaskId, id) => api.delete(`/time_tracking/claim_project_reports/${claimProjectReportId}/tracking_tasks/${trackingTaskId}/tracking_task_files/${id}`),
}

export default trackingTaskFilesService;