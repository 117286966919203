import React, { useCallback, useEffect, useState } from 'react'
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';
import { removeElements } from '@rd-web-markets/shared/dist/util/domUtils'
import { findParent } from '@rd-web-markets/shared/dist/util/domUtils'

export default function ReportDisplay({ 
  mode,
  allowComments,
  previewWidth,
  previewHeight,
  reportHtml,
  displayPageNumber,
  onHtmlLoaded,
  onPageLoaded,
  onPageChanged,
  onCommentSpanClicked = null,
  style = {},
  ignoreMessages = false
}) {
  const [htmlLoaded, setHtmlLoaded] = useState(false)
  const [iframeId, _] = useState(`preview-iframe-${mode}`)

  // The selection event is fired even if the user simply clicks somewhere in the page.
  // In that case nothing is selected and the anchorNode is null.
  // This should further be limited to only fire on text that belongs to an editable part of the report.
  const hasSelectedEditableText = useCallback(selection => {
    const nodesAreDifferent = selection.anchorNode && !selection.anchorNode.isEqualNode(selection.focusNode)
    const selectedAtLeastOneCharacter = selection.focusOffset !== selection.anchorOffset
    const hasSelectedText = nodesAreDifferent || selectedAtLeastOneCharacter
    const selectionIsInCategory = anchorNode => findParent(anchorNode, 'js-hook-Report-Template', 'pagedjs_page_content')
    const selectionIsInsideProjectSlice = anchorNode => findParent(anchorNode, 'js-hook-ClaimGroup', 'pagedjs_page_content')
    const selectionParentIsEditableText = anchorNode => selectionIsInCategory(anchorNode) || selectionIsInsideProjectSlice(anchorNode)
    
    return hasSelectedText && selectionParentIsEditableText(selection.anchorNode)
  }, [])

  useEffect(() => {
    const onFirstMessage = event => {
      const previewIframe = document.getElementById(iframeId);
      if (event.data?.pagesCount && previewIframe?.contentWindow === event.source) {
        window.removeEventListener('message', onFirstMessage)
        setHtmlLoaded(true)

        onHtmlLoaded({
          pageCount: event.data.pagesCount
        })
      }
    }

    window.addEventListener('message', onFirstMessage)

    if (!ignoreMessages) {
      window.addEventListener('message', event => {
        // we need to select the element each time in case we re-render with a different html
        const previewIframe = document.getElementById(iframeId);
        if (event.data && event.data.newPageNumber && event.data.newPageNumber !== displayPageNumber && previewIframe?.contentWindow === event.source) {
          const previewIframeDoc = previewIframe.contentDocument || previewIframe?.contentWindow?.document
          const activePageDomElement = previewIframeDoc.querySelector('.js-preview-page--active');
          onPageChanged(event.data.newPageNumber, activePageDomElement)
          removeElements(previewIframeDoc.body, 'preview-text-highlight-popover')
        }
      })
  
      window.addEventListener('message', event => {
        // we need to select the element each time in case we re-render with a different html
        const previewIframe = document.getElementById(iframeId);

        if (event.data && event.data === 'page loaded' && previewIframe?.contentWindow === event.source) {

          const activePageDomElement = previewIframe.contentWindow.document.querySelector('.js-preview-page--active');
          onPageLoaded(activePageDomElement)
        }
      })
    }
  }, [allowComments, displayPageNumber, hasSelectedEditableText, iframeId, ignoreMessages, mode, onCommentSpanClicked, onHtmlLoaded, onPageChanged, onPageLoaded])

  useEffect(() => {
    if (htmlLoaded) {
      const previewIframe = document.getElementById(iframeId)

      previewIframe?.contentWindow?.postMessage(
        {
          activePage: displayPageNumber
        }, 
        '*'
      )
    }

  }, [displayPageNumber, htmlLoaded, iframeId])

  return (
    <div style={style}>
      {!htmlLoaded && 
        <div style={{ position: 'absolute', height: '100%', width: '100%', fontSize:'10rem', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          <Loader style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', opacity: 1 }} />
        </div>
      }

      <iframe
        id={iframeId}
        title='preview'
        srcDoc={reportHtml}
        style={{
          minWidth: previewWidth,
          maxWidth: previewWidth,
          width: previewWidth,
          minHeight: previewHeight,
          maxHeight: previewHeight,
          height: previewHeight,
          overflowY: 'hidden'
        }}
      >
      </iframe>
    </div>
  )
}
