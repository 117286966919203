import React, { useState, useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Col, Form, Row, Card, Button as BootstrapButton } from 'react-bootstrap';
import { getReportTemplateOptions, apiKey } from '@rd-web-markets/shared/dist/util/tinyMceConfig';
import { Button, SaveButton } from '@rd-web-markets/shared/dist/util/buttons';
import CustomiseStaffingCostMethodologyModal from '@components/modals/CustomiseStaffingCostMethodologyModal';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS, EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS, EDITOR_CLAIM_PROJECT_FIELDS } from '@rd-web-markets/market/dist/constants';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useCustomTemplateTitle from '@rd-web-markets/shared/src/lib/hooks/useCustomTemplateTitle';
import { useTranslation } from 'react-i18next';
import ReportTemplateTitle from '@rd-web-markets/market/dist/reportTemplates/ReportTemplateTitle';
import { makeKey } from '@rd-web-markets/shared/dist/hooks/useConvertForTranslationKey';
import reportTemplateCategoryService from '@services/report_templates/report_template_category.service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import ImmutableUnderReviewFormCheck from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormCheck';
import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';

const ReportTemplateForm = (
  {
    loading,
    handleChange,
    handleCategoryChange,
    handleTinyMce,
    handleFinancialAnalysisMethodologyChange,
    handleSubmit,
    selectedTemplate,
    handleBack,
    handleChangeApplyAymingStyles
  }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { search } = useLocation()
  const [getTemplateTitle] = useCustomTemplateTitle();
  const customTitle = t(getTemplateTitle(search));
  const allFields = customTitle === 'Project Specfic' ? [...EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS, ...EDITOR_CLAIM_PROJECT_FIELDS] : EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS

  const save = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const getCommonEditorModulesSettings = reportTemplateCategory => ({
    stylesFormatterToggleIniitialValue: reportTemplateCategory?.should_apply_styles
  })

  const updateReportTemplateCagegory = useErrorHandling(useCallback(async updatedCategory => {
    const updatedReportTemplateCategory = await reportTemplateCategoryService.update(
      selectedTemplate.id,
      { 
        ...updatedCategory
      }
    )

    return updatedReportTemplateCategory
  }, [reportTemplateCategoryService, selectedTemplate.id]))

  const editorInitConfig = (category) => {
    if (!selectedTemplate) {
      return {}
    }
  
    const onStylesFormatterToggled = async should_apply_styles => {
      const updatedReportTemplateCategory = await updateReportTemplateCagegory({ ...category, should_apply_styles })
      return updatedReportTemplateCategory.should_apply_styles
    }

    return getReportTemplateOptions(
      selectedTemplate,
      allFields,
      EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS,
      {
        onStylesFormatterToggled,
        ...getCommonEditorModulesSettings(category)
      }
    )
  }



  if(!selectedTemplate) return <Loading />

  return (
    <>
      <CustomiseStaffingCostMethodologyModal
        handleFinancialAnalysisMethodologyChange={handleFinancialAnalysisMethodologyChange}
        template={selectedTemplate}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    <Card>
      <Card.Header className="bg-gradient-primary text-white">
        <ReportTemplateTitle customTitle={customTitle}/>
      </Card.Header>
      <Card.Body>
          <Form onSubmit={save}>
            <SaveButton variant="primary" loading={loading} className="float-right" text={t('save_report_template_close')}  isImmutableUnderReview={true}/>
            <Button
              onClick={handleBack}
              variant="light"
              loading={loading}
              icon='arrow_left'
              iconPosition={'left'}
              className="float-right mx-2">
                {t('back')}
            </Button>
            <Form.Group as={Row}>
              <Col sm={3}>
                <Form.Label className="col-form-label">
                  {process.env.PUBLIC_URL === '/it'? `${customTitle} Name` : 'Report Template Name'}
                </Form.Label>
              </Col>
              <Col sm={3}>
                <ImmutableUnderReviewFormControl
                  type="text"
                  value={selectedTemplate.name}
                  name="name"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            {selectedTemplate.report_template_categories &&
              selectedTemplate.report_template_categories.map(
                (category, index) => {
                  return (
                    <React.Fragment key={category.title}>
                      <Form.Group as={Row} className="pt-4">
                        <Col>
                          <Card>
                            <Card.Header className="bg-secondary">
                              {process.env.PUBLIC_URL !== '/it' && 'Category ' + (index + 1) + ':'} <strong>{t( makeKey(category.title) )}</strong>
                                <ImmutableUnderReviewFormCheck
                                  className="float-right ml-3"
                                  type="checkbox"
                                  name="exclude_from_report"
                                  checked={category.exclude_from_report}
                                  value={category.exclude_from_report}
                                  onChange={(e) => handleCategoryChange(e, index)}
                                  label={t('exclude_from_report')}
                                />
                                {category.title === 'Financial Analysis' && (
                                  <BootstrapButton
                                    onClick={() => setShowModal(true)}
                                    size="md"
                                    variant="info"
                                    className="float-right mx-4"
                                  >
                                    <span className='material-icons md-18'>edit</span> Customise
                                    Report
                                  </BootstrapButton>
                                )}
                              </Card.Header>

                              <ImmutableUnderReviewFormControl
                                as={Editor}
                                apiKey={apiKey}
                                onEditorChange={(e) => handleTinyMce(e, index)}
                                value={category.content}
                                name="content"
                                init={editorInitConfig(category)}
                              />
                            </Card>
                          </Col>
                        </Form.Group>
                      </React.Fragment>
                    );
                  }
                )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default ReportTemplateForm
