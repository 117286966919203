import ErrorHandler from '@components/util/ErrorHandler';
import oneTimeLinkUploadsService from '@services/claim/one_time_link_uploads.service';
import React, { useState, useEffect, useRef } from 'react'
import { Container, Card, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import logo from '@assets/images/logo.png';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import CostTemplateErrorsModal from '@components/modals/CostTemplateErrorsModal';
import { Table } from '@rd-web-markets/shared/dist/util';
import { Loading } from '@rd-web-markets/shared/dist/util';

const CostTemplateUploadPage = () => {
  const { import_id, link_id } = useParams();
  const [oneTimeLink, setOneTimeLink] = useState(null);
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const oneTimeLink = await oneTimeLinkUploadsService.get(import_id, link_id);
        setOneTimeLink(oneTimeLink);
      } catch(error) { 
        dispatch(handleError(error))
      }
    };
    getData();
  }, [link_id, dispatch]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImportCostTemplate = async (event) => {
    let fd = new FormData();
    fd.append('file', event.target.files[0]);
    setLoading(true);
    try {
      const response = await oneTimeLinkUploadsService.update(import_id, oneTimeLink.url, fd);
      setOneTimeLink({...response});
    } catch (error) {
      dispatch(handleError(error));
    }
    setLoading(false);
    hiddenFileInput.current.value = '';
  };

  const headers = [ 
    { text: 'Sheet' }, 
    { text: 'Error' }, 
    { text: 'Line' } 
  ];

  const rows = () => {
    return oneTimeLink.claim_import_cost_template_info.import_messages.map((error) => ({
      columns: [error.sheet_name, error.message, error.line],
    }));
  }

  const modalBody = () => {
    return <Table headers={headers} rows={rows} showControls={false} />;
  }

  const renderStatus = () => {
    switch(oneTimeLink.one_time_uploadable.import_status){
      case 'never_imported':
        <div className="text-center">
          Never Imported
        </div>
      case 'imported_unsuccessfully':
        return (
          <div className="text-center">
            There were errors importing the file.  
            <Button style={{padding: 0, margin: 0}} variant="link" onClick={() => setModalShow(true)}>
              You can check them here
            </Button>
          </div>
        );
      case 'imported_successfully':
        return (
          <div className="text-center">
            Imported Successfully
          </div>
        )
      default:
        return (
          <div></div>
        )
    }
  }
  if (!oneTimeLink) return <Loading />
  return (
    <>
      <CostTemplateErrorsModal
        title='Import Errors'
        body={modalBody()}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
      <ErrorHandler alertsContainerId='alerts-container' alertClassName='w-100 mw-100 mb-3'>
        <Container>
          <Row className="justify-content-center">
            <img src={logo} alt="Logo" className="m-5" />
          </Row>
          <Row className="justify-content-center">
            <Col xs={6}>
              <Card>
                <Card.Header>
                  <span>
                    { oneTimeLink.used_at ? 'You have uploaded your Cost Template ' : 'Upload Cost Template ' } 
                    {oneTimeLink.one_time_uploadable.claim.name}
                  </span>
                </Card.Header>
                <Card.Body>
                  <div id="alerts-container"></div>
                  <Col md={12} className="text-center">
                    <Button
                      disabled={oneTimeLink.used_at}
                      variant="info"
                      iconPosition="left"
                      size="lg"
                      icon="upload_file"
                      className="mb-4 block"
                      loading={loading}
                      onClick={handleClick}>
                        Upload Cost Template
                    </Button>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      ref={hiddenFileInput}
                      onChange={(e) => handleImportCostTemplate(e)}
                    ></input>
                    {renderStatus()}
                  </Col>

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </ErrorHandler>
    </>
  )
}

export default CostTemplateUploadPage
