import ReportTemplateList from '@components/admin/reportTemplates/ReportTemplateList';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import React, { useState } from 'react';
import { Row, Col, Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ClaimGroupReportTemplatesControls = ({ claimGroup, loading, handleDeleteReportTemplate }) => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const { search } = useLocation();
  const { t } = useTranslation();
  const isReportSaved = new URLSearchParams(search).get('report_saved') // for Italy
  
  const renderItalyAddReportButtons = (
    <>
      {!claimGroup.report_template_inexpert_declaration_dossier && !claimGroup.report_template_expert_declaration_dossier &&(
        <Row className="mt-2">
          <Col md={{ span: 2, offset: 10 }}>
            <Button
              isImmutableUnderReview={true}
              variant='primary'
              as={Link}
              to={{
                pathname: `/${user.account_type}/claim_groups/${claimGroup.id}/report_templates/create`,
                search: '?template_type=inexpert_declaration_dossier',
              }}
            >
              <span className="material-icons md-18">add</span> {t('add_self_declaration_dossier_report')}
            </Button>
          </Col>
        </Row>
      )}
      
      {!claimGroup.report_template_expert_declaration_dossier && !claimGroup.report_template_inexpert_declaration_dossier && (
        <Row className="mt-2">
          <Col md={{ span: 2, offset: 10 }}>
            <Button
              isImmutableUnderReview={true}
              variant='primary'
              as={Link}
              to={{
                pathname: `/${user.account_type}/claim_groups/${claimGroup.id}/report_templates/create`,
                search: '?template_type=expert_declaration_dossier',
              }}
            >
              <span className="material-icons md-18">add</span> {t('add_technical_expert_dossier_report')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );

  const renderUKAddReportButtons = (
    <>
      {!claimGroup.report_template_rnd && (
        <Row>
        <Col md={{ span: 2, offset: 10 }}>
          <Button
            isImmutableUnderReview={true}
            variant='primary'
            as={Link}
            to={{
              pathname: `/${user.account_type}/claim_groups/${claimGroup.id}/report_templates/create`,
              search:'?template_type=rnd',
            }}
          >
            <span className="material-icons md-18">add</span> Add Report Template
          </Button>
        </Col>
      </Row>)}

      {!claimGroup.report_template_AIF && (
        <Row>
          <Col md={{ span: 2, offset: 10 }}>
            <Button
              isImmutableUnderReview={true}
              variant='primary'
              as={Link}
              className='mt-2'
              to={{
                pathname: `/${user.account_type}/claim_groups/${claimGroup.id}/report_templates/create`,
                search: '?template_type=aif',
              }}
            >
              <span className="material-icons md-18">add</span> Add AIF Report Template
            </Button>
          </Col>
        </Row>
      )}
    </>
  );

  const renderCrossMarketAddReportButtons = !claimGroup.report_template && (
    <Row>
      <Col md={{ span: 2, offset: 10 }}>
        <Button
          isImmutableUnderReview={true}
          variant='primary'
          as={Link}
          to={{
            pathname: `/${user.account_type}/claim_groups/${claimGroup.id}/report_templates/create`,
            search:`${process.env.PUBLIC_URL === '/it' ? '?template_type=technical' : ''}`,
          }}
        >
          <span className="material-icons md-18">add</span> Add Report Template
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      {
        //TODO: Create a function and use constants
      }
      {process.env.PUBLIC_URL === '/it' 
        ? renderItalyAddReportButtons 
          : process.env.PUBLIC_URL === '/uk'
            ? renderUKAddReportButtons
            : renderCrossMarketAddReportButtons}
      <Row className='pt-3'>
        <Col>
          {claimGroup.report_template && (
            <ReportTemplateList
              claimGroup={claimGroup}
              loading={loading}
              reportTemplates={[claimGroup.report_template]}
              handleDelete={handleDeleteReportTemplate}
            />
          )}
          {claimGroup.report_template_rnd && ( //UK
              <ReportTemplateList
                claimGroup={claimGroup}
                loading={loading}
                reportTemplates={[claimGroup.report_template_rnd]}
                handleDelete={handleDeleteReportTemplate}
                searchParams={'?template_type=rnd'}
              />
            )}
          {claimGroup.report_template_AIF && ( //UK
              <ReportTemplateList
                claimGroup={claimGroup}
                loading={loading}
                reportTemplates={[claimGroup.report_template_AIF]}
                handleDelete={handleDeleteReportTemplate}
                searchParams={'?template_type=aif'}
              />
            )}
          {claimGroup.report_template_project_specific && ( //Italy
            <ReportTemplateList
              claimGroup={claimGroup}
              loading={loading}
              reportTemplates={[claimGroup.report_template_project_specific]}
              handleDelete={handleDeleteReportTemplate}
              searchParams='?template_type=technical'
            />
          )}
          {claimGroup.report_template_inexpert_declaration_dossier && ( //Italy
            <ReportTemplateList
              claimGroup={claimGroup}
              loading={loading}
              reportTemplates={[claimGroup.report_template_inexpert_declaration_dossier]}
              handleDelete={handleDeleteReportTemplate}
              searchParams='?template_type=inexpert_declaration_dossier'
            />
          )}
          {claimGroup.report_template_expert_declaration_dossier && ( //Italy
            <ReportTemplateList
              claimGroup={claimGroup}
              loading={loading}
              reportTemplates={[claimGroup.report_template_expert_declaration_dossier]}
              handleDelete={handleDeleteReportTemplate}
              searchParams='?template_type=expert_declaration_dossier'
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ClaimGroupReportTemplatesControls;
