import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import MasterSidebar from '@components/util/MasterSidebar';
import { AddButton } from '@rd-web-markets/shared/dist/util/buttons';
import Faq from './Faq';
import faqService from '@rd-web-markets/shared/dist/services/faq/faq.service';

const FaqTemplatePage = () => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { faqs, addFaq, deleteFaq } = faqService.useCrud(setLoading);

  return (
    <>
      <MasterSidebar accountType={user.account_type} currentPage='faq_template' />

      <Card className='mt-3'>
        <Card.Header>{`${t('faq_page')}`}</Card.Header>

        <Card.Body>
          <Card style={{ maxWidth: '1050px' }}>
            <Card.Body className='d-flex flex-wrap justify-content-between'>

              {faqs.map((faq, index) => (
                <Faq data={faq} deleteFaq={deleteFaq} />
              ))}

              <div className='d-flex justify-content-end w-100'>
                <AddButton variant='info' disabled={false} onClick={() => addFaq()} text={t('add_new_faq')} />
              </div>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </>
  );
};

export default FaqTemplatePage;
