import { useCallback, useState, useEffect } from 'react';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const userAccessProjectParticipationsService = {
  useParticipations(reportId) {
    const [participations, setParticipations] = useState(null);
    const fetchParticipations = useErrorHandling(useCallback(async () => {
      const participations = await userAccessProjectParticipationsService.all(reportId);
      setParticipations(participations);
    }));
    useEffect(() => {
      fetchParticipations();
    }, [reportId]);
    return [participations, setParticipations];
  },
  useParticipationsByReportIds(reportIds) {
    const [participations, setParticipations] = useState([]);
    const fetchParticipationsByIds = useErrorHandling(useCallback(async () => {
      const participations = await userAccessProjectParticipationsService.allByReportIds(reportIds);
      setParticipations(participations.filter(participation => {
        var _participation$user_a;
        return (_participation$user_a = participation.user_access) === null || _participation$user_a === void 0 ? void 0 : _participation$user_a.included_in_engagement_team;
      }).map(participation => ({
        value: participation.user_access.user.id,
        label: participation.user_access.user.name,
        reportId: participation.claim_project_report_id
      })));
    }, []));
    useEffect(() => {
      fetchParticipationsByIds();
    }, [fetchParticipationsByIds]);
    return participations;
  },
  useDeleteParticipation(reportId, setParticipations) {
    return useErrorHandling(useCallback(async (participation, participations) => {
      await userAccessProjectParticipationsService.delete(reportId, participation.id);
      const updatedParticipations = participations.filter(p => p.id !== participation.id);
      setParticipations([...updatedParticipations]);
    }, []));
  },
  useDeleteUserAccessProjectParticipationFromClaimGroup(claimGroup, setClaimGroup, setLoading) {
    return useErrorHandling(useCallback(async (selectedUserAccess, participation, reportId) => {
      setLoading(true);
      await userAccessProjectParticipationsService.delete(reportId, participation.id);
      const userAccessInClaimGroup = claimGroup.user_accesses.find(userAccess => userAccess.id === selectedUserAccess.id);
      userAccessInClaimGroup.user_access_project_participations = userAccessInClaimGroup.user_access_project_participations.filter(p => p.id !== participation.id);
      setClaimGroup({
        ...claimGroup
      });
    }, [claimGroup, setClaimGroup, setLoading]), useCallback(() => {
      setLoading(false);
    }, [setLoading]));
  },
  useSubmitUserAccessProjectParticipation(reportId, setParticipations, setSelectedUserAccess) {
    return useErrorHandling(useCallback(async (selectedUserAccess, participations) => {
      const newParticipation = await userAccessProjectParticipationsService.create(reportId, {
        user_access_id: selectedUserAccess.user_id
      });
      setParticipations([...participations, newParticipation]);
      setSelectedUserAccess(null);
    }, [reportId, setParticipations, setSelectedUserAccess]));
  },
  useCreateUserAccessProjectParticipationFromClaimGroup(claimGroup, setClaimGroup, setLoading) {
    return useErrorHandling(useCallback(async (selectedUserAccess, reportId) => {
      setLoading(true);
      const newParticipation = await userAccessProjectParticipationsService.create(reportId, {
        user_access_id: selectedUserAccess.id
      });
      const userAccessInClaimGroup = claimGroup.user_accesses.find(userAccess => userAccess.id === selectedUserAccess.id);
      userAccessInClaimGroup.user_access_project_participations.push(newParticipation);
      setClaimGroup({
        ...claimGroup
      });
      return userAccessInClaimGroup.user_access_project_participations;
    }, [claimGroup, setClaimGroup, setLoading]), useCallback(() => {
      setLoading(false);
    }, [setLoading]));
  },
  all(reportId) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/user_access_project_participations"), request.get);
  },
  allByReportIds(reportIds) {
    const query = reportIds.map(reportId => "reportId[]=".concat(reportId)).join('&');
    return fetch("/api/claim_project_reports/user_access_projects_participations?".concat(query), request.get);
  },
  create(reportId, user_access_project_participation) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/user_access_project_participations/"), {
      ...request.post,
      body: JSON.stringify({
        user_access_project_participation
      })
    });
  },
  delete(reportId, participationId) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/user_access_project_participations/").concat(participationId), {
      ...request.delete
    });
  },
  update(reportId, participationId, user_access_project_participation) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/user_access_project_participations/").concat(participationId), {
      ...request.put,
      body: JSON.stringify({
        user_access_project_participation
      })
    });
  }
};
export default userAccessProjectParticipationsService;