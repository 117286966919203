import { Table } from 'react-bootstrap';
import React from 'react';
import ClaimItem from './ClaimItem';
import { useTranslation } from 'react-i18next';

const ClaimList = ({claims, loading, handleDeleteClaim, role}) => {
  const { t } = useTranslation();

  return (
    <Table hover>
      <thead>
        <tr className="text-primary app-fs-table-header">
          <th className="font-weight-normal">{t('claim')}</th>
        </tr>
      </thead>
      <tbody>
        {claims.map((claim) => {
          return <ClaimItem loading={loading} handleDeleteClaim={handleDeleteClaim} key={claim.id} claim={claim} role={role}/>;
        })}
      </tbody>
    </Table>
  );
}

export default ClaimList
