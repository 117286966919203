import { useCallback, useEffect, useState } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const api = buildService('/claims/{id}/exonerations');
const useFetchExonerations = (claimId, setLoading, exonerationType) => {
  const [exonerations, setExonerations] = useState([]);
  const fetchExonerations = useErrorHandling(useCallback(async () => {
    setLoading(true);
    const allExonerations = await api.all(claimId, {
      exoneration_type: exonerationType
    });
    setExonerations(allExonerations);
  }, [claimId, exonerationType, setLoading]), useCallback(() => {
    setLoading(false);
  }, [setLoading]));
  useEffect(() => {
    fetchExonerations();
  }, [fetchExonerations]);
  return [exonerations, setExonerations, fetchExonerations];
};
const useUpdateExoneration = _ref => {
  let {
    claimId,
    exonerationType,
    exonerations,
    setExonerations,
    setLoading
  } = _ref;
  const updateExoneration = useErrorHandling(useCallback(async exoneration => {
    setLoading(true);
    const updatedExoneration = await api.update(claimId, exoneration.id, exoneration, {
      exoneration_type: exonerationType
    });
    const indexInState = exonerations.findIndex(c => c.id === updatedExoneration.id);
    exonerations.splice(indexInState, 1, updatedExoneration);
    setExonerations([...exonerations]);
    setLoading(false);
  }, [claimId, exonerationType, exonerations, setExonerations, setLoading]), useCallback(() => {
    setLoading(false);
  }, [setLoading]));
  return updateExoneration;
};
const useCrud = _ref2 => {
  let {
    claimId,
    setLoading,
    exonerationType
  } = _ref2;
  const [exonerations, setExonerations, fetchAllExonerations] = useFetchExonerations(claimId, setLoading, exonerationType);
  const updateExoneration = useUpdateExoneration({
    claimId,
    exonerationType,
    exonerations,
    setExonerations,
    setLoading
  });
  return {
    fetchAllExonerations,
    updateExoneration,
    exonerations,
    setExonerations
  };
};
const exonerationsService = {
  useCrud
};
export default exonerationsService;