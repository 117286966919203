import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/authSlice';
import uiReducer from './features/uiSlice';
import alertReducer from './features/alertSlice';
import reviewReducer from './features/reviewSlice';
import claimProjectReportsReducer from './features/claimProjectReportsSlice';
import companiesReducer from './features/companiesSlice';
export default configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    alerts: alertReducer,
    review: reviewReducer,
    claimProjectReports: claimProjectReportsReducer,
    companies: companiesReducer
  }
});