import React from 'react';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import { useTranslation } from 'react-i18next';


export default function EmailSentStatus({ emailTemplate, ...props }) {
  const { t } = useTranslation();

  const renderStatus = () => {
    if (process.env.PUBLIC_URL === '/it') {
      return (
        <span>
          <span className="material-icons">mail</span> {t('email_sent_at')}
          {createTimezonedDate(new Date(emailTemplate.sent_at)).toCustomLocaleTimeString()} { emailTemplate.sent_by && ('by ' + emailTemplate.sent_by)}.
        </span>
      );
    } else {
      return (
        <span>
          <span className="material-icons">mail</span> {t('email_sent_at')}
          {createTimezonedDate(new Date(emailTemplate.sent_at)).toCustomLocaleTimeString()}.
        </span>
      );
    }
  };

  return (
    <div className='text-center' {...props}>
      {emailTemplate?.sent_at && renderStatus()}
    </div>
  );
}
