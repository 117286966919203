import React, { useState } from 'react'

import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import Toast from '@components/util/Toast';


/**
 * Not currently used since we migrated to keycloak. Agreed that this needs to be reimplemented in the future.
 * So I returned this file.
 * @param {*} param0 
 * @returns 
 */
export default function SendInvitationEmail({ user, onSend }) {
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const sendInviteEmail = async user => {
    try {
      setButtonLoading(true);
      await UserService.sendInviteEmail(user.id)
      setShowToast(true)
    } catch (error) {
      dispatch(handleError(error))
    } finally {
      setButtonLoading(false);
      onSend();
    }
  }
  
  let tooltipText = 'Re-send Invitation'
  if (!user.invited && !user.confirmed_at) {
    tooltipText = 'Invite'
  }

  return (
    <div>
      <Toast show={showToast} message={'Invitation sent!'} onClose={() => setShowToast(false)} />
      
      <Button
        showLoadingText={false}
        loading={buttonLoading}
        icon='send'
        tooltipText={tooltipText}
        variant="light"
        className="p-1 d-inline-flex rounded-circle text-info fs-1rem" 
        onClick={() => sendInviteEmail(user)}
      >
      </Button>
    </div>
  )
}
