import { SIBLING_DIRECTION_NEXT, SIBLING_DIRECTION_PREV, ZERO_WIDTH_NO_BREAK_SPACE, findAnyNodeOrElementSibling, findFirstNonEmptyTextNode, findLastNonEmptyTextNode, isEmptySelection, isTextNode } from "../../../domUtils";
import AdditionHighlight from "./models/AdditionHighlight";
import DeletionHighlight from "./models/DeletionHighlight";
import TrackChangesHighlight from "./models/TrackChangesHighlight";

/**
 * When we place the cursor next to an image the browser generates a selection object, 
 * where the focus/anchor nodes are the parent element(div) and the offset is the index of the image child node in the childNodes list.
 * 
 * When we click next to an image we get the above selection from the browser.
 * When we delete a text that has an inline image in it - then we dont get the same selection data. We use this
 * function to get the same selection data and make both cases consistent.
 * @param {*} imgNode 
 * @returns 
 */
function getSelectionTargetAndOffsetForImageDeletion(imgNode) {
  const parent = imgNode.parentElement;
  const targetOffset = Array.from(parent.childNodes).indexOf(imgNode) + 1;
  const targetNode = parent;
  return {
    targetNode,
    targetOffset
  };
}

/**
 * 
 * @param {*} cursorSelection 
 * @param {*} deletionKey | Either 'Backspace' or 'Delete'
 * @param {*} highlightType | Either 'addition' or 'deletion'
 */
function isUserDeletingAHighlightWithEmptySelection(cursorSelection, deletionKey, highlightType) {
  if (!isEmptySelection(cursorSelection)) {
    return false;
  }
  if (!['Delete', 'Backspace'].includes(deletionKey)) {
    return false;
  }
  if (![AdditionHighlight.highlightType, DeletionHighlight.highlightType].includes(highlightType)) {
    throw new Error('Unrecognized highlight type: ' + highlightType);
  }
  const checkNodeFn = cursorSelection => TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, highlightType);
  const checkPrevSiblingFn = node => TrackChangesHighlight.isSiblingAOrInAHighlight(node, SIBLING_DIRECTION_PREV, highlightType);
  const checkNextSiblingFn = node => TrackChangesHighlight.isSiblingAOrInAHighlight(node, SIBLING_DIRECTION_NEXT, highlightType);
  const checkSiblingFn = deletionKey === 'Backspace' ? checkPrevSiblingFn : checkNextSiblingFn;
  const offsetIsInsideAnchorNode =
  // deleting to the left, so offset = 1 means we are deleting char 0, offset 0 means we are deleting last char of previous word
  deletionKey === 'Backspace' && cursorSelection.anchorOffset > 0 ||
  // deleting to the right, so offset = length - 1 means we are deleting last char, offset = length means we are deleting first char of next word
  deletionKey === 'Delete' && cursorSelection.anchorOffset < cursorSelection.anchorNode.textContent.length;
  if (!offsetIsInsideAnchorNode && checkSiblingFn(cursorSelection.anchorNode)) {
    return true;
  } else if (cursorSelection.anchorNode.textContent === ZERO_WIDTH_NO_BREAK_SPACE && checkSiblingFn(cursorSelection.anchorNode)) {
    return true;
  } else if (offsetIsInsideAnchorNode && checkNodeFn(cursorSelection)) {
    return true;
  } else {
    return false;
  }
}
const selectionUtils = {
  /**
   * We check both the node and the parent element as tiny's selection nodes are sometimes text nodes, and sometimes the parent elements
   * @param {*} cursorSelection 
   * @param {*} node 
   * @returns 
   */
  getOffsetIfnodeIsFocusOrAnchor(cursorSelection, node) {
    if (cursorSelection.anchorNode.isSameNode(node) || cursorSelection.anchorNode.isSameNode(node.parentElement)) {
      return cursorSelection.anchorOffset;
    } else if (cursorSelection.focusNode.isSameNode(node) || cursorSelection.focusNode.isSameNode(node.parentElement)) {
      return cursorSelection.focusOffset;
    } else {
      return -1;
    }
  },
  /**
   * Returns a selection object whose anchor and focus nodes ands offsets point to a character that is on the left side of the cursor.
   * E.g. if we are at the start of a text node, we want to delete the final character of the left text node.
   * Therefore we set the anchor / focus nodes to the left text node.
   * 
   * If isDeletingBackwards is true, that means we pressed Backspace and we want to delete the character on the left.
   * If isDeletingBackwards is false, that means we pressed Delete and we want to delete the character on the right.
   * When we handle the case where we are deleting using the Delete key, we want to put the cursor on the right side of
   * the character that we are trying to delete. After that we are once again deleting the character on the left side.
   * The difference with Delete deletion and Backspace deletion is that after the deletion, with Delete - the cursor moves forward,
   * while with Backspace - it moves backwards.
   * @param {Selection} cursorSelection 
   */
  createSelectionObjectForDeletion(cursorSelection, isDeletingBackwards) {
    // The selection data seems to be read-only, so we need to copy its data and increase the indexes by one manually.
    // We do this, so that we can use the common delete functionality regardless of whether we are pressing Delete or Backspace.
    // We only need the nodes and the offsets, the rest of the selection data is not needed.
    let targetNode = cursorSelection.anchorNode;
    let targetOffset = isDeletingBackwards ? cursorSelection.anchorOffset : cursorSelection.anchorOffset + 1;
    const isDeletingBackwardsAndAtTheStartOfTheLine = isDeletingBackwards && targetOffset === 0;
    const isDeletinfForwardsAndAfterTheEndOfTheLine = !isDeletingBackwards && targetOffset > targetNode.textContent.length;
    if (isDeletingBackwardsAndAtTheStartOfTheLine || isDeletinfForwardsAndAfterTheEndOfTheLine) {
      // Since we are deleting the right character, if it is in a different node, then in fact we are deleting the first character
      // of the node - on the left of index 1.
      targetNode = findAnyNodeOrElementSibling(targetNode, isDeletingBackwards ? 'previous' : 'next');
      if (targetNode.nodeName === 'IMG') {
        ;
        ({
          targetOffset,
          targetNode
        } = getSelectionTargetAndOffsetForImageDeletion(targetNode));
      } else if (!isTextNode(targetNode) && targetNode.childNodes[targetNode.childNodes.length - 1].nodeName === 'IMG' && targetOffset === 0 && isDeletingBackwards) {
        // if the cursor is at position 0 on the line, and the previous line is a div that has an image !!! and we are backspacing !!!
        ;
        ({
          targetOffset,
          targetNode
        } = getSelectionTargetAndOffsetForImageDeletion(targetNode.childNodes[targetNode.childNodes.length - 1]));
      } else {
        const nonEmptyTextNode = isDeletingBackwards ? findLastNonEmptyTextNode(targetNode) : findFirstNonEmptyTextNode(targetNode);
        targetNode = isTextNode(targetNode) ? targetNode : nonEmptyTextNode;
        targetOffset = isDeletingBackwards ? targetNode.textContent.length : 1;
      }
    }
    const cursorSelectionCopy = {
      anchorNode: targetNode,
      anchorOffset: targetOffset,
      focusNode: targetNode,
      focusOffset: targetOffset
    };
    return {
      cursorSelectionCopy,
      targetNode
    };
  },
  isUserDeletingADeletionHighlightWithEmptySelection(cursorSelection, deletionKey) {
    return isUserDeletingAHighlightWithEmptySelection(cursorSelection, deletionKey, 'deletion');
  },
  isUserDeletingAnAdditionHighlightWithEmptySelection(cursorSelection, deletionKey) {
    return isUserDeletingAHighlightWithEmptySelection(cursorSelection, deletionKey, 'addition');
  }
};
export default selectionUtils;